import React, { useState } from "react";
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBCardTitle,
  MDBCol,
  MDBContainer,
  MDBRow,
  toast,
} from "mdbreact";
import Skema from "./components/generate-nim/Skema";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import BaseRequest from "../../../services/BaseRequest";
import { padStart } from "lodash";

const GenerateNimPage = () => {
  // nipSchema tipe datanya = hashmap, yang keynya = urutan, value nya = informasi
  // TODO: <Improvement> bikin fitur agar bisa merubah urutan dari suatu skema. Pakai react-draggable
  const [nipSchema, setNipSchema] = useState([
    {
      name: "gender",
      max_digit: 1,
      value: null,
      editable: false,
    },
    {
      name: "angkatan",
      max_digit: 1,
      value: null,
      editable: true,
    },
    {
      name: "tahun_angkatan",
      max_digit: 2,
      value: null,
      editable: true,
    },
    {
      name: "kode_kelas",
      max_digit: 2,
      value: null,
      editable: true,
    },
    {
      name: "nomor_urut",
      max_digit: 5,
      value: 1,
      editable: true,
    },
  ]);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const incompleteSchema = nipSchema.filter((v) => {
    return v.editable === true && v.value === null;
  }).length;

  const onChangeNipSchema = (index, value) => {
    const newSchema = [...nipSchema];
    newSchema[index] = value;
    setNipSchema(newSchema);
  };

  const onGenerateNim = () => {
    setShowConfirmDialog(true);
  };

  const generateNim = async () => {
    try {
      setSubmitLoading(true);

      const { data } = await BaseRequest.post("/pendaftaran/generate-nim", {
        schema: nipSchema,
      });

      toast.success(data.message, {
        closeButton: false,
        position: "bottom-right",
      });

      setShowConfirmDialog(false);
    } catch (error) {
      const errorMessage = error.response
        ? error.response.data.message
        : error.message;
      toast.error(errorMessage, {
        closeButton: false,
        position: "bottom-right",
      });
    } finally {
      setSubmitLoading(false);
    }
  };

  return (
    <MDBContainer fluid>
      <MDBRow>
        <MDBCol>
          <MDBRow className="py-2">
            <MDBCol>
              <Skema
                nipSchema={nipSchema}
                onChangeNipSchema={onChangeNipSchema}
              />
            </MDBCol>
          </MDBRow>
        </MDBCol>
        <MDBCol className="py-2">
          <MDBCard>
            <MDBCardBody>
              <MDBCardTitle>Preview</MDBCardTitle>
              <MDBCardText>
                Berikut contoh NIM yang akan di generate
              </MDBCardText>
              <Preview schema={nipSchema} />
              <MDBBtn
                block
                color="success"
                onClick={() => onGenerateNim()}
                disabled={incompleteSchema > 0}
                className="mt-2"
              >
                {incompleteSchema > 0
                  ? `Mohon isi (${incompleteSchema}) yang masih kosong`
                  : "Generate NIM"}
              </MDBBtn>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
      <ConfirmationDialog
        isOpen={showConfirmDialog}
        title="Konfirmasi"
        description="Anda akan menghasilkan NIM untuk peserta baru yang sudah melakukan verifikasi WA. Lanjutkan ?"
        isLoading={submitLoading}
        backdrop={!submitLoading}
        toggleFunc={() => setShowConfirmDialog(!showConfirmDialog)}
        onYesClick={generateNim}
      />
    </MDBContainer>
  );
};

const Preview = ({ schema }) => {
  const { previewTextStyle } = styles;

  const preview = schema.reduce((res, v) => {
    let val;
    if (v.name === "gender") {
      val = Math.floor(Math.random() * (3 - 1) + 1);
    } else if (v.name === "nomor_urut") {
      val = padStart(v.value, v.max_digit, '0');
    } else if (!v.editable) {
      if (!v.max_digit) {
        val = "1";
      } else {
        val = Math.floor(Math.random() * 1000)
          .toString()
          .padStart(v.max_digit, "0");
      }
    } else {
      if (!v.value) {
        val = "?";
      } else {
        val = v.value;
      }
    }
    return res + val;
  }, "");

  return (
    <div className="text-center" style={previewTextStyle}>
      {preview}
    </div>
  );
};

const styles = {
  previewTextStyle: {
    fontSize: 30,
    letterSpacing: 10,
  },
};

export default GenerateNimPage;
