import { debounce } from "lodash";
import {
  MDBCol,
  MDBContainer,
  MDBInput,
  MDBRow,
  MDBSelect,
  toast,
} from "mdbreact";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {default as CustomDataTable} from "../../components/DataTable";
import BaseRequest from "../../../services/BaseRequest";

const PesertaBaruPage = () => {
  const [pesertaArr, setPesertaArr] = useState([]);
  const [dtRowsData, setDtRowsData] = useState([]);
  const [ dtTotalRow, setDtTotalRow ] = useState(1);
  const [ dtPage, setDtPage ] = useState(1);
  const [ dtPerRow, setDtPerRow ] = useState(10);
  const [dtProgressPending, setDtProgressPending] = useState(false);
  const [dtFilterParams, setDtFilterParams] = useState({
    searchKeyword: "",
    gender: "",
    isVerificationWA: "",
  });

  const getPesertaBaru = useCallback(
    async (page = 1, perPage = 10) => {
      const { data } = await BaseRequest.post("/users", {
        page: page,
        limit: perPage,
        sortBy: "created_at",
        desc: true,
        search: "",
        field_search: "name",
        filter: {
          status: "inactive",
        },
      });

      setDtTotalRow(data.data.total);
      
      const mappedData = data.data.data.map((v, i) =>({
        ...v,
        rowNo: i+data.data.from,
        name: (v.name) ? v.name : "-",
        email: v.email,
        is_verification_wa: v.is_verification_wa,
        domisili: (v.state_id === null) ? "-": ((v.state_id) ? v.state.name :v.city.name),
      }));

      return { rows: mappedData}
    },[]
  );

  const getData = useCallback((page = 1, perPage = 10) => {
    try {
      setDtProgressPending(true);
      getPesertaBaru(page, perPage).then((res) => {
        const { rows } = res;
        setPesertaArr(rows);
        setDtRowsData(rows);
      });
      
    } catch (error) {
      const errorMessage = error.response
        ? error.response.data.message
        : error.message;
      toast.error(errorMessage, {
        closeButton: false,
        position: "bottom-right",
      });
    } finally {
      setDtProgressPending(false);
    }
  }, [getPesertaBaru]);

  /* Effect untuk get Data */
  useEffect(() => {
    getData(dtPage, dtPerRow);
  }, [getData, dtPage, dtPerRow]);

  const handlePageChange = (page) => {
    setDtPage(page)
  }

  const handlePerRowsChange = (perRow) => {
    setDtPerRow(perRow)
  }

  /** Effect untuk handle filtering data table */
  useEffect(() => {
    const handleFilter = () => {
      const { searchKeyword, gender, isVerificationWA } = dtFilterParams;

      let filtered = [...pesertaArr]; // untuk menghindari mutation

      if (searchKeyword !== "") {
        const lcKeyword = searchKeyword.toLowerCase();

        filtered = filtered.filter((v) => {
          return (
            v.name.toLowerCase().includes(lcKeyword) ||
            v.email.toLowerCase().includes(lcKeyword) ||
            v.domisili.toLowerCase().includes(lcKeyword)
          );
        });
      }

      if (gender !== "") {
        filtered = filtered.filter((v) => v.gender === gender);
      }

      if (isVerificationWA !== "") {
        const isVerified = isVerificationWA === "1";
        filtered = filtered.filter((v) => v.is_verification_wa === isVerified);
      }

      setDtRowsData(filtered);
    };

    handleFilter();
  }, [dtFilterParams, pesertaArr]);

  /** Datatable Filter **/
  const subHeaderComponentMemo = useMemo(() => {
    const setSearchKeyword = debounce((keyword) => {
      setDtFilterParams({ ...dtFilterParams, searchKeyword: keyword });
    }, 1000);

    return (
      <MDBRow className={"w-100"}>
        <MDBCol size={"12"} md={"4"} sm={"12"}>
          <MDBInput
            id="cari"
            label="Cari"
            group
            outline
            onChange={(e) => setSearchKeyword(e.target.value)}
          ></MDBInput>
        </MDBCol>
        <MDBCol size={"12"} md={"4"} sm={"12"}>
          <MDBSelect
            options={genderOptions}
            selected=""
            label="Filter berdasarkan gender"
            outline
            getValue={(v) =>
              setDtFilterParams({ ...dtFilterParams, gender: v[0] })
            }
          />
        </MDBCol>
        <MDBCol size={"12"} md={"4"} sm={"12"}>
          <MDBSelect
            options={verificationWAOptions}
            selected=""
            label="Sudah verifikasi WA / belum"
            outline
            getValue={(v) =>
              setDtFilterParams({ ...dtFilterParams, isVerificationWA: v[0] })
            }
          />
        </MDBCol>
      </MDBRow>
    );
  }, [dtFilterParams]);

  return (
    <MDBContainer fluid>
      <h3>Peserta Baru</h3>

      <CustomDataTable
        columns={columns}
        data={dtRowsData}
        progressPending={dtProgressPending}
        subHeaderComponent={subHeaderComponentMemo}
        totalRow={dtTotalRow}
        handlePageChange={handlePageChange}
        handlePerRowsChange={handlePerRowsChange}
      />
    </MDBContainer>
  );
};

/** Datatable column definition **/
const columns = [
  {
    name: "No",
    selector: "rowNo",
    width: "4rem",
    right: true,
  },
  {
    name: "Nama",
    selector: "name",
    sortable: true,
  },
  {
    name: "Email",
    selector: "email",
  },
  {
    name: "Domisili",
    selector: "domisili",
  },
  {
    name: "Sudah Aktivasi WA ?",
    selector: "is_verification_wa",
    format: (row, index) => (row.is_verification_wa ? "Sudah" : "Belum"),
  },
];

const genderOptions = [
  {
    text: "Semua",
    value: "",
    checked: true,
  },
  {
    text: "Ikhwan",
    value: "ikhwan",
  },
  {
    text: "Akhwat",
    value: "akhwat",
  },
];

const verificationWAOptions = [
  {
    text: "Semua",
    value: "",
    checked: true,
  },
  {
    text: "Sudah",
    value: "1",
  },
  {
    text: "Belum",
    value: "0",
  },
];

export default PesertaBaruPage;
