import React from "react";
import {MDBBtn, MDBIcon, MDBModal, MDBModalBody, MDBModalFooter, MDBModalHeader, MDBSpinner} from "mdbreact";

const WarningModal = (props) => {
  const {
    isOpen,
    toggle,
    handler,
    loadingIndicator,
    title,
    text
  } = props;

  return (
    <MDBModal
      isOpen={isOpen}
      toggle={toggle}
      backdrop={false}
      cascading
    >
      <MDBModalHeader
        toggle={toggle}
        titleClass="d-inline title"
        className="text-center yellow darken-3 white-text"
      >
        <MDBIcon icon="exclamation-triangle" className={"pr-2"}/>
        {title}
      </MDBModalHeader>
      <MDBModalBody style={{whiteSpace: 'pre-wrap'}}>{text}</MDBModalBody>
      <MDBModalFooter>
        {
          loadingIndicator ?
          <MDBSpinner /> :
          <>
            <MDBBtn color="secondary" onClick={toggle}>
              Tidak
            </MDBBtn>
            <MDBBtn color="primary" onClick={handler}>
              Iya
            </MDBBtn>
          </>
        }
      </MDBModalFooter>
    </MDBModal>
  );
};

export default WarningModal;
