import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import BaseRequest from "../../../services/BaseRequest";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBTable,
  MDBTableBody,
  MDBBadge,
  MDBTableHead,
} from "mdbreact";
import moment from 'moment';

const ParticipantLogPage = () => {
  const { id } = useParams(); // Jika id !== undefined, maka ini adalah fitur edit data

  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      const getDataById = async () => {
        try {
          setLoading(true);
          const {
            data: { data }
          } = await BaseRequest.get(`/user-log/${id}`);
          setLogs(data);
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      };
      getDataById();
    }
  }, [id]);

  return (
    <MDBContainer fluid>
      <MDBRow center>
        <MDBCol size="12" md="12" sm="12">
          <MDBBtn
            onClick={() => {
              window.history.back();
            }}
            size="md"
            color="red"
            className="pull-right"
          >
            Kembali
          </MDBBtn>
          <h3 className="mt-3">User Log (6 bulan terakhir)</h3>
          <MDBRow>
            <MDBCol>
              <MDBTable striped className="mt-2">
                <MDBTableHead>
                  <tr>
                    <th>Waktu</th>
                    <th>Aktifitas</th>
                    <th>Jenis</th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  {loading && <tr><td colspan="3">Loading... mohon menunggu</td></tr>}
                  {!loading && logs.length == 0 && <tr><td colspan="3">Log tidak ditemukan</td></tr>}
                  {!loading && logs.map((log) => {
                    let content = "n/a";
                    if (log.quizzes?.id || log.courseparts?.id) {
                      // ini quiz
                      content = (
                        <div>
                          <a style={{ color: "#007bff", fontSize: 16 }} target="_blank"
                            href={log.quizzes?.title_id ? `/kurikulum/ujian-evaluasi/kuis/${log.quizzes?.id}/edit`: undefined}>
                            {log.quizzes?.title_id || "Quiz tidak ditemukan"} ({log.quizzes?.category || "?"})
                          </a>
                          <br /><small>{log.courseparts?.title_id}</small>
                        </div>
                      );
                    } else {
                      // ini materi
                      content = <div>
                        <a style={{ fontSize: 16 }}>{log.course_material_source?.course_material?.title_id}</a>
                        <br/><small>{log.course_material_source?.course_material?.author}</small>
                      </div>
                    }
                    return (
                      <tr>
                        <td>
                          <span>{moment(log.created_at).format("D MMM YYYY HH:mm")}</span>
                          <br/><small>{moment(log.created_at).fromNow()}</small>
                        </td>
                        <td>{content}</td>
                        <td>
                          {log.quizzes?.id || log.courseparts?.id ? <MDBBadge color="orange">Buka Quiz</MDBBadge> 
                            : <MDBBadge>Akses Materi</MDBBadge>
                          }
                        </td>
                      </tr>
                    );
                  })}
                </MDBTableBody>
              </MDBTable>
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}

export default ParticipantLogPage;