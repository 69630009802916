import React, {useCallback, useEffect, useMemo, useState} from "react";
import moment from "moment";
import BaseRequest from "../../../../services/BaseRequest";
import {useHistory} from "react-router-dom";
import {
  MDBContainer,
  MDBBtn,
  MDBIcon,
  MDBBtnFixed,
  toast,
  MDBRow,
  MDBCol
} from "mdbreact";
import {default as CustomDataTable} from "../../../components/DataTable";
import DeleteModal from "../../../modals/DeleteModal";
import TextField from "@material-ui/core/TextField";
import {getGenderOptions, getStatusOptions} from "../../configs/global";

const thisUri = "/kelas/admin-groups";
const apiUri = "/user-admin-group";

const AdminGroupPage = () => {
  const history = useHistory();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedDeleteItem, setSelectedDeleteItem] = useState({});
  const [deleteLoading, setDeleteLoading] = useState(false);

  /** Datatable variables **/
  const [ dtRowsData, setDtRowsData ] = useState([]);
  const [ dtSortBy, setDtSortBy ] = useState('created_at');
  const [ dtSortDirection, setDtSortDirection ] = useState('desc');
  const [ dtTotalRow, setDtTotalRow ] = useState(1);
  const [ dtPage, setDtPage ] = useState(1);
  const [ dtPerPage, setDtPerPage ] = useState(10);
  const [ dtSearchMeta, setDtSearchMeta ] = useState({
    filter: {},
    field_search: 'name, email, nip, gender',
    search: ''
  });
  const [ loadProgressData, setLoadProgressData ] = useState(true);

  /** Datatable column definition **/
  const columns = [
    {
      name: 'No',
      selector: 'rowNo',
      width: "4rem",
      right: true
    },
    {
      name: "Nama",
      selector: "name",
      sortable: true,
      style: {
        whiteSpace: 'none',
      }
    },
    {
      name: "NIP",
      selector: "nip",
      sortable: true,
      style: {
        whiteSpace: 'none',
      }
    },
    {
      cell: row => (<a href={`mailto:${row.email}`}>{row.email}</a>),
      name: "Email",
      selector: "email",
      sortable: true,
    },
    {
      cell: row => (`${row.phone_country_code} ${row.phone}`),
      name: "No. Telepon",
      selector: "phone",
      sortable: true,
      width: "10rem"
    },
    {
      name: "Gender",
      selector: "gender",
      sortable: true,
      width: "6rem"
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
      width: "6rem"
    },
    {
      name: "Dibuat Tanggal",
      selector: "created_at",
      sortable: true,
      width: "8rem"
    },
    {
      name: "Disunting Tanggal",
      selector: "updated_at",
      sortable: true,
      width: "8rem"
    },
    {
      cell: (row) =>
        <div className="btn-group-sm">
          <MDBBtn variant="contained" color="yellow" onClick={() => history.push(`${thisUri}/${row.id}/edit`)} >
            <MDBIcon icon="pen-nib" />
          </MDBBtn>
          <MDBBtn variant="contained" color="red" onClick={() => toggleDeleteModal(row.id)}>
            <MDBIcon icon="trash" />
          </MDBBtn>
        </div>,
      button: true
    }
  ];

  /** Datatable get data **/
  const getAdminGroups = useCallback(async (page = 1, perPage = 10) => {
    let uri = `${apiUri}`;

    try {
      setLoadProgressData(true);

      const {
        data: { data }
      } = await BaseRequest.post(uri, {
        sortBy: dtSortBy,
        desc: dtSortDirection === 'desc',
        page: page,
        limit: perPage,
        ...dtSearchMeta
      });

      setDtTotalRow(data.total);

      // Map the questions data
      const mappedData = data.data.map((v, i) => ({
        ...v,
        rowNo: data.from + i,
        gender: getGenderOptions(v.gender)[0].text,
        status: getStatusOptions(v.status)[0].text,
        created_at: moment(v.created_at).format("DD/MM/YYYY"),
        updated_at: moment(v.updated_at).format("DD/MM/YYYY")
      }));

      return { rows: mappedData }
    } catch (error) {
      let errorMessage = error.response
        ? error.response.data.message
        : error.message;
      toast.error(errorMessage, {
        closeButton: false,
        position: "bottom-right"
      });
    }

    return { rows: [] }
  }, [dtSearchMeta, dtSortBy, dtSortDirection]);

  const reloadDataFromServer = useCallback((page = 1, perPage = 10) => {
    getAdminGroups(page, perPage)
      .then((res) => {
        const { rows } = res;

        setDtRowsData(rows);
      })
      .finally(() => {
        setLoadProgressData(false);
      });
  }, [getAdminGroups]);

  useEffect(() => {
    reloadDataFromServer(dtPage, dtPerPage);
  }, [reloadDataFromServer, dtPage, dtPerPage]);

  /** Datatable sorting **/
  const onSortHandler = (column, sortDirection) => {
    setDtSortBy(column.selector);
    setDtSortDirection(sortDirection);

    reloadDataFromServer(dtPage, dtPerPage);
  };

  /** Datatable Filter **/
  const subHeaderComponentMemo = useMemo(() => {
    const handleFilter = (keyword) => {
      setLoadProgressData(true);

      let newSearchMeta = dtSearchMeta;
      newSearchMeta.search = keyword;

      setDtSearchMeta(newSearchMeta);

      getAdminGroups(newSearchMeta)
        .then((res) => {
          const { rows } = res;

          setDtRowsData(rows);
          setLoadProgressData(false);
        })
    };

    return (
      <MDBRow className={"w-100"}>
        <MDBCol size={"12"} md={"4"} sm={"12"}>
          <TextField
            label="Search"
            onChange={(e) => handleFilter(e.target.value)}
            className={"w-100"}
            name="search"
          />
        </MDBCol>
      </MDBRow>
    )
  }, [dtSearchMeta, getAdminGroups]);

  const toggleDeleteModal = (id) => {
    setSelectedDeleteItem(id);
    setShowDeleteModal(!showDeleteModal);
  }

  const handleDelete = async () => {
    try {
      setDeleteLoading(true);

      await BaseRequest.delete(`${apiUri}/${selectedDeleteItem}`);
      reloadDataFromServer(dtPage, dtPerPage);
    } catch (err) {
      console.error(err);
    } finally {
      toggleDeleteModal();
      setDeleteLoading(false);
    }
  };

  /** Datatable Change Page handler **/
  const onPageChangeHandler = (page) => {
    setDtPage(page)
  }

  const onPerRowsChangeHandler = (perPage) => {
    setDtPerPage(perPage)
  }

  return (
    <MDBContainer fluid>
      <h3>Admin Group</h3>

      <CustomDataTable
        columns={columns}
        data={dtRowsData}
        totalRow={dtTotalRow}
        onSortHandler={onSortHandler}
        subHeaderComponent={subHeaderComponentMemo}
        progressPending={loadProgressData}
        handlePerRowsChange={onPerRowsChangeHandler}
        handlePageChange={onPageChangeHandler}
      />

      <DeleteModal
        isOpen={showDeleteModal}
        toggle={toggleDeleteModal}
        handler={handleDelete}
        loadingIndicator={deleteLoading}
      />

      <MDBBtnFixed
        floating
        color="blue"
        icon="plus"
        size="lg"
        style={{bottom: "55px", right: "24px"}}
        onClick={() => history.push(`${thisUri}/create`)}
        className="no-li"
      />

    </MDBContainer>
  );
};

export default AdminGroupPage;
