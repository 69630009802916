import React, { useEffect, useCallback } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import BaseRequest from "services/BaseRequest";
import { useHistory } from "react-router-dom";
import { MDBModal, MDBModalHeader, MDBModalBody, MDBContainer, MDBRow, toast, MDBCol, MDBBtn } from 'mdbreact';

const KuisRevisiModal = (props) => {
    const{
        isOpen,
        toggleEditModal,
        selectedQuizQuestionId,
    } = props;

    const history = useHistory();
    const { register, handleSubmit, control, setValue, getValues, watch } = useForm();
    const thisUri = '/kurikulum/ujian-evaluasi/revision';
    const { fields } = useFieldArray({
        control,
        name: "quiz_answer", // unique name for your Field Array
    });

    const getQuestionAndAnswers = useCallback(async(filterId) => {
        const data = await BaseRequest.post('/quiz-questions',{
            sortBy: 'created_at',
            desc: 'true',
            page: 1,
            limit: 50,
            filter: {
                id: filterId
            },
        });
        const objectQuestions = data.data.data.data[0];

        for(let objectQuestion in objectQuestions) {
            setValue(objectQuestion, objectQuestions[objectQuestion]);
        }
    },[]);

    useEffect(()=> {
        if(!isOpen) return;
        getQuestionAndAnswers(selectedQuizQuestionId); 
        
    }, [isOpen]);

    const handleCorrectAnswerChange = e => {
        setValue(e.target.name, e.target.checked);
    };

    const processSubmit = async(payload) => {
        try{
            let url = '/quiz-revision';
            let isAnrevised = watch('isAnswerRevised');
            let isQurevised = watch('isQuestionRevised');

            if(isAnrevised !== 'true') {
                delete payload.quiz_answer;
            }

            if(isQurevised !== 'true') {
                payload.question_str = getValues('question_id');
                payload.waived_flag = false;
            } else {
                payload.question_str = payload.question_id;
            }

            payload.question_id = payload.id;
          
            const result = await BaseRequest.post(url, payload);

            toast.success(result.data.message, {
                closeButton: false,
                position: "bottom-right"
            });
            
            setTimeout(() => history.push(thisUri), 2000);
            toggleEditModal();

        } catch(error) {
            console.log(error);
            toast.error("Error Dalam Proses Revisi", {
                closeButton: true,
                position: "bottom-right"
            });
        }
    };

    return(
        <MDBModal size="lg" isOpen={isOpen} toggle={toggleEditModal} backdrop={false}>
            <MDBModalHeader toggle={toggleEditModal}>Revisi Soal/Jawaban Ujian Aktif</MDBModalHeader>
            <MDBModalBody>
                <MDBContainer>
                <form onSubmit={handleSubmit(processSubmit)}>
                    <MDBRow>
                        <MDBCol size="6"> 
                            <select name="isQuestionRevised" ref={register} className="browser-default custom-select">
                                <option value=''>Apakah Ingin Melakukan Revisi Soal ?</option>
                                <option value={true}>Ya</option>
                                <option value={false}>Tidak</option>
                            </select>
                        </MDBCol>
                    </MDBRow>
                   <MDBRow>&nbsp;</MDBRow>
                    <MDBRow>
                        <MDBCol size="12">
                            { watch('isQuestionRevised') === 'true' ? 
                            <textarea rows={6} column={24} name="question_id" 
                                ref={register} className="form-control" 
                            /> :
                            <textarea rows={6} column={24} name="question_id" 
                                ref={register} className="form-control" 
                                disabled
                            />
                            }
                            <input name="id" ref={register} type="hidden" />
                            <input name="quiz_id" ref={register} type="hidden"/>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>      
                        <br/>   
                    </MDBRow>
                    <MDBRow>
                        <MDBCol size="6">
                        { watch('isQuestionRevised') === 'true' ? 
                            <select 
                                {...register("waived_flag", {
                                    setValueAs: (v) => Boolean(v)
                                })} 
                                className="browser-default custom-select">
                                <option value=''>Apakah Ingin Melakukan Anulir Soal ?</option>
                                <option value='true'>Ya</option>
                                <option value='false'>Tidak</option>
                            </select> :
                            <select name="waived_flag" className="browser-default custom-select" disabled>
                                <option value=''>Apakah Ingin Melakukan Anulir Soal ?</option>
                            </select>
                        }
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>      
                        <p tag="footer" mb={3} className="blockquote-footer">Proses Anulir, Akan Merubah Jawaban Peserta yang Sudah Tersimpan Menjadi <cite title="Source Title">Benar</cite></p>    
                    </MDBRow>
                    <MDBRow>
                        <MDBCol size="6">
                            <select name="isAnswerRevised" ref={register} className="browser-default custom-select">
                                <option value=''>Apakah Ingin Melakukan Revisi Jawaban ?</option>
                                <option value='true'>Ya</option>
                                <option value='false'>Tidak</option>
                            </select>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol size="12">
                            <label>List Jawaban :</label>
                            {fields.map((field, idx) => {
                                const isChecked = (field.is_correct_answer === 0) ? false:true;
                                return(
                                    <fieldset key={`quiz_answer_list_${field.id}`} name={`quiz_answer_list_${field.id}}`} 
                                        disabled={ watch('isAnswerRevised') === 'true'? false:true  } > 
                                    <MDBRow>                        
                                        <MDBCol size="12">
                                            <input type="text" 
                                                name={`quiz_answer.${idx}.answer_id`}
                                                defaultValue={`${field.answer_id}`}
                                                key={`answer_${field.answer_id}`}
                                                ref={register} 
                                                className="form-control form-control-md"
                                            />
                                            <input type="hidden" 
                                                name={`quiz_answer.${idx}.id`}
                                                defaultValue={`${field.id}`}
                                                key={`answer_hidden_${field.answer_id}`}
                                                ref={register}  
                                            />
                                        </MDBCol>
                                        <MDBCol size="12">
                                            <div className="custom-control custom-checkbox pl-4">
                                                {isChecked ?
                                                <input type="checkbox" 
                                                    className="custom-control-input"
                                                    name={`quiz_answer.${idx}.is_correct_answer`}
                                                    onChange={handleCorrectAnswerChange}
                                                    ref={register}
                                                    defaultChecked={`${isChecked}`}
                                                    key={`isanswer_${field.answer_id}`}
                                                    id={`quiz_answer.${idx}.is_correct_answer`}
                                                /> :
                                                <input type="checkbox" 
                                                    className="custom-control-input"
                                                    name={`quiz_answer.${idx}.is_correct_answer`}
                                                    onChange={handleCorrectAnswerChange}
                                                    ref={register}
                                                    key={`isanswer_${field.answer_id}`}
                                                    id={`quiz_answer.${idx}.is_correct_answer`}
                                                /> }
                                                <label className="custom-control-label small" htmlFor={`quiz_answer.${idx}.is_correct_answer`}>
                                                    Jawaban Benar
                                                </label>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    </fieldset>
                                );
                                })
                            }
                        </MDBCol>
                    </MDBRow>                   
                    <MDBBtn type="submit" color="blue">Simpan Revisi</MDBBtn>
                </form>
                </MDBContainer>
            </MDBModalBody>
        </MDBModal>
    );
}

export default KuisRevisiModal;