import { SET_MENU } from './type';

export const setMenu = (role) => dispatch => {
  let menu;

  // Nama 'super admin' disesuaikan dengan nama role di database.
  // Bisa juga pakai id role nya sih. Yang mau refactor, silahkan. Tapi di fitur login nya juga harus di refactor
  // Agar localstorage nya terisi role id dari si user
  switch (role) {
    case 'super admin':
      menu = require('./json/superAdmin.json');
      break;
    case 'admin kurikulum':
      menu = require('./json/adminKurikulum.json');
      break;
    case 'admin pusat':
      menu = require('./json/koordinatorAdminPusat.json');
      break;
    case 'admin group':
      menu = require('./json/adminGroupRombel.json');
      break;
    case 'tim printing syahadah':
      menu = require('./json/timPrintingSyahadah.json');
      break;
    case 'asatidz':
      menu = require('./json/asatidz.json');
      break;
    default:
      menu = [];
  }

  dispatch({
    type: SET_MENU,
    payload: menu
  });
};
