/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useState} from "react";
import BaseRequest from "../../../services/BaseRequest";
import { useHistory, useParams } from "react-router-dom";
import {
  MDBContainer, 
  MDBBtn,
  MDBIcon,
  toast,
  MDBRow
} from "mdbreact";
import {default as CustomDataTable} from "../../components/DataTable";


const apiUri = "/quizzes/get-from-quiz";
const thisUri = "/quiz-archives";

const QuizClassListPage = () => {
  const { quizId } = useParams();
  const history = useHistory();

  /** Datatable variables **/
  const [ dtRowsData, setDtRowsData ] = useState([]);
  const [ dtSortBy, setDtSortBy ] = useState('created_at');
  const [ dtSortDirection, setDtSortDirection ] = useState('desc');
  const [ dtTotalRow ] = useState(1);
  const [ dtPage ] = useState(1);
  const [ dtPerPage ] = useState(10);
  const [ dtSearchMeta ] = useState({
    filter: {},
    field_search: 'name, nip',
    search: ''
  });
  const [ loadProgressData, setLoadProgressData ] = useState(true);

  /** Datatable column definition **/
  const columns = [
    {
      name: 'No',
      selector: 'rowNo',
      width: "4rem",
      right: true
    },
    {
      name: "Nama",
      selector: "name",
    },
    {
      cell: (row) =>
        <div className="btn-group-sm">
          <MDBBtn variant="contained" title="Archive" color="blue"  onClick={() => history.push(`${thisUri}/${quizId}/${row.id}`)}>
            <MDBIcon icon="file-archive" />
          </MDBBtn>
        </div>,
      button: true,
      width: "8rem"
    }
  ];

  /** Datatable get data **/
  const getClassData = useCallback(async (page = 1, perPage = 10) => {
    let uri = `${apiUri}`;

    try {
      setLoadProgressData(true);

      const {
        data: { data }
      } = await BaseRequest.post(uri, {
        quiz_id: quizId,
        sortBy: dtSortBy,
        desc: dtSortDirection === 'desc',
        page: page,
        limit: perPage,
        ...dtSearchMeta
      });

      const listOfClass = data.ListOfClass;
      // Map the questions data
      const mappedData = listOfClass.map((v, i) => ({
        ...v,
        rowNo: i + 1,
        id: v.id,
        name: v.name,
      }));
      

      return { rows: mappedData }
    } catch (error) {
      let errorMessage = error.response
        ? error.response.data.message
        : error.message;
      toast.error(errorMessage, {
        closeButton: false,
        position: "bottom-right"
      });
    }

    return { rows: [] }
  }, [dtSearchMeta, dtSortBy, dtSortDirection]);

  const reloadClassDataFromServer = useCallback((page = 1, perPage = 10) => {
    getClassData(page, perPage)
      .then((res) => {
        const { rows } = res;

        setDtRowsData(rows);
      })
      .finally(() => {
        setLoadProgressData(false);
      });
  }, [getClassData]);

  useEffect(() => {
    reloadClassDataFromServer(dtPage, dtPerPage);
  }, [dtPage, dtPerPage]);

  /** Datatable sorting **/
  const onSortHandler = (column, sortDirection) => {
    setDtSortBy(column.selector);
    setDtSortDirection(sortDirection);

    reloadClassDataFromServer(dtPage, dtPerPage);
  };

  /** Datatable Change Page handler **/
  const onPageChangeHandler = (page) => {
    setLoadProgressData(true);
    reloadClassDataFromServer(page);
  }

  const onPerRowsChangeHandler = (perPage, page) => {
    setLoadProgressData(true);
    reloadClassDataFromServer(page, perPage);
  }

  return (
    <MDBContainer fluid>
      <MDBRow>
        <MDBBtn color="red" size="sm" className="float-left" onClick={() => history.push('/kurikulum/ujian-evaluasi/kuis')}>
          Kembali
        </MDBBtn>
      </MDBRow>
      <br/>
      <h3>Kuis Archive</h3>
      <div>
        <CustomDataTable
          columns={columns}
          data={dtRowsData}
          totalRow={dtTotalRow}
          onSortHandler={onSortHandler}
          // subHeaderComponent={subHeaderComponentMemo}
          progressPending={loadProgressData}
          handlePerRowsChange={onPerRowsChangeHandler}
          handlePageChange={onPageChangeHandler}
        />
      </div>
    </MDBContainer>
  );
};

export default QuizClassListPage;
