import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import {
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBInput,
  MDBSelect,
  MDBSpinner,
  toast
} from "mdbreact";
import InputError from "../../../components/InputError";
import { Link } from "react-router-dom";
import BaseRequest from "../../../../services/BaseRequest";


const thisUri = "/perkuliahan/skema-nilai";
const apiUri = "/score-types";

const formField = [
  { name: "course_id", rules: { required: true } },
  { name: "name", rules: { required: true } },
  { name: "description" }
];

const RenderButton = ({ submitLoading }) => {
  if (submitLoading) {
    return <MDBSpinner />;
  }
  return (
    <div>
      <Link to={thisUri}>
        <MDBBtn size="md" color="red" className="mx-0">
          Kembali
        </MDBBtn>
      </Link>
      <MDBBtn type="submit" color="green" size="md">
        Simpan
      </MDBBtn>
    </div>
  );
};

const CourseScoreFormPage = () => {
  const history = useHistory();
  const { id } = useParams(); // Jika id !== undefined, maka ini adalah fitur edit data
  const { register, handleSubmit, errors, setValue, watch } = useForm();
  const [submitLoading, setSubmitLoading] = useState(false);
  let [listCourse, setListCourse] = useState([{text:'',value:''}]);

  const getCourses = async () => {
    const uri = `/courses?sortBy=created_at&desc=true`
    const { data: { data } } = await BaseRequest.post(uri);
    return data.data.map((v, i) => ({
      text: v.title_id,
      value: v.id
    }));
  }

  const handleChange = e => {
    setValue(e.target.name, e.target.value);
  };

  const handleCourseChange = id => {
    setValue('course_id', id[0]);
  }

  let course_id = watch('course_id')

  useEffect(() => {
    for (let v of formField) {
      register({ name: v.name }, v.rules || null);
    }
  }, [register]);


  useEffect(() => {
    if (id) {
      const getDataById = async () => {
        try {
          const {
            data: { data }
          } = await BaseRequest.get(`${apiUri}/${id}/edit`);

          for (let key in data) {
            setValue(key, data[key] || "");
          }
        } catch (error) {
          console.log(error);
        }
      };
      getDataById();
    }
  }, [id, setValue]);

  useEffect(() => {
    if(course_id){
      getCourses()
      .then(res => {
        setListCourse(()=>(
          res.map((v, i) => ({
            ...v,
            checked: (course_id === v.value)? true :false
          }))
        ))
      })
    }else{
      if(!id){
        getCourses()
        .then(res => {
          setListCourse(res)
        })
      }
    }
  },[course_id, id]);

  const onSubmit = async payload => {

    try {
      setSubmitLoading(true);
      let res = null;

      if (id) {
        
        res = await BaseRequest.put(`${apiUri}/${id}`, payload);
        
      } else {
        res = await BaseRequest.post(`${apiUri}/save`, payload);
      }
      toast.success(res.data.message, {
        closeButton: false,
        position: "bottom-right"
      });
      setTimeout(() => history.push(thisUri), 2000);
    } catch (error) {
      let errorMessage = error.response
        ? error.response.data.message
        : error.message;
      toast.error(errorMessage, {
        closeButton: false,
        position: "bottom-right"
      });
      setSubmitLoading(false);
    }
  };

  return (
    <MDBContainer>
      <MDBRow center>
        <MDBCol size="12" md="9" sm="12">
          <MDBCard>
            <MDBCardBody>
              <MDBCardTitle>
                {id ? "Edit" : "Tambah"} Sub Pelajaran
              </MDBCardTitle>
              <hr />
              <form onSubmit={handleSubmit(onSubmit)}>
                <MDBContainer>
                  <MDBRow>
                    <MDBCol size="12">

                      <MDBSelect
                        className={errors.course_id && "is-invalid"}
                        name="course_id"
                        getValue={handleCourseChange}
                        options={listCourse}
                        selected="Pilihan Mata Kuliah"
                        label="Mata Kuliah"
                      />
                      {errors.course_id &&
                        errors.course_id.type === "required" && (
                          <span className="text-danger small">Tidak boleh kosong</span>
                        )}

                      <MDBInput
                        className={errors.name && "is-invalid"}
                        name="name"
                        onChange={handleChange}
                        type="text"
                        label="Nama Skema Penilaian"
                        value={watch("name")}
                      >
                        {errors.title_id &&
                          errors.title_id.type === "required" && (
                            <InputError text="Tidak boleh kosong" />
                          )}
                      </MDBInput>
                      <MDBInput
                        name="description"
                        onChange={handleChange}
                        type="text"
                        label="Deskripsi"
                        value={watch("description")}
                      />                      
                    </MDBCol>
                  </MDBRow>
                </MDBContainer>

                <div className="text-center mt-3 black-text">
                  <RenderButton submitLoading={submitLoading} />
                </div>
              </form>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default CourseScoreFormPage;
