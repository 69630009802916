import React, {useEffect, useMemo, useState} from "react";
import {
  MDBBtn,
  MDBCol,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBRow, MDBSelect,
  toast
} from "mdbreact";
import {default as CustomDataTable} from "../../../../components/DataTable";
import BaseRequest from "../../../../../services/BaseRequest";

const questionTypes = require('../../shared/json/question-types.json');
const questionTypeOptions = [
  { "text": "Semua tipe", "value": "", "checked": true },
].concat(questionTypes);

const SelectSoalModal = (props) => {
  const {
    isOpen,
    toggle,
    selectedSoal,
    setSelectedSoal,
    questionCategories,
    coursePart
  } = props;

  const [soalState, setSoalState] = useState(selectedSoal);
  const [selectedSoalNo, setSelectedSoalNo] = useState(0);

  /** Datatable variables **/
  const [ dtRowsData, setDtRowsData ] = useState([]);
  const [ dtSortBy, setDtSortBy ] = useState('question_id');
  const [ dtSortDirection, setDtSortDirection ] = useState('asc');
  const [ dtTotalRow, setDtTotalRow ] = useState(1);
  const [ dtSearchMeta, setDtSearchMeta ] = useState({
    filter: { course_part_id: coursePart.id },
    field_search: 'question_id, question_en, question_ar',
    search: ''
  });
  const [ loadProgressData, setLoadProgressData ] = useState(true);

  /* Datatable column definition */
  const columns = [
    {
      name: "Pertanyaan (ID)",
      selector: "question_id",
      style: {
        whiteSpace: 'none',
      },
      sortable: true
    },
    {
      name: "Tingkat Kesulitan",
      selector: "type",
      width: "6rem",
      sortable: true
    },
    {
      name: "Jenis Soal",
      selector: "question_type",
      width: "5rem",
      sortable: true
    },
    {
      cell: (row) =>
        <div className="btn-group-md">
          <MDBInput label=" " type="checkbox" id={`checkbox-${row.id}`} onChange={(e) => addRemoveSoal(e.target.checked, row)} checked={isChecked(row.id)} />
        </div>,
      button: true,
      width: "3rem",
    }
  ];

  const addRemoveSoal = (checked, data) => {
    let soal = soalState;

    if (checked) {
      soal = [...soal, { ...data, isNew: true }];
    } else {
      soal = soalState;
      soal = soal.filter((item) => item !== data.id);
    }

    setSoalState(soal);
    setSelectedSoalNo(selectedSoalNo + 1);
  }

  /** Load the questions and question's categories **/
  useEffect(() => {
    if (!isOpen) return;

    setLoadProgressData(true);
    setSoalState(selectedSoal);
    setDtSearchMeta({...dtSearchMeta, search: ''});

    reloadQuestionDataFromServer();
  }, [isOpen]);

  const isChecked = (id) => {
    return soalState.some(soal => soal.id === id);
  }

  /** Get question list **/
  const getQuestions = async (page = 1, perPage = 5) => {
    let uri = `questions`;

    try {
      const {
        data: { data }
      } = await BaseRequest.post(uri, {
        sortBy: dtSortBy,
        desc: dtSortDirection === 'desc',
        page: page,
        limit: perPage,
        ...dtSearchMeta
      });

      setDtTotalRow(data.total);

      // Map the questions data
      const mappedData = data.data.map((v, i) => ({
        ...v,
        rowNo: data.from + i,
        id: v.id,
        question_id: v.question_id,
        type: questionTypes.filter((e) => e.value === v.type)[0].text,
        question_type: v.is_essay ? 'Essay' : 'PG'
      }));

      return { rows: mappedData }
    } catch (error) {
      let errorMessage = error.response
        ? error.response.data.message
        : error.message;
      toast.error(errorMessage, {
        closeButton: false,
        position: "bottom-right"
      });
    }

    return { rows: [] }
  };

  const reloadQuestionDataFromServer = (page = 1, perPage = 5) => {
    getQuestions(page, perPage)
      .then((res) => {
        const { rows } = res;

        setDtRowsData(rows);
      })
      .finally(() => {
        setLoadProgressData(false);
      });
  };

  /** Datatable sorting **/
  const onSortHandler = (column, sortDirection) => {
    setLoadProgressData(true);

    setDtSortBy(column.selector);
    setDtSortDirection(sortDirection);

    getQuestions()
      .then((res) => {
        const {rows} = res;

        setDtRowsData(rows);
        setLoadProgressData(false);
      });
  };

  /** Datatable Filter **/
  const subHeaderComponentMemo = useMemo(() => {
    const handleFilter = (keyword, comesFromSelect = false, fieldName = '') => {
      setLoadProgressData(true);

      let newSearchMeta = dtSearchMeta;

      if (comesFromSelect) {
        let selectedValue = keyword;

        if (selectedValue === '') {
          delete newSearchMeta.filter[fieldName];
        } else if (fieldName !== '') {
          newSearchMeta.filter[fieldName] = selectedValue;
        }
      } else {
        newSearchMeta.search = keyword;
      }

      setDtSearchMeta(newSearchMeta);

      getQuestions()
        .then((res) => {
          const { rows } = res;

          setDtRowsData(rows);
          setLoadProgressData(false);
        })
    };

    let checkedOption = questionTypeOptions.find((option) => (option.checked));
    handleFilter(checkedOption && checkedOption.value, true);

    return (
      /** Filter input groups **/
      <MDBRow className={"w-100 modal-filter-criteria"}>
        <MDBCol size={"12"} md={"6"} sm={"12"}>
          <MDBInput
            label="Pertemuan"
            value={coursePart.name}
            className={"search-input"}
            disabled

          />
        </MDBCol>
        <MDBCol size={"12"} md={"6"} sm={"12"}>
          <MDBSelect
            label="Kategori"
            getValue={(val) => handleFilter(val[0], true, 'question_category_id')}
            className={"w-100"}
            name="question_category_id"
            options={questionCategories}
          />
        </MDBCol>
        <MDBCol size={"12"} md={"6"} sm={"12"}>
          <MDBSelect
            label="Tipe"
            getValue={(val) => handleFilter(val[0], true, 'type')}
            className={"w-100"}
            name="type"
            options={questionTypeOptions}
          />
        </MDBCol>
        <MDBCol size={"12"} md={"6"} sm={"12"}>
          <MDBInput
            label="Cari Pertanyaan"
            onChange={(e) => handleFilter(e.target.value, false, 'search-keyword')}
            className={"search-input"}
            name="search-keyword"
          />
        </MDBCol>
      </MDBRow>
    )
  }, [dtSearchMeta]);

  /** Datatable Change Page handler **/
  const onPageChangeHandler = (page) => {
    setLoadProgressData(true);
    reloadQuestionDataFromServer(page);
  }

  const onPerRowsChangeHandler = (perPage, page) => {
    setLoadProgressData(true);
    reloadQuestionDataFromServer(page, perPage);
  }

  /** Set selected soal **/
  const addQuestions = () => {
    setSelectedSoal(soalState);
    toggle();
  }

  /** Reset selected questions from this form **/
  const resetSelectedQuestions = () => {
    setSoalState(selectedSoal);
    toggle();
  }

  return (
    <MDBModal size="lg" isOpen={isOpen} toggle={toggle} backdrop={false}>
      <MDBModalHeader toggle={toggle}>Pilih Soal</MDBModalHeader>
      <MDBModalBody>
        <CustomDataTable
          columns={columns}
          data={dtRowsData}
          totalRow={dtTotalRow}
          onSortHandler={onSortHandler}
          subHeaderComponent={subHeaderComponentMemo}
          progressPending={loadProgressData}
          defaultSortField={"question_id"}
          defaultSortDir={"asc"}
          handlePerRowsChange={onPerRowsChangeHandler}
          handlePageChange={onPageChangeHandler}
          paginationPerPage={5}
          paginationRowsPerPageOptions={[5, 10, 15, 20]}
          noDataComponent={`Data tidak ditemukan untuk pertemuan\n${coursePart.name}`}
        />

        <div className="text-center">
          <MDBBtn color="red" size="sm" onClick={() => resetSelectedQuestions()}>
            Batalkan
          </MDBBtn>
          <MDBBtn color="green" size="sm" onClick={() => addQuestions()} disabled={selectedSoalNo === 0}>
            Tambahkan {selectedSoalNo} Soal
          </MDBBtn>
        </div>
      </MDBModalBody>
    </MDBModal>
  );
}

export default SelectSoalModal;
