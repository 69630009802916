import React, {useCallback, useEffect, useMemo, useState} from "react";
import moment from "moment";
import BaseRequest from "../../../services/BaseRequest";
import {useHistory} from "react-router-dom";
import {
  MDBContainer,
  MDBBtn,
  MDBIcon,
  toast,
  MDBRow,
  MDBCol,
  MDBBtnFixed,
  MDBSelect,
  MDBTooltip
} from "mdbreact";
import {default as CustomDataTable} from "../../components/DataTable";
import DeleteModal from "../../modals/DeleteModal";
import TextField from "@material-ui/core/TextField";

const thisUri = "/countries";
const apiUri = "/countries";

const CountryPage = () => {
  const history = useHistory();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedDeleteItem, setSelectedDeleteItem] = useState({});
  const [deleteLoading, setDeleteLoading] = useState(false);

  /** Datatable variables **/
  const [ dtRowsData, setDtRowsData ] = useState([]);
  const [ dtSortBy, setDtSortBy ] = useState('created_at');
  const [ dtSortDirection, setDtSortDirection ] = useState('desc');
  const [ dtTotalRow, setDtTotalRow ] = useState(1);
  const [ dtSearchMeta, setDtSearchMeta ] = useState({
    filter: {},
    field_search: 'name,region,sub_region',
    search: ''
  });
  const [ dtPage, setDtPage ] = useState(1);
  const [ dtPerRow, setDtPerRow ] = useState(10);
  const [ loadProgressData, setLoadProgressData ] = useState(true);

  /** Datatable column definition **/
  const columns = [
    {
      name: 'No',
      selector: 'rowNo',
      width: "4rem",
      right: true
    },
    {
      name: "Negara",
      selector: "name",
      sortable: true
    },
    {
      name: "Benua",
      selector: "region",
      sortable: true
    },
    {
      name: "Sub Benua",
      selector: "subRegion",
      sortable: true
    },
    {
      name: "Dibuat Tanggal",
      selector: "created_at",
      sortable: true,
      width: "8rem"
    },
    {
      name: "Disunting Tanggal",
      selector: "updated_at",
      sortable: true,
      width: "8rem"
    },
    {
      width: "10rem",
      cell: (row) =>
        <div className="btn-group-sm">
          <MDBTooltip tag="span" placement="top">
            <MDBBtn
              color="primary"
              onClick={() => history.push(`${thisUri}/${row.id}/states`)}
            >
              <MDBIcon icon="globe-asia" />
            </MDBBtn>
            <span>Lihat Provinsi</span>
          </MDBTooltip>
          <MDBBtn variant="contained" color="yellow" onClick={() => history.push(`${thisUri}/${row.id}/edit`)} >
            <MDBIcon icon="pen-nib" />
          </MDBBtn>
          <MDBBtn variant="contained" color="red" onClick={() => toggleDeleteModal(row.id)}>
            <MDBIcon icon="trash" />
          </MDBBtn>
        </div>,
      button: true
    }
  ];

  /** Datatable get data **/
  const getDataCountries = useCallback(async (page = 1, perPage = 10) => {
    let uri = `${apiUri}`;

    try {
      const {
        data: { data }
      } = await BaseRequest.post(uri, {
        sortBy: dtSortBy,
        desc: dtSortDirection === 'desc',
        page: page,
        limit: perPage,
        ...dtSearchMeta
      });

      setDtTotalRow(data.total);

      // Map the questions data
      const mappedData = data.data.map((v, i) => ({
        rowNo: data.from + i,
        id: v.id,
        name: v.name,
        region: v.region,
        subRegion: v.sub_region,
        created_at: moment(v.created_at).format("DD/MM/YYYY"),
        updated_at: moment(v.updated_at).format("DD/MM/YYYY"),
      }));

      return { rows: mappedData }
    } catch (error) {
      let errorMessage = error.response
        ? error.response.data.message
        : error.message;
      toast.error(errorMessage, {
        closeButton: false,
        position: "bottom-right"
      });
    }

    return { rows: [] }
  }, [dtSearchMeta, dtSortBy, dtSortDirection]);

  const reloadDataFromServer = useCallback((page = 1, perPage = 10) => {
    getDataCountries(page, perPage)
      .then((res) => {
        const { rows } = res;

        setDtRowsData(rows);
      })
      .finally(() => {
        setLoadProgressData(false);
      });
  }, [getDataCountries]);

  useEffect(() => {
    reloadDataFromServer(dtPage, dtPerRow);
  }, [reloadDataFromServer, dtPage, dtPerRow]);

  /** Datatable sorting **/
  const onSortHandler = (column, sortDirection) => {
    setDtSortBy(column.selector);
    setDtSortDirection(sortDirection);

    reloadDataFromServer();
  };

  /** Datatable Filter **/
  const subHeaderComponentMemo = useMemo(() => {
    const handleFilter = (keyword, comesFromSelect = false) => {
      setLoadProgressData(true);

      let newSearchMeta = dtSearchMeta;

      if (comesFromSelect) {
        let selectedValue = keyword;

        if (selectedValue === '') {
          delete newSearchMeta.filter.country_id;
        } else {
          newSearchMeta.filter = {
            ...newSearchMeta.filter,
            country_id: selectedValue
          };
        }
      } else {
        newSearchMeta.search = keyword;
      }

      setDtSearchMeta(newSearchMeta);

      getDataCountries(newSearchMeta)
        .then((res) => {
          const { rows } = res;

          setDtRowsData(rows);
          setLoadProgressData(false);
        })
    };

    return (
      <MDBRow className={"w-100"}>
        <MDBCol size={"6"} md={"4"} sm={"6"}>
          <TextField
            label="Cari "
            onChange={(e) => handleFilter(e.target.value)}
            className={"search-input"}
            style={{marginTop:'14px'}}
            name="search"
            autoComplete="off"
          />
        </MDBCol>
    </MDBRow>
    )
  }, [dtSearchMeta, getDataCountries]);

  const toggleDeleteModal = (id) => {
    setSelectedDeleteItem(id);
    setShowDeleteModal(!showDeleteModal);
  }

  const handleDelete = async () => {
    try {
      setDeleteLoading(true);

      await BaseRequest.delete(`${apiUri}/${selectedDeleteItem}`);
      reloadDataFromServer();
    } catch (err) {
      console.error(err);
    } finally {
      toggleDeleteModal();
      setDeleteLoading(false);
    }
  };

  const handlePageChange = (page) => {
    setDtPage(page)
  }

  const handlePerRowsChange = (perRow) => {
    setDtPerRow(perRow)
  }

  return (
    <MDBContainer fluid>
      <h3>Negara</h3>

      <CustomDataTable
        columns={columns}
        data={dtRowsData}
        onSortHandler={onSortHandler}
        subHeaderComponent={subHeaderComponentMemo}
        progressPending={loadProgressData}

        totalRow={dtTotalRow}
        handlePageChange={handlePageChange}
        handlePerRowsChange={handlePerRowsChange}
      />

      <DeleteModal
        isOpen={showDeleteModal}
        toggle={toggleDeleteModal}
        handler={handleDelete}
        loadingIndicator={deleteLoading}
      />

      <MDBBtnFixed
        floating
        color="blue"
        icon="plus"
        size="lg"
        style={{bottom: "55px", right: "24px"}}
        onClick={() => history.push(`${thisUri}/create`)}
        className="no-li"
      />

    </MDBContainer>
  );
};

export default CountryPage;
