import React, {useState, useEffect} from "react";
import {useHistory, useParams} from "react-router-dom";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBProgress,
} from "mdbreact";
import BaseRequest from "../../../services/BaseRequest";
import moment from "moment";

const colors = ["info","success","danger","warning","default","primary","secondary"];

const ReportItem = ({question}) => {
  const history = useHistory();
  const total = question?.total;
  const theColor = colors[Math.floor(Math.random() * colors.length)];

  if (question.type!="text")
    return (
      <MDBContainer
        style={{ marginBottom: 30, padding: 10 }}
        className="border"
      >
        <h5>
          {question?.title} - (Total: {total})
        </h5>
        {question.options.map((opt) => {
          let percent = 0;
          if (total == 0) percent = 0;
          else percent = Number((opt.count / total) * 100.0).toFixed(0);
          return (
            <div style={{ marginTop: 5 }}>
              <span>{opt.label}</span>
              <MDBProgress value={percent} color={theColor}>
                {opt.count} ({percent}%)
              </MDBProgress>
            </div>
          );
        })}
      </MDBContainer>
    );
  
  // text
  return (
    <MDBContainer
      style={{ marginBottom: 30, padding: 10 }}
      className="border"
    >
      <h5>
        {question?.title} - (Total: {total})
      </h5>
      <button onClick={()=>history.push(`/survey/textreport/${question.id}`)}>Lihat Detail</button> 
    </MDBContainer>
  );
}

const SurveyReportPage = () => {
  const history = useHistory();
  const [report, setReport] = useState([]);
  const {id} = useParams(); // Jika id !== undefined, maka ini adalah fitur edit data

  const getReports = async () => {
    try {
      const {
        data: {data}
      } = await BaseRequest.get(`surveys/reports/${id}`);
      setReport(data);
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(()=>{
    getReports();
  },[]);

  return (
    <MDBContainer>
      <MDBRow center>
        <MDBCol size="12" md="12" sm="12">
          <h3 className="text-center">Report: {report?.title}</h3>
          <p className="text-center pb-5 mb-2">{moment(report?.start_at).format("DD MMM")} - {moment(report?.end_at).format("DD MMM YYYY")}</p>
          {report?.questions?.map(q => <ReportItem question={q}/>)}
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default SurveyReportPage;
