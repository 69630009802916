import React, { useState } from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBCardTitle,
  MDBIcon,
  MDBListGroup,
  MDBListGroupItem,
} from "mdbreact";
import { useForm } from "react-hook-form";

const Skema = ({ nipSchema, onChangeNipSchema }) => {
  const { cardStyle } = styles;

  return (
    <MDBCard style={cardStyle}>
      <MDBCardBody>
        <MDBCardTitle>Skema</MDBCardTitle>
        <MDBCardText>Mohon atur terlebih dahulu skema NIM nya</MDBCardText>
        <MDBCardText>
          Value pada nomor urut menandakan dari nomor berapa urutan NIM akan di
          generate
        </MDBCardText>
        <MDBListGroup>
          {nipSchema.map((schema, urutan) => {
            return (
              <ListItem
                urutan={urutan}
                schema={schema}
                key={`skema-${urutan}`}
                onChangeNipSchema={onChangeNipSchema}
              />
            );
          })}
        </MDBListGroup>
      </MDBCardBody>
    </MDBCard>
  );
};

const ListItem = ({ urutan, schema, onChangeNipSchema }) => {
  const { register, handleSubmit, errors } = useForm();

  const [isEditing, setIsEditing] = useState(false);

  const onSave = handleSubmit((data) => {
    const newValue = { ...schema, value: data.value };

    onChangeNipSchema(urutan, newValue);
    setIsEditing(false);
  });

  const onEdit = () => {
    setIsEditing(true);
  };

  return (
    <MDBListGroupItem>
      <div className="d-flex justify-content-between align-items-center">
        {schema.name}

        <span>
          <EditButton
            editable={schema.editable}
            isEditing={isEditing}
            onSave={onSave}
            onEdit={onEdit}
          />
        </span>
      </div>
      <ListItemInfo
        isEditing={isEditing}
        value={schema}
        register={register}
        errors={errors}
        onSave={onSave}
      />
    </MDBListGroupItem>
  );
};

const EditButton = ({ editable, isEditing, onEdit, onSave }) => {
  if (!editable) {
    return <></>;
  }

  if (isEditing) {
    return (
      <MDBIcon icon="save" style={{ cursor: "pointer" }} onClick={onSave} />
    );
  }

  return (
    <MDBIcon icon="pen-nib" style={{ cursor: "pointer" }} onClick={onEdit} />
  );
};

const ListItemInfo = ({ value, isEditing, register, errors, onSave }) => {
  const { labelStyle, validationErrorMessageStyle } = styles;
  if (isEditing) {
    return (
      <form onSubmit={onSave}>
        <div className="form-group">
          <label htmlFor="value" style={labelStyle}>
            Value
          </label>
          <input
            type="text"
            className="form-control"
            id="value"
            name="value"
            ref={register({
              required: "Tidak boleh kosong",
              maxLength: {
                value: value.max_digit,
                message: `Max digit = ${value.max_digit}`,
              },
              validate: {
                numeric: (v) => !isNaN(v) || "Harus numeric",
                positive: (v) => v >= 0 || "Harus positif"
              },
            })}
          />
          {errors.value && (
            <small style={validationErrorMessageStyle}>
              {errors.value.message}
            </small>
          )}
        </div>
      </form>
    );
  }

  return (
    <div>
      <small>Max Digit: {value.max_digit || "-"}, </small>
      <small>Value: {value.value || "-"}</small>
    </div>
  );
};

const styles = {
  cardStyle: {
    width: "100%",
  },
  labelStyle: {
    fontSize: 12,
  },
  validationErrorMessageStyle: {
    color: "red",
  },
};

export default Skema;
