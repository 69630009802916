import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import {
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBInput,
  MDBSpinner,
  toast,
  MDBSelect
} from "mdbreact";
import InputError from "../../components/InputError";
import { Link } from "react-router-dom";
import BaseRequest from "../../../services/BaseRequest";

const thisUri = "/participants";

const RenderButton = ({ submitLoading }) => {
  if (submitLoading) {
    return <MDBSpinner />;
  }
  return (
    <div>
      <MDBBtn onClick={()=>{window.history.back()}} size="md" color="red" className="mx-0">
        Kembali
      </MDBBtn>
      <MDBBtn type="submit" color="green" size="md">
        Simpan
      </MDBBtn>
    </div>
  );
};

const formField = [
  { name: "name", rules: { required: true } },
  { name: "phone_country_code", rules: { required: true } },
  { name: "phone", rules: { required: true } },
  { name: "age", rules: { required: true } },
  { name: "gender", rules: { required: true } },
  { name: "role_id" },
  { name: "email", rules: { required: true }},
  { name: "password" },
];

const ParticipantFormPage = () => {
  const history = useHistory();
  const { id } = useParams(); // Jika id !== undefined, maka ini adalah fitur edit data

  const { register, handleSubmit, errors, setValue, watch } = useForm();
  const [submitLoading, setSubmitLoading] = useState(false);

  let [listGender, setListGender] = useState([]);

  const getGender = () => {
    return [
      {text:'Ikhwan',value:'ikhwan'},
      {text:'Akhwat',value:'akhwat'},
    ]
  }

  const handleChange = e => {
    setValue(e.target.name, e.target.value);
  };

  const handleGenderChange = id => {
    setValue('gender', id[0]);
  };

  const name                = watch("name");
  const phone_country_code  = watch("phone_country_code");
  const phone               = watch("phone");
  const age                 = watch("age");
  const gender              = watch("gender");
  const role_id             = watch("role_id");
  const email               = watch("email");
  const password            = watch("password");

  useEffect(() => {
    for (let field of formField) {
      register({ name: field.name }, field.rules || null);
    }
  }, [register]);

  useEffect(() => {
    if (id) {
      const getDataById = async () => {
        try {
          const {
            data: { data }
          } = await BaseRequest.get(`/users/${id}/edit`);
          for (let key in data) {
            setValue(key, data[key] || "");
          }
        } catch (error) {
          console.log(error);
        }
      };
      getDataById();
    }
  }, [id, setValue]);

  useEffect(() => {
    if(gender){
      setListGender(()=>(
        getGender().map((v, i) => ({
          ...v,
          checked: (gender === v.value)? true :false
        }))
      ))
    }else{
      if(!id) setListGender(getGender())
    }
  },[gender, id]);

  const onSubmit = async payload => {
    try {
      setSubmitLoading(true);
      let res;
      if (id) {
        res = await BaseRequest.put(`/users/${id}`, payload);
      } else {
        res = await BaseRequest.post("/users/save", payload);
      }

      toast.success(res.data.message, {
        closeButton: false,
        position: "bottom-right"
      });
      setTimeout(() => window.history.back(), 1000);
    } catch (error) {
      let errorMessage = error.response
        ? error.response.data.message
        : error.message;
      toast.error(errorMessage, {
        closeButton: false,
        position: "bottom-right"
      });
      setSubmitLoading(false);
    }
  };

  return (
    <MDBContainer>
      <MDBRow center>
        <MDBCol size="12" md="9" sm="12">
          <MDBCard>
            <MDBCardBody>
              <MDBCardTitle>{id ? 'Edit' : 'Create'} Peserta</MDBCardTitle>
              <hr />
              <form onSubmit={handleSubmit(onSubmit)}>
                <MDBContainer>
                  <MDBRow>
                    <MDBCol>
                      <MDBInput
                        className={errors.name && "is-invalid"}
                        name="name"
                        onChange={handleChange}
                        type="text"
                        label="Nama Peserta"
                        icon="address-card"
                        iconClass="black-text"
                        value={name}
                      >
                        {errors.name && errors.name.type === "required" && (
                          <InputError text="Tidak boleh kosong" />
                        )}
                      </MDBInput>
                      <MDBInput
                        name="role_id"
                        type="hidden"
                        value={role_id}
                      >
                      </MDBInput>
                      <MDBInput
                        className={errors.phone_country_code && "is-invalid"}
                        name="phone_country_code"
                        onChange={handleChange}
                        type="text"
                        label="Kode Negara"
                        icon="mobile-alt"
                        iconClass="blue-text"
                        value={phone_country_code}
                      >
                        {errors.phone_country_code && errors.phone_country_code.type === "required" && (
                          <InputError text="Tidak boleh kosong" />
                        )}
                      </MDBInput>
                      <MDBInput
                        className={errors.phone && "is-invalid"}
                        name="phone"
                        onChange={handleChange}
                        type="text"
                        label="Nomor Telpon"
                        icon="mobile-alt"
                        iconClass="blue-text"
                        value={phone}
                      >
                        {errors.phone && errors.phone.type === "required" && (
                          <InputError text="Tidak boleh kosong" />
                        )}
                      </MDBInput>
                      <MDBInput
                        className={errors.age && "is-invalid"}
                        name="age"
                        onChange={handleChange}
                        type="text"
                        label="Umur"
                        icon="calendar-alt"
                        iconClass="black-text"
                        value={age}
                      >
                        {errors.age && errors.age.type === "required" && (
                          <InputError text="Tidak boleh kosong" />
                        )}
                      </MDBInput>
                      <MDBInput
                        className={errors.email && "is-invalid"}
                        name="email"
                        onChange={handleChange}
                        type="text"
                        label="Email"
                        icon="envelope"
                        iconClass="black-text"
                        value={email}
                      >
                        {errors.email && errors.email.type === "required" && (
                          <InputError text="Tidak boleh kosong" />
                        )}
                      </MDBInput>
                      <MDBInput
                        className={errors.password && "is-invalid"}
                        name="password"
                        onChange={handleChange}
                        type="text"
                        label="Password (Kosongkan jika tidak ingin merubah password)"
                        icon="key"
                        iconClass="black-text"
                        value={password}
                      />
                      <MDBSelect
                        className={errors.gender && "is-invalid"}
                        name="gender"
                        getValue={handleGenderChange}
                        options={listGender}
                        selected="Choose your option"
                        label="Jenis Kelamin"
                      />
                      {errors.gender &&
                        errors.gender.type === "required" && (
                          <span className="text-danger small">Tidak boleh kosong</span>
                        )}
                    </MDBCol>
                  </MDBRow>
                </MDBContainer>

                <div className="text-center mt-3 black-text">
                  <RenderButton submitLoading={submitLoading} />
                </div>
              </form>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default ParticipantFormPage;
