import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import DateTimePicker from 'react-datetime-picker';
import {
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBInput,
  MDBSelect,
  MDBSpinner,
  toast
} from "mdbreact";
import { MDBModal, MDBModalBody } from 'mdbreact';
import { MDBTable, MDBTableBody, MDBTableHead, MDBIcon } from 'mdbreact';

import InputError from "../../components/InputError";
import { Link } from "react-router-dom";
import BaseRequest from "../../../services/BaseRequest";
import moment from 'moment'
import QuizQuestionFormPage from './QuizQuestionFormPage'
const thisUri = `/quizzes`;

const formField = [
  { name: "course_id", rules: { required: true } },
  { name: "course_part_id", rules: { required: true } },
  { name: "title_id", rules: { required: true } },
  { name: "time_status", rules: { required: true } },
  { name: "category", rules: { required: true } },
  { name: "title_id", rules: { required: true } },
  { name: "title_en" },
  { name: "title_ar" },

  { name: "weight_correct" },
  { name: "weight_wrong" },
  { name: "weight_not_answer" },
  { name: "time" },
  { name: "title_ar" },
  { name: "time_open" },
  { name: "time_close" },
  { name: "copy_from_question_bank", rules: { required: true } }
];

const RenderButton = ({ submitLoading }) => {
  if (submitLoading) {
    return <MDBSpinner />;
  }
  return (
    <div>
      <Link to={thisUri}>
        <MDBBtn size="md" color="red" className="mx-0">
          Kembali
        </MDBBtn>
      </Link>
      <MDBBtn type="submit" color="green" size="md">
        Simpan
      </MDBBtn>
    </div>
  );
};

const QuizFormPage = () => {
  const history = useHistory();
  const { id } = useParams(); // Jika id !== undefined, maka ini adalah fitur edit data

  const { register, handleSubmit, errors, setValue, watch } = useForm();
  const [submitLoading, setSubmitLoading] = useState(false);

  let [listCourse, setListCourse] = useState([]);
  let [listCoursePart, setListCoursePart] = useState([]);
  let [listTimeStatus, setListTimeStatus] = useState([]);
  let [listCategory, setListCategory] = useState([]);
  let [isModalOpen, setIsModalOpen] = useState(false);
  let [listQuestion, setListQuestion] = useState([]);
  let [question, setQuestion] = useState(null);

  const setQuestions = (question) => {
      let questions = [...listQuestion]

      if(question.id){
        let idx = questions.findIndex(q => q.id === question.id)
        if(idx >= 0){
          questions[idx] = {
            ...question,
            title_id: question.question_id
          }
        }
      }else{
        let idx = questions.findIndex(q => q.question_idx === question.question_idx)
        if(idx >= 0){
          questions[idx] = {
            ...question,
            title_id: question.question_id
          }
        }else{
          let d = new Date()
          let question_idx = d.getFullYear()+d.getMonth()+d.getDate()+d.getHours()+d.getMinutes()+d.getSeconds()+d.getMilliseconds()
          questions.push({
            ...question,
            title_id: question.question_id,
            question_idx
          })
        }

      }
      setListQuestion(questions)
      setQuestion(null)
  }

  const deleteQuestion = (question) => {
    let questions = [...listQuestion]
    const idx = questions.findIndex(v => v.question_idx === question.question_idx);
    questions.splice(idx, 1);
    setQuestion(null)
    setIsModalOpen(false)
    setListQuestion(questions)
  }

  const editQuestion = (question) => {
    setQuestion(question)
    openModal()
  }

  const addQuestion = () => {
    setQuestion(null)
    openModal()
  }

  const openModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const handleTimeOpenChange = date => {
    setValue('time_open', date);
  }

  const handleTimeCloseChange = date => {
    setValue('time_close', date);
  }

  const getTimeStatus = () => {
    return [
      {text:'Flexible',value:'flexible'},
      {text:'Fixed',value:'fixed'}
    ]
  }

  const getCategory = () => {
    return [
      {text:'Daily',value:'daily'},
      {text:'Weekly',value:'weekly'},
      {text:'Mid Semester',value:'mid-semester'},
      {text:'Semester',value:'semester'}
    ]
  }

  const getCourses = async () => {
    const uri = `/courses?sortBy=created_at&desc=true`
    const { data: { data } } = await BaseRequest.post(uri);
    return data.data.map((v, i) => ({
      text: v.title_id,
      value: v.id
    }));
  };

  const getCourseParts = async (course_id) => {
    const uri = `/course-parts?sortBy=created_at&desc=true`
    const { data: { data } } = await BaseRequest.post(uri, {filter:{course_id: course_id}});

    return data.data.map((v, i) => ({
      text: v.title_id,
      value: v.id
    }));
  }

  const handleChange = e => {
    setValue(e.target.name, e.target.value);
  };

  const handleCourseChange = id => {
    setValue('course_id', id[0]);
  }

  const handleCoursePartChange = id => {
    setValue('course_part_id', id[0]);
  }

  const handleTimeStatusChange = id => {
    setValue('time_status', id[0]);
  }

  const handleCategoryChange = id => {
    setValue('category', id[0]);
  }

  let course_id = watch('course_id')
  let course_part_id = watch('course_part_id')
  let time_status = watch('time_status')
  let category = watch('category')

  useEffect(() => {
    for (let v of formField) {
      register({ name: v.name }, v.rules || null);
    }
  }, [register]);

  useEffect(() => {
    if (id) {
      const getDataById = async () => {
        try {
          const {
            data: { data }
          } = await BaseRequest.get(`${thisUri}/${id}/edit?with=quizQuestion`);

          for (let key in data) {
            if(key === 'weight_not_answer'){
              let val = data[key] === 0? '0' : data[key]
              setValue(key, val || "");
            }else if(key === 'time_open' | key === 'time_close'){
              setValue(key, new Date(data[key]));
            }else{
              setValue(key, data[key] || "");
            }
          }

          if(data.quiz_question && data.quiz_question.length > 0){
            let quiz_question = data.quiz_question
            let questions = []
            for(let x=0; x<quiz_question.length;x++){
              let answers = (quiz_question[x].quiz_answer)? quiz_question[x].quiz_answer : []
              delete quiz_question[x].quiz_answer
              questions.push({
                ...quiz_question[x],
                question_idx: quiz_question[x].id,
                answers
              })
            }
            setListQuestion(questions)
          }

        } catch (error) {
          console.log(error);
        }
      };
      getDataById();
    }
  }, [id, setValue]);

  useEffect(() => {
    if(course_id){
      getCourses()
      .then(res => {
        setListCourse(()=>(
          res.map((v, i) => ({
            ...v,
            checked: (course_id === v.value)? true :false
          }))
        ))
      })
    }else{
      if(!id){
        getCourses()
        .then(res => {
          setListCourse(res)
        })
      }
    }
  },[course_id, id]);

  useEffect(() => {
    if(course_id && course_part_id){
      getCourseParts(course_id)
      .then(res => {
        setListCoursePart(()=>(
          res.map((v, i) => ({
            ...v,
            checked: (course_part_id === v.value)? true :false
          }))
        ))
      })
    }else{
      if(course_id && !id){
        getCourseParts(course_id).then(res => {
          setListCoursePart(res)
        })
      }
    }
  },[course_id, course_part_id, id]);

  useEffect(() => {
    if(time_status){
      setListTimeStatus(()=>(
        getTimeStatus().map((v, i) => ({
          ...v,
          checked: (time_status === v.value)? true :false
        }))
      ))
    }else{
      if(!id) setListTimeStatus(getTimeStatus())
    }
  },[time_status, id]);

  useEffect(() => {
    if(category){
      setListCategory(()=>(
        getCategory().map((v, i) => ({
          ...v,
          checked: (category === v.value)? true :false
        }))
      ))
    }else{
      if(!id) setListCategory(getCategory())
    }
  },[category, id]);

  const onSubmit = async payload => {
    try {
      // setSubmitLoading(true);
      let res = null;

      payload.time_open = moment(payload.time_open).format('YYYY-MM-DD HH:mm:ss');
      payload.time_close = moment(payload.time_close).format('YYYY-MM-DD HH:mm:ss');
      payload.questions = listQuestion

      if (id) {
        res = await BaseRequest.put(`${thisUri}/${id}`, payload);
      } else {
        res = await BaseRequest.post(`${thisUri}/save`, payload);
      }
      toast.success(res.data.message, {
        closeButton: false,
        position: "bottom-right"
      });
      setTimeout(() => history.push(thisUri), 2000);
    } catch (error) {
      let errorMessage = error.response
        ? error.response.data.message
        : error.message;
      toast.error(errorMessage, {
        closeButton: false,
        position: "bottom-right"
      });
      setSubmitLoading(false);
    }
  };

  return (
    <MDBContainer>
      <MDBRow center>
        <MDBCol size="12" md="9" sm="12">
          <MDBCard>
            <MDBCardBody>
              <MDBCardTitle>
                {id ? "Edit" : "Tambah"} Kuis
              </MDBCardTitle>
              <hr />
              <form onSubmit={handleSubmit(onSubmit)}>
                <MDBContainer>
                  <MDBRow>
                    <MDBCol size="12">

                      <MDBSelect
                        className={errors.course_id && "is-invalid"}
                        name="course_id"
                        getValue={handleCourseChange}
                        options={listCourse}
                        selected="Pilihan Pelajaran"
                        label="Pelajaran"
                      />
                      {errors.course_id &&
                        errors.course_id.type === "required" && (
                          <span className="text-danger small">Tidak boleh kosong</span>
                        )}

                      <MDBSelect
                        className={errors.course_part_id && "is-invalid"}
                        name="course_id"
                        getValue={handleCoursePartChange}
                        options={listCoursePart}
                        selected="Pilihan Opsi"
                        label="Pilih Sub Pelajaran"
                      />
                      {errors.course_part_id &&
                        errors.course_part_id.type === "required" && (
                          <span className="text-danger small">Tidak boleh kosong</span>
                        )}

                      <MDBSelect
                        className={errors.time_status && "is-invalid"}
                        name="course_id"
                        getValue={handleTimeStatusChange}
                        options={listTimeStatus}
                        selected="Pilihan Jenis Waktu"
                        label="Jenis Waktu"
                      />
                      {errors.time_status &&
                        errors.time_status.type === "required" && (
                          <span className="text-danger small">Tidak boleh kosong</span>
                        )}

                      <MDBSelect
                        className={errors.category && "is-invalid"}
                        name="course_id"
                        getValue={handleCategoryChange}
                        options={listCategory}
                        selected="Pilihan Opsi"
                        label="Pilih Kategori"
                      />
                      {errors.category &&
                        errors.category.type === "required" && (
                          <span className="text-danger small">Tidak boleh kosong</span>
                        )}

                      <MDBInput
                        className={errors.title_id && "is-invalid"}
                        name="title_id"
                        onChange={handleChange}
                        type="text"
                        label="Judul (Indonesia)"
                        value={watch("title_id")}
                      >
                        {errors.title_id &&
                          errors.title_id.type === "required" && (
                            <InputError text="Tidak boleh kosong" />
                          )}
                      </MDBInput>

                      <MDBInput
                        name="title_en"
                        onChange={handleChange}
                        type="text"
                        label="Judul (English)"
                        value={watch("title_en")}
                      />
                      <MDBInput
                        name="title_ar"
                        onChange={handleChange}
                        type="text"
                        label="Judul (Arabic)"
                        value={watch("title_ar")}
                      />

                      <MDBInput
                        name="weight_correct"
                        onChange={handleChange}
                        type="number"
                        label="Bobot Status Jawaban"
                        value={watch("weight_correct")}
                      />
                      <MDBInput
                        name="weight_wrong"
                        onChange={handleChange}
                        type="number"
                        label="Bobot Jawaban Salah"
                        value={watch("weight_wrong")}
                      />
                      <MDBInput
                        name="weight_not_answer"
                        onChange={handleChange}
                        type="number"
                        label="Bobot Tidak Dijawab"
                        value={watch("weight_not_answer")}
                      />
                      <MDBInput
                        name="time"
                        onChange={handleChange}
                        type="number"
                        label="Durasi (Dalam detik)"
                        value={watch("time")}
                      />
                      <MDBInput
                        name="copy_question"
                        onChange={handleChange}
                        type="checkbox"
                        label="Apakah Anda Ingin Menggunakan Semua Pertanyaan dan Jawaban Dari Bank Soal ?"
                        value={watch("copy_from_question_bank")}
                      />

                      <label htmlFor="defaultFormLoginEmailEx" className="grey-text">
                        Waktu Dibuka
                      </label>
                      <DateTimePicker
                        onChange={handleTimeOpenChange}
                        value={watch("time_open")}
                        format="y-MM-dd HH:mm:ss"
                        className="form-control"
                        placeholder="Waktu Dibuka"
                      />
                      <br /><br />
                      <label htmlFor="defaultFormLoginEmailEx" className="grey-text">
                        Waktu Ditutup
                      </label>
                      <DateTimePicker
                        onChange={handleTimeCloseChange}
                        value={watch("time_close")}
                        format="y-MM-dd HH:mm:ss"
                        className="form-control"
                        placeholder="Waktu Ditutup"
                      />
                      <div className="d-flex justify-content-end mt-3">
                        <MDBBtn className="" size="md" color="secondary" onClick={addQuestion}>Tambah Pertanyaan</MDBBtn>
                      </div>
                      { listQuestion.length>0 &&
                        <MDBTable>
                          <MDBTableHead>
                            <tr>
                              <th>#</th>
                              <th>Pertanyaan (ID)</th>
                              <th></th>
                            </tr>
                          </MDBTableHead>
                          <MDBTableBody>
                            { listQuestion.map((a, index)=>
                              <tr key={index}>
                                <td> {(index + 1)}</td>
                                <td>{a.question_id}</td>
                                <td>
                                <MDBBtn size="md" color="red" onClick={() => deleteQuestion(a)}>
                                  <MDBIcon icon="trash" />
                                </MDBBtn>
                                <MDBBtn size="md" color="yellow" onClick={() => editQuestion(a, index)}>
                                  <MDBIcon icon="pen-nib" />
                                </MDBBtn>
                                </td>
                              </tr>
                            )}
                          </MDBTableBody>
                        </MDBTable>
                      }
                    </MDBCol>
                  </MDBRow>
                </MDBContainer>

                <div className="text-center mt-3 black-text">
                  <RenderButton submitLoading={submitLoading} />
                </div>
              </form>
              <MDBModal isOpen={isModalOpen} toggle={closeModal} fullHeight position="top" backdrop={false}>
                <MDBModalBody>
                  <QuizQuestionFormPage setQuestions={setQuestions} question={question} closeModal={closeModal}/>
                </MDBModalBody>
              </MDBModal>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default QuizFormPage;
