import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import BaseRequest from "../../../services/BaseRequest";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBTable,
  MDBTableBody,
  MDBBadge,
  MDBTableHead,
  MDBSpinner,
} from "mdbreact";
import moment from 'moment';

const ParticipantTranscriptDetailPage = () => {
  const { id, courseId } = useParams();

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const totalScore = useMemo(() => {
    let total = 0;
    list.forEach(it => total += it.score);
    return total;
  }, [list])

  useEffect(() => {
    if (id) {
      const getDataById = async () => {
        try {
          setLoading(true);
          const {
            data: { data }
          } = await BaseRequest.get(`/transcripts/course/${courseId}?user_id=${id}`, {
            user_id: id
          });
          setList(data);
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      };
      getDataById();
    }
  }, [id]);

  return (
    <MDBContainer fluid>
      <MDBRow center>
        <MDBCol size="12" md="12" sm="12">
          <MDBBtn
            onClick={() => {
              window.history.back();
            }}
            size="md"
            color="red"
            className="pull-right"
          >
            Kembali
          </MDBBtn>
          <h3 className="mt-3">Rekap Transkrip Nilai</h3>
          {loading && <MDBSpinner />}
          <MDBRow>
            <MDBCol>
              <MDBTable className="mt-2">
                <MDBTableHead>
                  <tr>
                    <th>No</th>
                    <th>Tanggal</th>
                    <th>Ujian</th>
                    <th>Jenis</th>
                    <th>Nilai</th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  {list.map((it,idx) => {
                    if (!it.quizzes) return <tr><td colSpan={5}>Quiz not found</td></tr>;
                    return (
                      <tr key={idx}>
                        <td>{idx + 1}</td>
                        <td>{moment(it.created_at).format("D-MMM-YYYY")}</td>
                        <td>{it.quizzes?.title_id}</td>
                        <td>{it.quizzes?.category}</td>
                        <td><mark>{it.score}</mark></td>
                      </tr>);
                  })}
                  {!loading && list.length==0 && <tr><td colSpan={5}>Rekap tidak tersedia</td></tr>}
                  <tr>
                    <td colSpan={4}>Total Nilai:</td>
                    <td><mark>{totalScore}</mark></td>
                  </tr>
                </MDBTableBody>
              </MDBTable>
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}

export default ParticipantTranscriptDetailPage;