import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isAuthenticated } from 'utils';

/**
 * Component ini digunakan untuk Login Page & Register Page
 * Jika user sudah login, maka mereka tidak bisa mengakses page yg di wrap dengan component ini, sebelum mereka logout
 */
const LoginRoute = ({ children, ...rest }) => {
  return (
    <Route
      {...rest}
      render={() => {
        if (!isAuthenticated()) {
          return children;
        }
        return <Redirect to="/" />;
      }}
    />
  );
};

export default LoginRoute;
