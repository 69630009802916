export const isAccessibleMenu = (access, menu, action) => {
  if (access[menu]) {
    return !access[menu].disable.find( dis => dis === action);
  } else {
    return true;
  }
}

// export const getPathRule = (menu, pathname) => {
//   for (const el of menu) {
//     if (el.hasOwnProperty('children')) {
//       const childs = el.children;
//       for (const elc of childs) {
//         if (elc.to === pathname) {
//           return elc.hasOwnProperty('disable') ? elc.disable : [];
//         }
//       }
//     } else {
//       if (el.to === pathname) {
//         return el.hasOwnProperty('disable') ? el.disable : [];
//       }
//     }
//     return [];
//   }
// }