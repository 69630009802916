import React from "react";
import { useTable, useSortBy } from "react-table";

import InfiniteScroll from "react-infinite-scroll-component";
import { makeStyles } from "@material-ui/styles";
import { LinearProgress } from "@material-ui/core";
import * as styles from "./InfiniteTable.module.css";

function InfiniteTable({ columns, data, update, hasMore }) {
  // Use the state and functions returned from useTable to build your UI

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy
  );

  React.useEffect(() => {
    console.log("sort");
  }, [sortBy]);

  /** Datatable progress indicator **/
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing,
      },
    },
  }));

  const LinearIndeterminate = () => {
    const classes = useStyles();

    return (
      <div className={classes.root}>
        <LinearProgress />
      </div>
    );
  };

  // Render the UI for your table
  return (
    <InfiniteScroll
      dataLength={rows.length}
      next={update}
      hasMore={hasMore}
      loader={<LinearIndeterminate />}
    >
      <table
        {...getTableProps()}
        className={`table table-striped ${styles.table}`}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={styles.head}
                >
                  {column.render("Header")}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <label
                          style={{
                            transform: "rotate(180deg)",
                            marginBottom: "0px",
                            marginLeft: "2px",
                          }}
                        >
                          {" "}
                          ▲
                        </label>
                      ) : (
                        " ▲"
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps([
                        {
                          className: styles.row,
                          style: cell.column.style,
                        },
                      ])}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </InfiniteScroll>
  );
}

export default InfiniteTable;
