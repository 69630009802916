import React, {useEffect, useState} from "react";
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import SecureLS from "secure-ls"

import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBIcon,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBBadge
} from "mdbreact";
import BaseRequest from "../services/BaseRequest";

const TopNavigationFunc = ({ onSideNavToggleClick, toggle, routeName }) => {
  const history = useHistory();

  const [userFullName, setUserFullName] = useState('');

  const handleToggleClickA = () => {
    onSideNavToggleClick();
  };

  const navStyle = {
    paddingLeft: toggle ? "16px" : "240px",
    transition: "padding-left .3s"
  };

  const doLogout = () => {
    var ls = new SecureLS({encodingType: 'aes'});
    ls.clear()
    // window.localStorage.clear();
    history.push("/login");
  };

  const goTo = (to) => {
    history.push(to);
  };

  useEffect(() => {
    const ls = new SecureLS({encodingType: 'aes'});
    const data = ls.get('tsl')

    setUserFullName(data.userFullName);
  }, [])

  return (
    <Router>
      <MDBNavbar
        className="flexible-MDBNavbar"
        light
        expand="md"
        scrolling
        fixed="top"
        style={{ zIndex: 3 }}
      >
        <div
          onClick={handleToggleClickA}
          key="sideNavToggleA"
          style={{
            lineHeight: "32px",
            marginleft: "1em",
            verticalAlign: "middle",
            cursor: "pointer"
          }}
        >
          <MDBIcon icon="bars" color="white" size="lg" />
        </div>

        <MDBNavbarBrand href="/" style={navStyle}>
          <strong>{routeName}</strong>
        </MDBNavbarBrand>
        <MDBNavbarNav expand="sm" right style={{ flexDirection: "row" }}>
          {/* 
          <MDBDropdown>
            <MDBDropdownToggle nav caret>
              <MDBBadge color="red" className="mr-2">
                3
              </MDBBadge>
              <MDBIcon icon="bell" />{" "}
              <span className="d-none d-md-inline">Notifikasi</span>
            </MDBDropdownToggle>
            <MDBDropdownMenu right style={{ minWidth: "400px" }}>
              <MDBDropdownItem href="#!">
                <MDBIcon icon="money-bill-alt" className="mr-2" />
                New order received
                <span className="float-right">
                  <MDBIcon icon="clock" /> 13 min
                </span>
              </MDBDropdownItem>
              <MDBDropdownItem href="#!">
                <MDBIcon icon="money-bill-alt" className="mr-2" />
                New order received
                <span className="float-right">
                  <MDBIcon icon="clock" /> 33 min
                </span>
              </MDBDropdownItem>
              <MDBDropdownItem href="#!">
                <MDBIcon icon="chart-line" className="mr-2" />
                Your campaign is about to end
                <span className="float-right">
                  <MDBIcon icon="clock" /> 53 min
                </span>
              </MDBDropdownItem>
            </MDBDropdownMenu>
          </MDBDropdown> 
          <MDBNavItem>
            <MDBNavLink to="#">
              <MDBIcon icon="comments" />
              <span className="d-none d-md-inline ml-1">Pesan</span>
            </MDBNavLink>
          </MDBNavItem>
          */}
          <MDBDropdown>
            <MDBDropdownToggle nav caret>
              <MDBIcon icon="user" />{" "}
              <span className="d-none d-md-inline" style={{textTransform: 'capitalize'}}>{userFullName}</span>
            </MDBDropdownToggle>
            <MDBDropdownMenu right style={{ minWidth: "200px" }}>
              <MDBDropdownItem onClick={doLogout}>Log Out</MDBDropdownItem>
              <MDBDropdownItem onClick={() => goTo('/profile')}>My Account</MDBDropdownItem>
            </MDBDropdownMenu>
          </MDBDropdown>
        </MDBNavbarNav>
      </MDBNavbar>
    </Router>
  );
};

export default TopNavigationFunc;
