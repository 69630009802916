import React, {useEffect, useState} from "react";
import {MDBCol, MDBInput, MDBRow} from "mdbreact";
import {
  getAnnouncementRecipientTypes,
  RECIPIENT_TYPE_ADMIN,
  RECIPIENT_TYPE_ADMIN_GROUP,
  RECIPIENT_TYPE_CLASS,
  RECIPIENT_TYPE_GROUP,
  RECIPIENT_TYPE_PARTICIPANT
} from "../configs/global";
import BaseRequest from "../../../services/BaseRequest";
import AsyncPaginate from "react-select-async-paginate";
import DateTimePicker from 'react-datetime-picker';
import InputError from "../../components/InputError";

const RecipientTypes = (props) => {
  const {
    announcement,
    watcher,
    setValue,
    getValues,
    errors,
    clearError
  } = props;

  const [recipientType, setRecipientType] = useState(watcher('type_recipient') || '');
  const [radioInputs, setRadioInputs] = useState([]);
  const [recipientValues, setRecipientValues] = useState([]);
  const [recipientPlaceHolder, setRecipientPlaceHolder] = useState('');
  const [selectedAllRecipient, setSelectedAllRecipient] = useState(watcher('select_all_recipient') || '');
  const [isSetTiming, setIsSetTiming] = useState(!watcher('is_timeless') || false);

  /** Initialize the radio inputs **/
  const onRecipientTypeInputChange = (value) => {
    setRecipientType(value);
    setValue('type_recipient', value);
    setValue('select_all_recipient', null);

    // Re-initialize some data
    setSelectedAllRecipient('');
    setRecipientValues([]);
    clearError(['classes', 'groups', 'admin_groups', 'participants', 'admins']);

    let placeholder = '';
    switch (value) {
      case RECIPIENT_TYPE_CLASS:
        placeholder = 'Pilih kelas/program...';
        break;
      case RECIPIENT_TYPE_GROUP:
        placeholder = 'Pilih group...';
        break;
      case RECIPIENT_TYPE_ADMIN_GROUP:
        placeholder = 'Pilih admin group...';
        break;
      case RECIPIENT_TYPE_PARTICIPANT:
        placeholder = 'Pilih peserta...';
        break;
      case RECIPIENT_TYPE_ADMIN:
        placeholder = 'Pilih admin...';
        break;
      default:
        break;
    }

    setRecipientPlaceHolder(placeholder);
  };

  useEffect(() => {
    let options = getAnnouncementRecipientTypes();
    let opts = []

    for (let x = 0; x < options.length; x++) {
      opts.push({
        ...options[x],
        checked: (announcement && announcement.type_recipient === options[x].value)
      })
    }

    const fieldName = "type_recipient";
    let radioInputs = opts.map((option, i) =>
      <MDBInput
        key={i}
        id={`radio-${i}`}
        className={(errors.type_recipient || errors[option.fieldName]) && 'is-invalid'}
        name={fieldName}
        onClick={() => onRecipientTypeInputChange(option.value)}
        type="radio"
        label={option.text}
        iconClass="black-text"
        value={option.value}
        checked={option.checked || recipientType === option.value}
      />
    );

    setRadioInputs(radioInputs);
  }, [announcement, recipientType, errors]);

  useEffect(() => {
    switch (announcement.type_recipient) {
      case RECIPIENT_TYPE_CLASS:
        setRecipientValues(announcement.classes.map(data => ({ label: data.name, value: data.id })))
        break;
      case RECIPIENT_TYPE_GROUP:
        setRecipientValues(announcement.groups.map(data => ({ label: data.name, value: data.id })))
        break;
      case RECIPIENT_TYPE_ADMIN_GROUP:
        setRecipientValues(announcement.admin_groups.map(data => ({ label: data.name, value: data.id })))
        break;
      case RECIPIENT_TYPE_PARTICIPANT:
        setRecipientValues(announcement.participants.map(data => ({ label: data.name, value: data.id })))
        break;
      case RECIPIENT_TYPE_ADMIN:
        setRecipientValues(announcement.admins.map(data => ({ label: data.name, value: data.id })))
        break;
    }
  }, [announcement])

  /** Load recipient options **/
  const loadClassOptions = async (search, loadedOptions, {page}) => {
    let apiUri = '/classes';
    let nameField = 'name';

    const {
      data: {data}
    } = await BaseRequest.post(apiUri, {
      limit: 10,
      sortBy: nameField,
      desc: false,
      field_search: nameField,
      search: search,
      page: page
    });

    let options = data.data.map((v) => {
      return {
        label: v[nameField],
        value: v.id
      }
    });

    return {
      options: options,
      hasMore: data && (data.to < data.total),
      additional: {
        page: page + 1,
      },
    };
  }

  const loadGroupOptions = async (search, loadedOptions, {page}) => {
    let apiUri = '/groups';
    let nameField = 'name_id';

    const {
      data: {data}
    } = await BaseRequest.post(apiUri, {
      limit: 10,
      sortBy: nameField,
      desc: false,
      field_search: nameField,
      search: search,
      page: page
    });

    let options = data.data.map((v) => {
      return {
        label: v[nameField],
        value: v.id,
      }
    });

    return {
      options: options,
      hasMore: data && (data.to < data.total),
      additional: {
        page: page + 1,
      },
    };
  }

  const loadAdminGroupOptions = async (search, loadedOptions, {page}) => {
    let apiUri = '/user-admin-group';
    let nameField = 'name';

    const {
      data: {data}
    } = await BaseRequest.post(apiUri, {
      limit: 10,
      sortBy: nameField,
      desc: false,
      field_search: nameField,
      search: search,
      page: page
    });

    let options = data.data.map((v) => {
      return {
        label: v[nameField],
        value: v.id,
      }
    });

    return {
      options: options,
      hasMore: data && (data.to < data.total),
      additional: {
        page: page + 1,
      },
    };
  }

  const loadParticipantOptions = async (search, loadedOptions, {page}) => {
    let apiUri = '/participants';
    let nameField = 'name';

    const {
      data: {data}
    } = await BaseRequest.post(apiUri, {
      limit: 10,
      sortBy: nameField,
      desc: false,
      field_search: nameField,
      search: search,
      page: page
    });

    let options = data.data.map((v) => {
      return {
        label: v[nameField],
        value: v.id,
      }
    });

    return {
      options: options,
      hasMore: data && (data.to < data.total),
      additional: {
        page: page + 1,
      },
    };
  }

  const loadAdminOptions = async (search, loadedOptions, {page}) => {
    let apiUri = '/user-admin';
    let nameField = 'name';

    const {
      data: {data}
    } = await BaseRequest.post(apiUri, {
      limit: 10,
      sortBy: nameField,
      desc: false,
      field_search: nameField,
      search: search,
      page: page
    });

    let options = data.data.map((v) => {
      return {
        label: v[nameField],
        value: v.id,
      }
    });

    return {
      options: options,
      hasMore: data && (data.to < data.total),
      additional: {
        page: page + 1,
      },
    };
  }

  /** Select all recipient handler **/
  const selectAllRecipients = (event) => {
    if (event.target.checked) {
      setSelectedAllRecipient(recipientType);
      setValue('select_all_recipient', recipientType);
    } else {
      setSelectedAllRecipient('');
      setValue('select_all_recipient', null);
    }
  }

  const handleSelectRecipientChange = (field, values) => {
    setValue(field, values);
    setRecipientValues(values);
  }

  const handleSetTimeInputChange = (isChecked) => {
    setValue('is_timeless', !isChecked);
    setIsSetTiming(isChecked);

    if (isChecked) {
      let {
        start_date,
        end_date
      } = getValues();

      setValue('start_date', start_date || new Date());
      setValue('end_date', end_date || new Date());
    } else {
      clearError(['start_date', 'end_date']);
    }
  }

  const handleDateInputChange = (field, value) => {
    setValue(field, value);

    if (field === 'start_date') {
      let endDate = watcher('end_date');
      if (value > endDate) setValue('end_date', null);
    }
  }

  return (
    <>
      <div className="md-form" style={{marginTop: "2.5rem"}}>
        <label style={{marginTop: "-1.5rem"}}>Penerima</label>
      </div>
      <MDBRow>
        {radioInputs}
      </MDBRow>
      <MDBRow style={{marginTop: "-1rem"}}>
        <MDBCol>
          {
            errors.type_recipient &&
            errors.type_recipient.type === "required" && (
              <InputError text="Tidak boleh kosong" className='d-block'/>
            )
          }
        </MDBCol>
      </MDBRow>
      <MDBRow>
        {
          (recipientType || watcher('type_recipient')) &&
          <MDBInput
            type="checkbox"
            onChange={selectAllRecipients}
            label="Pilih Semua"
            id={`select-all-${recipientType}`}
            checked={selectedAllRecipient && recipientType === selectedAllRecipient}
          />
        }
      </MDBRow>
      <MDBRow>
        <MDBCol>
          {
            (recipientType === RECIPIENT_TYPE_CLASS || watcher('type_recipient') === RECIPIENT_TYPE_CLASS) &&
            <>
              <AsyncPaginate
                value={recipientValues}
                loadOptions={loadClassOptions}
                onChange={(value) => handleSelectRecipientChange('classes', value)}
                additional={{
                  page: 1,
                }}
                isMulti
                closeMenuOnSelect={false}
                placeholder={recipientPlaceHolder}
                isDisabled={selectedAllRecipient === RECIPIENT_TYPE_CLASS}
              />
              {
                errors.classes && (
                  <InputError text={errors.classes.message} className='d-block'/>
                )
              }
            </>
          }
          {
            recipientType === RECIPIENT_TYPE_GROUP &&
            <>
              <AsyncPaginate
                value={recipientValues}
                loadOptions={loadGroupOptions}
                onChange={(value) => handleSelectRecipientChange('groups', value)}
                additional={{
                  page: 1,
                }}
                isMulti
                closeMenuOnSelect={false}
                placeholder={recipientPlaceHolder}
                isDisabled={selectedAllRecipient === RECIPIENT_TYPE_GROUP}
              />
              {
                errors.groups && (
                  <InputError text={errors.groups.message} className='d-block'/>
                )
              }
            </>
          }
          {
            recipientType === RECIPIENT_TYPE_ADMIN_GROUP &&
            <>
              <AsyncPaginate
                value={recipientValues}
                loadOptions={loadAdminGroupOptions}
                onChange={(value) => handleSelectRecipientChange('admin_groups', value)}
                additional={{
                  page: 1,
                }}
                isMulti
                closeMenuOnSelect={false}
                placeholder={recipientPlaceHolder}
                isDisabled={selectedAllRecipient === RECIPIENT_TYPE_ADMIN_GROUP}
              />
              {
                errors.admin_groups && (
                  <InputError text={errors.admin_groups.message} className='d-block'/>
                )
              }
            </>
          }
          {
            recipientType === RECIPIENT_TYPE_PARTICIPANT &&
            <>
              <AsyncPaginate
                value={recipientValues}
                loadOptions={loadParticipantOptions}
                onChange={(value) => handleSelectRecipientChange('participants', value)}
                additional={{
                  page: 1,
                }}
                isMulti
                closeMenuOnSelect={false}
                placeholder={recipientPlaceHolder}
                isDisabled={selectedAllRecipient === RECIPIENT_TYPE_PARTICIPANT}
              />
              {
                errors.participants && (
                  <InputError text={errors.participants.message} className='d-block'/>
                )
              }
            </>
          }
          {
            recipientType === RECIPIENT_TYPE_ADMIN &&
            <>
              <AsyncPaginate
                value={recipientValues}
                loadOptions={loadAdminOptions}
                onChange={(value) => handleSelectRecipientChange('admins', value)}
                additional={{
                  page: 1,
                }}
                isMulti
                closeMenuOnSelect={false}
                placeholder={recipientPlaceHolder}
                isDisabled={selectedAllRecipient === RECIPIENT_TYPE_ADMIN}
              />
              {
                errors.admins && (
                  <InputError text={errors.admins.message} className='d-block'/>
                )
              }
            </>
          }
        </MDBCol>
      </MDBRow>
      <MDBRow className="mt-2">
        <MDBInput
          type="checkbox"
          id="is_timeless"
          label="Atur batasan waktu"
          onChange={e => handleSetTimeInputChange(e.target.checked)}
          checked={!watcher('is_timeless')}
        />
      </MDBRow>
      <MDBRow>
        <MDBCol size="4">
          <label htmlFor="defaultFormLoginEmailEx" className="grey-text">
            Tanggal Awal
          </label>
          <DateTimePicker
            onChange={(value) => handleDateInputChange('start_date', value)}
            value={watcher('start_date')}
            format="dd-MM-yyyy"
            className="form-control"
            placeholder="Tanggal Awal"
            disabled={!isSetTiming}
          />
          {
            errors.start_date && (
              <InputError text={errors.start_date.message} className='d-block'/>
            )
          }
        </MDBCol>
        <MDBCol size="4">
          <label htmlFor="defaultFormLoginEmailEx" className="grey-text">
            Tanggal Akhir
          </label>
          <DateTimePicker
            onChange={(value) => handleDateInputChange('end_date', value)}
            value={watcher('end_date')}
            format="dd-MM-yyyy"
            className="form-control"
            placeholder="Tanggal Akhir"
            disabled={!isSetTiming}
            minDate={watcher('start_date')}
          />
          {
            errors.end_date && (
              <InputError text={errors.end_date.message} className='d-block'/>
            )
          }
        </MDBCol>
      </MDBRow>
    </>
  );
}

export default RecipientTypes;
