import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useContext,
} from "react";
import moment from "moment";
import BaseRequest from "../../../../../services/BaseRequest";
import { useHistory } from "react-router-dom";
import {
  MDBContainer,
  MDBBtn,
  MDBBtnFixed,
  MDBRow,
  MDBCol,
  MDBIcon,
  toast,
} from "mdbreact";
import { default as CustomDataTable } from "../../../../components/DataTable";
import { TextField, MenuItem } from "@material-ui/core";
import DeleteModal from "../../../../modals/DeleteModal";
import StatisticModal from "./StatisticModal";
import { AccessContext } from "../../../../AccessContext";

const thisUri = "/kurikulum/ujian-evaluasi/kuis";
const apiUri = "/quizzes";

const KuisPage = () => {
  const history = useHistory();

  const menu = "kuis";
  const access = useContext(AccessContext);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedDeleteItem, setSelectedDeleteItem] = useState({});
  const [deleteLoading, setDeleteLoading] = useState(false);

  /** Datatable variables **/
  const [dtRowsData, setDtRowsData] = useState([]);
  const [dtSortBy, setDtSortBy] = useState("created_at");
  const [dtSortDirection, setDtSortDirection] = useState("desc");
  const [dtTotalRow, setDtTotalRow] = useState(1);
  const [dtPage, setDtPage] = useState(1);
  const [dtPerPage, setDtPerPage] = useState(10);
  const [dtSearchMeta, setDtSearchMeta] = useState({
    filter: {},
    field_search: "title_id, title_en, title_ar",
    search: "",
    with: "course,coursePart",
    classes_id: "",
  });
  const [loadProgressData, setLoadProgressData] = useState(true);
  const [showStatisticModal, setShowStatisticModal] = useState(false);
  const [selectedQuiz, setSelectedQuiz] = useState(null);
  const [classes, setClasses] = useState([]);
  const [courses, setCourses] = useState([]);
  const [classname, setClassname] = useState("");
  const [course, setCourse] = useState("");
  const [search, setSearch] = useState("");

  /** Datatable column definition **/
  const columns = [
    {
      name: "No",
      selector: "rowNo",
      width: "4rem",
      right: true,
    },
    {
      name: "Judul (ID)",
      selector: "title_id",
    },
    {
      cell: (row) =>
        row.course_id ?
          (access.isAccessible('mata-kuliah', 'edit')?
            (<a
              href={`/perkuliahan/mata-kuliah/${row.course_id}/edit`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {row.course_title_id}
            </a>)
            :
            row.course_title_id
          ) : ("-"),
      name: "Mata Kuliah",
      selector: "course_title_id",
      style: {
        whiteSpace: "none",
      },
      width: "12rem"
    },
    {
      cell: (row) =>
        row.course_part_id ?
          (access.isAccessible('pertemuan', 'edit')? (
            <a
              href={`/perkuliahan/pertemuan/${row.course_part_id}/edit`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {row.course_part_title_id}
            </a>
          ) :
          row.course_part_title_id
         )
        : ("-"),
      name: "Pertemuan",
      selector: "course_part_title_id",
      width: "12rem"
    },
    {
      name: "Pertanyaan",
      selector: "number_of_questions",
      width: "7rem",
    },
    {
      name: "Kategori",
      selector: "category",
      width: "6rem",
    },
    {
      name: "Dibuat Tanggal",
      selector: "created_at",
      width: "8rem",
    },
    {
      cell: (row) => (
        <div className="btn-group-sm">
          {access.isAccessible(menu, "stats") && (
            <MDBBtn
              variant="contained"
              title="Statistik"
              color="blue"
              onClick={() => toggleStatisticModal(row)}
            >
              <MDBIcon icon="percent" />
            </MDBBtn>
          )}
          {access.isAccessible(menu, "edit") && (
            <MDBBtn
              variant="contained"
              color="yellow"
              onClick={() => history.push(`${thisUri}/${row.id}/edit`)}
            >
              <MDBIcon icon="pen-nib" />
            </MDBBtn>
          )}
          {access.isAccessible(menu, "delete") && (
            <MDBBtn
              variant="contained"
              color="red"
              onClick={() => toggleDeleteModal(row.id)}
            >
              <MDBIcon icon="trash" />
            </MDBBtn>
          )}
          {access.isAccessible(menu, "reset") && (
            <MDBBtn
              variant="contained"
              title="Reset Kuis"
              color="blue"
              onClick={() =>
                history.push(
                  `${thisUri}/${row.id}/${row.course_part_id}/${row.course.classes_id}/reset`
                )
              }
            >
              <MDBIcon icon="redo" />
            </MDBBtn>
          )}
          <MDBBtn 
            variant="contained" 
            title="Archive" 
            color="blue"  
            onClick={() => 
              history.push(
                `${thisUri}/${row.id}/archive`
              )
            }
          >
            <MDBIcon icon="file-archive" />
          </MDBBtn>
        </div>
      ),
      button: true,
      width: "8rem",
    },
  ];

  const ExpandedRow = ({ data }) => (
    <div className="p-3">
      <h5><u>Detil Kuis</u></h5>
      <ul style={{fontSize:14}}>
        <li>ID: {data.id}</li>
        <li>Durasi Pengerjaan: {data.time} dtk</li>
        <li>Bobot jawaban (Benar/Salah/Tidak Dijawab): {data.weight_correct}/{data.weight_wrong}/{data.weight_not_answer}</li>
        <li>Jenis Waktu: {data.time_status}</li>
        <li>Waktu Kuis: {data.time_open} s/d {data.time_close}</li>
        <li>Terakhir Disunting: {data.updated_at}</li>
      </ul>
    </div>
  );  

  /** Datatable get data **/
  const getQuizData = useCallback(
    async (page = 1, perPage = 10) => {
      let uri = `${apiUri}?with=course,coursePart`;

      try {
        setLoadProgressData(true);

        const {
          data: { data },
        } = await BaseRequest.post(uri, {
          sortBy: dtSortBy,
          desc: dtSortDirection === "desc",
          page: page,
          limit: perPage,
          ...dtSearchMeta,
        });

        setDtTotalRow(data.total);

        // Map the questions data
        const mappedData = data.data.map((v, i) => ({
          ...v,
          rowNo: data.from + i,
          id: v.id,
          course_title_id: v.course ? v.course.title_id : "",
          course_part_title_id: v.course_part ? v.course_part.title_id : "",
          title_id: v.title_id,
          number_of_questions: v.number_of_questions,
          time_status: v.time_status,
          category: v.category,
          title_en: v.title_en,
          title_ar: v.title_ar,
          created_at: moment(v.created_at).format("DD/MM/YYYY"),
          updated_at: moment(v.updated_at).format("DD/MM/YYYY"),
        }));

        return { rows: mappedData };
      } catch (error) {
        let errorMessage = error.response
          ? error.response.data.message
          : error.message;
        toast.error(errorMessage, {
          closeButton: false,
          position: "bottom-right",
        });
      }

      return { rows: [] };
    },
    [dtSearchMeta, dtSortBy, dtSortDirection]
  );

  const getClasses = useCallback(async () => {
    try {
      setLoadProgressData(true);
      const response = await BaseRequest.post(`/classes`);
      setClasses(response.data.data.data);
    } catch (error) {
      let errorMessage = error.response
        ? error.response.data.message
        : error.message;
      toast.error(errorMessage, {
        closeButton: false,
        position: "bottom-right",
      });
    }
  }, []);

  const getCourses = useCallback(async () => {
    try {
      setLoadProgressData(true);
      const response = await BaseRequest.post(`/courses`, {
        classes_id: dtSearchMeta.classes_id
      });
      setCourses(response.data.data.data);
    } catch (error) {
      let errorMessage = error.response
        ? error.response.data.message
        : error.message;
      toast.error(errorMessage, {
        closeButton: false,
        position: "bottom-right",
      });
    }
  }, [dtSearchMeta.classes_id]);

  const reloadQuizDataFromServer = useCallback(
    (page = 1, perPage = 10) => {
      getQuizData(page, perPage)
        .then((res) => {
          const { rows } = res;

          setDtRowsData(rows);
        })
        .finally(() => {
          setLoadProgressData(false);
        });
    },
    [getQuizData]
  );

  useEffect(() => {
    reloadQuizDataFromServer(dtPage, dtPerPage);
    getClasses();
    getCourses();
  }, [dtPage, dtPerPage, getClasses, getCourses, reloadQuizDataFromServer]);

  /** Datatable sorting **/
  const onSortHandler = (column, sortDirection) => {
    setDtSortBy(column.selector);
    setDtSortDirection(sortDirection);

    reloadQuizDataFromServer(dtPage, dtPerPage);
  };

  /** Datatable Filter **/
  const subHeaderComponentMemo = useMemo(() => {
    const handleFilter = (keyword) => {
      setLoadProgressData(true);
      setSearch(keyword);
      let newSearchMeta = dtSearchMeta;
      newSearchMeta.search = keyword;
      setDtSearchMeta(newSearchMeta);

      getQuizData(newSearchMeta).then((res) => {
        const { rows } = res;
        setDtRowsData(rows);
        setLoadProgressData(false);
      });
    };

    const handleFilterByClass = (keyword) => {
      setLoadProgressData(true);
      setClassname(keyword);
      setCourse("");
      let newSearchMeta = dtSearchMeta;
      newSearchMeta.classes_id = keyword;
      setDtSearchMeta(newSearchMeta);

      getQuizData(newSearchMeta).then((res) => {
        const { rows } = res;
        setDtRowsData(rows);
        setLoadProgressData(false);
      });
    };

    const handleFilterByCourse = (keyword) => {
      setLoadProgressData(true);
      setCourse(keyword);
      let newSearchMeta = dtSearchMeta;
      newSearchMeta.filter = { course_part_id: "", course_id: keyword };
      setDtSearchMeta(newSearchMeta);

      getQuizData(newSearchMeta).then((res) => {
        const { rows } = res;
        setDtRowsData(rows);
        setLoadProgressData(false);
      });
    };

    return (
      <MDBRow className={"w-100"}>
        <MDBCol size={"4"} md={"4"} sm={"12"}>
          <TextField
            label="Filter by Judul"
            onChange={(e) => handleFilter(e.target.value)}
            className={"w-100"}
            name="search"
            value={search}
          />
        </MDBCol>
        <MDBCol size={"4"} md={"4"} sm={"12"}>
          <TextField
            id="select-classes"
            select
            value={classname}
            onChange={(e) => handleFilterByClass(e.target.value)}
            className={"w-100"}
            label="Filter by Program/Kelas"
          >
            {classes.map((data, index) => (
              <MenuItem key={index} value={data.id}>
                {data.name}
              </MenuItem>
            ))}
          </TextField>
        </MDBCol>
        <MDBCol size={"4"} md={"4"} sm={"12"}>
          <TextField
            id="select-classes"
            select
            value={course}
            onChange={(e) => handleFilterByCourse(e.target.value)}
            className={"w-100"}
            label="Filter by Mata Kuliah"
          >
            {courses.map((data, index) => (
              <MenuItem key={index} value={data.id}>
                {data.title_id}
              </MenuItem>
            ))}
          </TextField>
        </MDBCol>
      </MDBRow>
    );
  }, [classes, classname, course, courses, dtSearchMeta, getQuizData, search]);

  const toggleDeleteModal = (id) => {
    setSelectedDeleteItem(id);
    setShowDeleteModal(!showDeleteModal);
  };

  const toggleStatisticModal = (quiz) => {
    setSelectedQuiz(quiz);
    setShowStatisticModal(!showStatisticModal);
  };

  const handleDelete = async () => {
    try {
      setDeleteLoading(true);

      await BaseRequest.delete(`${apiUri}/${selectedDeleteItem}`);
      reloadQuizDataFromServer(dtPage, dtPerPage);
    } catch (err) {
      console.error(err);
    } finally {
      toggleDeleteModal();
      setDeleteLoading(false);
    }
  };

  /** Datatable Change Page handler **/
  const onPageChangeHandler = (page) => {
    setLoadProgressData(true);
    reloadQuizDataFromServer(page);
  };

  const onPerRowsChangeHandler = (perPage, page) => {
    setLoadProgressData(true);
    reloadQuizDataFromServer(page, perPage);
  };

  const resetFilter = () => {
    setDtSearchMeta({
      filter: {},
      field_search: "title_id, title_en, title_ar",
      search: "",
      with: "course,coursePart",
      classes_id: "",
    });
    setClassname("");
    setCourse("");
    setSearch("");
  };

  return (
    <MDBContainer fluid>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h3>Kuis</h3>
        <button
          style={{
            border: "none",
            borderRadius: 6,
            color: "white",
            backgroundColor: "#36454F",
            padding: "2px 11px",
            fontSize: 12
          }}
          onClick={() => resetFilter()}
        >
          Reset Filter
        </button>
      </div>

      <div>
        <CustomDataTable
          columns={columns}
          data={dtRowsData}
          totalRow={dtTotalRow}
          onSortHandler={onSortHandler}
          subHeaderComponent={subHeaderComponentMemo}
          progressPending={loadProgressData}
          handlePerRowsChange={onPerRowsChangeHandler}
          handlePageChange={onPageChangeHandler}
          expandableRows={true}
          expandableRowsComponent={<ExpandedRow />}
        />
      </div>

      {access.isAccessible('kuis', 'create') && 
        <MDBBtnFixed
          floating
          color="blue"
          icon="plus"
          size="lg"
          style={{ bottom: "55px", right: "24px" }}
          onClick={() => history.push(`${thisUri}/create`)}
          className="no-li"
        />
      }


      <DeleteModal
        isOpen={showDeleteModal}
        toggle={toggleDeleteModal}
        handler={handleDelete}
        loadingIndicator={deleteLoading}
      />

      <StatisticModal
        isOpen={showStatisticModal}
        toggle={toggleStatisticModal}
        quiz={selectedQuiz}
      />
    </MDBContainer>
  );
};

export default KuisPage;
