import { SET_ACCESS } from './type';

export const setAccess = (role) => dispatch => {
  let access;

  // Nama 'super admin' disesuaikan dengan nama role di database.
  // Bisa juga pakai id role nya sih. Yang mau refactor, silahkan. Tapi di fitur login nya juga harus di refactor
  // Agar localstorage nya terisi role id dari si user
  switch (role) {
    case 'super admin':
      access = require('./json/superAdmin.json');
      break;
    case 'admin kurikulum':
      access = require('./json/adminKurikulum.json');
      break;
    case 'admin pusat':
      access = require('./json/koordinatorAdminPusat.json');
      break;
    case 'admin group':
      access = require('./json/adminGroupRombel.json');
      break;
    case 'tim printing syahadah':
      access = require('./json/timPrintingSyahadah.json');
      break;
    case 'asatidz':
      access = require('./json/asatidz.json');
      break;
    default:
      access = {};
  }

  dispatch({
    type: SET_ACCESS,
    payload: access
  });
};
