import React, { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment";
import BaseRequest from "../../../services/BaseRequest";
import { useHistory } from "react-router-dom";
import {
  MDBContainer,
  MDBBtn,
  MDBIcon,
  MDBBtnFixed,
  toast,
  MDBRow,
  MDBCol,
  MDBSelect,
} from "mdbreact";
import DeleteModal from "../../modals/DeleteModal";
import TextField from "@material-ui/core/TextField";
import {
  getGenderOptions,
  getStatusOptions,
  getRoleOptions,
} from "../configs/global";
import InfiniteTable from "components/components/InfiniteTable";

const thisUri = "/users/user-groups";
const apiUri = "/users";

const UserGroupPage = () => {
  const history = useHistory();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedDeleteItem, setSelectedDeleteItem] = useState({});
  const [deleteLoading, setDeleteLoading] = useState(false);

  /** Datatable variables **/
  const [dtRowsData, setDtRowsData] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [dtHasMore, setDtHasMore] = useState(false);
  const [dtSortBy, setDtSortBy] = useState("created_at");
  const [dtSortDirection, setDtSortDirection] = useState("desc");
  const [dtPage, setDtPage] = useState(1);
  const [dtPerPage, setDtPerPage] = useState(20);
  const [dtSearchMeta, setDtSearchMeta] = useState({
    filter: {
      role_id: {
        in: [
          "16f6ed41-7bc6-477c-a2b6-b7d0f3f99240", //admin kurikulum
          "3254fd49-3688-49b6-b27e-10b67e0407bb", //admin group
          "4054dc8b-b517-4338-b412-d0bb9b3ee76f", //koordinator admin wa
          "47bebd34-bd92-4f31-b01e-39145dacfd24", //ustadz
          "7be566e9-3fad-426d-8036-aad53bb1a143", //admin pusat
          "8449b545-6fbc-499e-8016-294ed43513d8", //superadmin
          "f786fea4-a40f-46ec-9c72-b340872ae6ab", //other system
          // "32e94719-cc61-4358-9ccd-8e81bbcd961a", //mahasiswa
          // "9264b6a4-e9dd-4610-a774-526991bc3588", //peserta
        ],
      },
    },
    field_search: "name, email, nip, gender, phone, status, role_id",
    search: "",
  });

  /** Datatable column definition **/

  /** Datatable get data **/
  const getUserGroups = useCallback(
    async (page = 1, perPage = 20, keyword) => {
      let uri = `${apiUri}`;

      try {
        const {
          data: { data },
        } = await BaseRequest.post(uri, {
          sortBy: dtSortBy,
          desc: dtSortDirection === "desc",
          page: page,
          limit: perPage,
          ...dtSearchMeta,
        });

        // Map the questions data
        const mappedData = data.data.map((v, i) => ({
          ...v,
          rowNo: data.from + i,
          gender: getGenderOptions(v.gender)[0].text,
          status: getStatusOptions(v.status)[0].text,
          created_at: moment(v.created_at).format("DD/MM/YYYY"),
          updated_at: moment(v.updated_at).format("DD/MM/YYYY"),
          role: v.role?.name,
        }));

        return { rows: mappedData, total: data.total };
      } catch (error) {
        let errorMessage = error.response
          ? error.response.data.message
          : error.message;
        toast.error(errorMessage, {
          closeButton: false,
          position: "bottom-right",
        });
      }

      return { rows: [] };
    },
    [dtSearchMeta, dtSortBy, dtSortDirection]
  );

  const reloadDataFromServer = useCallback(
    (page = 1, perPage = 20, keyword) => {
      if (page === 1) {
        setDtRowsData([]);
        setDtHasMore(true);
      }
      getUserGroups(page, perPage, keyword).then((res) => {
        const { rows, total } = res;

        if (page === 1) {
          setDtRowsData(rows);
          setDtHasMore(total > rows.length);
        } else {
          setDtRowsData((currentRows) => {
            const allRows = [...currentRows, ...rows];
            setDtHasMore(total > allRows.length);
            return allRows;
          });
        }
      });
    },
    [getUserGroups]
  );

  useEffect(() => {
    reloadDataFromServer(dtPage, dtPerPage, keyword);
  }, [reloadDataFromServer, dtPage, dtPerPage, keyword]);

  const toggleDeleteModal = (id) => {
    setSelectedDeleteItem(id);
    setShowDeleteModal(!showDeleteModal);
  };

  const handleDelete = async () => {
    try {
      setDeleteLoading(true);

      await BaseRequest.delete(`${apiUri}/${selectedDeleteItem}`);
      reloadDataFromServer(dtPage, dtPerPage);
    } catch (err) {
      console.error(err);
    } finally {
      toggleDeleteModal();
      setDeleteLoading(false);
    }
  };

  /** Datatable Filter **/
  const filterMemo = useMemo(() => {
    const handleFilter = (keyword, filter = null) => {
      let searchMeta = Object.assign(dtSearchMeta);

      if (filter) {
        searchMeta.filter = {
          ...searchMeta.filter,
          [filter]: keyword,
        };
      } else {
        searchMeta.search = keyword;
      }

      setKeyword(keyword);

      setDtSearchMeta(searchMeta);
      setDtPage(1);
    };

    const handleStatusChange = (val) => {
      handleFilter(val[0], "status");
    };

    const handleGenderChange = (val) => {
      handleFilter(val[0], "gender");
    };

    const handleRoleChange = (val) => {
      handleFilter(val[0], "role_id");
    };

    return (
      <MDBRow className={"w-100"}>
        <MDBCol size={"12"} md={"3"} sm={"12"}>
          <TextField
            label="Search"
            onChange={(e) => handleFilter(e.target.value)}
            className={"w-100"}
            name="search"
          />
        </MDBCol>
        <MDBCol size={"12"} md={"3"} sm={"12"}>
          <MDBSelect
            className={"w-100"}
            name="gender"
            getValue={handleGenderChange}
            options={getGenderOptions()}
            selected="Pilihan Gender"
            label="Gender"
          />
        </MDBCol>
        <MDBCol size={"12"} md={"3"} sm={"12"}>
          <MDBSelect
            className={"w-100"}
            name="status"
            getValue={handleStatusChange}
            options={getStatusOptions()}
            selected="Pilihan Status"
            label="Status"
          />
        </MDBCol>
        <MDBCol size={"12"} md={"3"} sm={"12"}>
          <MDBSelect
            className={"w-100"}
            name="role"
            getValue={handleRoleChange}
            options={getRoleOptions()}
            selected="Pilihan Role"
            label="Role"
          />
        </MDBCol>
      </MDBRow>
    );
  }, [dtSearchMeta, getUserGroups]);

  const columns = React.useMemo(
    () => [
      {
        Header: "No",
        accessor: "rowNo",
        style: {
          width: 4,
        },
        right: true,
      },
      {
        Header: "Nama",
        accessor: "name",
        sortable: true,
        style: {
          whiteSpace: "none",
        },
      },
      {
        Header: "NIP",
        accessor: "nip",
        sortable: true,
        style: {
          whiteSpace: "none",
        },
      },
      {
        Cell: (row) => <a href={`mailto:${row.value}`}>{row.value}</a>,
        Header: "Email",
        accessor: "email",
        sortable: true,
      },
      {
        Cell: (row) =>
          `${row.cell.row.original.phone_country_code} ${row.value}`,
        Header: "No. Telepon",
        accessor: "phone",
        sortable: true,
        style: {
          width: 1,
        },
      },
      {
        Header: "Gender",
        accessor: "gender",
        sortable: true,
        style: {
          width: 6,
        },
      },
      {
        Header: "Status",
        accessor: "status",
        sortable: true,
        style: {
          width: 6,
        },
      },
      {
        Header: "Role",
        accessor: "role",
        sortable: false,
        style: {
          width: 6,
        },
      },
      {
        Header: "Dibuat Tanggal",
        accessor: "created_at",
        sortable: true,
        style: {
          width: 8,
        },
      },
      {
        Header: "Disunting Tanggal",
        accessor: "updated_at",
        sortable: true,
        style: {
          width: 8,
        },
      },
      {
        accessor: "id",
        Cell: (row) => (
          <div className="btn-group-sm">
            <MDBBtn
              variant="contained"
              color="yellow"
              onClick={() => history.push(`${thisUri}/${row.value}/edit`)}
            >
              <MDBIcon icon="pen-nib" />
            </MDBBtn>
            <MDBBtn
              variant="contained"
              color="red"
              onClick={() => toggleDeleteModal(row.value)}
            >
              <MDBIcon icon="trash" />
            </MDBBtn>
          </div>
        ),
        button: true,
        style: {
          width: "107px",
        },
      },
    ],
    []
  );

  const fetchMoreData = () => {
    setDtPage((dtPage) => dtPage + 1);
  };

  const data = React.useMemo(() => dtRowsData, [dtRowsData]);

  return (
    <MDBContainer fluid>
      <h3>User Group</h3>

      {filterMemo}

      <InfiniteTable
        columns={columns}
        data={data}
        update={fetchMoreData}
        hasMore={dtHasMore}
      />
      <DeleteModal
        isOpen={showDeleteModal}
        toggle={toggleDeleteModal}
        handler={handleDelete}
        loadingIndicator={deleteLoading}
      />
      <MDBBtnFixed
        floating
        color="blue"
        icon="plus"
        size="lg"
        style={{ bottom: "55px", right: "24px" }}
        onClick={() => history.push(`${thisUri}/create`)}
        className="no-li"
      />
    </MDBContainer>
  );
};

export default UserGroupPage;
