import React, {useState, useEffect, useMemo} from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import {
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBIcon,
  MDBInput,
  MDBSelect,
  MDBSpinner,
  toast,
  MDBBtnFixed,
  MDBBtnFixedItem
} from "mdbreact";
import InputError from "../../../components/InputError";
import BaseRequest from "../../../../services/BaseRequest";
import {default as CustomDataTable} from "../../../components/DataTable";
import moment from "moment";
import KategoriSoalImportFormModal from "./KategoriSoalImportFormModal";
import DeleteModal from "../../../modals/DeleteModal";

const thisUri = "/kurikulum/bank-soal/kategori";
const apiUri = "/question-categories";
const formField = [
  { name: "name", rules: { required: true } }
];

const questionTypes = require('../shared/json/question-types.json');
const questionTypeOptions = [
  { "text": "Semua tipe", "value": "", "checked": true },
].concat(questionTypes);

const KategoriSoalEditFormPage = () => {
  const history = useHistory();

  const { id } = useParams();
  const { register, handleSubmit, errors, setValue, watch, reset } = useForm();

  const [ submitLoading, setSubmitLoading ] = useState(false);
  const [ fixedBtnStyle, setFixedBtnStyle ] = useState({
    transform: "scaleY(0.4) scaleX(0.4) translateY(40px) translateX(0)",
    opacity: "0"
  });

  /** Import Soal Variables **/
  const [ showImportDialog, setShowImportDialog ] = useState(false);

  /** Delete handler variables **/
  const [ showDeleteModal, setShowDeleteModal ] = useState(false);
  const [ deleteLoading, setDeleteLoading ] = useState(false);
  const [ deletedID, setDeletedID ] = useState(null);

  /** Datatable variables **/
  const [ dtRowsData, setDtRowsData ] = useState([]);
  const [ dtSortBy, setDtSortBy ] = useState('created_at');
  const [ dtSortDirection, setDtSortDirection ] = useState('desc');
  const [ dtTotalRow, setDtTotalRow ] = useState(1);
  const [ dtSearchMeta, setDtSearchMeta ] = useState({
    filter: {
      question_category_id: id
    },
    field_search: 'question_id, question_en, question_ar',
    search: ''
  });
  const [ loadProgressData, setLoadProgressData ] = useState(true);

  /** Handle form field changes **/
  const handleChange = (e) => {
    setValue(e.target.name, e.target.value);
  };

  /** Reset form handler **/
  const resetFormHandler = () => {
    setSubmitLoading(true);

    getCategory()
      .then((res) => {
        reset(res.categoryData);
      }).finally(() => {
        setSubmitLoading(false);
    });
  };

  /** Register the form field **/
  useEffect(() => {
    for (let v of formField) {
      register({ name: v.name }, v.rules || null);
    }
  }, [register]);

  /** Get category name **/
  let categoryName = watch('name');
  const getCategory = async () => {
    try {
      const {
        data: { data }
      } = await BaseRequest.get(`${apiUri}/${id}/edit`);

      return { categoryData: data };
    } catch (error) {
      let errorMessage = error.response
        ? error.response.data.message
        : error.message;
      toast.error(errorMessage, {
        closeButton: false,
        position: "bottom-right"
      });
    }
  };

  /** Get question list **/
  const getQuestions = async (page = 1, perPage = 10) => {
    let uri = `questions`;

    try {
      const {
        data: { data }
      } = await BaseRequest.post(uri, {
        sortBy: dtSortBy,
        desc: dtSortDirection === 'desc',
        page: page,
        limit: perPage,
        ...dtSearchMeta
      });

      setDtTotalRow(data.total);

      // Map the questions data
      const mappedData = data.data.map((v, i) => ({
        rowNo: data.from + i,
        id: v.id,
        question_id: v.question_id,
        question_en: v.question_en,
        question_ar: v.question_ar,
        type: questionTypes.filter((e) => e.value === v.type)[0].text,
        created_at: moment(v.created_at).format("DD MMMM YYYY"),
        updated_at: moment(v.updated_at).format("DD MMMM YYYY"),
      }));

      return { rows: mappedData }
    } catch (error) {
      let errorMessage = error.response
        ? error.response.data.message
        : error.message;
      toast.error(errorMessage, {
        closeButton: false,
        position: "bottom-right"
      });
    }

    return { rows: [] }
  };

  const reloadQuestionDataFromServer = (page = 1, perPage = 10) => {
    getQuestions(page, perPage)
      .then((res) => {
        const { rows } = res;

        setDtRowsData(rows);
      })
      .finally(() => {
        setLoadProgressData(false);
      });
  };

  /** Load the category and questions **/
  useEffect(() => {
    setLoadProgressData(true);

    getCategory()
      .then((res) => {
        const { categoryData } = res;

        setValue('name', categoryData.name);
      })
      .finally(() => {
        setLoadProgressData(false);
      });

    reloadQuestionDataFromServer();
  }, [id, setValue]);

  /** Handle form submission **/
  const onSubmit = async (payload) => {
    setSubmitLoading(true);

    try {
      let res = await BaseRequest.put(`question-categories/${id}`, {
        name: payload.name
      });

      toast.success(res.data.message, {
        closeButton: false,
        position: "bottom-right"
      });

      setSubmitLoading(false);
    } catch (error) {
      let errorMessage = error.response
        ? error.response.data.message
        : error.message;
      toast.error(errorMessage, {
        closeButton: false,
        position: "bottom-right"
      });
      setSubmitLoading(false);
    }
  };

  /** Datatable columns definition **/
  const columns = [
    {
      name: "No.",
      selector: "rowNo",
      width: "5rem",
      right: true
    },
    {
      name: "Pertanyaan (ID)",
      selector: "question_id",
      sortable: true
    },
    {
      name: "Pertanyaan (EN)",
      selector: "question_en",
      sortable: true
    },
    {
      name: "Pertanyaan (AR)",
      selector: "question_ar",
      sortable: true
    },
    {
      name: "Tipe",
      selector: "type",
      sortable: true
    },
    {
      name: "Dibuat Tanggal",
      selector: "created_at",
      sortable: true
    },
    {
      name: "Disunting Tanggal",
      selector: "updated_at",
      sortable: true
    },
    {
      cell: (row) =>
        <div className="btn-group-sm">
          <MDBBtn variant="contained" color="yellow" onClick={() => history.push(`${thisUri}/${id}/soal/${row.id}/edit`)} >
            <MDBIcon icon="pen-nib" />
          </MDBBtn>
          <MDBBtn variant="contained" color="red" onClick={() => toggleDeleteModal(row.id)}>
            <MDBIcon icon="trash" />
          </MDBBtn>
        </div>,
      button: true,
    }
  ];

  /** Datatable sorting **/
  const onSortHandler = (column, sortDirection) => {
    setLoadProgressData(true);

    setDtSortBy(column.selector);
    setDtSortDirection(sortDirection);

    getQuestions()
      .then((res) => {
        const {rows} = res;

        setDtRowsData(rows);
        setLoadProgressData(false);
      });
  };

  /** Datatable Filter **/
  const subHeaderComponentMemo = useMemo(() => {
    const handleFilter = (keyword, comesFromSelect = false) => {
      setLoadProgressData(true);

      let newSearchMeta = dtSearchMeta;

      if (comesFromSelect) {
        let selectedValue = keyword;

        if (selectedValue === '') {
          delete newSearchMeta.filter.type;
        } else {
          newSearchMeta.filter = {
            ...newSearchMeta.filter,
            type: selectedValue
          };
        }
      } else {
        newSearchMeta.search = keyword;
      }

      setDtSearchMeta(newSearchMeta);

      getQuestions()
        .then((res) => {
          const { rows } = res;

          setDtRowsData(rows);
          setLoadProgressData(false);
        })
    };

    let checkedOption = questionTypeOptions.find((option) => (option.checked));
    handleFilter(checkedOption && checkedOption.value, true);

    return (
      /** Filter input groups **/
      <MDBCard className={"w-100 data-table__filter-input"}>
        <MDBCardBody>
          <MDBRow className={"w-100"}>
            <MDBCol size={"12"} md={"2"} sm={"12"}>
              <MDBSelect
                label="Filter by Tipe"
                getValue={(val) => handleFilter(val[0], true)}
                className={"w-100"}
                name="type"
                options={questionTypeOptions}
              />
            </MDBCol>
            <MDBCol size={"12"} md={"4"} sm={"12"}>
              <MDBInput
                label="Search"
                onChange={(e) => handleFilter(e.target.value)}
                className={"search-input"}
                name="search"
              />
            </MDBCol>
          </MDBRow>
        </MDBCardBody>
      </MDBCard>
    )
  }, [dtSearchMeta]);

  /** Datatable Change Page handler **/
  const onPageChangeHandler = (page) => {
    setLoadProgressData(true);
    reloadQuestionDataFromServer(page);
  }

  const onPerRowsChangeHandler = (perPage, page) => {
    setLoadProgressData(true);
    reloadQuestionDataFromServer(page, perPage);
  }

  /** Fixed Button event handler **/
  const onHover = () => {
    setFixedBtnStyle({
      transform: "scaleY(1) scaleX(1) translateY(0) translateX(0)",
      opacity: "1"
    })
  };

  const onMouseLeave = () => {
    setFixedBtnStyle({
      transform: "scaleY(0.4) scaleX(0.4) translateY(40px) translateX(0)",
      opacity: "0"
    })
  };

  /** Delete question handler **/
  const toggleDeleteModal = (deletedID) => {
    setShowDeleteModal(!showDeleteModal);
    setDeletedID(deletedID);
  };

  const handleDelete = () => {
    setDeleteLoading(true);

    const deleteData = async () => {
      return await BaseRequest.delete(`questions/${deletedID}`);
    };

    deleteData()
      .then(() => {
        reloadQuestionDataFromServer();
      })
      .finally(() => {
        setShowDeleteModal(false);
        setDeleteLoading(false);
      });
  };

  return (
    <MDBContainer fluid>
      <h3>Edit Kategori Soal</h3>

      <form onSubmit={handleSubmit(onSubmit)}>
        <MDBRow className="mt-3">
          <MDBCol size="12" md="6" sm="12">
            <MDBInput
              label="Nama Kategori"
              className={errors.name && "is-invalid"}
              name="name"
              type="text"
              value={categoryName}
              onChange={handleChange}
            >
              {
                errors.name &&
                errors.name.type === "required" && (
                  <InputError text="Tidak boleh kosong" />
                )
              }
            </MDBInput>
          </MDBCol>
          <MDBCol size="12" md="6" sm="12" middle>
            {
              submitLoading ?
                <MDBSpinner/> :
                <>
                  <MDBBtn type={"reset"} size={"md"} color={"yellow"} onClick={resetFormHandler}>Reset</MDBBtn>
                  <MDBBtn type={"submit"} size={"md"} color={"green"}>Simpan</MDBBtn>
                </>
            }
          </MDBCol>
        </MDBRow>

        {/** Assigned soal list **/}
        <CustomDataTable
          columns={columns}
          data={dtRowsData}
          totalRow={dtTotalRow}
          onSortHandler={onSortHandler}
          subHeaderComponent={subHeaderComponentMemo}
          progressPending={loadProgressData}
          handlePageChange={onPageChangeHandler}
          handlePerRowsChange={onPerRowsChangeHandler}
        />
      </form>

      <MDBRow>
        <MDBCol className="text-left">
          <MDBBtn color="red" onClick={() => history.push(thisUri)} size="sm">Kembali</MDBBtn>
        </MDBCol>
      </MDBRow>

      {/** Menu Button **/}
      <div className={"fixed-btn-group btn-item-2"}>
        <MDBBtnFixed
          floating
          color="blue"
          icon="ellipsis-h"
          size="lg"
          style={{ bottom: "55px", right: "24px" }}
          onMouseEnter={onHover}
          onMouseLeave={onMouseLeave}
        >
          {/** Import soal from another category **/}
          <MDBBtnFixedItem
            buttonStyle={fixedBtnStyle}
            color="purple"
            icon="file-import"
            title={"Import Soal"}
            onClick={() => setShowImportDialog(true)}
          />

          {/** Create new Soal **/}
          <MDBBtnFixedItem
            buttonStyle={fixedBtnStyle}
            color="green"
            icon="plus"
            title={"Tambah soal"}
            onClick={() => history.push(`${thisUri}/${id}/soal/create`)}
          />
        </MDBBtnFixed>
      </div>

      {/** Import soal modal **/}
      <KategoriSoalImportFormModal
        apiUri={apiUri}
        toggle={() => setShowImportDialog(!showImportDialog)}
        isOpen={showImportDialog}
        currentCategoryID={id}
        reloadQuestions={reloadQuestionDataFromServer}
      />

      {/** Delete Modal **/}
      <DeleteModal
        isOpen={showDeleteModal}
        toggle={toggleDeleteModal}
        handler={handleDelete}
        loadingIndicator={deleteLoading}
      />
    </MDBContainer>
  );
};

export default KategoriSoalEditFormPage;
