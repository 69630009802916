import React, {useCallback, useEffect, useMemo, useState} from "react";
import moment from "moment";
import BaseRequest from "../../../../services/BaseRequest";
import { useHistory } from "react-router-dom";
import {
  MDBContainer,
  MDBBtn,
  MDBIcon,
  MDBBtnFixed,
  toast,
  MDBRow,
  MDBCol
} from "mdbreact";
import {default as CustomDataTable} from "../../../components/DataTable";
import DeleteModal from "../../../modals/DeleteModal";
import TextField from "@material-ui/core/TextField";

const thisUri = "/kurikulum/silabus";
const apiUri = "/course-parts";

const SilabusPage = () => {
  const history = useHistory();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedDeleteItem, setSelectedDeleteItem] = useState({});
  const [deleteLoading, setDeleteLoading] = useState(false);

  /** Datatable variables **/
  const [ dtRowsData, setDtRowsData ] = useState([]);
  const [ dtSortBy, setDtSortBy ] = useState('created_at');
  const [ dtSortDirection, setDtSortDirection ] = useState('desc');
  const [ dtTotalRow, setDtTotalRow ] = useState(1);
  const [ dtSearchMeta, setDtSearchMeta ] = useState({
    filter: {},
    field_search: 'title_id, source, type, author',
    search: ''
  });
  const [ loadProgressData, setLoadProgressData ] = useState(true);

  /** Datatable column definition **/
  const columns = [
    {
      name: 'No',
      selector: 'rowNo',
      width: "4rem",
      right: true
    },
    {
      name: "Judul (ID)",
      selector: "title_id",
      sortable: true,
      style: {
        whiteSpace: 'none',
      }
    },
    {
      cell: (row) => row.course && row.course.code
        ? <a href={`/kurikulum/mata-kuliah/${row.course.id}/edit`} target="_blank" rel="noopener noreferrer">{row.course.code}</a>
        : '-',
      name: "Kode Mata Kuliah",
      selector: "kode_mata_kuliah",
      sortable: true,
      style: {
        whiteSpace: 'none',
      },
      width: '10rem'
    },
    {
      cell: (row) => row.course && row.course.asatidzs.length > 0
        ? <ul className="list-on-datatable">
          {
            row.course.asatidzs.map((asatidz, index) =>
              <li key={index}>
                <a href={`/kurikulum/asatidz/${asatidz.id}/edit`} target="_blank" rel="noopener noreferrer">{asatidz.name}</a>
              </li>
            )
          }
        </ul>
        : '-',
      name: "Pengajar",
      selector: "kode_asatidz",
      sortable: true
    },
    {
      name: "Semester",
      selector: "semester",
      sortable: true,
      width: '5rem',
      right: true
    },
    {
      name: "SKS",
      selector: "sks",
      sortable: true,
      width: '3rem',
      right: true
    },
    {
      name: "Dibuat Tanggal",
      selector: "created_at",
      sortable: true,
      width: "8rem"
    },
    {
      name: "Disunting Tanggal",
      selector: "updated_at",
      sortable: true,
      width: "8rem"
    },
    {
      cell: (row) =>
        <div className="btn-group-sm">
          <MDBBtn variant="contained" color="yellow" onClick={() => history.push(`${thisUri}/${row.id}/edit`)} >
            <MDBIcon icon="pen-nib" />
          </MDBBtn>
          <MDBBtn variant="contained" color="red" onClick={() => toggleDeleteModal(row.id)}>
            <MDBIcon icon="trash" />
          </MDBBtn>
        </div>,
      button: true
    }
  ];

  /** Datatable get data **/
  const getDataSilabus = useCallback(async (page = 1, perPage = 10) => {
    let uri = `${apiUri}?with=course.asatidzs`;

    try {
      const {
        data: { data }
      } = await BaseRequest.post(uri, {
        sortBy: dtSortBy,
        desc: dtSortDirection === 'desc',
        page: page,
        limit: perPage,
        ...dtSearchMeta
      });

      setDtTotalRow(data.total);

      // Map the questions data
      const mappedData = data.data.map((v, i) => ({
        rowNo: data.from + i,
        id: v.id,
        title_id: v.title_id,
        kode_mata_kuliah: v.kode_mata_kuliah,
        course: v.course,
        asatidzs: v.course ? v.course.asatidzs : [],
        semester: v.semester,
        sks: v.sks,
        file_id: v.file_id,
        created_at: moment(v.created_at).format("DD/MM/YYYY"),
        updated_at: moment(v.updated_at).format("DD/MM/YYYY"),
      }));

      return { rows: mappedData }
    } catch (error) {
      let errorMessage = error.response
        ? error.response.data.message
        : error.message;
      toast.error(errorMessage, {
        closeButton: false,
        position: "bottom-right"
      });
    }

    return { rows: [] }
  }, [dtSearchMeta, dtSortBy, dtSortDirection]);

  const reloadDataFromServer = useCallback((page = 1, perPage = 10) => {
    getDataSilabus(page, perPage)
      .then((res) => {
        const { rows } = res;

        setDtRowsData(rows);
      })
      .finally(() => {
        setLoadProgressData(false);
      });
  }, [getDataSilabus]);

  useEffect(() => {
    reloadDataFromServer();
  }, [reloadDataFromServer]);

  /** Datatable sorting **/
  const onSortHandler = (column, sortDirection) => {
    setDtSortBy(column.selector);
    setDtSortDirection(sortDirection);

    reloadDataFromServer();
  };

  /** Datatable Filter **/
  const subHeaderComponentMemo = useMemo(() => {
    const handleFilter = (keyword) => {
      setLoadProgressData(true);

      let newSearchMeta = dtSearchMeta;
      newSearchMeta.search = keyword;

      setDtSearchMeta(newSearchMeta);

      getDataSilabus(newSearchMeta)
        .then((res) => {
          const { rows } = res;

          setDtRowsData(rows);
          setLoadProgressData(false);
        })
    };

    return (
      <MDBRow className={"w-100"}>
        <MDBCol size={"12"} md={"4"} sm={"12"}>
          <TextField
            label="Search"
            onChange={(e) => handleFilter(e.target.value)}
            className={"w-100"}
            name="search"
          />
        </MDBCol>
      </MDBRow>
    )
  }, [dtSearchMeta, getDataSilabus]);

  const toggleDeleteModal = (id) => {
    setSelectedDeleteItem(id);
    setShowDeleteModal(!showDeleteModal);
  }

  const handleDelete = async () => {
    try {
      setDeleteLoading(true);

      await BaseRequest.delete(`${apiUri}/${selectedDeleteItem}`);

      reloadDataFromServer();
    } catch (err) {
      console.error(err);
    } finally {
      toggleDeleteModal();
      setDeleteLoading(false);
    }
  };

  return (
    <MDBContainer fluid>
      <h3>Silabus</h3>

      <CustomDataTable
        columns={columns}
        data={dtRowsData}
        totalRow={dtTotalRow}
        onSortHandler={onSortHandler}
        subHeaderComponent={subHeaderComponentMemo}
        progressPending={loadProgressData}
      />

      <DeleteModal
        isOpen={showDeleteModal}
        toggle={toggleDeleteModal}
        handler={handleDelete}
        loadingIndicator={deleteLoading}
      />

      <MDBBtnFixed
        active={false}
        floating
        color="blue"
        icon="plus"
        size="lg"
        style={{bottom: "55px", right: "24px"}}
        onClick={() => history.push(`${thisUri}/create`)}
        className="no-li"
      />

    </MDBContainer>
  );
};

export default SilabusPage;
