import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import {
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBInput,
  MDBSpinner,
  toast,
  MDBFileInput
} from "mdbreact";
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import InputError from "../../../components/InputError";
import { Link } from "react-router-dom";
import BaseRequest from "../../../../services/BaseRequest";
import * as FileRequest from "../../../../services/FileRequest";

const thisUri = "/perkuliahan/kategori";
const apiUri = `/course-categories`;

const formField = [
  { name: "title_id", rules: { required: true } },
  { name: "title_en" },
  { name: "title_ar" },
  { name: "description_id" },
  { name: "description_en" },
  { name: "description_ar" },
  { name: "file_id" }
];

const RenderButton = ({ submitLoading }) => {
  if (submitLoading) {
    return <MDBSpinner />;
  }
  return (
    <div>
      <Link to={thisUri}>
        <MDBBtn size="md" color="red" className="mx-0">
          Kembali
        </MDBBtn>
      </Link>
      <MDBBtn type="submit" color="green" size="md">
        Simpan
      </MDBBtn>
    </div>
  );
};

const KategoriPerkuliahanFormPage = () => {
  const history = useHistory();
  const { id } = useParams(); // Jika id !== undefined, maka ini adalah fitur edit data

  const { register, handleSubmit, errors, setValue, watch } = useForm();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [file, setFile] = useState({});

  const handleChange = e => {
    setValue(e.target.name, e.target.value);
  };

  const ckEditorHandleChange = (name, editor) => {
    let value = editor.getData();
    setValue(name, value);
  };

  useEffect(() => {
    for (let v of formField) {
      register({ name: v.name }, v.rules || null);
    }
  }, [register]);

  const [filePreview, setFilePreview] = useState('');
  useEffect(() => {
    if (id) {
      const getDataById = async () => {
        try {
          const {
            data: { data }
          } = await BaseRequest.get(`${apiUri}/${id}/edit`);
          for (let key in data) {
            setValue(key, data[key] || "");
          }
          if(data.file){
            setFilePreview(data.file.url)
          }
        } catch (error) {
          console.log(error);
        }
      };
      getDataById();
    }
  }, [id, setValue]);

  const onSubmit = async payload => {
    try {
      setSubmitLoading(true);
      let res = null;
      if (file instanceof File) {
        const fd = new FormData();
        fd.append('file', file);
        const { data } = await FileRequest.upload(fd);
        payload.file_id = data.data.id;
      }

      if (id) {
        if (payload.file_id === "") delete payload.file_id; // Agar tidak error di API nya.
        res = await BaseRequest.put(`${apiUri}/${id}`, payload);
        if(res.data.file){
          setFilePreview(res.data.file.url)
        }
      } else {
        res = await BaseRequest.post(`${apiUri}/save`, payload);
      }
      toast.success(res.data.message, {
        closeButton: false,
        position: "bottom-right"
      });
      setTimeout(() => history.push(thisUri), 2000);
    } catch (error) {
      let errorMessage = error.response
        ? error.response.data.message
        : error.message;
      toast.error(errorMessage, {
        closeButton: false,
        position: "bottom-right"
      });
      setSubmitLoading(false);
    }
  };

  return (
    <MDBContainer>
      <MDBRow center>
        <MDBCol size="12" md="9" sm="12">
          <MDBCard>
            <MDBCardBody>
              <MDBCardTitle>
                {id ? "Edit" : "Tambah"} Kategori
              </MDBCardTitle>
              <hr />
              <form onSubmit={handleSubmit(onSubmit)}>
                <MDBContainer>
                  <MDBRow>
                    <MDBCol size="12">
                      <MDBInput
                        className={errors.title_id && "is-invalid"}
                        name="title_id"
                        onChange={handleChange}
                        type="text"
                        label="Judul (Indonesia)"
                        icon="address-card"
                        iconClass="black-text"
                        value={watch("title_id")}
                      >
                        {errors.title_id &&
                          errors.title_id.type === "required" && (
                            <InputError text="Tidak boleh kosong" />
                          )}
                      </MDBInput>
                      <MDBInput
                        name="title_en"
                        onChange={handleChange}
                        type="text"
                        label="Judul (English)"
                        icon="address-card"
                        iconClass="black-text"
                        value={watch("title_en")}
                      />
                      <MDBInput
                        name="title_ar"
                        onChange={handleChange}
                        type="text"
                        label="Judul (Arabic)"
                        icon="address-card"
                        iconClass="black-text"
                        value={watch("title_ar")}
                      />
                      <MDBInput
                          type="text"
                          label="Deskripsi (Indonesia)"
                          disabled
                          className={'ck-editor__label'}
                      />
                      <CKEditor
                          editor={ ClassicEditor }
                          config={{placeholder: "Deskripsi (Indonesia)"}}
                          data={watch('description_id')}
                          onChange={ (event, editor) => ckEditorHandleChange('description_id', editor) }
                      />

                      <MDBInput
                          type="text"
                          label="Deskripsi (English)"
                          disabled
                          className={'ck-editor__label'}
                      />
                      <CKEditor
                          editor={ ClassicEditor }
                          config={{placeholder: "Deskripsi (English)"}}
                          data={watch('description_en')}
                          onChange={ (event, editor) => ckEditorHandleChange('description_en', editor) }
                      />

                      <MDBInput
                          type="text"
                          label="Deskripsi (Arabic)"
                          disabled
                          className={'ck-editor__label'}
                      />
                      <CKEditor
                          editor={ ClassicEditor }
                          config={{placeholder: "Deskripsi (Arabic)"}}
                          data={watch('description_ar')}
                          onChange={ (event, editor) => ckEditorHandleChange('description_ar', editor) }
                      />
                      <MDBFileInput textFieldTitle="Upload Picture" getValue={(v) => setFile(v[0])} />
                      {filePreview &&
                        <img src={filePreview} className='rounded' style={{ maxWidth: '300px'}}/>
                      }
                    </MDBCol>
                  </MDBRow>
                </MDBContainer>

                <div className="text-center mt-3 black-text">
                  <RenderButton submitLoading={submitLoading} />
                </div>
              </form>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default KategoriPerkuliahanFormPage;
