import React, {useEffect, useMemo, useState} from "react";
import {
  MDBBtn,
  MDBCol,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBRow,
  toast
} from "mdbreact";
import {default as CustomDataTable} from "../../../components/DataTable";
import BaseRequest from "../../../../services/BaseRequest";

const SelectMataKuliahModal = (props) => {
  const {
    isOpen,
    toggle,
    selectedCourse,
    setSelectedCourse
  } = props;

  const [courseState, setCourseState] = useState(selectedCourse);
  const [selectedCourseNo, setSelectedCourseNo] = useState(0);

  /** Datatable variables **/
  const [ dtRowsData, setDtRowsData ] = useState([]);
  const [ dtSortBy, setDtSortBy ] = useState('title_id');
  const [ dtSortDirection, setDtSortDirection ] = useState('asc');
  const [ dtTotalRow, setDtTotalRow ] = useState(1);
  const [ loadProgressData, setLoadProgressData ] = useState(true);

  /* Datatable column definition */
  const columns = [
    {
      name: "Kode Mata Kuliah",
      selector: "code",
      sortable: true,
      width: "15rem"
    },
    {
      name: "Nama Mata Kuliah",
      selector: "title_id",
      sortable: true,
      width: "30rem"
    },
    {
      name: "Kredit",
      selector: "credits",
      width: "10rem"
    },
    {
      cell: (row) =>
        <div className="btn-group-md">
          <MDBInput label=" " type="checkbox" id={`checkbox-${row.id}`} onChange={(e) => addRemoveParticipant(e.target.checked, row)} checked={isChecked(row.id)} />
        </div>,
      button: true,
      width: "3rem",
    }
  ];

  const addRemoveParticipant = (checked, data) => {
    let course = courseState;

    if (checked) {
      course = [...course, { ...data, isNew: true }];
      setSelectedCourseNo(selectedCourseNo + 1);
    } else {
      course = courseState;
      course = course.filter((item) => item.id !== data.id).filter((anItem) => anItem.course_id !== data.id);
      setSelectedCourseNo(selectedCourseNo - 1);
    }

    setCourseState(course);
    
  }

  /** Load the questions and question's categories **/
  useEffect(() => {
    if (!isOpen) return;

    setLoadProgressData(true);
    setCourseState(selectedCourse);
    //setDtSearchMeta({...dtSearchMeta, search: ''});

    reloadDataFromServer();
  }, [isOpen]);

  const isChecked = (id) => {
    if(courseState.some(course => course.course_id === id))
      return courseState.some(course => course.course_id === id);
    else
      return courseState.some(course => course.id === id)
  }

  /** Get Course list **/
  const getCourses = async (page = 1, perPage = 5) => {
    let uri = `courses`;

    try {
      const {
        data: { data }
      } = await BaseRequest.post(uri, {
        sortBy: dtSortBy,
        desc: dtSortDirection === 'desc',
        page: page,
        limit: perPage,
        //...dtSearchMeta
      });

      setDtTotalRow(data.total);

      // Map the participant data
      const mappedData = data.data.map((v, i) => ({
        ...v,
        rowNo: data.from + i
      }));

      return { rows: mappedData }
    } catch (error) {
      let errorMessage = error.response
        ? error.response.data.message
        : error.message;
      toast.error(errorMessage, {
        closeButton: false,
        position: "bottom-right"
      });
    }

    return { rows: [] }
  };

  const reloadDataFromServer = (page = 1, perPage = 5) => {
    getCourses(page, perPage)
      .then((res) => {
        const { rows } = res;

        setDtRowsData(rows);
      })
      .finally(() => {
        setLoadProgressData(false);
      });
  };

  /** Datatable sorting **/
  const onSortHandler = (column, sortDirection) => {
    setLoadProgressData(true);

    setDtSortBy(column.selector);
    setDtSortDirection(sortDirection);

    getCourses()
      .then((res) => {
        const {rows} = res;

        setDtRowsData(rows);
        setLoadProgressData(false);
      });
  };

  /** Datatable Filter **/
  const subHeaderComponentMemo = useMemo(() => {
    const handleFilter = (keyword, comesFromSelect = false, fieldName = '') => {
      setLoadProgressData(true);
    };

    return (
      /** Filter input groups **/
      <MDBRow className={"w-100 text-truncate"}>
        <MDBCol size={"12"} md={"4"} sm={"12"}>
          <MDBInput
            label="Search"
            onChange={(e) => handleFilter(e.target.value, false, 'search-keyword')}
            className={"search-input"}
            name="search-keyword"
          />
        </MDBCol>
      </MDBRow>
    )
  }, [/*dtSearchMeta*/]);

  /** Datatable Change Page handler **/
  const onPageChangeHandler = (page) => {
    setLoadProgressData(true);
    reloadDataFromServer(page);
  }

  const onPerRowsChangeHandler = (perPage, page) => {
    setLoadProgressData(true);
    reloadDataFromServer(page, perPage);
  }

  /** Set selected participant **/
  const addCourses = () => {
    setSelectedCourse(courseState);
    toggle();
  }

  /** Reset selected participants from this form **/
  const resetSelectedParticipants = () => {
    setCourseState(selectedCourse);
    toggle();
  }

  return (
    <MDBModal size="lg" isOpen={isOpen} toggle={toggle} backdrop={false}>
      <MDBModalHeader toggle={toggle}>Pilih Mata Kuliah</MDBModalHeader>
      <MDBModalBody>
        <CustomDataTable
          columns={columns}
          data={dtRowsData}
          totalRow={dtTotalRow}
          onSortHandler={onSortHandler}
          subHeaderComponent={subHeaderComponentMemo}
          progressPending={loadProgressData}
          defaultSortField={"name"}
          defaultSortDir={"asc"}
          handlePerRowsChange={onPerRowsChangeHandler}
          handlePageChange={onPageChangeHandler}
          paginationPerPage={5}
          paginationRowsPerPageOptions={[5, 10, 15, 20]}
        />

        <div className="text-center">
          <MDBBtn color="red" size="sm" onClick={() => resetSelectedParticipants()}>
            Batalkan
          </MDBBtn>
          <MDBBtn color="green" size="sm" onClick={() => addCourses()} disabled={selectedCourseNo === 0}>
            Tambahkan {selectedCourseNo} Mata Kuliah
          </MDBBtn>
        </div>
      </MDBModalBody>
    </MDBModal>
  );
}

export default SelectMataKuliahModal;
