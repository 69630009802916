import React, { useState, useEffect } from 'react';
import ReactDomServer from 'react-dom/server';
import {useHistory} from "react-router-dom";

import {
  MDBContainer,
} from "mdbreact";

import BaseRequest from "../../../../services/BaseRequest";

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction';
import bootstrapTheme from '@fullcalendar/bootstrap';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';

import moment from 'moment';
import momentHijri from 'moment-hijri';
import AcademicCalendarFormModal from './AcademicCalendarFormModal';

import CustomEventSpan from './components/CustomEventSpan';


const thisUri = "/kurikulum/kalender-akademik";
const apiUri = "/academic-calendars";

const AcademicCalendarPage = () => {
  const history = useHistory();

  const [ showCreateModal, setShowCreateModal ] = useState(false);
  const [ selectedCal, setSelectedCal ] = useState({start: new Date(), end: new Date() });
  const [ eventData, setEventData ] = useState([]);
  const [ toggleTriggerLoad, setToggleTriggerLoad ] = useState(false);

  // const events = [
  //   {
  //     id: 1,
  //     title: 'UAS',
  //     start: new Date().setDate(new Date().getDate() + 1),
  //     end: new Date().setDate(new Date().getDate() + 5),
  //     className: 'bg-warning text-white',
  //     extendedProps: {
  //       description: 'Lorem Ipsum Dolor sit Amet',
  //     },
  //   },
  //   {
  //     id: 2,
  //     title: 'Libur Nasional',
  //     start: new Date().setDate(new Date().getDate() + 8),
  //     end: new Date().setDate(new Date().getDate() + 9),
  //     className: 'bg-danger text-white',
  //     extendedProps: {
  //       description: 'Lorem Ipsum Dolor sit Amet',
  //     },
  //   },
  // ];

  const triggerLoad = () => {
    setToggleTriggerLoad(!toggleTriggerLoad)
  }

  const getAcademicCalendar = async () => {
    const uri = apiUri
    const { data: { data } } = await BaseRequest.post(uri, {
      page: 1,
      limit: -1
    });
    // console.log(data);
    return data.data.map((v, i) => ({
      id: v.id,
      title: v.title,
      description: v.description,
      start: moment(v.start_date + ' ' + v.start_time, 'YYYY-MM-DD HH:mm').toDate(),
      end: moment(v.end_date + ' ' + v.end_time, 'YYYY-MM-DD HH:mm').toDate(),
      className: 'text-white',
      backgroundColor: v.color,
      extendedProps: {
        descripton: v.description
      }
    }))
  };

  // Triggered on first load and after create / edit modal submission
  // by accessing triggerLoadEventData()
  useEffect(() => {
    async function fetchData() {
      setEventData(await getAcademicCalendar());
    }
    fetchData();
  }, [toggleTriggerLoad]);

  const handleEventClick = (e) => {
    // console.log(e.event.extendedProps.description);
    // console.log('Coordinates: ' + e.jsEvent.pageX + ',' + e.jsEvent.pageY);

    const data = e.event;
    setSelectedCal({
      id: data.id,
      title: data.title,
      description: data.extendedProps.description,
      start: data.start,
      end: data.end,
      color: data.backgroundColor,
    });
    setShowCreateModal(true);
  }

  const customContent = (e) => ({ 
    html: ReactDomServer.renderToStaticMarkup(<CustomEventSpan data={e.event}/>)}
  )

  const handleDateClick = (e) => {
    // console.log(e.date);
    setSelectedCal({
      start: e.date,
      end: moment(e.date).add('days', 1).toDate()
    });
    setShowCreateModal(true);
    // history.push(`${thisUri}/${e.date}/create`);
  }

  const handleSelect = (e) => {
    setSelectedCal({
        start: e.start,
        end: e.end
    });
    setShowCreateModal(true);
  }

  return (
    <MDBContainer fluid>
      <h3>Kalender Akademik</h3>
      <FullCalendar
        plugins={[ dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin ]}
        initialView="dayGridMonth"
        handleWindowResize={true}
        dayHeaders={true}
        buttonText={{
          today: 'Today',
          month: 'Month',
          week: 'Week',
          day: 'Day',
          list: 'List',
          prev: 'Prev',
          next: 'Next',
        }}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay',
        }}
        dayCellContent={(e) => momentHijri(e.date).format('iD') + (e.dayNumberText ? ' (' + e.dayNumberText + ')' : '')}
        eventClick={handleEventClick}
        dateClick={handleDateClick}
        selectable={true}
        select={handleSelect}
        events={eventData}
        eventContent={customContent}
        // eventRemove={handleRemoveEventClick}
      />
      <AcademicCalendarFormModal 
        thisUri={thisUri} apiUri={apiUri} 
        selectedCal={selectedCal}
        toggle={() => setShowCreateModal(!showCreateModal)} 
        isOpen={showCreateModal}
        triggerLoadEventData={() => triggerLoad() } />
    </MDBContainer>
  )
}

export default AcademicCalendarPage;