import React, {useState} from "react";
import {MDBBtn, MDBCol, MDBRow, MDBStep, MDBStepper} from "mdbreact";
import {Link} from "react-router-dom";

/**
 * Form with Stepper component
 * rules:
 *  - The items must be > 1 (min. 2 items)
 *  - The first item should be a form
 * */
export const FormStepper = (props) => {
  const {
    stepperDetails,
    onSubmit,
    triggerValidation,
    backUri,
    generateTheForm
  } = props;

  const [activeStep, setActiveStep] = useState('step-0');

  const swapStepper = (step) => {
    triggerValidation().then(result => {
      if (!result) return;

      setActiveStep('step-' + step);
    });
  }

  const getTheRestStep = () => {
    const steps = [];

    for (let i = 1; i < stepperDetails.items.length; i++) {
      steps.push(
        <div key={'component-step-' + i}>
          { stepperDetails.items[i].component }

          {/* Action button */}
          <MDBRow>
            <MDBCol size={"6"}>
              <MDBBtn className="float-left mt-3" color="blue" size="md" onClick={() => swapStepper(i - 1)}>
                Kembali
              </MDBBtn>
            </MDBCol>
            <MDBCol size={"6"}>
              {
                i + 1 === stepperDetails.items.length ?
                  <MDBBtn className="float-right mt-3" color="green" size="md" onClick={() => onSubmit()}>
                    Simpan
                  </MDBBtn> :
                  <MDBBtn className="float-right mt-3" color="blue" size="md" onClick={() => swapStepper(i)}>
                    Lanjut
                  </MDBBtn>
              }
            </MDBCol>
          </MDBRow>
        </div>
      );
    }

    return steps;
  }

  return (
    <>
      <MDBStepper form>
        {
          stepperDetails.items.map((item, key) => {
            return(
              <MDBStep form key={'stepper-key-' + key}>
                <a onClick={() => swapStepper(key)}>
                  <MDBBtn color={ (activeStep === 'step-' + key) ? "indigo" : "default" } circle>
                    {item.index}
                  </MDBBtn>
                </a>
                <p>{item.label}</p>
              </MDBStep>
            );
          })
        }
      </MDBStepper>

      {
        activeStep === 'step-0' ?
          <form>
            {/* The form fields */}
            <MDBRow>
              <MDBCol size="12">
                { generateTheForm() }
              </MDBCol>
            </MDBRow>

            {/* Action button */}
            <MDBRow>
              <MDBCol size={"6"}>
                <Link to={backUri}>
                  <MDBBtn className="float-left mt-3" color="red" size="md">
                    Kembali
                  </MDBBtn>
                </Link>
              </MDBCol>
              <MDBCol size={"6"}>
                <MDBBtn className="float-right mt-3" color="blue" size="md" onClick={() => swapStepper(1)}>
                  Lanjut
                </MDBBtn>
              </MDBCol>
            </MDBRow>
          </form> :
          getTheRestStep()
      }
    </>
  )
}
