import React, {useMemo, useState} from "react";
import {default as CustomDataTable} from "../../../components/DataTable";
import {MDBBtn, MDBBtnFixed, MDBCol, MDBContainer, MDBIcon, MDBRow} from "mdbreact";
import TextField from "@material-ui/core/TextField";
import SelectMataKuliahModal from "./SelectMataKuliahModal";

const MataKuliahPicker = (props) => {
  const {
    courseData,
    setSelectedCourse
  } = props;

  const [isSelectorModalOpen, setIsSelectorModalOpen] = useState(false);

  /* Datatable column definition */
  const columns = [
    {
      name: "Kode Mata Kuliah",
      selector: "code",
      sortable: true,
      width: "15rem"
    },
    {
      name: "Nama Mata Kuliah",
      selector: "title_id",
      sortable: true,
      width: "30rem"
    },
    {
      name: "Kredit",
      selector: "credits",
      width: "10rem"
    },
    {
      cell: (row) =>
        <div className="btn-group-sm">
          <MDBBtn variant="contained" color="red" onClick={() => deleteCourse(row.id)}>
            <MDBIcon icon="trash" />
          </MDBBtn>
        </div>,
      button: true,
      width: "4rem",
    }
  ];

  /* Datatable variables */
  const [dtRowsData, setDtRowsData] = useState(courseData);
  const [dtSearchMeta, setDtSearchMeta] = useState('');
  let courseFromMap = dtRowsData.map(prop => prop.course);
  const filteredCourse = (typeof courseFromMap[0]!=='undefined')?dtRowsData.map(prop => {
    if(prop.course) return prop.course;
    else {
      return prop;
    }
  }):dtRowsData;

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <MDBRow className={"w-100"}>
        <MDBCol size={"12"} md={"4"} sm={"12"}>
          <TextField
            label="Filter by Mata Kuliah"
            onChange={(e) => setDtSearchMeta(e.target.value)}
            className={"w-100"}
            name="search"
          />
        </MDBCol>
      </MDBRow>
    );
  }, [dtSearchMeta]);

  const deleteCourse = (id) => {
    let newCourse = dtRowsData.filter(item => item.course.id !== id);
    setDtRowsData(newCourse);
    setSelectedCourse(newCourse);
  }

  const toggleSelectCourseModal = () => {
    setIsSelectorModalOpen(!isSelectorModalOpen);
  }

  const saveSelectedCourse = (courses) => {
    setDtRowsData(courses);
    setSelectedCourse(courses);
  }

  return (
    <MDBContainer>
      <CustomDataTable
        columns={columns}
        data={filteredCourse}
        subHeaderComponent={subHeaderComponentMemo}
        serverSide={false}
        defaultSortField='name'
        defaultSortDir='asc'
      />

      <SelectMataKuliahModal
        isOpen={isSelectorModalOpen}
        toggle={toggleSelectCourseModal}
        selectedCourse={dtRowsData}
        setSelectedCourse={saveSelectedCourse}
      />

      <MDBBtnFixed
        floating
        color="blue"
        icon="plus"
        size="lg"
        style={{bottom: "55px", right: "24px"}}
        onClick={() => toggleSelectCourseModal()}
      />
    </MDBContainer>
  )
}

export default MataKuliahPicker;
