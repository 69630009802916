import React, {useCallback, useEffect, useMemo, useState} from "react";
import {MDBBtn, MDBBtnFixed, MDBCol, MDBContainer, MDBIcon, MDBInput, MDBRow, MDBSelect, toast} from "mdbreact";
import {default as CustomDataTable} from "../../components/DataTable";
import DeleteModal from "../../modals/DeleteModal";
import BaseRequest from "../../../services/BaseRequest";
import moment from "moment";
import {useHistory} from "react-router";
import {getStatusOptions} from "../configs/global";
import WarningModal from "../../modals/WarningModal";

const thisUri = '/pengumuman'
const apiUri = '/announcements'

const PengumumanPage = () => {
  const history = useHistory();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedDeleteItem, setSelectedDeleteItem] = useState({});
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [showActivationModal, setShowActivationModal] = useState(false);
  const [selectedActivationItem, setSelectedActivationItem] = useState({});
  const [activationLoading, setActivationLoading] = useState(false);

  /** Datatable variables **/
  const [ loadProgressData, setLoadProgressData ] = useState(true);
  const [ dtRowsData, setDtRowsData ] = useState([]);
  const [ dtSortBy, setDtSortBy ] = useState('created_at');
  const [ dtSortDirection, setDtSortDirection ] = useState('desc');
  const [ dtTotalRow, setDtTotalRow ] = useState(1);
  const [ dtPage, setDtPage ] = useState(1);
  const [ dtPerPage, setDtPerPage ] = useState(10);
  const [ dtSearchMeta, setDtSearchMeta ] = useState({
    filter: {},
    field_search: 'title_id',
    search: ''
  });

  /** Datatable column definition **/
  const columns = [
    {
      name: 'No',
      selector: 'rowNo',
      width: "4rem",
      right: true
    },
    {
      name: "Judul",
      selector: "title",
      sortable: true,
      style: {
        whiteSpace: 'none',
      }
    },
    {
      cell: (row => <a href={row.hyperlink} target="_blank" rel="noopener noreferrer">{row.hyperlink}</a>),
      name: "Link",
      selector: "hyperlink",
      sortable: true
    },
    {
      name: "Mulai Dari",
      selector: "start_date",
      sortable: true,
      width: "8rem"
    },
    {
      name: "Berakhir Pada",
      selector: "end_date",
      sortable: true,
      width: "8rem"
    },
    {
      name: "Tipe Penerima",
      selector: "type_recipient",
      sortable: true,
      width: "6rem"
    },
    {
      cell: (row) => <a href="#" onClick={() => toggleActivationModal(row)}>{row.status_text}</a>,
      name: "Status",
      selector: "status",
      sortable: true,
      width: "6rem"
    },
    {
      name: "Dibuat Tanggal",
      selector: "created_at",
      sortable: true,
      width: "8rem"
    },
    {
      name: "Disunting Tanggal",
      selector: "updated_at",
      sortable: true,
      width: "8rem"
    },
    {
      cell: (row) =>
        <div className="btn-group-sm">
          <MDBBtn variant="contained" color="red" onClick={() => toggleDeleteModal(row.id)}>
            <MDBIcon icon="trash" />
          </MDBBtn>
        </div>,
      button: true,
      width: "4rem"
    }
  ];

  /** Activation handler **/
  const toggleActivationModal = pengumuman => {
    setSelectedActivationItem(pengumuman);
    setShowActivationModal(!showActivationModal);
  }

  const handleActivation = async () => {
    try {
      setActivationLoading(true);

      await BaseRequest.post(`${apiUri}/${selectedActivationItem.status === 'active' ? 'deactivate' : 'activate'}/${selectedActivationItem.id}`);
      reloadDataFromServer(dtPage, dtPerPage);
    } catch (err) {
      console.error(err);
    } finally {
      toggleActivationModal({});
      setActivationLoading(false);
    }
  }

  /** Datatable expanded row component **/
  const PengumumanExpandedRow = ({ data }) => (
    <div className="p-3">
      <h5><
        u>Isi Pengumuman</u>
      </h5>
      <p>{data.content}</p>
    </div>
  )

  /** Datatable get data **/
  const getAnnouncements = useCallback(async (page = 1, perPage = 10) => {
    let uri = `${apiUri}`;

    try {
      setLoadProgressData(true);

      const {
        data: { data }
      } = await BaseRequest.post(uri, {
        sortBy: dtSortBy,
        desc: dtSortDirection === 'desc',
        page: page,
        limit: perPage,
        ...dtSearchMeta
      });

      setDtTotalRow(data.total);

      // Map the questions data
      const mappedData = data.data.map((v, i) => ({
        ...v,
        rowNo: data.from + i,
        start_date: moment(v.start_date).format("DD/MM/YYYY hh:mm:ss"),
        end_date: moment(v.end_date).format("DD/MM/YYYY hh:mm:ss"),
        status_text: getStatusOptions(v.status)[0].text,
        created_at: moment(v.created_at).format("DD/MM/YYYY"),
        updated_at: moment(v.updated_at).format("DD/MM/YYYY"),
      }));

      return { rows: mappedData }
    } catch (error) {
      let errorMessage = error.response
        ? error.response.data.message
        : error.message;
      toast.error(errorMessage, {
        closeButton: false,
        position: "bottom-right"
      });
    }

    return { rows: [] }
  }, [dtSearchMeta, dtSortBy, dtSortDirection]);

  const reloadDataFromServer = useCallback((page = 1, perPage = 10) => {
    getAnnouncements(page, perPage)
      .then((res) => {
        const { rows } = res;

        setDtRowsData(rows);
      })
      .finally(() => {
        setLoadProgressData(false);
      });
  }, [getAnnouncements]);

  useEffect(() => {
    reloadDataFromServer(dtPage, dtPerPage);
  }, [reloadDataFromServer, dtPage, dtPerPage]);

  /** Datatable Filter **/
  const subHeaderComponentMemo = useMemo(() => {
    const handleFilter = (keyword, comesFromSelect = false, filterName = null) => {
      setLoadProgressData(true);

      let newSearchMeta = dtSearchMeta;

      if (comesFromSelect) {
        let selectedValue = keyword;

        if (selectedValue === '') {
          delete newSearchMeta.filter[filterName];
        } else {
          newSearchMeta.filter[filterName] = selectedValue;
        }
      } else {
        newSearchMeta.search = keyword;
      }

      setDtSearchMeta(newSearchMeta);

      getAnnouncements(newSearchMeta)
        .then((res) => {
          const { rows } = res;

          setDtRowsData(rows);
          setLoadProgressData(false);
        })
    };

    return (
      <div className="w-100 data-table__filter-input">
        <MDBRow className={"w-100"}>
          <MDBCol size={"12"} md={"2"} sm={"12"}>
            <MDBInput
              label="Cari Judul (ID)"
              onChange={(e) => handleFilter(e.target.value)}
              className="search-input"
              name="search"
            />
          </MDBCol>
        </MDBRow>
      </div>
    )
  }, [dtSearchMeta, getAnnouncements]);

  /** Datatable sorting **/
  const onSortHandler = (column, sortDirection) => {
    setDtSortBy(column.selector);
    setDtSortDirection(sortDirection);

    reloadDataFromServer(dtPage, dtPerPage);
  };

  const toggleDeleteModal = (id) => {
    setSelectedDeleteItem(id);
    setShowDeleteModal(!showDeleteModal);
  }

  const handleDelete = async () => {
    try {
      setDeleteLoading(true);

      await BaseRequest.delete(`${apiUri}/${selectedDeleteItem}`);
      reloadDataFromServer(dtPage, dtPerPage);
    } catch (err) {
      console.error(err);
    } finally {
      toggleDeleteModal();
      setDeleteLoading(false);
    }
  };

  /** Datatable Change Page handler **/
  const onPageChangeHandler = (page) => {
    setDtPage(page)
  }

  const onPerRowsChangeHandler = (perPage) => {
    setDtPerPage(perPage)
  }

  return (
    <MDBContainer fluid>
      <h3>Pengumuman</h3>

      <CustomDataTable
        columns={columns}
        data={dtRowsData}
        totalRow={dtTotalRow}
        onSortHandler={onSortHandler}
        subHeaderComponent={subHeaderComponentMemo}
        progressPending={loadProgressData}
        handlePerRowsChange={onPerRowsChangeHandler}
        handlePageChange={onPageChangeHandler}
        expandableRows={true}
        expandableRowsComponent={<PengumumanExpandedRow />}
      />

      <DeleteModal
        isOpen={showDeleteModal}
        toggle={toggleDeleteModal}
        handler={handleDelete}
        loadingIndicator={deleteLoading}
      />

      <WarningModal
        isOpen={showActivationModal}
        toggle={toggleActivationModal}
        handler={handleActivation}
        loadingIndicator={activationLoading}
        title={"Aktifasi pengumuman"}
        text={selectedActivationItem.status === 'active' ? 'Non-aktifkan pengumuman?' : 'Aktifkan pengumuman?'}
      />

      <MDBBtnFixed
        floating
        color="blue"
        icon="plus"
        size="lg"
        style={{bottom: "55px", right: "24px"}}
        onClick={() => history.push(`${thisUri}/create`)}
        className="no-li"
      />
    </MDBContainer>
  );
}

export default PengumumanPage;
