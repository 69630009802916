import React, {useCallback, useEffect, useMemo, useState, useContext} from "react";
import moment from "moment";
import BaseRequest from "../../../../services/BaseRequest";
import {useHistory} from "react-router-dom";
import {MDBBtn, MDBBtnFixed, MDBCol, MDBContainer, MDBIcon, MDBInput, MDBRow, toast} from "mdbreact";
import {default as CustomDataTable} from "../../../components/DataTable";
import DeleteModal from "../../../modals/DeleteModal";
import { AccessContext } from '../../../AccessContext';

const thisUri = "/perkuliahan/mata-kuliah-pilihan";
const apiUri = "/course-batches";

const MataKuliahPilihanPage = () => {
  const history = useHistory();
  const menu = 'mata-kuliah-pilihan';
  const access = useContext(AccessContext);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedDeleteItem, setSelectedDeleteItem] = useState({});
  const [deleteLoading, setDeleteLoading] = useState(false);


  /** Datatable variables **/
  const [ dtRowsData, setDtRowsData ] = useState([]);
  const [ dtSortBy, setDtSortBy ] = useState('created_at');
  const [ dtSortDirection, setDtSortDirection ] = useState('desc');
  const [ dtTotalRow, setDtTotalRow ] = useState(1);
  const [ dtPage, setDtPage ] = useState(1);
  const [ dtPerPage, setDtPerPage ] = useState(10);
  const [ dtSearchMeta, setDtSearchMeta ] = useState({
    filter: {course_package_id:null},
    field_search: 'course.title_id',
    search: ''
  });
  const [ loadProgressData, setLoadProgressData ] = useState(true);

  /** Datatable column definition **/
  const columns = [
    {
      name: 'No',
      selector: 'rowNo',
      width: "4rem",
      right: true
    },
    {
      name: "Nama Mata Kuliah",
      selector: "title_id",
      sortable: true,
      style: {
        whiteSpace: 'none',
      }
    },
    {
      name: "Semester",
      selector: "semester",
      sortable: true,
      style: {
        whiteSpace: 'none',
      }
    },
    {
      name: "Periode",
      selector: "period",
      sortable: true,
      style: {
        whiteSpace: 'none',
      }
    },
    {
      name: "Status Mata Kuliah",
      selector: "status",
      sortable: true,
      style: {
        whiteSpace: 'none',
      }
    },
    {
      name: "Pengajar",
      selector: "asatidzs",
      sortable: true,
      width: "16rem"
    },
    {
      name: "Dibuat Tanggal",
      selector: "created_at",
      sortable: true,
      width: "8rem"
    },
    {
      name: "Disunting Tanggal",
      selector: "updated_at",
      sortable: true,
      width: "8rem"
    },
    {
      cell: (row) =>
        <div className="btn-group-sm">
          { access.isAccessible(menu, 'edit') &&
            <MDBBtn variant="contained" color="yellow" onClick={() => history.push(`${thisUri}/${row.id}/edit`)} >
              <MDBIcon icon="pen-nib" />
            </MDBBtn>
          }
          { access.isAccessible(menu, 'delete') &&
            <MDBBtn variant="contained" color="red" onClick={() => toggleDeleteModal(row.id)}>
              <MDBIcon icon="trash" />
            </MDBBtn>
          }
        </div>,
      button: true,
      width: "8rem"
    }
  ];

  /** Datatable get data **/
  const getDataMataKuliah = useCallback(async (page = 1, perPage = 10) => {
    let uri = `${apiUri}`;

    try {
      setLoadProgressData(true);

      const {
        data: { data }
      } = await BaseRequest.post(uri, {
        sortBy: dtSortBy,
        desc: dtSortDirection === 'desc',
        page: page,
        limit: perPage,
        ...dtSearchMeta
      });

      setDtTotalRow(data.total);

      // Map the questions data
      let filteredData = data.data.filter(v => v.course_package_id === null);
      const mappedData = filteredData.map((v, i) => ({
        rowNo: data.from + i,
        id: v.id,
        title_id: (v.course)?v.course.title_id:'-',
        period: v.period,
        status: mapCourseType(v.status),
        semester: v.semester,
        asatidzs: (v.asatidz)?v.asatidz.name:'-',
        created_at: moment(v.created_at).format("DD/MM/YYYY"),
        updated_at: moment(v.updated_at).format("DD/MM/YYYY"),
      }));

      return { rows: mappedData }
    } catch (error) {
      let errorMessage = error.response
        ? error.response.data.message
        : error.message;
      toast.error(errorMessage, {
        closeButton: false,
        position: "bottom-right"
      });
    }

    return { rows: [] }
  }, [dtSearchMeta, dtSortBy, dtSortDirection]);

  const reloadDataFromServer = useCallback((page = 1, perPage = 10) => {
    getDataMataKuliah(page, perPage)
      .then((res) => {
        const { rows } = res;

        setDtRowsData(rows);
      })
      .finally(() => {
        setLoadProgressData(false);
      });
  }, [getDataMataKuliah]);

  useEffect(() => {
    reloadDataFromServer(dtPage, dtPerPage);
  }, [reloadDataFromServer, dtPage, dtPerPage]);

  /** Datatable sorting **/
  const onSortHandler = (column, sortDirection) => {
    setDtSortBy(column.selector);
    setDtSortDirection(sortDirection);

    reloadDataFromServer(dtPage, dtPerPage);
  };

  const mapCourseType = (courseType) => {
    switch(courseType){
      case 'required': return 'wajib';break;
      case 'optional': return 'pilihan';break;
      case 'advanced': return 'bersyarat';break;
      default: return '-';
    }
  };


  /** Datatable Filter **/
  const subHeaderComponentMemo = useMemo(() => {
    const handleFilter = (keyword, comesFromSelect = false, filterName = null) => {
      setLoadProgressData(true);

      let newSearchMeta = dtSearchMeta;

      if (comesFromSelect) {
        let selectedValue = keyword;

        if (selectedValue === '') {
          delete newSearchMeta.filter[filterName];
        } else {
          newSearchMeta.filter[filterName] = selectedValue;
        }
      } else {
        newSearchMeta.search = keyword;
      }

      setDtSearchMeta(newSearchMeta);

      getDataMataKuliah(newSearchMeta)
        .then((res) => {
          const { rows } = res;

          setDtRowsData(rows);
          setLoadProgressData(false);
        })
    };

    return (
      <div className="w-100 data-table__filter-input">
        <MDBRow className={"w-100"}>
          <MDBCol size={"12"} md={"2"} sm={"12"}>
            <MDBInput
              label="Cari Judul (ID)"
              onChange={(e) => handleFilter(e.target.value)}
              className="search-input"
              name="search"
            />
          </MDBCol>
        </MDBRow>
      </div>
    )
  }, [dtSearchMeta, getDataMataKuliah]);

  const toggleDeleteModal = (id) => {
    setSelectedDeleteItem(id);
    setShowDeleteModal(!showDeleteModal);
  }

  const handleDelete = async () => {
    try {
      setDeleteLoading(true);

      await BaseRequest.delete(`${apiUri}/${selectedDeleteItem}`);
      reloadDataFromServer(dtPage, dtPerPage);
    } catch (err) {
      console.error(err);
    } finally {
      toggleDeleteModal();
      setDeleteLoading(false);
    }
  };

  /** Datatable Change Page handler **/
  const onPageChangeHandler = (page) => {
    setDtPage(page)
  }

  const onPerRowsChangeHandler = (perPage) => {
    setDtPerPage(perPage)
  }

  return (
    <MDBContainer fluid>
      <h3>Konfigurasi Mata Kuliah Pilihan</h3>

      <CustomDataTable
        columns={columns}
        data={dtRowsData}
        totalRow={dtTotalRow}
        onSortHandler={onSortHandler}
        subHeaderComponent={subHeaderComponentMemo}
        progressPending={loadProgressData}
        handlePerRowsChange={onPerRowsChangeHandler}
        handlePageChange={onPageChangeHandler}
      />

      <DeleteModal
        isOpen={showDeleteModal}
        toggle={toggleDeleteModal}
        handler={handleDelete}
        loadingIndicator={deleteLoading}
      />

      { access.isAccessible(menu, 'create') &&
        <MDBBtnFixed
          floating
          color="blue"
          icon="plus"
          size="lg"
          style={{bottom: "55px", right: "24px"}}
          onClick={() => history.push(`${thisUri}/create`)}
          className="no-li"
        />
      }

    </MDBContainer>
  );
};

export default MataKuliahPilihanPage;
