import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  toast
} from "mdbreact";
import BaseRequest from "../../../../services/BaseRequest";
import {Form} from "../../../components";
import {getGenderOptions, getStatusOptions} from "../../configs/global";

const thisUri = `/kelas/admin-groups`;
const apiUri = `/user-admin-group`;

const AdminGroupFormPage = () => {
  const history = useHistory();
  const { id } = useParams(); // Jika id !== undefined, maka ini adalah fitur edit data

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    watch,
    getValues,
    trigger: triggerValidation,
    setError
  } = useForm();

  const [submitLoading, setSubmitLoading] = useState(false);

  const formField = [
    {
      name: 'name',
      label: 'Nama',
      rules: { required: true }
    },
    {
      name: 'email',
      rules: { required: true },
      label: 'Email',
      type: 'email'
    },
    {
      name: 'password',
      rules: !id && { required: true },
      label: 'Password',
      type: 'password'
    },
    {
      name: 'nip',
      label: 'NIP',
      rules: { required: true }
    },
    {
      name: 'phone_country_code',
      rules: { required: false },
      label: 'Kode Telepon Negara'
    },
    {
      name: 'phone',
      rules: { required: false },
      label: 'No. Telepon'
    },
    {
      name: 'gender',
      type: 'select',
      rules: { required: true },
      label: 'Gender',
      options: getGenderOptions()
    },
    {
      name: 'status',
      type: 'select',
      rules: { required: true },
      label: 'Status',
      options: getStatusOptions()
    }
  ];

  /** Register the form fields **/
  useEffect(() => {
    for (let v of formField) {
      register({ name: v.name }, v.rules || null);
    }
  }, [register]);

  /** Get Mata Kuliah Data **/
  useEffect(() => {
    if (id) {
      const getDataById = async () => {
        try {
          const {
            data: { data }
          } = await BaseRequest.get(`${apiUri}/${id}/edit`);

          return data;
        } catch (error) {
          console.log(error);
        }
      };

      getDataById().then(data => {
        for (let key in data) {
          setValue(key, data[key] || "");
        }
      });
    }
  }, [id, setValue]);

  const onSubmit = async payload => {
    setSubmitLoading(true);
    let res;

    if (id) {
      res = await BaseRequest.put(`${apiUri}/${id}`, payload);
    } else {
      res = await BaseRequest.post(`${apiUri}/save`, payload);
    }

    toast.success(res.data.message, {
      closeButton: false,
      position: "bottom-right"
    });

    setTimeout(() => history.push(thisUri), 2000);
  };

  /** Define form fields **/
  const getFormFields = () => {
    return formField.map((field) => ({ ...field, error: errors[field.name] }));
  };

  /** Input text change handler **/
  const handleInputTextChange = (e) => {
    const { name, value } = e.target;
    setValue(name, value);
  };

  /** Select Input change handler **/
  const handleSelectChange = (field, value) => {
    if (field && value) {
      setValue(field, value);
    }
  };

  return (
    <MDBContainer>
      <MDBRow center>
        <MDBCol size="12" md="12" sm="12">
          <h3 className="text-center pt-4 pb-5 mb-2">
            {id ? "Edit" : "Tambah"} Admin Group
          </h3>

          <Form
            onSubmit={handleSubmit(onSubmit)}
            fields={getFormFields()}
            watcher={watch}
            onInputTextChange={handleInputTextChange}
            onSelectChange={handleSelectChange}
            submitLoading={submitLoading}
            setSubmitLoading={setSubmitLoading}
            values={getValues()}
            backUri={thisUri}
            triggerValidation={triggerValidation}
            setError={setError}
          />
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default AdminGroupFormPage;
