import React from "react";
import { Route, Switch } from "react-router-dom";

import DV1 from "./dashboard/v1";

import ProtectedRoute from "./components/ProtectedRoute";

import ProfilePage from "./pages/profile/ProfilePage";
import RolePage from "./pages/roles/RolePage";
import RoleFormPage from "./pages/roles/RoleFormPage";
import CountryPage from "./pages/countries/CountryPage";
import CountryFormPage from "./pages/countries/CountryFormPage";
import StatePage from "./pages/states/StatePage";
import StateFormPage from "./pages/states/StateFormPage";
import CityPage from "./pages/cities/CityPage";
import CityFormPage from "./pages/cities/CityFormPage";
import ProgramPage from './pages/classes/program/ProgramPage';
import ProgramFormPage from './pages/classes/program/ProgramFormPage';
import JobPage from './pages/jobs/JobPage';
import JobFormPage from './pages/jobs/JobFormPage';
import EducationPage from './pages/educations/EducationPage';
import EducationFormPage from "./pages/educations/EducationFormPage";
import QuizPage from "./pages/quizzes/QuizPage";
import QuizFormPage from "./pages/quizzes/QuizFormPage";
import QuizQuestionPage from './pages/quiz-questions/QuizQuestionPage';
import QuizQuestionFormPage from './pages/quiz-questions/QuizQuestionFormPage';
import ParticipantFormPage from './pages/participants/ParticipantFormPage';
import ParticipantDetailPage from './pages/participants/ParticipantDetailPage';
import ParticipantLogPage from './pages/participants/ParticipantLogPage';
import ParticipantTranscriptPage from './pages/participants/ParticipantTranscriptPage';
import ParticipantTranscriptDetailPage from './pages/participants/ParticipantTranscriptDetailPage';
import QuizAnswerPage from './pages/quiz-answers/QuizAnswerPage';
import QuizAnswerFormPage from './pages/quiz-answers/QuizAnswerFormPage';
import UjianEvaluasiPage from "./pages/kurikulum/ujian-evaluasi/UjianEvaluasiPage";
import UjianLogPage from "./pages/kurikulum/ujian-evaluasi/UjianLogPage";

import KategoriSoalPage from "./pages/kurikulum/kategori-soal/KategoriSoalPage";
import KategoriSoalEditFormPage from "./pages/kurikulum/kategori-soal/KategoriSoalEditFormPage";
import SoalFormPage from "./pages/kurikulum/shared/pages/SoalFormPage";
import KuisPage from "./pages/kurikulum/ujian-evaluasi/kuis/KuisPage";
import KuisFormPage from "./pages/kurikulum/ujian-evaluasi/kuis/KuisFormPage";
import KuisResetPage from "./pages/kurikulum/ujian-evaluasi/kuis/reset/KuisResetPage";
import KuisResetParticipantPage from "./pages/kurikulum/ujian-evaluasi/kuis/reset/KuisResetParticipantPage";
import KuisRevisionPage from "./pages/kurikulum/ujian-evaluasi/kuis/revisi/KuisRevisionPage";
import GroupsPage from "./pages/classes/groups/GroupsPage";
import GroupsFormPage from "./pages/classes/groups/GroupsFormPage";
import GroupsParticipantPage from "./pages/classes/groups/GroupsParticipantPage";
import ProgramParticipantsPage from "./pages/classes/program/ProgramParticipantsPage";
import MataKuliahPage from "./pages/perkuliahan/mata-kuliah/MataKuliahPage";
import MataKuliahFormPage from "./pages/perkuliahan/mata-kuliah/MataKuliahFormPage";
import MataKuliahPasteFormPage from "./pages/perkuliahan/mata-kuliah/MataKuliahPasteFormPage";
import SilabusPage from "./pages/kurikulum/silabus/SilabusPage";
import SilabusFormPage from "./pages/kurikulum/silabus/SilabusFormPage";
import AsatidzFormPage from "./pages/kurikulum/asatidz/AsatidzFormPage";
import AsatidzPage from "./pages/kurikulum/asatidz/AsatidzPage";
import AcademicCalendarPage from "./pages/kurikulum/academic-calendar/AcademicCalendarPage";
import AdminGroupPage from "./pages/classes/admin-groups/AdminGroupPage";
import AdminGroupFormPage from "./pages/classes/admin-groups/AdminGroupFormPage";
import SubMataKuliahPage from "./pages/perkuliahan/sub-mata-kuliah/SubMataKuliahPage";
import SubMataKuliahFormPage from "./pages/perkuliahan/sub-mata-kuliah/SubMataKuliahFormPage";
import KategoriPerkuliahanPage from "./pages/perkuliahan/kategori-perkuliahan/KategoriPerkuliahanPage";
import KategoriPerkuliahanFormPage from "./pages/perkuliahan/kategori-perkuliahan/KategoriPerkuliahanFormPage";
import MateriPerkuliahanPage from "./pages/perkuliahan/materi-perkuliahan/MateriPerkuliahanPage";
import MateriPerkuliahanFormPage from "./pages/perkuliahan/materi-perkuliahan/MateriPerkuliahanFormPage";
import MataKuliahDetail from "./pages/perkuliahan/mata-kuliah/MataKuliahDetail";
import PaketMataKuliahPage from "./pages/perkuliahan/paket-mata-kuliah/PaketMataKuliahPage";
import PaketMataKuliahFormPage from "./pages/perkuliahan/paket-mata-kuliah/PaketMataKuliahFormPage";
import MataKuliahPilihanPage from "./pages/perkuliahan/mata-kuliah-pilihan/MataKuliahPilihanPage";
import MataKuliahPilihanFormPage from "./pages/perkuliahan/mata-kuliah-pilihan/MataKuliahPilihanFormPage";
import ParticipantPage from "./pages/participants/ParticipantPage";
import PengumumanPage from "./pages/pengumuman/PengumumanPage";
import PengumumanFormPage from "./pages/pengumuman/PengumumanFormPage";
import PengaturanPage from "./pages/kurikulum/ujian-evaluasi/pengaturan/PengaturanPage";
import PengaturanFormPage from "./pages/kurikulum/ujian-evaluasi/pengaturan/PengaturanFormPage";
import PredikatPage from "./pages/pengaturan/predikat/PredikatPage";
import PredikatFormPage from "./pages/pengaturan/predikat/PredikatFormPage";
import AcademicCalendarFormPage from "./pages/kurikulum/academic-calendar/AcademicCalendarFormPage";
import SyahadahPage from "./pages/syahadah/SyahadahPage";
import SyahadahFormPage from "./pages/syahadah/SyahadahFormPage";
import KuisParticipantPage from "./pages/kurikulum/ujian-evaluasi/kuis/KuisParticipantPage";
import SurveyPage from "./pages/surveys/SurveyPage";
import SurveyFormPage from "./pages/surveys/SurveyFormPage";
import SurveyReportPage from "./pages/surveys/SurveyReportPage";
import SurveyTextReportPage from "./pages/surveys/SurveyTextReportPage";
import UserGroupPage from './pages/users/UserGroupPage';
import UserGroupFormPage from './pages/users/UserGroupFormPage';
import PesertaBaruPage from "./pages/pmb/PesertaBaruPage";
import QuizArchivePage from "./pages/quiz-archives/QuizArchivePage";
import QuizClassListPage from "./pages/quiz-archives/QuizClassListPage";
import GenerateNimPage from "./pages/pmb/GenerateNimPage";
import GroupingPesertaPage from "./pages/pmb/GroupingPesertaPage";
import PesertaGroupPage from "./pages/pmb/PesertaGroupPage";
import CourseScoresPage from "./pages/perkuliahan/skema-nilai/CourseScorePage";
import CourseScoresFormPage from "./pages/perkuliahan/skema-nilai/CourseScoreFormPage";
import CourseScoresDetail from "./pages/perkuliahan/skema-nilai/CourseScoreDetail";
import IsianRencanaStudiPage from "./pages/kurikulum/isian-rencana-studi/IsianRencanaStudiPage";
import IsianRencanaStudiFormPage from "./pages/kurikulum/isian-rencana-studi/IsianRencanaStudiFormPage";
import StudentCardPage from './pages/student-card/StudentCardPage';
import StudentCardFormPage from './pages/student-card/StudentCardFormPage';
import TranscriptSpecialFormPage from './pages/perkuliahan/transkrip-khusus/TranscriptSpecialFormPage';
import TranscriptSpecialList from "./pages/perkuliahan/transkrip-khusus/TranscriptSpecialList";

const fourOFour = () => <h1 className="text-center">404</h1>;

class Routes extends React.Component {
  render() {
    return (
      <Switch>
        <ProtectedRoute path="/" exact>
          <DV1 />
        </ProtectedRoute>

        <ProtectedRoute path="/profile" exact>
          <ProfilePage />
        </ProtectedRoute>

        <ProtectedRoute path="/roles" exact>
          <RolePage />
        </ProtectedRoute>
        <ProtectedRoute path="/roles/create" exact>
          <RoleFormPage />
        </ProtectedRoute>
        <ProtectedRoute path="/roles/:id/edit" exact>
          <RoleFormPage />
        </ProtectedRoute>

        <ProtectedRoute path="/countries" exact>
          <CountryPage />
        </ProtectedRoute>
        <ProtectedRoute path="/countries/create" exact>
          <CountryFormPage />
        </ProtectedRoute>
        <ProtectedRoute path="/countries/:id/edit" exact>
          <CountryFormPage />
        </ProtectedRoute>

        <ProtectedRoute path="/countries/:countryId/states" exact>
          <StatePage />
        </ProtectedRoute>
        <ProtectedRoute path="/countries/:countryId/states/create" exact>
          <StateFormPage />
        </ProtectedRoute>
        <ProtectedRoute path="/countries/:countryId/states/:id/edit" exact>
          <StateFormPage />
        </ProtectedRoute>

        <ProtectedRoute path="/countries/:countryId/states/:stateId/cities" exact>
          <CityPage />
        </ProtectedRoute>
        <ProtectedRoute path="/countries/:countryId/states/:stateId/cities/create" exact>
          <CityFormPage />
        </ProtectedRoute>
        <ProtectedRoute path="/countries/:countryId/states/:stateId/cities/:id/edit" exact>
          <CityFormPage />
        </ProtectedRoute>

        <ProtectedRoute path="/jobs" exact>
          <JobPage />
        </ProtectedRoute>
        <ProtectedRoute path="/jobs/create" exact>
          <JobFormPage />
        </ProtectedRoute>
        <ProtectedRoute path="/jobs/:id/edit" exact>
          <JobFormPage />
        </ProtectedRoute>

        <ProtectedRoute path="/educations" exact>
          <EducationPage />
        </ProtectedRoute>
        <ProtectedRoute path="/educations/create" exact>
          <EducationFormPage />
        </ProtectedRoute>
        <ProtectedRoute path="/educations/:id/edit" exact>
          <EducationFormPage />
        </ProtectedRoute>

        <ProtectedRoute path="/educations" exact>
          <EducationPage />
        </ProtectedRoute>
        <ProtectedRoute path="/educations/create" exact>
          <EducationFormPage />
        </ProtectedRoute>
        <ProtectedRoute path="/educations/:id/edit" exact>
          <EducationFormPage />
        </ProtectedRoute>

        <ProtectedRoute path="/quizzes" exact>
          <QuizPage />
        </ProtectedRoute>
        <ProtectedRoute path="/quizzes/create" exact>
          <QuizFormPage />
        </ProtectedRoute>
        <ProtectedRoute path="/quizzes/:id/edit" exact>
          <QuizFormPage />
        </ProtectedRoute>

        <ProtectedRoute path="/quiz-questions" exact>
          <QuizQuestionPage />
        </ProtectedRoute>
        <ProtectedRoute path="/quiz-questions/create" exact>
          <QuizQuestionFormPage />
        </ProtectedRoute>
        <ProtectedRoute path="/quiz-questions/:id/edit" exact>
          <QuizQuestionFormPage />
        </ProtectedRoute>

        <ProtectedRoute path="/educations" exact>
          <EducationPage />
        </ProtectedRoute>
        <ProtectedRoute path="/educations/create" exact>
          <EducationFormPage />
        </ProtectedRoute>
        <ProtectedRoute path="/educations/:id/edit" exact>
          <EducationFormPage />
        </ProtectedRoute>

        {/* <ProtectedRoute path="/participants/male" exact>
          <ParticipantMalePage />
        </ProtectedRoute>
        <ProtectedRoute path="/participants/female" exact>
          <ParticipantFemalePage />
        </ProtectedRoute>
        <ProtectedRoute path="/participants/create" exact>
          <ParticipantFormPage />
        </ProtectedRoute> */}
        <ProtectedRoute path="/participants/:id/edit" exact>
          <ParticipantFormPage />
        </ProtectedRoute>
        <ProtectedRoute path="/participants/:id/show" exact>
          <ParticipantDetailPage />
        </ProtectedRoute>
        <ProtectedRoute path="/participants/:id/logs" exact>
          <ParticipantLogPage />
        </ProtectedRoute>
        <ProtectedRoute path="/participants/:id/transcript" exact>
          <ParticipantTranscriptPage />
        </ProtectedRoute>
        <ProtectedRoute path="/participants/:id/transcript/:courseId" exact>
          <ParticipantTranscriptDetailPage />
        </ProtectedRoute>

        <ProtectedRoute path="/quizzes" exact>
          <QuizPage />
        </ProtectedRoute>
        <ProtectedRoute path="/quizzes/create" exact>
          <QuizFormPage />
        </ProtectedRoute>
        <ProtectedRoute path="/quizzes/:id/edit" exact>
          <QuizFormPage />
        </ProtectedRoute>

        <ProtectedRoute path="/quiz-questions" exact>
          <QuizQuestionPage />
        </ProtectedRoute>
        <ProtectedRoute path="/quiz-questions/create" exact>
          <QuizQuestionFormPage />
        </ProtectedRoute>
        <ProtectedRoute path="/quiz-questions/:id/edit" exact>
          <QuizQuestionFormPage />
        </ProtectedRoute>

        <ProtectedRoute path="/quiz-answers" exact>
          <QuizAnswerPage />
        </ProtectedRoute>
        <ProtectedRoute path="/quiz-answers/create" exact>
          <QuizAnswerFormPage />
        </ProtectedRoute>
        <ProtectedRoute path="/quiz-answers/:id/edit" exact>
          <QuizAnswerFormPage />
        </ProtectedRoute>

        <ProtectedRoute path="/quiz-archives/:quiz_id/:class_id" exact>
          <QuizArchivePage />
        </ProtectedRoute>

        {/**============================================================================================================
         ** Menu: Kelola Peserta
         ============================================================================================================**/}
        <ProtectedRoute path="/peserta/:gender" exact>
          <ParticipantPage />
        </ProtectedRoute>

        {/**============================================================================================================
         ** Menu: Perkuliahan
         ============================================================================================================**/}
        {/*** Kategori ***/}
        <ProtectedRoute path="/perkuliahan/kategori" exact>
          <KategoriPerkuliahanPage />
        </ProtectedRoute>
        <ProtectedRoute path="/perkuliahan/kategori/create" exact>
          <KategoriPerkuliahanFormPage />
        </ProtectedRoute>
        <ProtectedRoute path="/perkuliahan/kategori/:id/edit" exact>
          <KategoriPerkuliahanFormPage />
        </ProtectedRoute>

        {/*** Mata Kuliah ***/}
        <ProtectedRoute path="/perkuliahan/mata-kuliah" exact>
          <MataKuliahPage />
        </ProtectedRoute>
        <ProtectedRoute path="/perkuliahan/mata-kuliah/:id/show" exact>
          <MataKuliahDetail />
        </ProtectedRoute>
        <ProtectedRoute path="/perkuliahan/mata-kuliah/create" exact>
          <MataKuliahFormPage />
        </ProtectedRoute>
        <ProtectedRoute path="/perkuliahan/mata-kuliah/paste" exact>
          <MataKuliahPasteFormPage />
        </ProtectedRoute>
        <ProtectedRoute path="/perkuliahan/mata-kuliah/:id/edit" exact>
          <MataKuliahFormPage />
        </ProtectedRoute>
        <ProtectedRoute path="/perkuliahan/paket/" exact>
          <PaketMataKuliahPage />
        </ProtectedRoute>
        <ProtectedRoute path="/perkuliahan/paket/create" exact>
          <PaketMataKuliahFormPage />
        </ProtectedRoute>
        <ProtectedRoute path="/perkuliahan/paket/:id/edit" exact>
          <PaketMataKuliahFormPage />
        </ProtectedRoute>
        <ProtectedRoute path="/perkuliahan/skema-nilai/" exact>
          <CourseScoresPage />
        </ProtectedRoute>
        <ProtectedRoute path="/perkuliahan/skema-nilai/create" exact>
          <CourseScoresFormPage />
        </ProtectedRoute>
        <ProtectedRoute path="/perkuliahan/skema-nilai/:id/edit" exact>
          <CourseScoresFormPage />
        </ProtectedRoute>
        <ProtectedRoute path="/perkuliahan/skema-nilai/:id/detail" exact>
          <CourseScoresDetail />
        </ProtectedRoute>
        <ProtectedRoute path="/perkuliahan/mata-kuliah-pilihan/" exact>
          <MataKuliahPilihanPage />
        </ProtectedRoute>
        <ProtectedRoute path="/perkuliahan/mata-kuliah-pilihan/create" exact>
          <MataKuliahPilihanFormPage />
        </ProtectedRoute>
        <ProtectedRoute path="/perkuliahan/mata-kuliah-pilihan/:id/edit" exact>
          <MataKuliahPilihanFormPage />
        </ProtectedRoute>

        {/*** Pertemuan ***/}
        <ProtectedRoute path="/perkuliahan/pertemuan" exact>
          <SubMataKuliahPage />
        </ProtectedRoute>
        <ProtectedRoute path="/perkuliahan/pertemuan/create" exact>
          <SubMataKuliahFormPage />
        </ProtectedRoute>
        <ProtectedRoute path="/perkuliahan/pertemuan/:id/edit" exact>
          <SubMataKuliahFormPage />
        </ProtectedRoute>

        {/*** Materi Perkuliahan ***/}
        <ProtectedRoute path="/perkuliahan/materi" exact>
          <MateriPerkuliahanPage />
        </ProtectedRoute>
        <ProtectedRoute path="/perkuliahan/materi/create" exact>
          <MateriPerkuliahanFormPage />
        </ProtectedRoute>
        <ProtectedRoute path="/perkuliahan/materi/:id/edit" exact>
          <MateriPerkuliahanFormPage />
        </ProtectedRoute>

        {/**============================================================================================================
         ** Menu: Kurikulum
         ============================================================================================================**/}
        {/*** Silabus ***/}
        <ProtectedRoute path="/kurikulum/silabus" exact>
          <SilabusPage />
        </ProtectedRoute>
        <ProtectedRoute path="/kurikulum/silabus/create" exact>
          <SilabusFormPage />
        </ProtectedRoute>
        <ProtectedRoute path="/kurikulum/silabus/:id/edit" exact>
          <SilabusFormPage />
        </ProtectedRoute>

        {/*** Isian Rencana Studi ***/}
        <ProtectedRoute path="/kurikulum/isian-rencana-studi" exact>
          <IsianRencanaStudiPage />
        </ProtectedRoute>
        <ProtectedRoute path="/kurikulum/isian-rencana-studi/:id/show" exact>
          <IsianRencanaStudiFormPage />
        </ProtectedRoute>

        {/*** Asatidz ***/}
        <ProtectedRoute path="/kurikulum/asatidz" exact>
          <AsatidzPage />
        </ProtectedRoute>
        <ProtectedRoute path="/kurikulum/asatidz/create" exact>
          <AsatidzFormPage />
        </ProtectedRoute>
        <ProtectedRoute path="/kurikulum/asatidz/:id/edit" exact>
          <AsatidzFormPage />
        </ProtectedRoute>

        {/*** Kalender Akademik ***/}
        <ProtectedRoute path="/kurikulum/kalender-akademik" exact>
          <AcademicCalendarPage />
        </ProtectedRoute>
        <ProtectedRoute path="/kurikulum/kalender-akademik/:date/create" exact>
          <AcademicCalendarFormPage />
        </ProtectedRoute>

        {/*** Ujian/Evaluasi ***/}
        <ProtectedRoute path="/kurikulum/ujian-evaluasi" exact>
          <UjianEvaluasiPage />
        </ProtectedRoute>
        <ProtectedRoute path="/kurikulum/ujian-evaluasi/log" exact>
          <UjianLogPage />
        </ProtectedRoute>
        <ProtectedRoute path="/kurikulum/ujian-evaluasi/revision" exact>
          <KuisRevisionPage />
        </ProtectedRoute>

        {/* Ujian/Evaluasi > Kuis */}
        <ProtectedRoute path="/kurikulum/ujian-evaluasi/kuis" exact>
          <KuisPage />
        </ProtectedRoute>
        <ProtectedRoute path="/kurikulum/ujian-evaluasi/kuis/create" exact>
          <KuisFormPage />
        </ProtectedRoute>
        <ProtectedRoute path="/kurikulum/ujian-evaluasi/kuis/:id/edit" exact>
          <KuisFormPage />
        </ProtectedRoute>
        {/* Buat ngelist participant yg belum/sudah jawab kuis */}
        <ProtectedRoute path="/kurikulum/ujian-evaluasi/kuis/:id/list/:mode" exact>
          <KuisParticipantPage />
        </ProtectedRoute>
        <ProtectedRoute path="/kurikulum/ujian-evaluasi/kuis/:quizId/:coursePartId/:classId/reset" exact>
          <KuisResetPage />
        </ProtectedRoute>
        <ProtectedRoute path="/kurikulum/ujian-evaluasi/kuis/:quizId/:coursePartId/:classId/:groupId/reset" exact>
          <KuisResetParticipantPage />
        </ProtectedRoute>
        <ProtectedRoute path="/kurikulum/ujian-evaluasi/kuis/:quizId/archive" exact>
          <QuizClassListPage />
        </ProtectedRoute>

        {/* Ujian/Evaluasi > Pengaturan */}
        <ProtectedRoute path="/kurikulum/ujian-evaluasi/pengaturan" exact>
          <PengaturanPage />
        </ProtectedRoute>
        <ProtectedRoute path="/kurikulum/ujian-evaluasi/pengaturan/create" exact>
          <PengaturanFormPage />
        </ProtectedRoute>
        <ProtectedRoute path="/kurikulum/ujian-evaluasi/pengaturan/:id/edit" exact>
          <PengaturanFormPage />
        </ProtectedRoute>

        {/* Bank Soal > Kategori Soal */}
        <ProtectedRoute path="/kurikulum/bank-soal/kategori" exact>
          <KategoriSoalPage />
        </ProtectedRoute>
        <ProtectedRoute path="/kurikulum/bank-soal/kategori/:id/edit" exact>
          <KategoriSoalEditFormPage />
        </ProtectedRoute>

        {/* Bank Soal > Kategori Soal > Soal */}
        <ProtectedRoute path="/kurikulum/bank-soal/kategori/:idCategory/soal/create" exact>
          <SoalFormPage />
        </ProtectedRoute>
        <ProtectedRoute path="/kurikulum/bank-soal/kategori/:idCategory/soal/:id/edit" exact>
          <SoalFormPage />
        </ProtectedRoute>

        {/*** Transkrip Khusus ***/}
        <ProtectedRoute path="/kurikulum/transkrip" exact>
          <TranscriptSpecialList />
        </ProtectedRoute>
        <ProtectedRoute path="/kurikulum/transkrip/:id/edit" exact>
          <TranscriptSpecialFormPage />
        </ProtectedRoute>

        {/**============================================================================================================
         ** Menu: Kelas
         ============================================================================================================**/}
        {/*** Kelas > Kelas ***/}
        <ProtectedRoute path="/kelas/program" exact>
          <ProgramPage />
        </ProtectedRoute>
        <ProtectedRoute path="/kelas/program/create" exact>
          <ProgramFormPage />
        </ProtectedRoute>
        <ProtectedRoute path="/kelas/program/:id/edit" exact>
          <ProgramFormPage />
        </ProtectedRoute>
        <ProtectedRoute path="/kelas/program/:id/participants" exact>
          <ProgramParticipantsPage />
        </ProtectedRoute>

        {/*** Kelas > Group ***/}
        <ProtectedRoute path="/kelas/groups" exact>
          <GroupsPage />
        </ProtectedRoute>
        <ProtectedRoute path="/kelas/groups/create" exact>
          <GroupsFormPage />
        </ProtectedRoute>
        <ProtectedRoute path="/kelas/groups/:id/edit" exact>
          <GroupsFormPage />
        </ProtectedRoute>
        <ProtectedRoute path="/kelas/groups/:classId/:groupId/detail" exact>
          <GroupsParticipantPage />
        </ProtectedRoute>



        {/*** Kelas > Admin Group ***/}
        <ProtectedRoute path="/kelas/admin-groups" exact>
          <AdminGroupPage />
        </ProtectedRoute>
        <ProtectedRoute path="/kelas/admin-groups/create" exact>
          <AdminGroupFormPage />
        </ProtectedRoute>
        <ProtectedRoute path="/kelas/admin-groups/:id/edit" exact>
          <AdminGroupFormPage />
        </ProtectedRoute>

        {/**============================================================================================================
         ** Menu: Pengumuman
         ============================================================================================================**/}
        <ProtectedRoute path="/pengumuman" exact>
          <PengumumanPage />
        </ProtectedRoute>
        <ProtectedRoute path="/pengumuman/create" exact>
          <PengumumanFormPage />
        </ProtectedRoute>

        <ProtectedRoute path="/e-student-card" exact>
          <StudentCardPage />
        </ProtectedRoute>
        <ProtectedRoute path="/e-student-card/:id/edit" exact>
          <StudentCardFormPage />
        </ProtectedRoute>
        <ProtectedRoute path="/e-student-card/create" exact>
          <StudentCardFormPage />
        </ProtectedRoute>

        {/**============================================================================================================
         ** Menu: Syahadah
         ============================================================================================================**/}
        <ProtectedRoute path="/syahadah" exact>
          <SyahadahPage />
        </ProtectedRoute>
        <ProtectedRoute path="/syahadah/create" exact>
          <SyahadahFormPage />
        </ProtectedRoute>
        <ProtectedRoute path="/syahadah/:id/edit" exact>
          <SyahadahFormPage />
        </ProtectedRoute>

        <ProtectedRoute path="/pengaturan/predikat" exact>
          <PredikatPage />
        </ProtectedRoute>
        <ProtectedRoute path="/pengaturan/predikat/create" exact>
          <PredikatFormPage />
        </ProtectedRoute>
        <ProtectedRoute path="/pengaturan/predikat/:id/edit" exact>
          <PredikatFormPage />
        </ProtectedRoute>

        {/**============================================================================================================
         ** Menu: Survey
         ============================================================================================================**/}
        <ProtectedRoute path="/survey" exact>
          <SurveyPage />
        </ProtectedRoute>
        <ProtectedRoute path="/survey/create" exact>
          <SurveyFormPage />
        </ProtectedRoute>
        <ProtectedRoute path="/survey/:id/edit" exact>
          <SurveyFormPage />
        </ProtectedRoute>
        <ProtectedRoute path="/survey/:id/report" exact>
          <SurveyReportPage />
        </ProtectedRoute>
        <ProtectedRoute path="/survey/textreport/:id" exact>
          <SurveyTextReportPage />
        </ProtectedRoute>

        {/*** User > User Group ***/}
        <ProtectedRoute path="/users/user-groups" exact>
          <UserGroupPage />
        </ProtectedRoute>
        <ProtectedRoute path="/users/user-groups/create" exact>
          <UserGroupFormPage />
        </ProtectedRoute>
        <ProtectedRoute path="/users/user-groups/:id/edit" exact>
          <UserGroupFormPage />
        </ProtectedRoute>

        {/* Penerimaan Mahasiswa Baru */}
        <ProtectedRoute path="/pmb/peserta-baru" exact>
          <PesertaBaruPage />
        </ProtectedRoute>
        <ProtectedRoute path="/pmb/generate-nim" exact>
          <GenerateNimPage />
        </ProtectedRoute>
        <ProtectedRoute path="/pmb/grouping" exact>
          <GroupingPesertaPage />
        </ProtectedRoute>
        <ProtectedRoute path="/pmb/grouping/:groupId/peserta" exact>
          <PesertaGroupPage />
        </ProtectedRoute>

        <Route component={fourOFour} />
      </Switch>
    );
  }
}

export default Routes;
