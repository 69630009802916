import React, {useEffect, useState} from 'react';
import { useForm } from 'react-hook-form';
import {useHistory, useParams} from 'react-router-dom';
import {MDBCol, MDBContainer, MDBRow, toast,} from 'mdbreact';
import {Form} from '../../../components';
import BaseRequest from '../../../../services/BaseRequest';
import {getStatusOptions, getGenderOptions} from '../../configs/global';
import ParticipantPicker from "./ParticipantPicker";
import SaveModal from "../../../modals/SaveModal";
import WarningModal from "../../../modals/WarningModal";

const thisUri = '/kelas/groups';
const apiUri = '/groups';

export const GroupsFormPage = () => {
  const history = useHistory();
  const routeParams = useParams();

  const { id } = useParams();

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    watch,
    getValues,
    trigger: triggerValidation
  } = useForm();

  const [submitLoading, setSubmitLoading] = useState(false);
  const [group, setGroup] = useState();
  const [selectedParticipant, setSelectedParticipant] = useState([]);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);

  useEffect(() => {
    const formField = [
      { name: 'name_id', rules: { required: true } },
      { name: 'name_en', rules: { required: false } },
      { name: 'name_ar', rules: { required: false } },
      { name: 'gender', rules: { required: true } },
      { name: 'status', rules: { required: true } },
      { name: 'classes_id', rules: { required: true } },
      { name: 'user_id', rules: { required: true } },
    ];

    for (let field of formField) {
      register({ name: field.name }, field.rules || null);
    }
  }, [register]);

  // effect to determine weather to display create or edit mode
  useEffect(() => {
    if (id) {
      const findById = async () => {
        try {
          const { data } = await BaseRequest.get(
            `${apiUri}/${id}/edit?with=participants`,
          ).then((res) => res.data);

          for (let key in data) {
            setValue(key, data[key] || '');
          }

          return data;
        } catch (error) {
          console.error('error', error);
        }
      };

      findById().then(group => {
        setGroup(group);
        setSelectedParticipant(group.participants);
      });
    }
  }, [id, setValue]);

  // related to classes module
  const [classesOptions, setClassesOptions] = useState([]);
  useEffect(() => {
    const fetchClassesData = async () => {
      try {
        const { data } = await BaseRequest.post('/classes', {
          sortBy: 'name',
          desc: false
        }).then(
          (res) => res.data,
        );

        return data.data.map((c) => ({
          text: c.name,
          value: c.id,
        }));
      } catch (error) {
        console.error('error', error);
      }
    };

    fetchClassesData()
      .then(res => setClassesOptions(res));
  }, []);

  // related to classes module
  const [adminGroupOptions, setAdminGroupOptions] = useState([]);
  useEffect(() => {
    const fetchAdminGroups = async () => {
      try {
        const { data } = await BaseRequest.post('/user-admin-group', {
          sortBy: 'name',
          desc: false
        }).then(
          (res) => res.data,
        );

        return data.data.map((c) => ({
          text: c.name,
          value: c.id,
        }));
      } catch (error) {
        console.error('error', error);
      }
    };

    fetchAdminGroups()
      .then(res => setAdminGroupOptions(res));
  }, []);

  const onSubmit = async (payload) => {
    const { id } = routeParams;
    try {
      setSubmitLoading(true);

      /* Save the group data */
      let res;
      if (id) {
        res = await BaseRequest.put(`${apiUri}/${id}`, payload);
      } else {
        res = await BaseRequest.post(`${apiUri}/save`, payload);
      }

      /* Save the group participant */
      let participantsIDData = selectedParticipant.map(item => item.id);
      res = await BaseRequest.post(`group-participants/save-multiple`, {
        group_id: res.data.data.id,
        users: participantsIDData
      });

      toast.success(res.data.message, {
        closeButton: false,
        position: 'bottom-right',
      });

      setTimeout(() => history.push(thisUri), 2000);
    } catch (error) {
      let errorMessage = error.response
        ? error.response.data.message
        : error.message;

      toast.error(errorMessage, {
        closeButton: false,
        position: 'bottom-right',
      });

      setSubmitLoading(false);
    }
  };

  const handleSelectChange = (field, value) => {
    if (field === 'gender' && selectedParticipant.length > 0 && group.gender !== value) {
      setShowWarningModal(true);
    }

    if (value){
      setValue(field, value);
    }
  };

  const handleInputTextChange = (e) => {
    const { name, value } = e.target;

    setValue(name, value);
  };

  const getFormFields = () => {
    const formField = [
      {
        name: 'name_id',
        rules: { required: true },
        label: 'Nama (Indonesia)',
      },
      {
        name: 'name_en',
        rules: { required: false },
        label: 'Nama (English)',
      },
      { name: 'name_ar', rules: { required: false }, label: 'Nama (Arabic)' },
      {
        name: 'gender',
        label: 'Pilih Gender',
        rules: { required: true },
        type: 'select',
        options: getGenderOptions(),
      },
      {
        name: 'status',
        label: 'Pilih Status',
        rules: { required: true },
        type: 'select',
        options: getStatusOptions(),
      },
      {
        name: 'classes_id',
        label: 'Pilih Kelas',
        rules: { required: true },
        type: 'select',
        options: classesOptions,
      },
      {
        name: 'user_id',
        label: 'Pilih Admin Grup',
        rules: { required: true },
        type: 'select',
        options: adminGroupOptions,
      }
    ];

    return formField.map((field) => ({ ...field, error: errors[field.name] }));
  };

  const getStepperDetails = () => {
    return {
      backUri: apiUri,
      items: [
        { index: 1, label: "Detil Grup" },
        {
          index: 2,
          label: "Peserta",
          component: <ParticipantPicker
            participantData={selectedParticipant}
            setSelectedParticipant={setSelectedParticipant}
            selectedGender={getValues()['gender']}
          />
        },
      ]
    };
  }

  /** Toggle save modal  **/
  const toggleSaveModal = () => {
    if (selectedParticipant.length <= 0) {
      let errorMessage = "Peserta tidak boleh kosong.";

      toast.error(errorMessage, {
        closeButton: false,
        position: 'bottom-right',
      });

      return;
    }

    triggerValidation()
      .then(isValid => isValid && setShowSaveModal(true));
  }

  const removeSelectedParticipant = () => {
    setSelectedParticipant([]);
    setShowWarningModal(false);
  }

  const cancelChangeGender = () => {
    setValue('gender', group.gender);
    setShowWarningModal(false)
  }

  return (
    <MDBContainer>
      <MDBRow center>
        <MDBCol size="12" md="12" sm="12">
          <h3 className="text-center pt-4 pb-5 mb-2">
            {id ? "Edit" : "Tambah"} Grup
          </h3>

          <Form
            stepper
            stepperDetails={getStepperDetails()}
            onSubmit={toggleSaveModal}
            fields={getFormFields()}
            watcher={watch}
            onInputTextChange={handleInputTextChange}
            onSelectChange={handleSelectChange}
            submitLoading={submitLoading}
            values={getValues()}
            backUri={thisUri}
            triggerValidation={triggerValidation}
          />
        </MDBCol>
      </MDBRow>

      {/** Save Modal **/}
      <SaveModal
        isOpen={showSaveModal}
        toggle={() => setShowSaveModal(!showSaveModal)}
        handler={handleSubmit(onSubmit)}
        loadingIndicator={submitLoading}
      />

      {/** Warning Modal **/}
      <WarningModal
        isOpen={showWarningModal}
        toggle={() => cancelChangeGender()}
        handler={removeSelectedParticipant}
        title={"Peringatan"}
        text={`Saat ini data peserta sudah terisi. Jika gender diganti, maka data peserta akan dihapus.\nLanjutkan?`}
      />
    </MDBContainer>
  );
};

export default GroupsFormPage;
