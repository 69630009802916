import React, {useState, useEffect} from "react";
import { useForm } from "react-hook-form";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  toast,
  MDBStepper,
  MDBStep,
  MDBBtn,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBSelect
} from "mdbreact";
import BaseRequest from "../../../services/BaseRequest";
import {Form} from "../../components";
import DateTimePicker from "react-datetime-picker";
import moment from "moment";
import SurveyQuestionsForm from "./SurveyQuestionsForm";
import AsyncPaginate from "react-select-async-paginate";

let thisUri = `/survey`;
const apiUri = `/surveys`;

const SurveyFormPage = () => {
  const history = useHistory();
  const {id} = useParams(); // Jika id !== undefined, maka ini adalah fitur edit data

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    watch,
    getValues,
    trigger: triggerValidation,
    clearErrors: clearError
  } = useForm();

  const [survey, setSurvey] = useState({});
  const [submitLoading, setSubmitLoading] = useState(false);
  const [activeTabPos, setActiveTabPos] = useState(0);

  /** The form fields **/
  const formFields = [
    {
      name: "title",
      rules: { required: true },
      label: "Judul Survey",
    },
    {
      name: "description",
      label: "Deskripsi",
    },
    {
      name: "start_at",
      rules: { required: true },
      type: "custom",
      label: "Survey Dimulai",
      component: (
        <DateTimePicker
          onChange={(date) => setValue("start_at", date)}
          value={watch("start_at")}
          format="y-MM-dd HH:mm:ss"
          className="form-control"
        />
      ),
    },
    {
      name: "end_at",
      rules: { required: true },
      type: "custom",
      label: "Survey Berakhir",
      component: (
        <DateTimePicker
          onChange={(date) => setValue("end_at", date)}
          value={watch("end_at")}
          format="y-MM-dd HH:mm:ss"
          className="form-control"
        />
      ),
    },
  ];

  /** Register the form fields **/
  useEffect(() => {
    for (let formField of formFields) {
      register({name: formField.name}, formField.rules || null);
    }
    register({ name: 'class'});
  }, [register]);

  const loadClassOptions = async (search, loadedOptions, {page}) => {
    let apiUri = '/classes';
    let nameField = 'name';

    const {
      data: {data}
    } = await BaseRequest.post(apiUri, {
      limit: 20,
      sortBy: nameField,
      desc: false,
      field_search: nameField,
      search: search,
      page: page
    });
    let options = data.data.map((v) => {
      return {
        label: v[nameField],
        value: v.id
      }
    });
    if (page==1) options = [{ label: "Semua Program", value: "" }, ...options];

    return {
      options: options,
      hasMore: data && (data.to < data.total),
      additional: {
        page: page + 1,
      },
    };
  }

  /** Get Syahadah Data **/
  useEffect(() => {
    if (id) {
      const getDataById = async () => {
        try {
          const {
            data: {data}
          } = await BaseRequest.get(`${apiUri}/${id}/edit?with=class`);
          return data;
        } catch (error) {
          console.log(error);
        }
      };

      getDataById().then(data => {
        setSurvey(data);

        if (data.class) // null, jika untuk semua program
          setValue("class", { label: data.class.name, value: data.class.id })
        setValue("title", data["title"]);
        setValue("description", data["description"]);
        setValue("start_at", moment(data["start_at"]).toDate());
        setValue("end_at", moment(data["end_at"]).toDate());
      });
    }
  }, [id, setValue]);

  const onSubmit = async payload => {
    try {
      let res;
      payload.class_id = payload.class?.value || null;
      payload.start_at = moment(payload.start_at).format("YYYY-MM-DD HH:mm:ss");
      payload.end_at = moment(payload.end_at).format("YYYY-MM-DD HH:mm:ss");

      setSubmitLoading(true);
      if (id) {
        res = await BaseRequest.put(`${apiUri}/${id}`, payload);
      } else {
        res = await BaseRequest.post(`${apiUri}/save`, payload);
      }

      toast.success(res.data.message, {
        closeButton: false,
        position: "bottom-right"
      });

      setTimeout(() => history.push(thisUri), 500);
    } catch (error) {
      let errorMessage = error.response
        ? error.response.data.message
        : error.message;
      toast.error(errorMessage, {
        closeButton: false,
        position: "bottom-right"
      });

      setSubmitLoading(false);
    }
  };

  /** Define form fields **/
  const getFormFields = () => {
    return formFields.map((field) => ({...field, error: errors[field.name]}));
  };

  /** Input text change handler **/
  const handleInputTextChange = (e) => {
    const {name, value} = e.target;
    setValue(name, value);
  };

  /** Radio Input change handler **/
  const handleSelectChange = (field, value) => {
    if (field && value) {
      setValue(field, value);
    }
  };

  return (
    <MDBContainer>
      <MDBRow center>
        <MDBCol size="12" md="12" sm="12">
          <h3 className="text-center pt-4 pb-5 mb-2">
            {id ? "Edit" : "Tambah"} Survey
          </h3>
 
          {id && <MDBStepper form>
            <MDBStep form>
              <a onClick={() => setActiveTabPos(0)}>
                <MDBBtn color={activeTabPos==0 ? "indigo":"default"} circle>
                  1
                </MDBBtn>
                <p>Edit</p>
              </a>
            </MDBStep>
            <MDBStep form>
              <a onClick={() => setActiveTabPos(1)}>
                <MDBBtn color={activeTabPos==1 ? "indigo":"default"} circle>
                  2
                </MDBBtn>
              </a>
              <p>Pilihan</p>
            </MDBStep>
          </MDBStepper>
          }

          {activeTabPos==0 && <>
            <div style={{ fontSize: 13 }}>Peruntukan Survey</div>
            <AsyncPaginate
              value={watch("class")}
              loadOptions={loadClassOptions}
              onChange={(value) => setValue("class", value)}
              additional={{
                page: 1,
              }}
              closeMenuOnSelect={true}
              placeholder={"Pilih Program"}
              defaultValue={{ label: "Semua Program", value: "" }}
            />
            <Form
              onSubmit={handleSubmit(onSubmit)}
              fields={getFormFields()}
              watcher={watch}
              onInputTextChange={handleInputTextChange}
              onSelectChange={handleSelectChange}
              submitLoading={submitLoading}
              values={getValues()}
              backUri={thisUri}
              triggerValidation={triggerValidation}
            />
          </>}
          {activeTabPos==1 && <SurveyQuestionsForm id={id}/>}

        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default SurveyFormPage;
