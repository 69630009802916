import React, {useEffect, useState} from 'react';
import { useForm } from 'react-hook-form';
import {useHistory, useParams} from 'react-router-dom';
import {MDBCol, MDBContainer, MDBRow, toast,} from 'mdbreact';
import {Form} from '../../../components';
import BaseRequest from '../../../../services/BaseRequest';

import MataKuliahPicker from "./MataKuliahPicker";
import SaveModal from "../../../modals/SaveModal";
import WarningModal from "../../../modals/WarningModal";

const thisUri = '/perkuliahan/paket';
const apiUri = '/course-packages';

export const PaketMataKuliahFormPage = () => {
  const history = useHistory();
  const routeParams = useParams();

  const { id } = useParams();

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    watch,
    getValues,
    trigger: triggerValidation
  } = useForm();

  const [submitLoading, setSubmitLoading] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState([]);
  const [showSaveModal, setShowSaveModal] = useState(false);

  useEffect(() => {
    const formField = [
      { name: 'name', rules: { required: true } },
      { name: 'description', rules: { required: false } },
    ];

    for (let field of formField) {
      register({ name: field.name }, field.rules || null);
    }
  }, [register]);

  // effect to determine weather to display create or edit mode
  useEffect(() => {
    if (id) {
      //console.log(id);
      const findById = async () => {
        try {
          const { data } = await BaseRequest.get(`${apiUri}/${id}/edit?with=courseBatches`).then((res) => res.data);

          for (let key in data) {
            //console.log(key);
            setValue(key, data[key] || '');
          }

          return data;
        } catch (error) {
          console.error('error', error);
        }
      };

       findById().then(data => {
         setSelectedCourse(data.course_batches);
       });
    }
  }, [id, setValue]);



  const onSubmit = async (payload) => {
    const { id } = routeParams;
    try {
      setSubmitLoading(true);

      /* Save the group data */
      let res;
      if (id) {
        res = await BaseRequest.put(`${apiUri}/${id}`, payload);
      } else {
        res = await BaseRequest.post(`${apiUri}/save`, payload);
      }

      /* Save the Course Selected */
      let coursesIDData = selectedCourse.map(item => {
        if(item.course_id) return item.course_id;
        else return item.id;
      });
      console.log(coursesIDData);
      res = await BaseRequest.post(`course-batches/save-multiple`, {
        course_package_id: res.data.data.id,
        course_id: coursesIDData
      });

      toast.success(res.data.message, {
        closeButton: false,
        position: 'bottom-right',
      });

      setTimeout(() => history.push(thisUri), 2000);
    } catch (error) {
      let errorMessage = error.response
        ? error.response.data.message
        : error.message;

      toast.error(errorMessage, {
        closeButton: false,
        position: 'bottom-right',
      });

      setSubmitLoading(false);
    }
  };


  const handleInputTextChange = (e) => {
    const { name, value } = e.target;

    setValue(name, value);
  };

  const getFormFields = () => {
    const formField = [
      {
        name: 'name',
        rules: { required: true },
        label: 'Nama Paket',
      },
      {
        name: 'description',
        rules: { required: false },
        label: 'Deskripsi',
      }
    ];

    return formField.map((field) => ({ ...field, error: errors[field.name] }));
  };

  const getStepperDetails = () => {
    return {
      backUri: apiUri,
      items: [
        { 
            index: 1, 
            label: "Detil Paket" 
        },
        {
          index: 2,
          label: "Mata Kuliah",
          component: <MataKuliahPicker
            courseData={selectedCourse}
            setSelectedCourse={setSelectedCourse}
          />
        },
      ]
    };
  }

  /** Toggle save modal  **/
  const toggleSaveModal = () => {
    if (selectedCourse.length <= 0) {
      let errorMessage = "Mata Kuliah tidak boleh kosong.";

      toast.error(errorMessage, {
        closeButton: false,
        position: 'bottom-right',
      });

      return;
    }

    triggerValidation()
      .then(isValid => isValid && setShowSaveModal(true));
  }

  return (
    <MDBContainer>
      <MDBRow center>
        <MDBCol size="12" md="12" sm="12">
          <h3 className="text-center pt-4 pb-5 mb-2">
            {id ? "Edit" : "Tambah"} Paket Mata Kuliah
          </h3>

          <Form
            stepper
            stepperDetails={getStepperDetails()}
            onSubmit={toggleSaveModal}
            fields={getFormFields()}
            watcher={watch}
            onInputTextChange={handleInputTextChange}
            //onSelectChange={handleSelectChange}
            submitLoading={submitLoading}
            values={getValues()}
            backUri={thisUri}
            triggerValidation={triggerValidation}
          />
        </MDBCol>
      </MDBRow>

      {/** Save Modal **/}
      <SaveModal
        isOpen={showSaveModal}
        toggle={() => setShowSaveModal(!showSaveModal)}
        handler={handleSubmit(onSubmit)}
        loadingIndicator={submitLoading}
      />
    </MDBContainer>
  );
};

export default PaketMataKuliahFormPage;
