/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import {
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBContainer,
} from "mdbreact";
import BaseRequest from "services/BaseRequest";


const apiUrl = '/quiz-archives/details';

const QuizDetailModal = (props) => {
  const {
      isOpen,
      toggle,
      quizId,
      user
    } = props;
  
  const [detailData, setDetailData] = useState(null); 
  
  useEffect(()=>{
    if (isOpen && user && quizId) {
      loadData();
    }
  },[user, quizId, isOpen]);

  const loadData = async() => {
    const { data: { data } } = await BaseRequest.post(apiUrl, {
       user_id: user.user_id,
       quiz_id: quizId
      //quiz_id: "49ea2a26-c4b0-4c82-97ea-216aa4133a03",
      //user_id: "f794dc03-5b8e-428c-ae13-42fb22b73c2a"
    });

    setDetailData(JSON.parse(data));
  }

  const renderDetailData = () => {
    return detailData.map((detail, index) => {
      return(
        <tr key={index}>
          <td>{index + 1}</td>
          <td>
            {detail.questionString}<br/><br/>
            <span style={{fontWeight: '600'}}>Jawaban Seharusnya :</span><br/>
            {detail.correctAnswerString}<br/>
            <span style={{fontWeight: '600'}}>Jawaban yang dipilih : </span><br/>
            {detail.answerString}<br/>
          </td>
        </tr>
      )
    })
  }

  if (!user) return null;
  
  return (
      <MDBModal size="md" isOpen={isOpen} toggle={toggle} backdrop={false}>
        <MDBModalHeader toggle={toggle}>Peserta (NIP): {user.nip}</MDBModalHeader>
        <MDBModalBody>
          <MDBContainer>
            <div style={{fontSize:14}}>
              {user.name}<br/>
              {user.score}<br/>
              {user.time}
            </div>
            <div style={{fontSize:14}}>
              <table className="table">
                <tbody>
                  {
                    detailData && renderDetailData()
                  }
                </tbody>
              </table>
            </div>
          </MDBContainer>
        </MDBModalBody>
      </MDBModal>
    );    
}

export default QuizDetailModal;
