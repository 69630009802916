import React, {useMemo, useState} from "react";
import {default as CustomDataTable} from "../../../components/DataTable";
import {MDBBtn, MDBBtnFixed, MDBCol, MDBContainer, MDBIcon, MDBRow} from "mdbreact";
import TextField from "@material-ui/core/TextField";
import SelectParticipantModal from "./SelectParticipantModal";

const ParticipantPicker = (props) => {
  const {
    participantData,
    setSelectedParticipant,
    selectedGender
  } = props;

  const [isSelectorModalOpen, setIsSelectorModalOpen] = useState(false);

  /* Datatable column definition */
  const columns = [
    {
      name: "NIP",
      selector: "nip",
      sortable: true,
      width: "10rem"
    },
    {
      name: "Nama",
      selector: "name",
      style: {
        whiteSpace: 'none',
      },
      sortable: true,
      cell: row => {
        return (
          <div className="w-100">
            <span>
              {row.name} {row.isNew && <sup className="red-text">Baru</sup>}
            </span>
          </div>
        );
      }
    },
    {
      name: "No. Telepon",
      selector: "phone",
      width: "12rem",
      sortable: true,
      cell: row => (`${row.phone_country_code} ${row.phone}`)
    },
    {
      name: "Negara",
      selector: "country.name",
      width: "10rem",
      sortable: true
    },
    {
      name: "Status",
      selector: "status",
      width: "5rem",
      sortable: true
    },
    {
      cell: (row) =>
        <div className="btn-group-sm">
          <MDBBtn variant="contained" color="red" onClick={() => deleteParticipant(row.id)}>
            <MDBIcon icon="trash" />
          </MDBBtn>
        </div>,
      button: true,
      width: "4rem",
    }
  ];

  /* Datatable variables */
  const [dtRowsData, setDtRowsData] = useState(participantData);
  const [dtSearchMeta, setDtSearchMeta] = useState('');
  const filteredParticipant = dtRowsData.filter(item => item.name && item.name.toLowerCase().includes(dtSearchMeta.toLowerCase()));

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <MDBRow className={"w-100"}>
        <MDBCol size={"12"} md={"4"} sm={"12"}>
          <TextField
            label="Filter by perserta"
            onChange={(e) => setDtSearchMeta(e.target.value)}
            className={"w-100"}
            name="search"
          />
        </MDBCol>
      </MDBRow>
    );
  }, [dtSearchMeta]);

  const deleteParticipant = (id) => {
    let newParticipant = dtRowsData.filter(item => item.id !== id);
    setDtRowsData(newParticipant);
    setSelectedParticipant(newParticipant);
  }

  const toggleSelectSoalModal = () => {
    setIsSelectorModalOpen(!isSelectorModalOpen);
  }

  const saveSelectedParticipant = (participants) => {
    setDtRowsData(participants);
    setSelectedParticipant(participants);
  }

  return (
    <MDBContainer>
      <CustomDataTable
        columns={columns}
        data={filteredParticipant}
        subHeaderComponent={subHeaderComponentMemo}
        serverSide={false}
        defaultSortField='name'
        defaultSortDir='asc'
      />

      <SelectParticipantModal
        isOpen={isSelectorModalOpen}
        toggle={toggleSelectSoalModal}
        selectedParticipant={dtRowsData}
        setSelectedParticipant={saveSelectedParticipant}
        selectedGender={selectedGender}
      />

      <MDBBtnFixed
        floating
        color="blue"
        icon="plus"
        size="lg"
        style={{bottom: "55px", right: "24px"}}
        onClick={() => toggleSelectSoalModal()}
      />
    </MDBContainer>
  )
}

export default ParticipantPicker;
