import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useContext,
} from "react";
import BaseRequest from "../../../../services/BaseRequest";
import { useHistory } from "react-router-dom";
import {
  MDBContainer,
  MDBBtn,
  MDBIcon,
  MDBBtnFixed,
  toast,
  MDBRow,
  MDBCol,
} from "mdbreact";
import { default as CustomDataTable } from "../../../components/DataTable";
import DeleteModal from "../../../modals/DeleteModal";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import { getCoursePriceOptions, getStatusOptions } from "../../configs/global";
import { ProgramExpandedRow } from "./ProgramExpandedRow";
import { AccessContext } from "../../../AccessContext";

const thisUri = "/kelas/program";
const apiUri = "/classes";

export const ProgramPage = () => {
  const history = useHistory();

  const menu = "program";
  const access = useContext(AccessContext);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedDeleteItem, setSelectedDeleteItem] = useState({});
  const [deleteLoading, setDeleteLoading] = useState(false);

  /** Datatable variables **/
  const [dtRowsData, setDtRowsData] = useState([]);
  const [dtSortBy, setDtSortBy] = useState("created_at");
  const [dtSortDirection, setDtSortDirection] = useState("desc");
  const [dtTotalRow, setDtTotalRow] = useState(1);
  const [dtPage, setDtPage] = useState(1);
  const [dtPerPage, setDtPerPage] = useState(10);
  const [dtSearchMeta, setDtSearchMeta] = useState({
    filter: {},
    field_search: "name, period",
    search: "",
  });
  const [loadProgressData, setLoadProgressData] = useState(true);

  /** Datatable column definition **/
  const columns = [
    {
      name: "Nama",
      selector: "name",
      sortable: true,
      style: {
        whiteSpace: "none",
      },
    },
    {
      name: "Periode",
      selector: "period",
      sortable: true,
      width: "8rem",
    },
    {
      name: "Harga",
      selector: "price",
      sortable: true,
      width: "6rem",
    },
    {
      name: "Tipe Kelas",
      selector: "type",
      sortable: true,
      width: "6rem",
    },
    {
      name: "Ikhwan",
      selector: "ikhwan",
      sortable: false,
      width: "6rem",
    },
    {
      name: "Akhwat",
      selector: "akhwat",
      sortable: false,
      width: "6rem",
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
      width: "6rem",
    },
    {
      name: "Dibuat Tanggal",
      selector: "created_at",
      sortable: true,
      width: "8rem",
    },
    {
      name: "Disunting Tanggal",
      selector: "updated_at",
      sortable: true,
      width: "8rem",
    },
    {
      cell: (row) => (
        <div className="btn-group-sm">
          {access.isAccessible(menu, "users") && (
            <MDBBtn
              variant="contained"
              color="blue"
              onClick={() => history.push(`${thisUri}/${row.id}/participants`)}
            >
              <MDBIcon icon="users" />
            </MDBBtn>
          )}
          {access.isAccessible(menu, "edit") && (
            <MDBBtn
              variant="contained"
              color="yellow"
              onClick={() => history.push(`${thisUri}/${row.id}/edit`)}
            >
              <MDBIcon icon="pen-nib" />
            </MDBBtn>
          )}
          {access.isAccessible(menu, "delete") && (
            <MDBBtn
              variant="contained"
              color="red"
              onClick={() => toggleDeleteModal(row.id)}
            >
              <MDBIcon icon="trash" />
            </MDBBtn>
          )}
        </div>
      ),
      button: true,
      width: "10rem",
    },
  ];

  /** Datatable get data **/
  const getClassesData = useCallback(
    async (page = 1, perPage = 10) => {
      let uri = `${apiUri}`;

      try {
        setLoadProgressData(true);

        const {
          data: { data },
        } = await BaseRequest.post(uri, {
          sortBy: dtSortBy,
          desc: dtSortDirection === "desc",
          page: page,
          limit: perPage,
          with: "groups,courses.asatidzs",
          ...dtSearchMeta,
        });

        setDtTotalRow(data.total);

        // Map the questions data
        const mappedData = data.data.map((v, i) => {
          let coursePrice = getCoursePriceOptions(v.course_price);
          let status = getStatusOptions(v.status);

          return {
            id: v.id,
            name: v.name,
            period: v.period,
            type: v.type,
            courses: v.courses || [],
            groups: v.groups,
            price: coursePrice.length > 0 ? coursePrice[0].text : "-",
            ikhwan: v.total_ikhwan,
            akhwat: v.total_akhwat,
            status: status.length > 0 ? status[0].text : "-",
            created_at: moment(v.created_at).format("DD/MM/YYYY"),
            updated_at: moment(v.updated_at).format("DD/MM/YYYY"),
          };
        });

        return { rows: mappedData };
      } catch (error) {
        let errorMessage = error.response
          ? error.response.data.message
          : error.message;
        toast.error(errorMessage, {
          closeButton: false,
          position: "bottom-right",
        });
      }

      return { rows: [] };
    },
    [dtSearchMeta, dtSortBy, dtSortDirection]
  );

  const reloadDataFromServer = useCallback(
    (page = 1, perPage = 10) => {
      getClassesData(page, perPage)
        .then((res) => {
          const { rows } = res;

          setDtRowsData(rows);
        })
        .finally(() => {
          setLoadProgressData(false);
        });
    },
    [getClassesData]
  );

  useEffect(() => {
    reloadDataFromServer(dtPage, dtPerPage);
  }, [reloadDataFromServer, dtPage, dtPerPage]);

  /** Datatable sorting **/
  const onSortHandler = (column, sortDirection) => {
    setDtSortBy(column.selector);
    setDtSortDirection(sortDirection);

    reloadDataFromServer(dtPage, dtPerPage);
  };

  /** Datatable Filter **/
  const subHeaderComponentMemo = useMemo(() => {
    const handleFilter = (keyword) => {
      setLoadProgressData(true);

      let newSearchMeta = dtSearchMeta;
      newSearchMeta.search = keyword;

      setDtSearchMeta(newSearchMeta);

      getClassesData(newSearchMeta).then((res) => {
        const { rows } = res;

        setDtRowsData(rows);
        setLoadProgressData(false);
      });
    };

    return (
      <MDBRow className={"w-100"}>
        <MDBCol size={"12"} md={"4"} sm={"12"}>
          <TextField
            label="Search"
            onChange={(e) => handleFilter(e.target.value)}
            className={"w-100"}
            name="search"
          />
        </MDBCol>
      </MDBRow>
    );
  }, [dtSearchMeta, getClassesData]);

  const toggleDeleteModal = (id) => {
    setSelectedDeleteItem(id);
    setShowDeleteModal(!showDeleteModal);
  };

  const handleDelete = async () => {
    try {
      setDeleteLoading(true);

      await BaseRequest.delete(`${apiUri}/${selectedDeleteItem}`);
      reloadDataFromServer(dtPage, dtPerPage);
    } catch (err) {
      console.error(err);
    } finally {
      toggleDeleteModal();
      setDeleteLoading(false);
    }
  };

  /** Datatable Change Page handler **/
  const onPageChangeHandler = (page) => {
    setDtPage(page);
  };

  const onPerRowsChangeHandler = (perPage) => {
    setDtPerPage(perPage);
  };

  return (
    <MDBContainer fluid>
      <h3>Program</h3>

      <CustomDataTable
        columns={columns}
        data={dtRowsData}
        totalRow={dtTotalRow}
        onSortHandler={onSortHandler}
        subHeaderComponent={subHeaderComponentMemo}
        progressPending={loadProgressData}
        expandableRows={true}
        handlePerRowsChange={onPerRowsChangeHandler}
        handlePageChange={onPageChangeHandler}
        expandableRowsComponent={<ProgramExpandedRow />}
      />

      <DeleteModal
        isOpen={showDeleteModal}
        toggle={toggleDeleteModal}
        handler={handleDelete}
        loadingIndicator={deleteLoading}
      />

      {!access.isAccessible(menu, "create") ? null : (
        <MDBBtnFixed
          floating
          color="blue"
          icon="plus"
          size="lg"
          style={{ bottom: "55px", right: "24px" }}
          onClick={() => history.push(`${thisUri}/create`)}
          className="no-li"
        />
      )}
    </MDBContainer>
  );
};

export default ProgramPage;
