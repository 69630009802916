import React, {useState, useEffect} from "react";
import { useForm } from "react-hook-form";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  toast
} from "mdbreact";
import BaseRequest from "../../../services/BaseRequest";
import {Form} from "../../components";
import {
  getAnnouncementRecipientTypes, RECIPIENT_TYPE_ADMIN,
  RECIPIENT_TYPE_ADMIN_GROUP,
  RECIPIENT_TYPE_CLASS,
  RECIPIENT_TYPE_GROUP, RECIPIENT_TYPE_PARTICIPANT
} from "../configs/global";
import RecipientTypes from "./RecipientTypes";
import moment from "moment";

let thisUri = `/pengumuman`;
const apiUri = `/announcements`;

const PengumumanFormPage = () => {
  const history = useHistory();
  const {id} = useParams(); // Jika id !== undefined, maka ini adalah fitur edit data

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    watch,
    getValues,
    trigger: triggerValidation,
    clearErrors: clearError
  } = useForm();

  const [announcement, setAnnouncement] = useState({});
  const [submitLoading, setSubmitLoading] = useState(false);

  /** The form fields **/
  const formFields = [
    {
      name: 'title',
      rules: {required: true},
      label: 'Judul'
    },
    {
      name: 'content',
      rules: {required: true},
      label: 'Isi Pengumuman',
      type: 'textarea'
    },
    {
      name: 'hyperlink',
      rules: {required: false},
      label: 'Hyperlink'
    },
    {
      name: 'type_recipient',
      rules: {required: true},
      type: 'custom',
      component: <RecipientTypes
        announcement={announcement}
        watcher={watch}
        setValue={setValue}
        errors={errors}
        clearError={clearError}
        getValues={getValues}
      />
    },
  ];

  /** Recipient fields **/
  const recipientFields = [
    {
      name: 'classes',
      rules: {
        validate: {
          requiredIf: value => (getValues().type_recipient === RECIPIENT_TYPE_CLASS && getValues().select_all_recipient !== RECIPIENT_TYPE_CLASS ? (value || "Pilih semua atau pilih beberapa") : true )
        }
      }
    },
    {
      name: 'groups',
      rules: {
        validate: {
          requiredIf: value => (getValues().type_recipient === RECIPIENT_TYPE_GROUP && getValues().select_all_recipient !== RECIPIENT_TYPE_GROUP ? (value || "Pilih semua atau pilih beberapa") : true )
        }
      }
    },
    {
      name: 'admin_groups',
      rules: {
        validate: {
          requiredIf: value => (getValues().type_recipient === RECIPIENT_TYPE_ADMIN_GROUP && getValues().select_all_recipient !== RECIPIENT_TYPE_ADMIN_GROUP ? (value || "Pilih semua atau pilih beberapa") : true )
        }
      }
    },
    {
      name: 'participants',
      rules: {
        validate: {
          requiredIf: value => (getValues().type_recipient === RECIPIENT_TYPE_PARTICIPANT && getValues().select_all_recipient !== RECIPIENT_TYPE_PARTICIPANT ? (value || "Pilih semua atau pilih beberapa") : true )
        }
      }
    },
    {
      name: 'admins',
      rules: {
        validate: {
          requiredIf: value => (getValues().type_recipient === RECIPIENT_TYPE_ADMIN && getValues().select_all_recipient !== RECIPIENT_TYPE_ADMIN ? (value || "Pilih semua atau pilih beberapa") : true )
        }
      }
    },
    {name: 'select_all_recipient'},
    {name: 'is_timeless'},
    {
      name: 'start_date',
      rules: {
        validate: {
          requiredIf: value => (!getValues().is_timeless ? (value || "Tidak boleh kosong") : true )
        }
      }
    },
    {
      name: 'end_date',
      rules: {
        validate: {
          requiredIf: value => (!getValues().is_timeless ? (value || "Tidak boleh kosong") : true )
        }
      }
    },
  ];

  /** Register the form fields **/
  useEffect(() => {
    for (let formField of formFields) {
      register({name: formField.name}, formField.rules || null);
    }

    for (let recipientField of recipientFields) {
      register({name: recipientField.name}, recipientField.rules || null);
    }
  }, [register]);

  /** Get Announcement Data **/
  useEffect(() => {
    if (id) {
      const getDataById = async () => {
        try {
          const {
            data: {data}
          } = await BaseRequest.get(`${apiUri}/${id}/edit?with=classes,groups`);

          return data;
        } catch (error) {
          console.log(error);
        }
      };

      getDataById().then(data => {
        setAnnouncement(data);
        for (let key in data) {
          if (key === 'start_date' || key === 'end_date') {
            setValue(key, moment(data[key]).toDate() || null);
          } else if (key === 'is_for_all_class' && data[key]) {
            setValue('select_all_recipient', RECIPIENT_TYPE_CLASS);
          } else if (key === 'is_for_all_group' && data[key]) {
            setValue('select_all_recipient', RECIPIENT_TYPE_GROUP);
          } else if (key === 'is_for_all_admin_group' && data[key]) {
            setValue('select_all_recipient', RECIPIENT_TYPE_ADMIN_GROUP);
          } else if (key === 'is_for_all_admin' && data[key]) {
            setValue('select_all_recipient', RECIPIENT_TYPE_ADMIN);
          } else if (key === 'is_for_all_participant' && data[key]) {
            setValue('select_all_recipient', RECIPIENT_TYPE_PARTICIPANT);
          } else {
            setValue(key, data[key] || "");
          }
        }
      });
    }
  }, [id, setValue]);

  const onSubmit = async payload => {
    try {
      setSubmitLoading(true);

      let res;

      switch (payload.type_recipient) {
        case RECIPIENT_TYPE_CLASS:
          delete payload['groups'];
          delete payload['admin_groups'];
          delete payload['participants'];
          delete payload['admins'];

          if (payload.select_all_recipient === RECIPIENT_TYPE_CLASS) {
            delete payload['classes'];
            payload['is_for_all_class'] = true;
          } else {
            payload['classes'] = payload['classes'].map(data => data.value);
          }
          break;
        case RECIPIENT_TYPE_GROUP:
          delete payload['classes'];
          delete payload['admin_groups'];
          delete payload['participants'];
          delete payload['admins'];

          if (payload.select_all_recipient === RECIPIENT_TYPE_GROUP) {
            delete payload['groups'];
            payload['is_for_all_group'] = true;
          } else {
            payload['groups'] = payload['groups'].map(data => data.value);
          }
          break;
        case RECIPIENT_TYPE_ADMIN_GROUP:
          delete payload['classes'];
          delete payload['groups'];
          delete payload['participants'];
          delete payload['admins'];

          if (payload.select_all_recipient === RECIPIENT_TYPE_ADMIN_GROUP) {
            delete payload['admin_groups'];
            payload['is_for_all_admin_groups'] = true;
          } else {
            payload['admin_groups'] = payload['admin_groups'].map(data => data.value);
          }
          break;
        case RECIPIENT_TYPE_PARTICIPANT:
          delete payload['classes'];
          delete payload['groups'];
          delete payload['admin_groups'];
          delete payload['admins'];

          if (payload.select_all_recipient === RECIPIENT_TYPE_PARTICIPANT) {
            delete payload['participants'];
            payload['is_for_all_participant'] = true;
          } else {
            payload['participants'] = payload['participants'].map(data => data.value);
          }
          break;
        case RECIPIENT_TYPE_ADMIN:
          delete payload['classes'];
          delete payload['groups'];
          delete payload['admin_groups'];
          delete payload['participants'];

          if (payload.select_all_recipient === RECIPIENT_TYPE_ADMIN) {
            delete payload['admins'];
            payload['is_for_all_admin'] = true;
          } else {
            payload['admins'] = payload['admins'].map(data => data.value);
          }
          break;
        default:
          break;
      }

      if (payload.is_timeless) {
        payload.start_date = null;
        payload.end_date = null;
      } else {
        payload.start_date = moment(payload.start_date).format("YYYY-MM-DD");
        payload.end_date = moment(payload.end_date).format("YYYY-MM-DD");
      }

      if (id) {
        res = await BaseRequest.put(`${apiUri}/${id}`, payload);
      } else {
        res = await BaseRequest.post(`${apiUri}/save`, payload);
      }

      toast.success(res.data.message, {
        closeButton: false,
        position: "bottom-right"
      });

      setTimeout(() => history.push(thisUri), 2000);
    } catch (error) {
      let errorMessage = error.response
        ? error.response.data.message
        : error.message;
      toast.error(errorMessage, {
        closeButton: false,
        position: "bottom-right"
      });

      setSubmitLoading(false);
    }
  };

  /** Define form fields **/
  const getFormFields = () => {
    return formFields.map((field) => ({...field, error: errors[field.name]}));
  };

  /** Input text change handler **/
  const handleInputTextChange = (e) => {
    const {name, value} = e.target;
    setValue(name, value);
  };

  /** Radio Input change handler **/
  const handleSelectChange = (field, value) => {
    if (field && value) {
      setValue(field, value);
    }
  };

  return (
    <MDBContainer>
      <MDBRow center>
        <MDBCol size="12" md="12" sm="12">
          <h3 className="text-center pt-4 pb-5 mb-2">
            {id ? "Edit" : "Tambah"} Pengumuman
          </h3>

          <Form
            onSubmit={handleSubmit(onSubmit)}
            fields={getFormFields()}
            watcher={watch}
            onInputTextChange={handleInputTextChange}
            onSelectChange={handleSelectChange}
            submitLoading={submitLoading}
            values={getValues()}
            backUri={thisUri}
            triggerValidation={triggerValidation}
          />
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default PengumumanFormPage;
