import React, {useEffect, useState} from "react";
import {
  MDBBtn, MDBIcon,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
  MDBSpinner,
  toast
} from "mdbreact";
import { TwitterPicker } from 'react-color';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useForm } from "react-hook-form";
import BaseRequest from "../../../../services/BaseRequest";
import InputError from "../../../components/InputError";
import { useHistory } from "react-router-dom";
import DateTimePicker from 'react-datetime-picker';
import moment from 'moment';

import DeleteModal from "../../../../components/modals/DeleteModal";

const formField = [
  { name: "title", rules: { required: true } },
  { name: "description", rules: { required: true } },
  { name: 'start_time' },
  { name: 'end_time' },
  { name: 'color' }
];

const AcademicCalendarFormModal = (props) => {
  const { thisUri, apiUri, toggle, isOpen, selectedCal, triggerLoadEventData } = props;

  const history = useHistory();
  const [ pageTitle, setPageTitle ] = useState('Tambah Kalender Akademik');
  const [ submitLoading, setSubmitLoading ] = useState(false);
  const [ id, setId ] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  // const [colorPickerPicked, setColorPickerPicked] = useState('#fff');

  // Setup the form
  const { register, handleSubmit, errors, setValue, watch } = useForm();

  useEffect(() => {
    if(selectedCal.id) {
      setPageTitle('Ubah Kalender Akademik');
      setId(selectedCal.id);
    } else {
      setPageTitle('Tambah Kalender Akademik');
      setId('');
    }
  }, [selectedCal.id]);

  useEffect(() => {
    if(selectedCal.color) {
      setValue('color', selectedCal.color)
    } else {
      setValue('color', '')
    }
  }, [selectedCal.color, setValue]);

  useEffect(() => {
    if(selectedCal.title) {
      setValue('title', selectedCal.title)
    } else {
      setValue('title', '')
    }
  }, [selectedCal.title, setValue]);

  useEffect(() => {
    if(selectedCal.description) {
      setValue('description', selectedCal.description)
    } else {
      setValue('description', '')
    }
  }, [selectedCal.description, setValue]);

  useEffect(() => {
    if(selectedCal.start) {
      setValue('start_time', selectedCal.start);
    }
  }, [selectedCal.start, setValue]);
  
  useEffect(() => {
    if(selectedCal.end) {
      setValue('end_time', selectedCal.end);
    }
  }, [selectedCal.end, setValue]);

  // Register form fields
  useEffect(() => {
    for (let v of formField) {
      register({ name: v.name }, v.rules || null);
    }
  }, [ register ]);

  const colorPickerHandleChange = (color) => {
    // setColorPickerPicked(color.hex);
    setValue('color', color.hex)
  };

  // Handle form field changes
  const handleChange = e => {
    setValue(e.target.name, e.target.value);
  };

  const ckEditorHandleChange = (name, editor) => {
    let value = editor.getData();
    setValue(name, value);
  };

  const handleStartTimeChange = date => {
    setValue('start_time', date);
  }

  const handleEndTimeChange = date => {
    setValue('end_time', date);
  }

  const generateAPIPayloadForCreate = (payload) => {
    const APIPayload = {
      start_date: moment(payload.start_time).format('YYYY-MM-DD'),
      start_time: moment(payload.start_time).format('HH:mm'),
      end_date: moment(payload.end_time).format('YYYY-MM-DD'),
      end_time: moment(payload.end_time).format('HH:mm'),
      title: payload.title,
      description: payload.description,
      color: payload.color
    }

    return APIPayload
  }

  const generateAPIPayloadForUpdate = (payload) => {
    const APIPayload = {
      id: id,
      start_date: moment(payload.start_time).format('YYYY-MM-DD'),
      start_time: moment(payload.start_time).format('HH:mm'),
      end_date: moment(payload.end_time).format('YYYY-MM-DD'),
      end_time: moment(payload.end_time).format('HH:mm'),
      title: payload.title,
      description: payload.description,
      color: payload.color
    }

    return APIPayload
  }

  const toggleDeleteModal = (id) => {
    // setSelectedDeleteItem(id);
    setShowDeleteModal(!showDeleteModal);
  }

  const handleDelete = async () => {
    try {
      setDeleteLoading(true);

      await BaseRequest.delete(`${apiUri}/${id}`);
      // reloadDataFromServer();
    } catch (err) {
      console.error(err);
    } finally {
      triggerLoadEventData()
      toggleDeleteModal()
      toggle()
      setDeleteLoading(false);
    }
  };

  // Submit the form
  const onSubmit = async (payload) => {
    setSubmitLoading(true);
    try {
      let res = id === '' ?
        await BaseRequest.post(`${apiUri}/save`, generateAPIPayloadForCreate(payload))
      :
        await BaseRequest.put(`${apiUri}/${id}`, generateAPIPayloadForUpdate(payload))
      

      toast.success(res.data.message, {
        closeButton: false,
        position: "bottom-right"
      });

      setTimeout(() => {
        triggerLoadEventData()
        toggle()
        setSubmitLoading(false)
      }, 2000);
    } catch (error) {
      let errorMessage = error.response
        ? error.response.data.message
        : error.message;
      toast.error(errorMessage, {
        closeButton: false,
        position: "bottom-right"
      });

      setSubmitLoading(false);
    }
  };

  return (
    <MDBModal isOpen={isOpen} toggle={toggle} backdrop={false} cascading>
      <form onSubmit={handleSubmit(onSubmit)}>
        <MDBModalHeader
          toggle={toggle}
          titleClass="d-inline title"
          className="text-center green darken-3 white-text"
        >
          <MDBIcon icon="plus" className={"pr-2"}/>
          {pageTitle}
        </MDBModalHeader>
        <MDBModalBody>
          <MDBInput
            label="Nama Event Akademik"
            className={errors.title && "is-invalid"}
            name="title"
            type="text"
            value={watch("title")}
            onChange={handleChange}
          >
            {
              errors.title &&
              errors.title.type === "required" && (
                <InputError text="Tidak boleh kosong" />
              )
            }
          </MDBInput>
          <MDBInput
            type="text"
            label="Deskripsi"
            disabled
            className={'ck-editor__label'}
          />
          <CKEditor
              editor={ ClassicEditor }
              config={{placeholder: "Deskripsi"}}
              data={watch('description')}
              onChange={ (event, editor) => ckEditorHandleChange('description', editor) }
          />
          <label htmlFor="defaultFormLoginEmailEx" className="grey-text">
            Waktu Mulai
          </label>
          <DateTimePicker
            onChange={handleStartTimeChange}
            value={watch('start_time')}
            format="y-MM-dd HH:mm:ss"
            className="form-control"
            placeholder="Waktu Dibuka"
          />
          <label htmlFor="defaultFormLoginEmailEx" className="grey-text">
            Waktu Akhir
          </label>
          <DateTimePicker
            onChange={handleEndTimeChange}
            value={watch('end_time')}
            format="y-MM-dd HH:mm:ss"
            className="form-control"
            placeholder="Waktu Dibuka"
          />
          <MDBInput
            label="Warna"
            className={errors.color && "is-invalid"}
            name="color"
            type="text"
            value={watch("color")}
            onChange={handleChange}
          ></MDBInput>
          <TwitterPicker
            color={watch("color")}
            onChangeComplete={colorPickerHandleChange}
          />
        </MDBModalBody>
        <MDBModalFooter>
          <div className={"text-center"}>
            {
              submitLoading ?
                <MDBSpinner/> :
                <>
                  <MDBBtn type={"button"} size={"md"} color={"danger"} onClick={toggle}>Kembali</MDBBtn>
                  <MDBBtn type={"submit"} size={"md"} color={"green"}>Simpan</MDBBtn>
                  {
                    id !== '' ?
                    <MDBBtn 
                      type={"button"} size={"md"} color={"red"}
                      onClick={() => toggleDeleteModal(id)}>
                      Delete
                    </MDBBtn>
                    :
                    ''
                  }
                </>
            }
          </div>
        </MDBModalFooter>
      </form>
      <DeleteModal
        isOpen={showDeleteModal}
        toggle={toggleDeleteModal}
        handler={handleDelete}
        loadingIndicator={deleteLoading}
      />
    </MDBModal>
  );
};

export default AcademicCalendarFormModal;
