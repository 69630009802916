import React, {useEffect, useState} from "react";
import {
  MDBBtn, MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader, MDBSelect,
  MDBSpinner,
  toast
} from "mdbreact";
import { useForm } from "react-hook-form";
import BaseRequest from "../../../../services/BaseRequest";

const formField = [
  { name: "selectedCategory", rules: { required: true } }
];

const KategoriSoalImportFormModal = (props) => {
  const { apiUri, toggle, isOpen, currentCategoryID, reloadQuestions } = props;

  const title = "Import Soal";
  const [categoryList, setCategoryList] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);

  // Setup the form
  const { register, handleSubmit, errors, setValue, watch, reset } = useForm();
  const selectedCategory = watch('selectedCategory');

  // Register form fields
  useEffect(() => {
    for (let v of formField) {
      register({ name: v.name }, v.rules || null);
    }
  }, [ register ]);

  // Load category list data
  useEffect(() => {
    const loadCategoryData = async () => {
      let {
        data: { data }
      } = await BaseRequest.post(`${apiUri}?limit=-1`);

      let categories = data.data
        .filter(category => category.id !== currentCategoryID)
        .map((v) => {
          return {
            text: v.name,
            value: v.id
          };
        });

      return { data: categories };
    };

    loadCategoryData()
      .then((res) => {
        setCategoryList(res.data);
      })
  }, [currentCategoryID]);

  // Submit the form
  const onSubmit = async (payload) => {
    setSubmitLoading(true);

    try {
      // Import the soal from selected kategori
      let res = await BaseRequest.post(`questions/duplicate`, {
        question_category_old_id: payload.selectedCategory,
        question_category_new_id: currentCategoryID
      });

      toast.success(res.data.message, {
        closeButton: false,
        position: "bottom-right"
      });

      setTimeout(() => {
        // Close the import modal
        toggle();

        // Reload the question data
        reloadQuestions();

        setSubmitLoading(false);
      }, 1000);
    } catch (error) {
      let errorMessage = error.response
        ? error.response.data.message
        : error.message;
      toast.error(errorMessage, {
        closeButton: false,
        position: "bottom-right"
      });

      setSubmitLoading(false);
    }
  };

  return (
    <MDBModal isOpen={isOpen} toggle={toggle} backdrop={false} size={"lg"} cascading>
      <form onSubmit={handleSubmit(onSubmit)}>
        <MDBModalHeader
          toggle={toggle}
          titleClass="d-inline title"
          className="text-center purple darken-3 white-text"
        >
          <MDBIcon icon="file-import" className={"pr-2"}/>
          {title}
        </MDBModalHeader>
        <MDBModalBody>
          <MDBSelect
            search
            name="selectedCategory"
            label="Pilih Kategori"
            selected="Pilih kategori yang akan di import"
            options={categoryList}
            getValue={(v) => setValue("selectedCategory", v[0])}
          />
          {
            errors.selectedCategory &&
            errors.selectedCategory.type === "required" && (
            <span className="text-danger small">Tidak boleh kosong</span>
          )}
        </MDBModalBody>
        <MDBModalFooter>
          <div className={"text-center"}>
            {
              submitLoading ?
                <MDBSpinner/> :
                <>
                  <MDBBtn type={"button"} size={"md"} color={"red"} onClick={toggle}>Kembali</MDBBtn>
                  <MDBBtn type={"submit"} size={"md"} color={"green"}>Import</MDBBtn>
                </>
            }
          </div>
        </MDBModalFooter>
      </form>
    </MDBModal>
  );
};

export default KategoriSoalImportFormModal;
