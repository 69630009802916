import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBInput,
  MDBSelect,
  MDBSpinner,
  toast,
  MDBFileInput
} from "mdbreact";
import InputError from "../../../components/InputError";
import { Link } from "react-router-dom";
import BaseRequest from "../../../../services/BaseRequest";
import * as FileRequest from "../../../../services/FileRequest";
import * as queryString from "query-string";

let thisUri = `/perkuliahan/pertemuan`;
const apiURi = `/course-parts`;

const formField = [
  { name: "course_id", rules: { required: true } },
  { name: "title_id", rules: { required: true } },
  { name: "title_en" },
  { name: "title_ar" },
  { name: "description_id" },
  { name: "description_en" },
  { name: "description_ar" },
  { name: "file_id" }
];

const RenderButton = ({ submitLoading }) => {
  if (submitLoading) {
    return <MDBSpinner />;
  }
  return (
    <div>
      <Link to={thisUri}>
        <MDBBtn size="md" color="red" className="mx-0">
          Kembali
        </MDBBtn>
      </Link>
      <MDBBtn type="submit" color="green" size="md">
        Simpan
      </MDBBtn>
    </div>
  );
};

const SubMataKuliahFormPage = () => {
  const history = useHistory();
  const { id } = useParams(); // Jika id !== undefined, maka ini adalah fitur edit data
  const location = useLocation();

  const { register, handleSubmit, errors, setValue, watch } = useForm();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [file, setFile] = useState({});

  let [listCourse, setListCourse] = useState([{text:'',value:''}]);

  const getCourses = async () => {
    const uri = `/courses`
    const { data: { data } } = await BaseRequest.post(uri, {
      sortBy: 'title_id',
      desc: false
    });
    return data.data.map((v, i) => ({
      text: v.title_id,
      value: v.id
    }));
  }

  const handleChange = e => {
    setValue(e.target.name, e.target.value);
  };

  const handleCourseChange = id => {
    setValue('course_id', id[0]);
  }

  let course_id = watch('course_id')

  useEffect(() => {
    for (let v of formField) {
      register({ name: v.name }, v.rules || null);
    }
  }, [register]);

  const [filePreview, setFilePreview] = useState('');
  useEffect(() => {
    if (id) {
      const getDataById = async () => {
        try {
          const {
            data: { data }
          } = await BaseRequest.get(`${apiURi}/${id}/edit`);

          for (let key in data) {
            setValue(key, data[key] || "");
          }

          if(data.file){
            setFilePreview(data.file.url)
          }

        } catch (error) {
          console.log(error);
        }
      };
      getDataById();
    }
  }, [id, setValue]);

  useEffect(() => {
    if(course_id){
      getCourses()
      .then(res => {
        setListCourse(()=>(
          res.map((v, i) => ({
            ...v,
            checked: (course_id === v.value)
          }))
        ))
      })
    }else{
      if(!id){
        getCourses()
        .then(res => {
          setListCourse(res)
        })
      }
    }
  },[course_id, id]);

  useEffect(() => {
    // Check where the edit form comes from
    let parsed = queryString.parse(location.search);
    if (parsed.from) {
      switch (parsed.from) {
        case 'detail-page':
          thisUri = `/perkuliahan/mata-kuliah/${parsed.id}`;
          break;
        default:
          break;
      }
    }
  }, [location])

  const onSubmit = async payload => {

    try {
      setSubmitLoading(true);
      let res = null;
      if (file instanceof File) {
        const fd = new FormData();
        fd.append('file', file);
        const { data } = await FileRequest.upload(fd);
        payload.file_id = data.data.id;
      }

      if (id) {
        if (payload.file_id === "") delete payload.file_id; // Agar tidak error di API nya.
        res = await BaseRequest.put(`${apiURi}/${id}`, payload);
        if(res.data.file){
          setFilePreview(res.data.file.url)
        }
      } else {
        res = await BaseRequest.post(`${apiURi}/save`, payload);
      }
      toast.success(res.data.message, {
        closeButton: false,
        position: "bottom-right"
      });
      setTimeout(() => history.push(thisUri), 2000);
    } catch (error) {
      let errorMessage = error.response
        ? error.response.data.message
        : error.message;
      toast.error(errorMessage, {
        closeButton: false,
        position: "bottom-right"
      });
      setSubmitLoading(false);
    }
  };

  return (
    <MDBContainer>
      <MDBRow center>
        <MDBCol size="12" md="9" sm="12">
          <MDBCard>
            <MDBCardBody>
              <MDBCardTitle>
                {id ? "Edit" : "Tambah"} Pertemuan
              </MDBCardTitle>
              <hr />
              <form onSubmit={handleSubmit(onSubmit)}>
                <MDBContainer>
                  <MDBRow>
                    <MDBCol size="12">

                      <MDBSelect
                        className={errors.course_id && "is-invalid"}
                        name="course_id"
                        getValue={handleCourseChange}
                        options={listCourse}
                        selected="Pilihan Mata Kuliah"
                        label="Mata Kuliah"
                      />
                      {errors.course_id &&
                        errors.course_id.type === "required" && (
                          <span className="text-danger small">Tidak boleh kosong</span>
                        )}

                      <MDBInput
                        className={errors.title_id && "is-invalid"}
                        name="title_id"
                        onChange={handleChange}
                        type="text"
                        label="Judul (Indonesia)"
                        value={watch("title_id")}
                      >
                        {errors.title_id &&
                          errors.title_id.type === "required" && (
                            <InputError text="Tidak boleh kosong" />
                          )}
                      </MDBInput>
                      <MDBInput
                        name="title_en"
                        onChange={handleChange}
                        type="text"
                        label="Judul (English)"
                        value={watch("title_en")}
                      />
                      <MDBInput
                        name="title_ar"
                        onChange={handleChange}
                        type="text"
                        label="Judul (Arabic)"
                        value={watch("title_ar")}
                      />
                      <MDBInput
                        name="description_id"
                        onChange={handleChange}
                        type="textarea"
                        label="Deskripsi (Indonesia)"
                        rows="2"
                        value={watch("description_id")}
                      />
                      <MDBInput
                        name="description_en"
                        onChange={handleChange}
                        type="textarea"
                        label="Deskripsi (English)"
                        rows="2"
                        value={watch("description_en")}
                      />
                      <MDBInput
                        name="description_ar"
                        onChange={handleChange}
                        type="textarea"
                        label="Deskripsi (Arabic)"
                        rows="2"
                        value={watch("description_ar")}
                      />
                      <MDBFileInput textFieldTitle="Upload Picture" getValue={(v) => setFile(v[0])} />
                      {filePreview &&
                        <img src={filePreview} className='rounded' style={{ maxWidth: '300px'}}/>
                      }
                    </MDBCol>
                  </MDBRow>
                </MDBContainer>

                <div className="text-center mt-3 black-text">
                  <RenderButton submitLoading={submitLoading} />
                </div>
              </form>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default SubMataKuliahFormPage;
