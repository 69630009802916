import React, {useCallback, useEffect, useMemo, useState} from "react";
import {MDBBtn, MDBBtnFixed, MDBCol, MDBContainer, MDBIcon, MDBInput, MDBRow, MDBSelect, toast} from "mdbreact";
import {default as CustomDataTable} from "../../components/DataTable";
import DeleteModal from "../../modals/DeleteModal";
import BaseRequest from "../../../services/BaseRequest";
import moment from "moment";
import {useHistory} from "react-router";
import WarningModal from "../../modals/WarningModal";

const thisUri = '/syahadah'
const apiUri = '/syahadahs'

const jobStatuses = [
  {
    text: 'Aktif',
    value: '1',
    checked: true
  },
  {
    text: 'Sudah Beres',
    value: '2'
  },
];

const SyahadahPage = () => {
  const history = useHistory();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedDeleteItem, setSelectedDeleteItem] = useState({});
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [showActivationModal, setShowActivationModal] = useState(false);
  const [selectedActivationItem, setSelectedActivationItem] = useState(null);
  const [activationLoading, setActivationLoading] = useState(false);

  /** Datatable variables **/
  const [ loadProgressData, setLoadProgressData ] = useState(true);
  const [ dtRowsData, setDtRowsData ] = useState([]);
  const [ dtSortBy, setDtSortBy ] = useState('created_at');
  const [ dtSortDirection, setDtSortDirection ] = useState('desc');
  const [ dtTotalRow, setDtTotalRow ] = useState(1);
  const [ dtPage, setDtPage ] = useState(1);
  const [ dtPerPage, setDtPerPage ] = useState(10);
  const [ dtSearchMeta, setDtSearchMeta ] = useState({
    // filter: {},
    field_search: 'cert_name',
    search: '',
    with: 'class,course,course_part'
  });
  const [ dtShowFinalJobs, setDtShowFinalJobs] = useState(false);

  /** Datatable column definition **/
  const columns = [
    {
      name: 'No',
      selector: 'rowNo',
      width: "4rem",
      right: true
    },
    {
      cell: (row) => {
        if (row.class)
          return <a>Program ►<br/>{row.class.name}</a>;
        else if (row.course)
          return <a href={`/perkuliahan/mata-kuliah/${row.course.id}/show`} target="_blank" rel="noopener noreferrer">Mata Kuliah ►<br/>{row.course.title_id}</a>
        else if (row.course_part)
          return <a>Pertemuan ►<br/>{row.course_part.title_id}</a>;
      },
      name: "E-Syahadah Untuk",
      selector: "info",
      style: {
        whiteSpace: 'none',
      },
      width: "16rem"
    },
    {
      name: "Judul Sertifikat",
      selector: "certificate_name"
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
    },
    {
      name: "Job Result",
      selector: "result_message",
      // width: "16rem"
    },
    {
      name: "Dibuat Tanggal",
      selector: "created_at",
      sortable: true,
      width: "8rem"
    },
    {
      cell: (row) =>
        <>
          {row.status == "not_started" && 
          <>
            <div className="btn-group-sm">
              <MDBBtn variant="contained" color="yellow" onClick={() => history.push(`${thisUri}/${row.id}/edit`)}>
                <MDBIcon icon="pen-nib" />
              </MDBBtn>
            </div>
            <div className="btn-group-sm">
              <MDBBtn variant="contained" color="green" onClick={() => toggleActivationModal(row.id)}>
                <MDBIcon icon="play" />
              </MDBBtn>
            </div>
          </>
          }
          <div className="btn-group-sm">
            <MDBBtn variant="contained" color="red" onClick={() => toggleDeleteModal(row.id)}>
              <MDBIcon icon="trash" />
            </MDBBtn>
          </div>
        </>,
      button: true,
      width: "8rem"
    }
  ];

  /** Activation handler **/
  const toggleActivationModal = id => {
    setSelectedActivationItem(id);
    setShowActivationModal(!showActivationModal);
  }

  const handleActivation = async () => {
    try {
      setActivationLoading(true);
      await BaseRequest.put(`${apiUri}/${selectedActivationItem}/start`);
      reloadDataFromServer(dtPage, dtPerPage);
    } catch (err) {
      console.error(err);
    } finally {
      toggleActivationModal({});
      setActivationLoading(false);
    }
  }

  /** Datatable expanded row component **/
  const SyahadahExpandedRow = ({ data }) => (
    <div className="p-3">
      <h5><u>Detil E-Syahadah Job</u></h5>
      <ul>
        <li>Job ID: {data.id}</li>
        <li>Judul Sertifikat (Tahun): {data.cert_name} ({data.cert_period})</li>
        <li>Format Penomoran: {data.cert_number_format}</li>
        <li>Tanda Tangan: {data.signee_name} / {data.signee_position} / {data.sign_month_year}</li>
        <li>Terakhir Disunting: {moment(data.updated_at).format("DD/MM/YYYY")}</li>
      </ul>
    </div>
  )

  /** Datatable get data **/
  const getSyahadahs = useCallback(async (page = 1, perPage = 10) => {
    let uri = `${apiUri}?${dtShowFinalJobs? 'filter[status][in]=success,failed'
      : 'filter[status][in]=not_started,in_progress,ready'}`;

    try {
      setLoadProgressData(true);

      const {
        data: { data }
      } = await BaseRequest.post(uri, {
        sortBy: dtSortBy,
        desc: dtSortDirection === 'desc',
        page: page,
        limit: perPage,
        ...dtSearchMeta
      });

      setDtTotalRow(data.total);

      // Map the questions data
      const mappedData = data.data.map((v, i) => {
        return{
          ...v,
          rowNo: data.from + i,
          certificate_name: `${v.cert_name} ${v.cert_period}`,
          status: v.status,
          result_message: v.result_message,
          created_at: moment(v.created_at).format("DD/MM/YYYY"),
        }
      });

      return { rows: mappedData }
    } catch (error) {
      let errorMessage = error.response
        ? error.response.data.message
        : error.message;
      toast.error(errorMessage, {
        closeButton: false,
        position: "bottom-right"
      });
    }

    return { rows: [] }
  }, [dtSearchMeta, dtSortBy, dtSortDirection, dtShowFinalJobs]);

  const reloadDataFromServer = useCallback((page = 1, perPage = 10) => {
    getSyahadahs(page, perPage)
      .then((res) => {
        const { rows } = res;

        setDtRowsData(rows);
      })
      .finally(() => {
        setLoadProgressData(false);
      });
  }, [getSyahadahs]);

  useEffect(() => {
    reloadDataFromServer(dtPage, dtPerPage);
  }, [reloadDataFromServer, dtPage, dtPerPage]);

  /** Datatable X **/
  const subHeaderComponentMemo = useMemo(() => {
    const handleFilter = (keyword, comesFromSelect = false, XName = null) => {
      setLoadProgressData(true);

      let newSearchMeta = dtSearchMeta;

      if (comesFromSelect) {
        let selectedValue = keyword;

        if (selectedValue === '') {
          delete newSearchMeta.X[XName];
        } else {
          newSearchMeta.X[XName] = selectedValue;
        }
      } else {
        newSearchMeta.search = keyword;
      }

      setDtSearchMeta(newSearchMeta);

      getSyahadahs(newSearchMeta)
        .then((res) => {
          const { rows } = res;

          setDtRowsData(rows);
          setLoadProgressData(false);
        })
    };

    return (
      <div className="w-100 data-table__filter-input">
        <MDBRow className={"w-100"}>
          <MDBCol size={"12"} md={"2"} sm={"12"}>
            <MDBSelect
              label="Status Job"
              getValue={value => setDtShowFinalJobs(value == "2")}
              className="search-input"
              name="status_job"
              options={jobStatuses}
            />
          </MDBCol>
          <MDBCol size={"12"} md={"4"} sm={"12"}>
            <MDBInput
              label="Cari Judul Sertifikat"
              onChange={(e) => handleFilter(e.target.value)}
              className="search-input"
              name="search"
            />
          </MDBCol>
        </MDBRow>
      </div>
    )
  }, [dtSearchMeta, getSyahadahs]);

  /** Datatable sorting **/
  const onSortHandler = (column, sortDirection) => {
    setDtSortBy(column.selector);
    setDtSortDirection(sortDirection);

    reloadDataFromServer(dtPage, dtPerPage);
  };

  const toggleDeleteModal = (id) => {
    setSelectedDeleteItem(id);
    setShowDeleteModal(!showDeleteModal);
  }

  const handleDelete = async () => {
    try {
      setDeleteLoading(true);

      await BaseRequest.delete(`${apiUri}/${selectedDeleteItem}`);
      reloadDataFromServer(dtPage, dtPerPage);
    } catch (err) {
      console.error(err);
    } finally {
      toggleDeleteModal();
      setDeleteLoading(false);
    }
  };

  /** Datatable Change Page handler **/
  const onPageChangeHandler = (page) => {
    setDtPage(page)
  }

  const onPerRowsChangeHandler = (perPage) => {
    setDtPerPage(perPage)
  }

  return (
    <MDBContainer fluid>
      <h3>E-Syahadah</h3>

      <CustomDataTable
        columns={columns}
        data={dtRowsData}
        totalRow={dtTotalRow}
        onSortHandler={onSortHandler}
        subHeaderComponent={subHeaderComponentMemo}
        progressPending={loadProgressData}
        handlePerRowsChange={onPerRowsChangeHandler}
        handlePageChange={onPageChangeHandler}
        expandableRows={true}
        expandableRowsComponent={<SyahadahExpandedRow />}
      />

      <DeleteModal
        isOpen={showDeleteModal}
        toggle={toggleDeleteModal}
        handler={handleDelete}
        loadingIndicator={deleteLoading}
      />

      <WarningModal
        isOpen={showActivationModal}
        toggle={toggleActivationModal}
        handler={handleActivation}
        loadingIndicator={activationLoading}
        title={"Konfirmasi"}
        text={'Mulai E-Syahadah job sekarang?'}
      />

      <MDBBtnFixed
        floating
        color="blue"
        icon="plus"
        size="lg"
        style={{bottom: "55px", right: "24px"}}
        onClick={() => history.push(`${thisUri}/create`)}
        className="no-li"
      />
    </MDBContainer>
  );
}

export default SyahadahPage;
