import React, {useCallback, useEffect, useMemo, useState} from "react";
import moment from "moment";
import BaseRequest from "../../../../../services/BaseRequest";
import {useHistory, useParams} from "react-router-dom";
import {
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
  MDBRow,
  MDBSelect,
  MDBSpinner,
  toast
} from "mdbreact";
import {default as CustomDataTable} from "../../../../components/DataTable";
import TextField from "@material-ui/core/TextField";

const genderOptions = [
  {
    text: 'Ikhwan',
    value: 'ikhwan',
    checked: true
  },
  {
    text: 'Akhwat',
    value: 'akhwat'
  },
];

const KuisParticipantPage = () => {
  const history = useHistory();
  const { id, mode } = useParams();

  /** Datatable variables **/
  const [ dtRowsData, setDtRowsData ] = useState([]);
  const [ dtSortBy, setDtSortBy ] = useState('created_at');
  const [ dtSortDirection, setDtSortDirection ] = useState('desc');
  const [ dtTotalRow, setDtTotalRow ] = useState(1);
  const [ dtSearchMeta, setDtSearchMeta ] = useState({
    filter: {gender:'ikhwan'},
    field_search: 'name,nip',
    search: ''
  });
  const [ dtPage, setDtPage ] = useState(1);
  const [ dtPerRow, setDtPerRow ] = useState(10);
  const [ loadProgressData, setLoadProgressData ] = useState(true);
  const [ quiz, setQuiz ] = useState(null);

  /** Datatable column definition **/
  const columns = [
    {
      name: 'No',
      selector: 'rowNo',
      width: "4rem",
      right: true
    },
    {
      name: "Peserta",
      selector: "title",
      sortable: true
    },
    {
      name: "Kontak",
      selector: "kontak",
    },
  ];

  const handleSendWA = item => {
    let waNumber = item.phone_country_code.replace('+','') + item.phone
    window.open(`https://api.whatsapp.com/send?phone=${waNumber}&text=`,'_blank')
  }

  /** Datatable get data **/
  const getDataParticipants = useCallback(async (page = 1, perPage = 10) => {
    let uri = `${mode == "sudah-menjawab"? "taken-quiz" : "not-taken-quiz"}`;

    try {
      const {
        data: { data }
      } = await BaseRequest.post(uri, {
        sortBy: dtSortBy,
        //desc: dtSortDirection === 'desc', bikin error
        page: page,
        limit: perPage,
        quiz_id: id,
        ...dtSearchMeta
      });

      setDtTotalRow(data.total);

      // Map the questions data
      const mappedData = data.data.map((v, i) => ({
        rowNo: data.from + i,
        id: v.id,
        title: (
          <>
            <span style={{color:'rgb(140, 131, 131)'}}>{v.nip}</span><br/>
            <span style={{fontWeight:'bold'}}>
              <span style={{color:'rgb(48, 139, 218)'}}>{v.name}</span>
            </span>
          </>
        ),
        kontak: (
          <div className="btn-group-sm">
            <MDBBtn color="green" onClick={() => handleSendWA(v)}>
              {/*<MDBIcon icon="whatsapp" />*/}Kirim WA
            </MDBBtn>
          </div>
        ),
      }));

      return { rows: mappedData }
    } catch (error) {
      let errorMessage = error.response
        ? error.response.data.message
        : error.message;
      toast.error(errorMessage, {
        closeButton: false,
        position: "bottom-right"
      });
    }

    return { rows: [] }
  }, [dtSearchMeta, dtSortBy, dtSortDirection]);

  const reloadDataFromServer = useCallback((page = 1, perPage = 10) => {
    getDataParticipants(page, perPage)
      .then((res) => {
        const { rows } = res;

        setDtRowsData(rows);
      })
      .finally(() => {
        setLoadProgressData(false);
      });
  }, [getDataParticipants]);

  useEffect(() => {
    reloadDataFromServer(dtPage, dtPerRow);
  }, [reloadDataFromServer, dtPage, dtPerRow]);

  /** Datatable sorting **/
  const onSortHandler = (column, sortDirection) => {
    setDtSortBy(column.selector);
    setDtSortDirection(sortDirection);

    reloadDataFromServer();
  };

  const getQuiz = async (id) => {
    try {
      const {
        data: { data }
      } = await BaseRequest.get(`quizzes/${id}/edit?with=course,coursepart`);
      setQuiz(data);
    } catch (error) {
      let errorMessage = error.response
        ? error.response.data.message
        : error.message;
      toast.error(errorMessage, {
        closeButton: false,
        position: "bottom-right"
      });
    }
  }

  useEffect(()=>{
    if (id) getQuiz(id);
  },[id]);

  /** Datatable Filter **/
  const subHeaderComponentMemo = useMemo(() => {
    const handleFilter = (keyword, comesFromSelect = false) => {
      setLoadProgressData(true);

      let newSearchMeta = dtSearchMeta;

      if (comesFromSelect) {
        let selectedValue = keyword;

        if (selectedValue === '') {
          delete newSearchMeta.filter.gender;
        } else {
          newSearchMeta.filter = {
            ...newSearchMeta.filter,
            gender: selectedValue
          };
        }
      } else {
        newSearchMeta.search = keyword;
      }

      setDtSearchMeta(newSearchMeta);

      getDataParticipants(newSearchMeta)
        .then((res) => {
          const { rows } = res;

          setDtRowsData(rows);
          setLoadProgressData(false);
        })
    };

    return (
      <MDBRow className={"w-100"}>
      
        <MDBCol size={"6"} md={"2"} sm={"6"}>
          <MDBSelect
            label="Filter"
            getValue={(val) => handleFilter(val[0], true)}
            className={"w-100 search-input"}
            name="gender_id"
            options={genderOptions}
          />
        </MDBCol>
        
        <MDBCol size={"6"} md={"4"} sm={"6"}>
          <TextField
            label="Cari Nama / NIP"
            onChange={(e) => handleFilter(e.target.value)}
            className={"search-input"}
            style={{marginTop:'14px'}}
            name="search"
            autoComplete="off"
          />
        </MDBCol>
    </MDBRow>
    )
  }, [dtSearchMeta, getDataParticipants]);

  const handlePageChange = (page) => {
    setDtPage(page)
  }

  const handlePerRowsChange = (perRow) => {
    setDtPerRow(perRow)
  }

  return (
    <MDBContainer fluid>
      <h3>{mode==="sudah-menjawab"? `Peserta Sudah Menjawab Kuis`:`Peserta Belum Menjawab Kuis`}</h3>

      {quiz && 
        <p>
          {quiz.title_id} / {quiz.course && quiz.course.title_id} 
          / {quiz.coursepart && quiz.coursepart.title_id} / {quiz.category}
        </p>
      }

      <CustomDataTable
        columns={columns}
        data={dtRowsData}
        onSortHandler={onSortHandler}
        subHeaderComponent={subHeaderComponentMemo}
        progressPending={loadProgressData}
        totalRow={dtTotalRow}
        handlePageChange={handlePageChange}
        handlePerRowsChange={handlePerRowsChange}
      />

    </MDBContainer>
  );
};

export default KuisParticipantPage;
