import React from "react";
import { Route, Redirect } from "react-router-dom";
import SecureLS from "secure-ls"

/**
 * User tidak bisa mengakses halaman yang di wrap dengan component ini, sebelum mereka Login terlebih dahulu
 */
const ProtectedRoute = ({ children, ...rest }) => {
  const getToken = () => {
	var ls = new SecureLS({encodingType: 'aes'});
    var data = ls.get('tsl')
    var token = data.token
    var expiration = data.expiration
    // var token = window.localStorage.getItem("token");
    // var expiration = window.localStorage.getItem("expiration");

    if (!token || !expiration) {
      return null;
    }
    
    if (Date.now() > parseInt(expiration)) {
      destroyLocalStorage();
      return null;
    } else {
      return token;
    }
  };

  const isAuthenticated = () => {
    if (getToken()) {
      return true;
    } else {
      return false;
    }
  }

  const destroyLocalStorage = () => {
	var ls = new SecureLS({encodingType: 'aes'});
	ls.removeAll();
    // window.localStorage.removeItem("userId");
    // window.localStorage.removeItem("token");
    // window.localStorage.removeItem("expiration");
  }

  return (
    <Route
      {...rest}
      render={() => {
        if (isAuthenticated()) {
          return children;
        }
        
        return <Redirect to="/login" />;
      }}
    />
  );
};

export default ProtectedRoute;
