import { SET_MENU } from "./type";

const INITIAL_STATE = []

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_MENU:
      return [...action.payload];
    default:
      return state;
  }
};
