import React, {useCallback, useEffect, useState} from "react";
import {
  MDBBtn,
  MDBCol,
  MDBContainer, MDBIcon,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBRow,
  MDBSpinner,
  MDBTabContent, MDBTabPane
} from "mdbreact";
import {useHistory, useParams} from "react-router";
import BaseRequest from "../../../../services/BaseRequest";
import SubMataKuliahList from "./SubMataKuliahList";
import MateriPerkuliahanList from "./MateriPerkuliahanList";
import {Link} from "react-router-dom";

const thisUri = '/perkuliahan/mata-kuliah';

const MataKuliahDetail = () => {
  const { id } = useParams();
  const history = useHistory();

  const [mataKuliah, setMataKuliah] = useState({});
  const [loadingData, setLoadingData] = useState(false);
  const [activeItemJustified, setActiveItemJustified] = useState("1");

  const getMataKuliahDetail = useCallback(async () => {
    setLoadingData(true);

    const { data: {
      data
    } } = await BaseRequest.get(`/courses/${id}/edit?with=courseCategory,coursePart,asatidzs,classes,coursePart,courseMaterial`);

    return data;
  }, [id])

  useEffect(() => {
    getMataKuliahDetail()
      .then((data) => setMataKuliah(data))
      .finally(() => setLoadingData(false));
  }, [getMataKuliahDetail])

  const toggleJustified = (tabNo) => {
    if (activeItemJustified !== tabNo) {
      setActiveItemJustified(tabNo);
    }
  }

  return (
    <MDBContainer fluid>
      <MDBRow center>
        <h4>Detail Mata Kuliah</h4>
      </MDBRow>
      <MDBRow center>
        <h3 className="pt-1 pb-3 mb-2">
          {mataKuliah && mataKuliah.title_id}
        </h3>

        <MDBCol size="12" md="12" sm="12">
          {
            (loadingData && mataKuliah) ?
            <div className="text-center">
              <MDBSpinner big />
            </div> :
              <div>
                <MDBNav tabs className="nav-justified" color='blue'>
                  <MDBNavItem>
                    <MDBNavLink to="#" className={activeItemJustified !== "1" ? 'inactive-nav-link' : ''} onClick={() => toggleJustified("1")} role="tab" >
                      Mata Kuliah / Silabus
                    </MDBNavLink>
                  </MDBNavItem>
                  <MDBNavItem>
                    <MDBNavLink to="#" className={activeItemJustified !== "2" ? 'inactive-nav-link' : ''} onClick={() => toggleJustified("2")} role="tab" >
                      Pertemuan
                    </MDBNavLink>
                  </MDBNavItem>
                  <MDBNavItem>
                    <MDBNavLink to="#" className={activeItemJustified !== "3" ? 'inactive-nav-link' : ''} onClick={() => toggleJustified("3")} role="tab" >
                      Materi Perkuliahan
                    </MDBNavLink>
                  </MDBNavItem>
                </MDBNav>

                <MDBTabContent
                  className="card"
                  activeItem={activeItemJustified}
                >
                  {/** Mata Kuliah / Silabus **/}
                  <MDBTabPane tabId="1" role="tabpanel">
                    <MDBRow className="mt-2 p-2">
                      {/** Mata Kuliah **/}
                      <MDBCol size="4">
                        <MDBRow>
                          <MDBCol className="text-center">
                            <h4>Mata Kuliah</h4>
                          </MDBCol>
                        </MDBRow>
                        <MDBRow className="p-2">
                          <MDBCol>Kategori</MDBCol>
                          <MDBCol>: {
                            mataKuliah.course_category ?
                              <a href={`/perkuliahan/kategori/${mataKuliah.course_category.id}/edit`} target="_blank">
                                { mataKuliah.course_category.title_id || '-' } <MDBIcon icon="external-link-alt" size="sm"/>
                              </a> : '-'
                          }
                          </MDBCol>
                        </MDBRow>
                        <MDBRow className="p-2">
                          <MDBCol>Kelas / Program</MDBCol>
                          <MDBCol>: {
                            mataKuliah.classes ?
                              <a href={`/kelas/program/${mataKuliah.classes.id}/edit`} target="_blank">
                                { mataKuliah.classes.name || '-' } <MDBIcon icon="external-link-alt" size="sm"/>
                              </a> : '-'
                          }
                          </MDBCol>
                        </MDBRow>
                        <MDBRow className="p-2">
                          <MDBCol>Judul (Indonesia)</MDBCol>
                          <MDBCol>: { mataKuliah.title_id || '-' }</MDBCol>
                        </MDBRow>
                        <MDBRow className="p-2">
                          <MDBCol>Judul (English)</MDBCol>
                          <MDBCol>: { mataKuliah.title_en || '-' }</MDBCol>
                        </MDBRow>
                        <MDBRow className="p-2">
                          <MDBCol>Judul (Arabic)</MDBCol>
                          <MDBCol>: { mataKuliah.title_ar || '-' }</MDBCol>
                        </MDBRow>
                        <MDBRow className="p-2">
                          <MDBCol>Deskripsi (Indonesia)</MDBCol>
                          <MDBCol>: { mataKuliah.description_id || '-' }</MDBCol>
                        </MDBRow>
                        <MDBRow className="p-2">
                          <MDBCol>Deskripsi (English)</MDBCol>
                          <MDBCol>: { mataKuliah.description_en || '-' }</MDBCol>
                        </MDBRow>
                        <MDBRow className="p-2">
                          <MDBCol>Deskripsi (Arabic)</MDBCol>
                          <MDBCol>: { mataKuliah.description_ar || '-' }</MDBCol>
                        </MDBRow>
                        <MDBRow className="p-2">
                          <MDBBtn color="yellow" onClick={() => history.push(`${thisUri}/${id}/edit?from=detail-page&id=${id}`)} size="sm">
                            Edit mata kuliah
                          </MDBBtn>
                        </MDBRow>
                      </MDBCol>

                      {/** Silabus **/}
                      <MDBCol size="8">
                        <MDBRow>
                          <MDBCol className="text-center">
                            <h4>Silabus</h4>
                          </MDBCol>
                        </MDBRow>
                        <MDBRow>
                          <MDBCol size="8">
                            <MDBRow className="p-2">
                              <MDBCol>Kode Mata Kuliah</MDBCol>
                              <MDBCol>: { mataKuliah.code || '-' }</MDBCol>
                            </MDBRow>
                            <MDBRow className="p-2">
                              <MDBCol>SKS</MDBCol>
                              <MDBCol>: { mataKuliah.credits || '-' }</MDBCol>
                            </MDBRow>
                            <MDBRow className="p-2">
                              <MDBCol>Semester</MDBCol>
                              <MDBCol>: { mataKuliah.semester || '-' }</MDBCol>
                            </MDBRow>
                            <MDBRow className="p-2">
                              <MDBCol>Tujuan Mata Kuliah<br/>(Kompetensi yang di harapkan)</MDBCol>
                              <MDBCol>: { mataKuliah.competencies || '-' }</MDBCol>
                            </MDBRow>
                            <MDBRow className="p-2">
                              <MDBCol>Pokok Bahasan setiap pertemuan</MDBCol>
                              <MDBCol>: { mataKuliah.subject_per_meeting || '-' }</MDBCol>
                            </MDBRow>
                            <MDBRow className="p-2">
                              <MDBCol>Strategi Perkuliahan</MDBCol>
                              <MDBCol>: { mataKuliah.strategy || '-' }</MDBCol>
                            </MDBRow>
                            <MDBRow className="p-2">
                              <MDBCol>Evaluasi Perkuliahan</MDBCol>
                              <MDBCol>: { mataKuliah.evaluation || '-' }</MDBCol>
                            </MDBRow>
                            <MDBRow className="p-2">
                              <MDBCol>Sumber Rujukan</MDBCol>
                              <MDBCol>: { mataKuliah.source_of_reference || '-' }</MDBCol>
                            </MDBRow>
                          </MDBCol>
                          <MDBCol size="4">
                            Foto Kitab
                            <br />
                            <img src={mataKuliah.file && mataKuliah.file.url} className='rounded' style={{ maxWidth: '300px'}} alt="" />
                          </MDBCol>
                        </MDBRow>
                      </MDBCol>
                    </MDBRow>
                  </MDBTabPane>

                  {/** Pertemuan **/}
                  <MDBTabPane tabId="2" role="tabpanel">
                    <SubMataKuliahList mataKuliahID={id} subMataKuliahData={mataKuliah.course_part || [] } />
                  </MDBTabPane>

                  {/** Materi Perkuliahan **/}
                  <MDBTabPane tabId="3" role="tabpanel">
                    <MateriPerkuliahanList mataKuliahID={id} materiPerkuliahanData={mataKuliah.course_material || []} />
                  </MDBTabPane>
                </MDBTabContent>

                <MDBRow className="mt-3" center>
                  <Link to={thisUri}>
                    <MDBBtn size="md" color="red" className="mx-0">
                      Kembali
                    </MDBBtn>
                  </Link>
                </MDBRow>
              </div>
          }
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}

export default MataKuliahDetail;
