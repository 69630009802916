import BaseRequest from "../../services/BaseRequest";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBMask,
  MDBInput,
  MDBView,
  MDBBtn,
  MDBSpinner,
  toast
} from "mdbreact";
import InputError from "../components/InputError";
import iconTSL from "../../assets/images/tsl-logogram-red.png"
import SecureLS from "secure-ls"
import "./Login.css";

const RenderButton = ({ isLoading }) => {
  if (isLoading) {
    return <MDBSpinner />;
  }
  return (
    <MDBBtn className="gradient-tsl" size="md" type="submit">
      Masuk
    </MDBBtn>
  );
};

const Login = () => {
  const { register, handleSubmit, errors, setValue } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const onSubmit = async ({ username, password }) => {
    try {
      setIsLoading(true);

      await BaseRequest.get(process.env.REACT_APP_CSRF_BASE_URL + '/sanctum/csrf-cookie')
        .then((res) => {
          BaseRequest.post("/login", { username, password }).then((data) => {
            if (data.status === 200) {
              var ls = new SecureLS({ encodingType: 'aes' });
              ls.set('tsl', {
                userId: `${data.data.data.id}`,
                userFullName: `${data.data.data.name}`,
                token: `${data.data.data.token}`,
                expiration: parseInt(`${data.data.data.expire_token}`) * 1000,
                role: `${data.data.data.role.name}`
              });
              history.push("/");
            }
            
          })
        .catch((err) => {
          toast.error( "Afwan, password yang dimasukkan salah. Silahkan cek kembali", {
            closeButton: false,
            position: "bottom-right",
          });
          setIsLoading(false);
        });
      });
    } catch (error) {
      console.log("c");
      let errorMessage = error.message;
      if (error.response) {
        errorMessage = error.response.data.message;
      }
      toast.error(errorMessage, {
        closeButton: false,
        position: "bottom-right"
      });
      setIsLoading(false);
    }
  };

  const handleChange = e => {
    setValue(e.target.name, e.target.value);
  };

  useEffect(() => {
    register({ name: "username" }, { required: true });
    register({ name: "password" }, { required: true });
  }, [register]);

  return (
    <div className="classic-form-page" id="login">
      <MDBView>
        <MDBMask
          className="d-flex justify-content-center align-items-center"
          overlay="stylish-strong"
        >
          <MDBContainer>
            <MDBRow>
              <MDBCol md="10" lg="6" xl="5" sm="12" className="mt-5 mx-auto">
                <MDBCard>
                  <MDBCardBody>
                    <div className="header-login gradient-tsl">
                      <img src={iconTSL} alt='logo tsl' className="logo-login" />
                      <h3 className="text-login">
                        Ahlan wa Sahlan
                      </h3>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)} noValidate>
                      <MDBInput
                        className={errors.username && "is-invalid"}
                        name="username"
                        onChange={handleChange}
                        type="text"
                        label="Username"
                        icon="address-card"
                        iconClass="black-text"
                      >
                        {errors.username &&
                          errors.username.type === "required" && (
                            <InputError text="Tidak boleh kosong" />
                          )}
                      </MDBInput>
                      <MDBInput
                        className={errors.password && "is-invalid"}
                        name="password"
                        onChange={handleChange}
                        type="password"
                        label="Password"
                        icon="key"
                        iconClass="black-text"
                      >
                        {errors.password &&
                          errors.password.type === "required" && (
                            <InputError text="Tidak boleh kosong" />
                          )}
                      </MDBInput>
                      <div className="text-center mt-3 black-text">
                        <RenderButton isLoading={isLoading} />
                      </div>
                    </form>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBMask>
      </MDBView>
    </div>
  );
};

export default Login;
