import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  toast
} from "mdbreact";
import BaseRequest from "../../../../services/BaseRequest";
import {Form} from "../../../components";
import SaveModal from "../../../modals/SaveModal";
import CoursePicker from "./CoursePicker";

const thisUri = `/kurikulum/asatidz`;
const apiUri = `/asatidzs`;

const AsatidzFormPage = () => {
  const history = useHistory();
  const { id } = useParams();

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    watch,
    getValues,
    trigger: triggerValidation
  } = useForm();

  const [submitLoading, setSubmitLoading] = useState(false);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [showSaveModal, setShowSaveModal] = useState(false);

  const formField = [
    {
      name: 'name',
      rules: { required: true },
      label: 'Nama'
    }
  ];

  useEffect(() => {
    for (let field of formField) {
      register({ name: field.name }, field.rules || null);
    }
  }, [register, formField]);

  const getCourses = async () => {
    const uri = `/courses?sortBy=created_at&desc=true`
    const { data: { data } } = await BaseRequest.post(uri);

    return data.data.map((v, i) => ({
      text: v.title_id,
      value: v.id
    }));
  }

  let course_id = watch('course_id')

  useEffect(() => {
    if (id) {
      const getDataById = async () => {
        try {
          const {
            data: { data }
          } = await BaseRequest.get(`${apiUri}/${id}/edit?with=courses`);

          for (let key in data) {
            setValue(key, data[key] || "");
          }

          return data;
        } catch (error) {
          console.log(error);
        }
      };

      getDataById().then(data => {
        setSelectedCourses(data.courses);
      });
    }
  }, [id, setValue]);

  useEffect(() => {
    if(course_id){
      getCourses()
      .then(res => {
        setSelectedCourses(()=>(
          res.map((v, i) => ({
            ...v,
            checked: (course_id === v.value)
          }))
        ))
      })
    } else {
      if(!id){
        getCourses()
        .then(res => {
          setSelectedCourses(res)
        })
      }
    }
  },[course_id, id]);

  const onSubmit = async payload => {
    try {
      setSubmitLoading(true);
      let res = null;

      if (id) {
        res = await BaseRequest.put(`${apiUri}/${id}`, payload);
      } else {
        res = await BaseRequest.post(`${apiUri}/save`, payload);
      }

      // Save the courses
      let coursesIDData = selectedCourses.map(item => item.id);
      res = await BaseRequest.post('asatidz-courses/add-multiple-course', {
        'asatidz_id': res.data.data.id,
        'courses': coursesIDData
      })

      toast.success(res.data.message, {
        closeButton: false,
        position: "bottom-right"
      });
      setTimeout(() => history.push(thisUri), 2000);
    } catch (error) {
      let errorMessage = error.response
        ? error.response.data.message
        : error.message;
      toast.error(errorMessage, {
        closeButton: false,
        position: "bottom-right"
      });
      setSubmitLoading(false);
    }
  };

  /** Define stepper details **/
  const getStepperDetails = () => {
    return {
      backUri: thisUri,
      items: [
        { index: 1, label: "Detil Asatidz" },
        {
          index: 2,
          label: "Mata Kuliah",
          component: <CoursePicker
            coursesData={selectedCourses}
            setSelectedCourse={setSelectedCourses}
          />
        },
      ]
    };
  }

  /** Toggle save modal  **/
  const toggleSaveModal = () => {
    if (selectedCourses.length <= 0) {
      let errorMessage = "Mata Kuliah tidak boleh kosong.";

      toast.error(errorMessage, {
        closeButton: false,
        position: 'bottom-right',
      });

      return;
    }

    triggerValidation()
      .then(isValid => isValid && setShowSaveModal(true));
  }

  /** Define form fields **/
  const getFormFields = () => {
    return formField.map((field) => ({ ...field, error: errors[field.name] }));
  };

  const handleInputTextChange = (e) => {
    const { name, value } = e.target;

    setValue(name, value);
  };

  return (
    <MDBContainer>
      <MDBRow center>
        <MDBCol size="12" md="12" sm="12">
          <h3 className="text-center pt-4 pb-5 mb-2">
            {id ? "Edit" : "Tambah"} Asatidz
          </h3>

          <Form
            stepper
            stepperDetails={getStepperDetails()}
            onSubmit={toggleSaveModal}
            fields={getFormFields()}
            watcher={watch}
            onInputTextChange={handleInputTextChange}
            submitLoading={submitLoading}
            values={getValues()}
            backUri={thisUri}
            triggerValidation={triggerValidation}
          />
        </MDBCol>
      </MDBRow>

      {/** Save Modal **/}
      <SaveModal
        isOpen={showSaveModal}
        toggle={() => setShowSaveModal(!showSaveModal)}
        handler={handleSubmit(onSubmit)}
        loadingIndicator={submitLoading}
      />
    </MDBContainer>
  );
};

export default AsatidzFormPage;
