import React, {useEffect, useState} from "react";
import { useForm } from "react-hook-form";
import {Link, useHistory, useParams} from "react-router-dom";
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBInput,
  MDBRow,
  MDBSelect,
  MDBSpinner,
  MDBAutocomplete,
  toast
} from "mdbreact";
import DateTimePicker from 'react-datetime-picker';
import moment from 'moment';
import InputError from "../../../components/InputError";
import BaseRequest from "../../../../services/BaseRequest";
import ReactPlayer from "react-player";
import * as queryString from "query-string";
import {useLocation} from "react-router";

let thisUri = "/perkuliahan/materi";
const apiUri = "/course-materials";

const formField = [
  { name: "course_id", rules: { required: true } },
  { name: "course_part_id", rules: { required: true } },
  // { name: "asatidz_id", rules: { required: true } },
  { name: "author", rules: { required: true } },
  { name: "title_id", rules: { required: true } },
  { name: "title_en" },
  { name: "title_ar" },
  { name: "description_id" },
  { name: "description_en" },
  { name: "description_ar" },
  { name: "time_to_show" }
];

const RenderButton = ({ submitLoading }) => {
  if (submitLoading) {
    return <MDBSpinner />;
  }
  return (
    <div>
      <Link to={thisUri}>
        <MDBBtn size="md" color="red" className="mx-0">
          Kembali
        </MDBBtn>
      </Link>
      <MDBBtn type="submit" color="green" size="md">
        Simpan
      </MDBBtn>
    </div>
  );
};

const getType = () => {
  return [
    {text:'Video',value:'video'},
    {text:'Audio',value:'audio'},
    {text:'Document',value:'document'},
    {text:'URL',value:'url'}
  ]
}

const mime = require('mime-types');

const MateriPerkuliahanFormPage = () => {
  const history = useHistory();
  const { id } = useParams(); // Jika id !== undefined, maka ini adalah fitur edit data
  const location = useLocation();

  const { register, control, handleSubmit, errors, setValue, watch, getValues } = useForm();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [materiPerkuliahan, setMateriPerkuliahan] = useState({});

  const initialAttachmentMetaData = {
    type: '',
    typeOptions: getType(),
    source: '',
    showPreviewButton: false,
    showPlayer: false,
    playerLoading: false,
    reactPlayerStyle: {
      margin: 'auto'
    }
  };
  const [attachments, setAttachments] = useState([initialAttachmentMetaData]);

  let [listCourse, setListCourse] = useState([]);
  let [listCoursePart, setListCoursePart] = useState([]);
  let [listAsatidzs, setListAsatidzs] = useState([]);

  const getCourses = async () => {
    const uri = `/courses`
    const { data: { data } } = await BaseRequest.post(uri, {
      sortBy: 'title_id',
      desc: false
    });
    return data.data.map((v, i) => ({
      text: v.title_id,
      value: v.id
    }));
  }

  const getCourseParts = async (course_id) => {
    const uri = `/course-parts`
    const { data: { data } } = await BaseRequest.post(uri, {
      sortBy: 'title_id',
      desc: false,
      filter: {
        course_id: course_id
      }
    });
    return data.data.map((v, i) => ({
      text: v.title_id,
      value: v.id
    }));
  }

  const getAsatidzs = async () => {
    const uri = `/asatidzs`
    const { data: { data } } = await BaseRequest.post(uri, {
      sortBy: 'name',
      desc: false
    });

    // return data.data.map((v, i) => ({
      // text: v.name,
      // value: v.id
    // }));

    return data.data.map((v, i) => (
      v.name
    ));
  }

  const handleTimeToShowChange = date => {
    setValue('time_to_show', date);
  }
  
  const handleChange = (e, index = null) => {
    setValue(e.target.name, e.target.value);

    if (e.target.name === 'source' && index !== null) {
      let source = e.target.value;

      const updatedAttachment = attachments.map((item, i) => {
        if (i !== index) return item;

        return { ...item, source: source, showPlayer: false, showPreviewButton: (item.type === 'audio' || item.type === 'video') && source };
      });

      setAttachments(updatedAttachment);
    }
  };

  const handleCourseChange = id => {
    setValue('course_id', id[0]);
  }

  const handleCoursePartChange = id => {
    setValue('course_part_id', id[0]);
  }

  /* TODO: Wait for the valid implementation
  const handleAsatidzChange = id => {
    setValue('asatidz_id', id[0]);
    setValue('author', id[0]);
  }*/

  const handleAuthorChange = value => {
    setValue('author', value);
  }

  const handleTypeChange = (value, index) => {
    let selectedType = value[0];

    const updatedAttachment = attachments.map((item, i) => {
      if (i !== index) return item;

      return { ...item, type: selectedType, showPlayer: false, showPreviewButton: (selectedType === 'audio' || selectedType === 'video') && item.source };
    });

    setAttachments(updatedAttachment);
  }

  let course_id = watch('course_id')
  let course_part_id = watch('course_part_id')
  let author = watch('author')

  useEffect(() => {
    for (let v of formField) {
      register({ name: v.name }, v.rules || null);
    }
  }, [register]);

  useEffect(() => {
    if (id) {
      const getDataById = async () => {
        try {
          const {
            data: { data }
          } = await BaseRequest.get(`${apiUri}/${id}/edit`);

          setMateriPerkuliahan(data);

          // Collect attachments
          if (data.sources) {
            let attachment = data.sources.map(item => {

              return {
                ...initialAttachmentMetaData,
                source: item.source,
                type: item.type,
                typeOptions: getType().map(option => {
                  return { ...option, checked: item.type === option.value }
                }),
                showPreviewButton: item.type === 'audio' || item.type === 'video'
              }
            });

            setAttachments(attachment);
          }

          for (let key in data) {
            setValue(key, data[key] || "");
          }

        } catch (error) {
          console.log(error);
        }
      };
      getDataById();
    }
  }, [id, setValue]);

  useEffect(() => {
    if(course_id) {
      getCourses()
      .then(res => {
        setListCourse(()=>(
          res.map((v, i) => ({
            ...v,
            checked: (course_id === v.value)
          }))
        ))
      })
    } else {
      if(!id){
        getCourses()
        .then(res => {
          setListCourse(res)
        })
      }
    }
  },[course_id, id]);

  useEffect(() => {
    if(materiPerkuliahan && materiPerkuliahan.course_part_id){
      getCourseParts(course_id)
      .then(res => {
        setListCoursePart(()=>(
          res.map((v, i) => ({
            ...v,
            checked: (materiPerkuliahan.course_part_id === v.value)
          }))
        ))
      })
    } else {
      if(!id){
        getCourseParts(course_id)
        .then(res => {
          setListCoursePart(res)
        })
      }
    }
  },[course_id, materiPerkuliahan, id]);

  useEffect(() => {
    if(author){
      getAsatidzs()
      .then(res => {
        setListAsatidzs(res)
      })
    }else{
      if(!id){
        getAsatidzs()
          .then(res => {
            setListAsatidzs(res)
          })
      }
    }
  },[author, id]);

  useEffect(() => {
    // Check where the edit form comes from
    let parsed = queryString.parse(location.search);
    if (parsed.from) {
      switch (parsed.from) {
        case 'detail-page':
          thisUri = `/perkuliahan/mata-kuliah/${parsed.id}`;
          break;
        default:
          break;
      }
    }
  }, [location])

  const formatDateTime = (dateTime) => {
    let formattedDate = new Date(dateTime);
    //console.log(formattedDate)
    return moment(formattedDate).format('YYYY-MM-DD HH:mm:ss');
    // return formattedDate
  }
  const onSubmit = async payload => {

    try {
      setSubmitLoading(true);
      let res = null;

      // collect the attachments
      let attachment = attachments.filter(item => item.type && item.source);
      payload['time_to_show'] = formatDateTime(payload['time_to_show']);

      if (attachment.length > 0) {
        payload['sources'] = attachments.map(item => {
          if (item.type && item.source) return {
            type: item.type, source: item.source
          }
        });
      }

      if (id) {
        res = await BaseRequest.put(`${apiUri}/${id}`, payload);
      } else {
        res = await BaseRequest.post(`${apiUri}/save`, payload);
      }
      toast.success(res.data.message, {
        closeButton: false,
        position: "bottom-right"
      });
      setTimeout(() => history.push(thisUri), 2000);
    } catch (error) {
      let errorMessage = error.response
        ? error.response.data.message
        : error.message;
      toast.error(errorMessage, {
        closeButton: false,
        position: "bottom-right"
      });
      setSubmitLoading(false);
    }
  };

  const openPlayer = (index) => {
    const updatedAttachment = attachments.map((item, i) => {
      if (i !== index) return item;

      let reactPlayerStyle;
      if (item.type === 'audio') {
        reactPlayerStyle = {
          margin: 'auto',
          height: '2.5rem'
        };
      } else {
        reactPlayerStyle = {
          margin: 'auto'
        };
      }

      return {...item, playerLoading: true, showPlayer: true, reactPlayerStyle: reactPlayerStyle}
    });

    setAttachments(updatedAttachment);
  }

  const closePlayer = (index) => {
    const updatedAttachment = attachments.map((item, i) => {
      if (i !== index) return item;

      return { ...item, showPlayer: false, playerLoading: false };
    });

    setAttachments(updatedAttachment);
  }

  const stopPlayerLoading = (index) => {
    const updatedAttachment = attachments.map((item, i) => {
      if (i !== index) return item;

      return { ...item, playerLoading: false };
    });

    setAttachments(updatedAttachment);
  }

  const addNewFileInput = () => {
    let validAttachments = attachments.filter(item => item.type && item.source);
    if (validAttachments.length === attachments.length) {
      setAttachments([...attachments, initialAttachmentMetaData]);
    }
  }

  const deleteAttachment = (index) => {
    let filteredAttachment = attachments.filter((item, i) => i !== index);
    setAttachments(filteredAttachment);
  }

  return (
    <MDBContainer>
      <MDBRow center>
        <MDBCol size="12" lg="9" md="12" sm="12">
          <MDBCard>
            <MDBCardBody>
              <MDBCardTitle>
                {id ? "Edit" : "Tambah"} Materi Perkuliahan
              </MDBCardTitle>
              <hr />
              <form onSubmit={handleSubmit(onSubmit)}>
                <MDBContainer>
                  <MDBRow>
                    <MDBCol size="12">

                      <MDBSelect
                        className={errors.course_id && "is-invalid"}
                        name="course_id"
                        getValue={handleCourseChange}
                        options={listCourse}
                        selected="Pilihan Mata Kuliah"
                        label="Mata Kuliah"
                      />
                      {errors.course_id &&
                        errors.course_id.type === "required" && (
                          <span className="text-danger small">Tidak boleh kosong</span>
                        )}

                      <MDBSelect
                        className={errors.course_part_id && "is-invalid"}
                        name="course_part_id"
                        getValue={handleCoursePartChange}
                        options={listCoursePart}
                        selected="Pilihan Pertemuan"
                        label="Pertemuan"
                      />
                      {errors.course_part_id &&
                        errors.course_part_id.type === "required" && (
                          <span className="text-danger small">Tidak boleh kosong</span>
                        )}

                      {/*<MDBSelect
                        className={errors.asatidz_id && "is-invalid"}
                        name="asatidz_id"
                        getValue={handleAsatidzChange}
                        options={listAsatidzs}
                        selected="Pilihan Asatidz"
                        label="Asatidz"
                      />
                      {errors.asatidz_id &&
                        errors.asatidz_id.type === "required" && (
                          <span className="text-danger small">Tidak boleh kosong</span>
                        )}*/}

                      {/* <MDBInput
                        className={errors.author && "is-invalid"}
                        name="author"
                        onChange={handleChange}
                        type="text"
                        label="Pemateri"
                        value={watch("author")}
                      >
                        {errors.author &&
                        errors.author.type === "required" && (
                          <InputError text="Tidak boleh kosong" />
                        )}
                      </MDBInput> */}

                      <MDBAutocomplete
                        className={errors.author && "is-invalid"}
                        data={listAsatidzs}
                        label="Pemateri"
                        name="author"
                        id="author"
                        clear
                        value={watch("author")}
                        getValue={handleAuthorChange}
                      />
                      {errors.author &&
                        errors.author.type === "required" && (
                          <span className="text-danger small">Tidak boleh kosong</span>
                        )}

                      <MDBInput
                        className={errors.title_id && "is-invalid"}
                        name="title_id"
                        onChange={handleChange}
                        type="text"
                        label="Judul (Indonesia)"
                        value={watch("title_id")}
                      >
                        {errors.title_id &&
                          errors.title_id.type === "required" && (
                            <InputError text="Tidak boleh kosong" />
                          )}
                      </MDBInput>
                      <MDBInput
                        name="title_en"
                        onChange={handleChange}
                        type="text"
                        label="Judul (English)"
                        value={watch("title_en")}
                      />
                      <MDBInput
                        name="title_ar"
                        onChange={handleChange}
                        type="text"
                        label="Judul (Arabic)"
                        value={watch("title_ar")}
                      />

                      <MDBInput
                        name="description_id"
                        onChange={handleChange}
                        type="textarea"
                        label="Deskripsi (Indonesia)"
                        rows="2"
                        value={watch("description_id")}
                      />
                      <MDBInput
                        name="description_en"
                        onChange={handleChange}
                        type="textarea"
                        label="Deskripsi (English)"
                        rows="2"
                        value={watch("description_en")}
                      />
                      <MDBInput
                        name="description_ar"
                        onChange={handleChange}
                        type="textarea"
                        label="Deskripsi (Arabic)"
                        rows="2"
                        value={watch("description_ar")}
                      />
                      <label htmlFor="defaultFormLoginEmailEx" className="grey-text">
                        Waktu Ditampilkan
                      </label>
                      <DateTimePicker
                        onChange={handleTimeToShowChange}
                        value={watch("time_to_show")}
                        className="form-control"
                        placeholder="Waktu ditampilkan"
                      />
                      <br /><br />
                      <MDBRow>
                        <h3>Lampiran</h3>
                        <div className="btn-group-sm">
                          <MDBBtn color="green" variant="contained" onClick={addNewFileInput}>
                            <MDBIcon icon="plus" size="sm" />
                          </MDBBtn>
                        </div>
                      </MDBRow>

                      {
                        attachments.map((item, index) => (
                          <div key={index}>
                            <MDBRow>
                              <MDBCol size="2" lg="2" md="3" sm="12">
                                <MDBSelect
                                  className={errors[`type-${index}`] && "is-invalid"}
                                  getValue={(value) => handleTypeChange(value, index)}
                                  options={item.typeOptions}
                                  selected="Pilihan Jenis File"
                                  label="Jenis File"
                                />
                                {errors[`type-${index}`] &&
                                errors[`type-${index}`].type === "required" && (
                                  <span className="text-danger small">Tidak boleh kosong</span>
                                )}
                              </MDBCol>

                              <MDBCol size="8" lg="8" md="6" sm="12">
                                <MDBInput
                                  className={errors[`source-${index}`] && "is-invalid"}
                                  name='source'
                                  onChange={(e) => handleChange(e, index)}
                                  type="text"
                                  label="Source (url)"
                                  value={item.source}
                                >
                                  {errors[`source-${index}`] &&
                                  errors[`source-${index}`].type === "required" && (
                                    <InputError text="Tidak boleh kosong" />
                                  )}
                                </MDBInput>
                              </MDBCol>
                              <MDBCol size="2" lg="2" md="3" sm="12" className="align-bottom m-auto">
                                <div className="btn-group-sm">
                                  {
                                    item.showPlayer ?
                                      <MDBBtn variant="contained" color="yellow" onClick={() => closePlayer(index)}>
                                        <MDBIcon icon="eye-slash" />
                                      </MDBBtn> :
                                      <MDBBtn variant="contained" color="blue" onClick={() => openPlayer(index)} disabled={!item.showPreviewButton}>
                                        <MDBIcon icon="eye" />
                                      </MDBBtn>
                                  }
                                  <MDBBtn variant="contained" color="red" onClick={() => deleteAttachment(index)}>
                                    <MDBIcon icon="trash" />
                                  </MDBBtn>
                                </div>
                              </MDBCol>
                            </MDBRow>

                            {/** Preview video/audio **/}
                            {
                              item.showPreviewButton &&
                              <div className="text-center">
                                {
                                  item.showPlayer &&
                                  <>
                                    { item.playerLoading && <MDBSpinner /> }
                                    <ReactPlayer
                                      className={'player ' + (item.type === 'audio' && 'audio-type')}
                                      url={ mime.lookup(item.source) ? [{ src: item.source, type: mime.lookup(item.source) }] : item.source}
                                      controls={true}
                                      style={item.reactPlayerStyle}
                                      onReady={() => stopPlayerLoading(index)}
                                    />
                                  </>
                                }
                              </div>
                            }
                          </div>
                        ))
                      }

                    </MDBCol>
                  </MDBRow>
                </MDBContainer>

                <div className="text-center mt-3 black-text">
                  <RenderButton submitLoading={submitLoading} />
                </div>
              </form>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default MateriPerkuliahanFormPage;
