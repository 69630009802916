import ax from "./BaseAxios";

export default {
  get(url) {
    return ax.get(url);
  },
  post(url, data) {
    return ax.post(url, data);
  },
  put(url, data) {
    return ax.put(url, data);
  },
  delete(url) {
    return ax.delete(url);
  }
};
