import React, {useState, useEffect} from "react";
import { useForm } from "react-hook-form";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  toast
} from "mdbreact";
import BaseRequest from "../../../services/BaseRequest";
import {Form} from "../../components";
import SyahadahTypes from "./SyahadahTypes";

let thisUri = `/syahadah`;
const apiUri = `/syahadahs`;

const SyahadahFormPage = () => {
  const history = useHistory();
  const {id} = useParams(); // Jika id !== undefined, maka ini adalah fitur edit data

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    watch,
    getValues,
    trigger: triggerValidation,
    clearErrors: clearError
  } = useForm();

  const [syahadah, setSyahadah] = useState({});
  const [submitLoading, setSubmitLoading] = useState(false);

  /** The form fields **/
  const formFields = [
    {
      name: 'type_syahadah',
      rules: {required: true},
      type: 'custom',
      component: <SyahadahTypes
        syahadah={syahadah}
        watcher={watch}
        setValue={(key, val)=> {
          setValue(key, val);
        }}
        errors={errors}
        clearError={clearError}
        getValues={getValues}
      />
    },
    {
      name: 'class',
      type: 'custom',
      component: null
    },
    {
      name: 'course',
      type: 'custom',
      component: null
    },
    {
      name: 'course_part',
      type: 'custom',
      component: null
    },
    {
      name: 'cert_name',
      rules: {required: true},
      label: 'Judul Sertifikat'
    },
    {
      name: 'cert_period',
      rules: {required: true},
      label: 'Periode Sertifikat'
    },
    {
      name: 'cert_number_format',
      rules: {required: true},
      label: 'Format Penomoran Sertifikat misal: TSL2020-{{number}}'
    },
    {
      name: 'signee_name',
      rules: {required: true},
      label: 'Nama Lengkap yg Menandatangani'
    },
    {
      name: 'signee_position',
      rules: {required: true},
      label: 'Jabatannya (misal: Direktur Learning)'
    },
    {
      name: 'sign_month_year',
      rules: {required: true},
      label: 'Ditandatangani pada Bulan dan Tahun'
    },
  ];

  // const syahadahTypeFields = [];

  /** Register the form fields **/
  useEffect(() => {
    for (let formField of formFields) {
      register({name: formField.name}, formField.rules || null);
    }

    // for (let field of syahadahTypeFields) {
    //   register({name: field.name}, field.rules || null);
    // }
  }, [register]);

  /** Get Syahadah Data **/
  useEffect(() => {
    if (id) {
      const getDataById = async () => {
        try {
          const {
            data: {data}
          } = await BaseRequest.get(`${apiUri}/${id}/edit`);

          return data;
        } catch (error) {
          console.log(error);
        }
      };

      getDataById().then(data => {
        setSyahadah(data);
        for (let key in data) {
          setValue(key, data[key] || "");
        }
      });
    }
  }, [id, setValue]);

  const onSubmit = async payload => {
    try {
      let res;
      let selectedId;
      switch (payload.type_syahadah) {
        case 'class':
          if (!payload.class) {
            alert("Silahkan pilih Program terlebih dahulu");
            return;
          }
          selectedId = payload.class.value;
          payload.class_id = selectedId;
          payload.course_id = id? null: undefined;
          payload.course_part_id = id? null: undefined;
          break;
        case 'course':
          if (!payload.course) {
            alert("Silahkan pilih Mata Kuliah terlebih dahulu");
            return;
          }
          selectedId = payload.course.value;
          payload.class_id = id? null: undefined;
          payload.course_id = selectedId;
          payload.course_part_id = id? null: undefined;
          break;
        case 'course_part':
          if (!payload.course_part) {
            alert("Silahkan pilih Pertemuan terlebih dahulu");
            return;
          }
          selectedId = payload.course_part.value;
          payload.class_id = id? null: undefined;
          payload.course_id = id? null: undefined;
          payload.course_part_id = selectedId;
          break;
        default:
          break;
      }
      delete payload["class"];
      delete payload["course"];
      delete payload["course_part"];
      delete payload.type_syahadah;

      setSubmitLoading(true);
      if (id) {
        res = await BaseRequest.put(`${apiUri}/${id}`, payload);
      } else {
        res = await BaseRequest.post(`${apiUri}/save`, payload);
      }

      toast.success(res.data.message, {
        closeButton: false,
        position: "bottom-right"
      });

      setTimeout(() => history.push(thisUri), 2000);
    } catch (error) {
      let errorMessage = error.response
        ? error.response.data.message
        : error.message;
      toast.error(errorMessage, {
        closeButton: false,
        position: "bottom-right"
      });

      setSubmitLoading(false);
    }
  };

  /** Define form fields **/
  const getFormFields = () => {
    return formFields.map((field) => ({...field, error: errors[field.name]}));
  };

  /** Input text change handler **/
  const handleInputTextChange = (e) => {
    const {name, value} = e.target;
    setValue(name, value);
  };

  /** Radio Input change handler **/
  const handleSelectChange = (field, value) => {
    if (field && value) {
      setValue(field, value);
    }
  };

  return (
    <MDBContainer>
      <MDBRow center>
        <MDBCol size="12" md="12" sm="12">
          <h3 className="text-center pt-4 pb-5 mb-2">
            {id ? "Edit" : "Tambah"} E-Syahadah Job
          </h3>

          <Form
            onSubmit={handleSubmit(onSubmit)}
            fields={getFormFields()}
            watcher={watch}
            onInputTextChange={handleInputTextChange}
            onSelectChange={handleSelectChange}
            submitLoading={submitLoading}
            values={getValues()}
            backUri={thisUri}
            triggerValidation={triggerValidation}
          />
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default SyahadahFormPage;
