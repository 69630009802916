import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import BaseRequest from "../../../services/BaseRequest";
import {
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBRow,
  MDBCol,
  MDBBtn,
} from "mdbreact";
import ProfileItem from './components/ProfileItem';

const ParticipantDetailPage = () => {
  const { id } = useParams(); // Jika id !== undefined, maka ini adalah fitur edit data

  const [profile, setProfile] = useState({});

  useEffect(() => {
    if (id) {
      const getDataById = async () => {
        try {
          const {
            data: { data }
          } = await BaseRequest.get(`/participants/${id}/edit`);

          setProfile(data);
        } catch (error) {
          console.log(error);
        }
      };
      getDataById();
    }
  }, [id]);

  return (
    <MDBContainer>
      <MDBRow center>
        <MDBCol size="12" md="9" sm="12">
          <MDBCard>
            <MDBCardBody>
            <MDBCardTitle>
              <div className="d-flex justify-content-between">
                {profile !== {} ? profile.name : 'Participant'}
                <MDBBtn onClick={()=>{window.history.back()}} size="md" color="red" className="pull-right">
                  Kembali
                </MDBBtn>
              </div>
            </MDBCardTitle>
              <hr />
              <form>
                <MDBContainer>
                  <MDBRow>
                    <MDBCol>
                      { profile !== {} &&
                      <>
                        <ProfileItem label='NIP' value={profile.nip}/>
                        <ProfileItem label='Name' value={profile.name}/>
                        <ProfileItem label='Email' value={profile.email}/>
                        <ProfileItem label='Birth Date' value={profile.birth_date}/>
                        <ProfileItem label='Birth Place' value={profile.birth_place}/>
                        <ProfileItem label='Gender' value={profile.gender}/>
                        <ProfileItem label='Phone' value={profile.phone}/>
                        { profile.country &&
                          <ProfileItem label='Phone Code' value={profile.country.phone_code}/>
                        }
                        <ProfileItem label='Marital Status' value={profile.marital_status}/>
                        <ProfileItem label='Study Program' value={profile.study_program}/>
                        <ProfileItem label='Alamat' value={profile.address}/>
                        <ProfileItem label='Kota' value={profile.city?.name}/>
                        { profile.country &&
                          <ProfileItem label='Country' value={profile.country.name}/>
                        }
                        <ProfileItem label='Status' value={profile.status}/>
                        <ProfileItem label='WA Terverifikasi' value={profile.is_verification_wa? "Sudah": "Belum"}/>
                        <ProfileItem label='Tanggal Terdaftar' value={profile.created_at}/>
                        <ProfileItem label='Login Terakhir' value={profile.last_login_at}/>
                      </>
                      }
                    </MDBCol>
                  </MDBRow>
                </MDBContainer>
              </form>

            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}

export default ParticipantDetailPage;
