import React, {useEffect, useState} from "react";
import {
  MDBBtn, MDBIcon,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
  MDBSpinner,
  toast
} from "mdbreact";
import { useForm } from "react-hook-form";
import BaseRequest from "../../../../services/BaseRequest";
import InputError from "../../../components/InputError";
import { useHistory } from "react-router-dom";

const formField = [
  { name: "name", rules: { required: true } }
];

const KategoriSoalAddFormModal = (props) => {
  const { thisUri, apiUri, toggle, isOpen } = props;

  const title = "Tambah Kategori Soal";

  const history = useHistory();
  const [submitLoading, setSubmitLoading] = useState(false);

  // Setup the form
  const { register, handleSubmit, errors, setValue, watch } = useForm();

  // Register form fields
  useEffect(() => {
    for (let v of formField) {
      register({ name: v.name }, v.rules || null);
    }
  }, [ register ]);

  // Handle form field changes
  const handleChange = e => {
    setValue(e.target.name, e.target.value);
  };

  // Submit the form
  const onSubmit = async (payload) => {
    setSubmitLoading(true);

    try {
      let res = await BaseRequest.post(`${apiUri}/save`, payload);

      toast.success(res.data.message, {
        closeButton: false,
        position: "bottom-right"
      });

      setTimeout(() => history.push(`${thisUri}/${res.data.data.id}/edit`), 2000);
    } catch (error) {
      let errorMessage = error.response
        ? error.response.data.message
        : error.message;
      toast.error(errorMessage, {
        closeButton: false,
        position: "bottom-right"
      });

      setSubmitLoading(false);
    }
  };

  return (
    <MDBModal isOpen={isOpen} toggle={toggle} backdrop={false} cascading>
      <form onSubmit={handleSubmit(onSubmit)}>
        <MDBModalHeader
          toggle={toggle}
          titleClass="d-inline title"
          className="text-center green darken-3 white-text"
        >
          <MDBIcon icon="plus" className={"pr-2"}/>
          {title}
        </MDBModalHeader>
        <MDBModalBody>
          <MDBInput
            label="Nama Kategori"
            className={errors.name && "is-invalid"}
            name="name"
            type="text"
            value={watch("name")}
            onChange={handleChange}
          >
            {
              errors.name &&
              errors.name.type === "required" && (
                <InputError text="Tidak boleh kosong" />
              )
            }
          </MDBInput>
        </MDBModalBody>
        <MDBModalFooter>
          <div className={"text-center"}>
            {
              submitLoading ?
                <MDBSpinner/> :
                <>
                  <MDBBtn type={"button"} size={"md"} color={"red"} onClick={toggle}>Kembali</MDBBtn>
                  <MDBBtn type={"submit"} size={"md"} color={"green"}>Simpan & Kelola Soal</MDBBtn>
                </>
            }
          </div>
        </MDBModalFooter>
      </form>
    </MDBModal>
  );
};

export default KategoriSoalAddFormModal;
