import React, {useCallback, useEffect, useMemo, useState, useContext} from "react";
import moment from "moment";
import BaseRequest from "../../../../services/BaseRequest";
import {useParams} from "react-router-dom";
import {MDBBtn, MDBContainer, MDBIcon,toast} from "mdbreact";
import {default as CustomDataTable} from "../../../components/DataTable";
import { Link } from 'react-router-dom';
import { AccessContext } from "../../../AccessContext";

const thisUri = "/kurikulum/isian-rencana-studi";
const apiUri = "/user-plan-courses";

const IsianRencanaStudiFormPage = () => {

  const { id } = useParams();
  const access = useContext(AccessContext);
  const menu = 'irs';

  /** Datatable variables **/
  const [ dtRowsData, setDtRowsData ] = useState([]);
  const [ dtSortBy, setDtSortBy ] = useState('created_at');
  const [ dtSortDirection, setDtSortDirection ] = useState('desc');
  const [ dtTotalRow, setDtTotalRow ] = useState(1);
  const [ dtPage, setDtPage ] = useState(1);
  const [ dtPerPage, setDtPerPage ] = useState(10);
  const [ loadProgressData, setLoadProgressData ] = useState(true);

  /** Datatable column definition **/
  const columns = [
    {
        name: 'No',
        selector: 'rowNo',
        width: "4rem",
        right: true
    },
    {
        name: "Nama Mata Kuliah",
        selector: "course_title_id",
        sortable: true,
        style: {
          whiteSpace: 'none',
        },
        width: "20rem"
    },
    {
        name: "Kredit",
        selector: "credit",
        sortable: true,
        style: {
            whiteSpace: 'none',
        },
        width: "10rem"
    },
    {
        name: "Disetujui",
        selector: "approved_at",
        sortable: true,
        width: "8rem"
    },
    {
        name: "Ditolak",
        selector: "rejected_at",
        sortable: true,
        width: "8rem"
    },
    {
      name: "Dibuat Tanggal",
      selector: "created_at",
      sortable: true,
      width: "8rem"
    },
    {
      name: "Disunting Tanggal",
      selector: "updated_at",
      sortable: true,
      width: "8rem"
    },
    {
        cell: (row) =>
        (row.approved_at === '-' && row.rejected_at === '-')?
          <div className="btn-group-sm">
            <MDBBtn variant="contained" color="green" onClick={() => toggleApproval(row.id, 'approve')} >
              <MDBIcon icon="check" />
            </MDBBtn>
            <MDBBtn variant="contained" color="red" onClick={() => toggleApproval(row.id, 'reject')} >
              <MDBIcon icon="times" />
            </MDBBtn>
          </div>
          :
          (row.approved_at !== '-' ? 
            <div className="btn-group-sm">
              Disetujui
              { access.isAccessible(menu, 'reset') &&
                <MDBBtn variant="contained" color="blue" onClick={() => toggleReset(row.id)} >
                  <MDBIcon icon="unlock"> Reset</MDBIcon>
                </MDBBtn>
              }
            </div>
            : 
              <div className="btn-group-sm">
              Ditolak
              { access.isAccessible(menu, 'reset') &&
                <MDBBtn variant="contained" color="blue" onClick={() => toggleReset(row.id)} >
                  <MDBIcon icon="unlock"> Reset</MDBIcon>
                </MDBBtn>
              }
              </div>
            ),
        button: true
      }
  ];

  const toggleApproval = (id, status) => { 
    if(status === 'approve') {
        handleApprovalProcess(id);
    }
    else if(status === 'reject') {
        handleRejectionProcess(id);
    }
  }

  const toggleReset = async (rowId) => {
    try{
      setLoadProgressData(true);
      await BaseRequest.put(`${apiUri}/${rowId}/reset`);
      reloadDataFromServer();
    } catch(err) {
      console.log(err);
    } finally {
      setLoadProgressData(false);
    }
  }

  const handleApprovalProcess = async (rowId) => {
    try {
        setLoadProgressData(true);
      await BaseRequest.put(`${apiUri}/${rowId}/approve`);
      reloadDataFromServer();
    } catch (err) {
      console.error(err);
    } finally {
        setLoadProgressData(false);
    }
  };

  const handleRejectionProcess = async (rowId) => {
    try {
        setLoadProgressData(true);
      await BaseRequest.put(`${apiUri}/${rowId}/reject`);
      reloadDataFromServer();
    } catch (err) {
      console.error(err);
    } finally {
        setLoadProgressData(false);
    }
  };

  /** Datatable get data **/
  const getDataIsianRencanaStudi = useCallback(async (page = 1, perPage = 10) => {
    let uri = `${apiUri}`;
    try {
      setLoadProgressData(true);

      const {
        data: { data }
      } = await BaseRequest.post(uri, {
        sortBy: dtSortBy,
        desc: dtSortDirection === 'desc',
        page: page,
        limit: perPage,
        field_search: 'user_plan_study_id',
        search: id,
      });

      setDtTotalRow(data.total);

      // Map the questions data
      const mappedData = data.data.map((v, i) => ({
        rowNo: data.from + i,
        id: v.id,
        course_title_id: v.course.title_id,
        credit: v.credit,
        approved_at: (v.approved_at)?moment(v.approved_at).format("DD/MM/YYYY"):'-',
        rejected_at: (v.rejected_at)?moment(v.rejected_at).format("DD/MM/YYYY"):'-',
        created_at: moment(v.created_at).format("DD/MM/YYYY"),
        updated_at: moment(v.updated_at).format("DD/MM/YYYY"),
      }));

      return { rows: mappedData }
    } catch (error) {
      let errorMessage = error.response
        ? error.response.data.message
        : error.message;
      toast.error(errorMessage, {
        closeButton: false,
        position: "bottom-right"
      });
    }

    return { rows: [] }
  }, [dtSortBy, dtSortDirection]);

  const reloadDataFromServer = useCallback((page = 1, perPage = 10) => {
    getDataIsianRencanaStudi(page, perPage)
      .then((res) => {
        const { rows } = res;

        setDtRowsData(rows);
      })
      .finally(() => {
        setLoadProgressData(false);
      });
  }, [getDataIsianRencanaStudi]);

  useEffect(() => {
    reloadDataFromServer(dtPage, dtPerPage);
  }, [reloadDataFromServer, dtPage, dtPerPage]);

  /** Datatable sorting **/
  const onSortHandler = (column, sortDirection) => {
    setDtSortBy(column.selector);
    setDtSortDirection(sortDirection);

    reloadDataFromServer(dtPage, dtPerPage);
  };

  /** Datatable Change Page handler **/
  const onPageChangeHandler = (page) => {
    setDtPage(page)
  }

  const onPerRowsChangeHandler = (perPage) => {
    setDtPerPage(perPage)
  }

  return (
    <MDBContainer fluid>
      <h3>List Details Isian Rencana Studi</h3>

      <Link to={thisUri}>
        <MDBBtn size="md" color="red" className="mx-0">
          Kembali
        </MDBBtn>
      </Link>

      <CustomDataTable
        columns={columns}
        data={dtRowsData}
        totalRow={dtTotalRow}
        onSortHandler={onSortHandler}
        progressPending={loadProgressData}
        handlePerRowsChange={onPerRowsChangeHandler}
        handlePageChange={onPageChangeHandler}
      />

    </MDBContainer>
  );
};

export default IsianRencanaStudiFormPage;
