import React, {useEffect, useState} from "react";
import {MDBCol, MDBContainer, MDBRow, toast} from "mdbreact";
import {useHistory, useParams} from "react-router-dom";
import {Form} from "../../../../components";
import { useForm } from "react-hook-form";
import BaseRequest from "../../../../../services/BaseRequest";

const quizCategory = require("../../shared/json/quiz-category.json");
const thisUri = "/kurikulum/ujian-evaluasi/pengaturan";
const apiUri = "/quiz-configurations";

const PengaturanFormPage = () => {
  const history = useHistory();
  const { id } = useParams(); // Jika id !== undefined, maka ini adalah fitur edit data

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    watch,
    getValues,
    trigger: triggerValidation
  } = useForm();

  const [submitLoading, setSubmitLoading] = useState(false);

  const formField = [
    {
      name: 'type',
      label: 'Type Soal',
      rules: { required: true },
      type: 'select',
      options: quizCategory,
    },
    {
      name: 'number_of_questions',
      rules: { required: true },
      label: 'Jumlah Pertanyaan',
      type: 'number'
    },
  ];

  /** Register the form fields **/
  useEffect(() => {
    for (let field of formField) {
      register({ name: field.name }, field.rules || null);
    }
  }, [register, formField]);

  /** Define form fields **/
  const getFormFields = () => {
    return formField.map((field) => ({ ...field, error: errors[field.name] }));
  };

  /** Input text change handler **/
  const handleInputTextChange = (e) => {
    const { name, value } = e.target;
    setValue(name, value);
  };

  /** Select Input change handler **/
  const handleSelectChange = (field, value) => {
    if (field && value) {
      setValue(field, value);
    }
  };

  /** Get Pengaturan Kuis data **/
  useEffect(() => {
    if (id) {
      const getDataById = async () => {
        try {
          const {
            data: { data }
          } = await BaseRequest.get(`${apiUri}/${id}/edit`);

          return data;
        } catch (error) {
          console.log(error);
        }
      };

      getDataById().then(data => {
        for (let key in data) {
          setValue(key, data[key] || "");
        }
      });
    }
  }, [id, setValue]);

  const onSubmit = async payload => {
    try {
      setSubmitLoading(true);
      let res;

      if (id) {
        res = await BaseRequest.put(`${apiUri}/${id}`, payload);
      } else {
        res = await BaseRequest.post(`${apiUri}/save`, payload);
      }

      toast.success(res.data.message, {
        closeButton: false,
        position: "bottom-right"
      });

      setTimeout(() => history.push(thisUri), 2000);
    } catch (error) {
      let errorMessage = error.response
        ? error.response.data.message
        : error.message;

      toast.error(errorMessage, {
        closeButton: false,
        position: "bottom-right"
      });

      setSubmitLoading(false);
    }
  };

  return (
    <MDBContainer fluid>
      <h3 className="text-center pt-4 pb-5 mb-2">
        {id ? "Edit" : "Tambah"} Pengaturan Ujian
      </h3>

      <MDBRow center>
        <MDBCol size="8">
          <Form
            onSubmit={handleSubmit(onSubmit)}
            fields={getFormFields()}
            watcher={watch}
            onInputTextChange={handleInputTextChange}
            onSelectChange={handleSelectChange}
            submitLoading={submitLoading}
            values={getValues()}
            backUri={thisUri}
            triggerValidation={triggerValidation}
          />
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}

export default PengaturanFormPage;
