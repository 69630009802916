import React from 'react';
import {
  MDBInput,
} from "mdbreact";

const ProfileItem = ({value, label}) => {
  return (
    <MDBInput
      label={label} 
      value={value !== null ? value : ' - '}
      type="text" 
      iconClass="black-text" 
      disabled/>
  )
}

export default ProfileItem;