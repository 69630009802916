import SecureLS from "secure-ls"

export const getToken = () => {
  var ls = new SecureLS({encodingType: 'aes'});
  var data = ls.get('tsl')
  var token = data.token
  var expiration = data.expiration
  
  if (!token || !expiration) {
    return null;
  }

  if (Date.now() > parseInt(expiration)) {
    invalidateUser();
    return null;
  } else {
    return token;
  }
};

export const getRole = () => {
  var ls = new SecureLS({encodingType: 'aes'});
  var data = ls.get('tsl');
  var role = data.role;
  if(role){
    try{
      return role
    }catch(err){
    }
  }
}

export const invalidateUser = () => {
  var ls = new SecureLS({encodingType: 'aes'});
  ls.removeAll();
};

export const isAuthenticated = () => {
  if (getToken()) {
    return true;
  } else {
    return false;
  }
};
