import React, {useEffect, useMemo, useState} from "react";
import { useForm } from "react-hook-form";
import {
  MDBBtn,
  MDBCol,
  MDBInput,
  MDBModal,
  MDBSelect,
  MDBModalBody,
  MDBModalHeader,
  MDBRow,
  MDBSpinner,
  toast
} from "mdbreact";
import BaseRequest from "../../../../services/BaseRequest";
import InputError from "../../../components/InputError";

const apiUri = '/course-scores';
const formField = [
      {
          name: "code",
          rules: 
          {
              required: true
          }
      },
      {
          name: "value",
          rules:
          {
              required: true
          }
      }
];
const RenderButton = ({ submitLoading }) => {
    if (submitLoading) {
      return <MDBSpinner />;
    }
    return (
      <div>
        <MDBBtn type="submit" color="green" size="md">
          Simpan
        </MDBBtn>
      </div>
    );
  };

const SubTipeUjianModal = (props) => {
  const {
    isOpen,
    toggle,
    selectedId,
    selectedScoreTypeId
  } = props;

  const { register, handleSubmit, errors, setValue, watch } = useForm();
  const [submitLoading, setSubmitLoading] = useState(false);
  let [listQuizType, setListQuizType] = useState([{text:'',value:''}]);
  let code = watch('code');

  const handleChange = e => {
    setValue(e.target.name, e.target.value);
  };

  const handleCourseChange = id => {
      console.log(id);
    setValue('code', id[0]);
  }

  const getQuizTypes = async () => {
    const uri = 'quiz-configurations?sortBy=type';
    const { data: {data}} = await BaseRequest.post(uri);
    return data.data.map((v,i) =>({
        text: v.type,
        value: v.type
    }));
  }

  useEffect(() => {
    for (let v of formField) {
      register({ name: v.name }, v.rules || null);
    }
  }, [register]);

  useEffect(() => {
    if (selectedId) {
      const getDataById = async () => {
        try {
          const {
            data: { data }
          } = await BaseRequest.get(`${apiUri}/${selectedId}/edit`);

          for (let key in data) {
            setValue(key, data[key] || "");
          }
        } catch (error) {
          console.log(error);
        }
      };
      getDataById();
    }
  }, [selectedId, setValue]);

  useEffect(() => {
      if(code){
          getQuizTypes()
          .then(res => {
            setListQuizType(()=>(
              res.map((v, i) => ({
                ...v,
                checked: (code === v.value)? true :false
              }))
            ))
          })
      } 
      else{
        getQuizTypes().then(data => {
            setListQuizType(data);
        });
    }
  },[code]);

  const onSubmit = async payload => {
    
    payload.score_type_id = selectedScoreTypeId;

    try {
      setSubmitLoading(true);
      let res = null;
      
      if (selectedId) {
        res = await BaseRequest.put(`${apiUri}/${selectedId}`, payload);
        
      } else {
        res = await BaseRequest.post(`${apiUri}/save`, payload);
      }
      toast.success(res.data.message, {
        closeButton: false,
        position: "bottom-right"
      });

      setSubmitLoading(false);
      toggle();

    } catch (error) {
      let errorMessage = error.response
        ? error.response.data.message
        : error.message;
      toast.error(errorMessage, {
        closeButton: false,
        position: "bottom-right"
      });
      setSubmitLoading(false);
      toggle();
    }
  };
  

  return (
    <MDBModal size="lg" isOpen={isOpen} toggle={toggle} backdrop={false}>
      <MDBModalHeader toggle={toggle}>Input Tipe Ujian</MDBModalHeader>
      <MDBModalBody>
              <form onSubmit={handleSubmit(onSubmit)}>
                  <MDBRow>
                    <MDBCol size="12">
                      {/* <MDBInput
                        className={errors.code && "is-invalid"}
                        name="code"
                        onChange={handleChange}
                        type="text"
                        label="Tipe UJIAN"
                        value={watch("code")}
                      >
                        {errors.code &&
                          errors.code.type === "required" && (
                            <InputError text="Tidak boleh kosong" />
                          )}
                      </MDBInput> */}
                      <MDBSelect
                        className={errors.code && "is-invalid"}
                        name="code"
                        getValue={handleCourseChange}
                        options={listQuizType}
                        selected="Pilihan Tipe Ujian"
                        label="Tipe Ujian"
                      />
                      {errors.code &&
                        errors.code.type === "required" && (
                          <span className="text-danger small">Tidak boleh kosong</span>
                        )}

                      <MDBInput
                        name="value"
                        onChange={handleChange}
                        type="text"
                        label="Bobot Ujian(%)"
                        value={watch("value")}
                      />             
                    </MDBCol>
                  </MDBRow>
                <div className="text-center mt-3 black-text">
                  <RenderButton submitLoading={submitLoading} />
                </div>
              </form>
            </MDBModalBody>
          </MDBModal>
  );
};
export default SubTipeUjianModal;
