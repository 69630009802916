import React, {useCallback, useEffect, useMemo, useState, useContext} from "react";
import moment from "moment";
import BaseRequest from "../../../../services/BaseRequest";
import {useHistory} from "react-router-dom";
import {MDBBtn, MDBBtnFixed, MDBCol, MDBContainer, MDBIcon, MDBInput, MDBRow, MDBSelect, toast} from "mdbreact";
import {default as CustomDataTable} from "../../../components/DataTable";
import DeleteModal from "../../../modals/DeleteModal";
import { AccessContext } from '../../../AccessContext';

const thisUri = "/perkuliahan/mata-kuliah";
const apiUri = "/courses";

const MataKuliahPage = () => {
  const history = useHistory();
  const menu = 'mata-kuliah';
  const access = useContext(AccessContext);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedDeleteItem, setSelectedDeleteItem] = useState({});
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState([]);

  /** Datatable variables **/
  const [ dtRowsData, setDtRowsData ] = useState([]);
  const [ dtSortBy, setDtSortBy ] = useState('created_at');
  const [ dtSortDirection, setDtSortDirection ] = useState('desc');
  const [ dtTotalRow, setDtTotalRow ] = useState(1);
  const [ dtPage, setDtPage ] = useState(1);
  const [ dtPerPage, setDtPerPage ] = useState(10);
  const [ dtSearchMeta, setDtSearchMeta ] = useState({
    filter: {},
    field_search: 'title_id',
    search: ''
  });
  const [ loadProgressData, setLoadProgressData ] = useState(true);

  /** Datatable column definition **/
  const columns = [
    {
      name: 'No',
      selector: 'rowNo',
      width: "4rem",
      right: true
    },
    {
      cell: (row) => row.category
        ? (access.isAccessible('program', 'edit')? (<a href={`/perkuliahan/kategori/${row.category.id}/edit`} target="_blank" rel="noopener noreferrer">{row.category.title_id}</a>) : row.category.title_id)
        : '-',
      name: "Kategori",
      selector: "category",
      sortable: true,
      style: {
        whiteSpace: 'none',
      },
      width: "10rem"
    },
    {
      name: "Judul (ID)",
      selector: "title_id",
      sortable: true,
      style: {
        whiteSpace: 'none',
      }
    },
    {
      cell: (row) => row.asatidzs.length > 0
        ? <ul className="list-on-datatable">
          {
            row.asatidzs.map((asatidz, index) =>
              <li key={index}>
                {access.isAccessible('asatidz', 'edit')? <a href={`/kurikulum/asatidz/${asatidz.id}/edit`} target="_blank" rel="noopener noreferrer">{asatidz.name}</a> : asatidz.name }
              </li>
            )
          }
        </ul>
        : '-',
      name: "Pengajar",
      selector: "asatidzs",
      sortable: true,
      width: "16rem"
    },
    {
      cell: (row) => row.class
        ? (access.isAccessible('kelas', 'edit')? <a href={`/kurikulum/silabus/${row.class.id}/edit`} target="_blank" rel="noopener noreferrer">{row.class.name}</a> : row.class.name)
        : '-',
      name: "Kelas",
      selector: "class",
      width: "10rem"
    },
    {
      name: "Dibuat Tanggal",
      selector: "created_at",
      sortable: true,
      width: "8rem"
    },
    {
      name: "Disunting Tanggal",
      selector: "updated_at",
      sortable: true,
      width: "8rem"
    },
    {
      cell: (row) =>
        <div className="btn-group-sm">
          { access.isAccessible(menu, 'show') &&
            <MDBBtn variant="contained" color="blue" onClick={() => history.push(`${thisUri}/${row.id}/show`)} >
              <MDBIcon icon="info-circle" />
            </MDBBtn>
          }
          { access.isAccessible(menu, 'edit') &&
            <MDBBtn variant="contained" color="yellow" onClick={() => history.push(`${thisUri}/${row.id}/edit`)} >
              <MDBIcon icon="pen-nib" />
            </MDBBtn>
          }
          { access.isAccessible(menu, 'delete') &&
            <MDBBtn variant="contained" color="red" onClick={() => toggleDeleteModal(row.id)}>
              <MDBIcon icon="trash" />
            </MDBBtn>
          }
        </div>,
      button: true,
      width: "8rem"
    }
  ];

  /** Get category options for the filter **/
  useEffect(() => {
    const getCategories = async () => {
      const { data: { data }} = await BaseRequest.post('/course-categories', {
        page: 1,
        limit: -1,
        sortBy: "title_id",
        desc: false
      });

      return data.data.map((category) => {
        return {
          "text": category.title_id,
          "value": category.id
        }
      });
    }

    getCategories()
      .then((data) => {
        setCategoryOptions([{
          "text": 'Pilih Kategori',
          "value": '0',
          "checked": true
        }, ...data]);
      })
  }, []);

  /** Datatable get data **/
  const getDataMataKuliah = useCallback(async (page = 1, perPage = 10) => {
    let uri = `${apiUri}?with=courseCategory,coursePart,asatidzs,classes`;

    try {
      setLoadProgressData(true);

      const {
        data: { data }
      } = await BaseRequest.post(uri, {
        sortBy: dtSortBy,
        desc: dtSortDirection === 'desc',
        page: page,
        limit: perPage,
        ...dtSearchMeta
      });

      setDtTotalRow(data.total);

      // Map the questions data
      const mappedData = data.data.map((v, i) => ({
        rowNo: data.from + i,
        id: v.id,
        title_id: v.title_id,
        silabus: v.course_part.length > 1 ? v.course_part[0] : null,
        type: v.type,
        source: v.source,
        asatidzs: v.asatidzs,
        category: v.course_category,
        class: v.classes,
        created_at: moment(v.created_at).format("DD/MM/YYYY"),
        updated_at: moment(v.updated_at).format("DD/MM/YYYY"),
      }));

      return { rows: mappedData }
    } catch (error) {
      let errorMessage = error.response
        ? error.response.data.message
        : error.message;
      toast.error(errorMessage, {
        closeButton: false,
        position: "bottom-right"
      });
    }

    return { rows: [] }
  }, [dtSearchMeta, dtSortBy, dtSortDirection]);

  const reloadDataFromServer = useCallback((page = 1, perPage = 10) => {
    getDataMataKuliah(page, perPage)
      .then((res) => {
        const { rows } = res;

        setDtRowsData(rows);
      })
      .finally(() => {
        setLoadProgressData(false);
      });
  }, [getDataMataKuliah]);

  useEffect(() => {
    // reloadDataFromServer(dtPage, dtPerPage);
  }, [reloadDataFromServer, dtPage, dtPerPage]);

  /** Datatable sorting **/
  const onSortHandler = (column, sortDirection) => {
    setDtSortBy(column.selector);
    setDtSortDirection(sortDirection);

    reloadDataFromServer(dtPage, dtPerPage);
  };

  /** Datatable Filter **/
  const subHeaderComponentMemo = useMemo(() => {
    const handleFilter = (keyword, comesFromSelect = false, filterName = null) => {
      setLoadProgressData(true);

      let newSearchMeta = dtSearchMeta;

      if (comesFromSelect) {
        let selectedValue = keyword;

        if (selectedValue === '') {
          delete newSearchMeta.filter[filterName];
        } else {
          newSearchMeta.filter[filterName] = selectedValue;
        }
      } else {
        newSearchMeta.search = keyword;
      }

      setDtSearchMeta(newSearchMeta);

      getDataMataKuliah(newSearchMeta)
        .then((res) => {
          const { rows } = res;

          setDtRowsData(rows);
          setLoadProgressData(false);
        })
    };

    return (
      <div className="w-100 data-table__filter-input">
        <MDBRow className={"w-100"}>
          <MDBCol size={"12"} md={"2"} sm={"12"}>
            <MDBSelect
              search
              label="Kategori"
              getValue={(val) => handleFilter(val[0], true, "course_category_id")}
              className={"w-100"}
              name="type"
              options={categoryOptions}
            />
          </MDBCol>
          <MDBCol size={"12"} md={"2"} sm={"12"}>
            <MDBInput
              label="Cari Judul (ID)"
              onChange={(e) => handleFilter(e.target.value)}
              className="search-input"
              name="search"
            />
          </MDBCol>
        </MDBRow>
      </div>
    )
  }, [dtSearchMeta, getDataMataKuliah, categoryOptions]);

  const toggleDeleteModal = (id) => {
    setSelectedDeleteItem(id);
    setShowDeleteModal(!showDeleteModal);
  }

  const handleDelete = async () => {
    try {
      setDeleteLoading(true);

      await BaseRequest.delete(`${apiUri}/${selectedDeleteItem}`);
      reloadDataFromServer(dtPage, dtPerPage);
    } catch (err) {
      console.error(err);
    } finally {
      toggleDeleteModal();
      setDeleteLoading(false);
    }
  };

  /** Datatable Change Page handler **/
  const onPageChangeHandler = (page) => {
    setDtPage(page)
  }

  const onPerRowsChangeHandler = (perPage) => {
    setDtPerPage(perPage)
  }

  return (
    <MDBContainer fluid>
      <h3>Mata Kuliah</h3>

      <CustomDataTable
        columns={columns}
        data={dtRowsData}
        totalRow={dtTotalRow}
        onSortHandler={onSortHandler}
        subHeaderComponent={subHeaderComponentMemo}
        progressPending={loadProgressData}
        handlePerRowsChange={onPerRowsChangeHandler}
        handlePageChange={onPageChangeHandler}
      />

      <DeleteModal
        isOpen={showDeleteModal}
        toggle={toggleDeleteModal}
        handler={handleDelete}
        loadingIndicator={deleteLoading}
      />

      { access.isAccessible(menu, 'create') &&
        <>
        <MDBBtnFixed
          floating
          color="blue"
          icon="plus"
          size="lg"
          style={{bottom: "55px", right: "24px"}}
          onClick={() => history.push(`${thisUri}/create`)}
          className="no-li"
        />
        <MDBBtnFixed
          floating
          color="orange"
          icon="paste"
          size="lg"
          style={{bottom: "55px", right: "94px"}}
          onClick={() => history.push(`${thisUri}/paste`)}
          className="no-li"
        />
        </>
      }

    </MDBContainer>
  );
};

export default MataKuliahPage;
