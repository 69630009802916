import React, { useState } from "react";
import BaseRequest from "../../../../services/BaseRequest";
import { useHistory } from "react-router-dom";
import { 
    MDBBtn, 
    MDBCol, 
    MDBContainer, 
    MDBBtnFixed, 
    MDBInput, 
    MDBRow,
    MDBTable,
    MDBTableBody,
    MDBTableHead, 
    MDBIcon,
} from "mdbreact";
import TranscriptSpecialFormPage from "./TranscriptSpecialFormPage";
const thisUri = "/kurikulum/transkrip-khusus";

const TranscriptSpecialList = () => {
    const history = useHistory();
    /** State */
    const [ selectedYear, setSelectedYear ] = useState();
    const [ participant, setParticipant] = useState();
    const [ selectedSpCourse, setSelectedSpCourse ] = useState();
    const [ dtRowsData, setDtRowsData ] = useState([]);
    const [ showFormPage, setShowFormPage] = useState(false);
    const [ payloadForm, setPayloadForm] = useState();
    const handleInputChange =(e) => {
        if(e.target) {
            const { value,name } = e.target;
            switch (name) {
                case "irs_year":
                    setSelectedYear(value);
                    break;
                case "participant":
                    setParticipant(value);
                    break;
                default:
                    break;
            }
        } else if(Array.isArray(e) && e.length > 0) {
            setSelectedSpCourse(e[0]);
        }
    };

    const handleTranscriptSearch = () => {
        const getParticipant = async() => {
            try {
                const {
                    data: { data }
                } = await BaseRequest.post('/users', {
                    filter: {
                        nip: participant
                    }
                });
                return data;
            } catch (error) {
                console.log(error)
            }
        };
        const getDataById = async (userId) => {
            try {
            //   setLoading(true);
                const {
                    data: { data }
                } = await BaseRequest.post(`/transcripts`, {
                    user_id: userId,
                    transcript_type: 1,
                });
        
                return data;
            } catch (error) {
              console.log(error);
            } finally {
            //   setLoading(false);
            }
        };
        
        getParticipant().then((user)=> {
            const uId = user.data[0].id;
            getDataById(uId).then((dt)=> {
                setDtRowsData(dt.course);
            });
        });
        
    };

    const toggleEditModal = (props) => {
        setShowFormPage(!showFormPage);
        setPayloadForm(props);
    };

    const toggleFormPage = () => {
        setShowFormPage(!showFormPage);
    };

  return (
    <MDBContainer fluid>
        <h3>Form Kelola Nilai Mata Kuliah Khusus</h3>
        <MDBRow>
            <MDBCol>
                <MDBInput
                    name="participant"
                    onChange={handleInputChange}
                    type="text"
                    label="NIP Peserta"
                    icon="address-card"
                    iconClass="black-text"
                >
                </MDBInput>
            </MDBCol>
            <MDBCol>
                <MDBBtn color="primary" onClick={handleTranscriptSearch}>Cari</MDBBtn>
            </MDBCol>
        </MDBRow>
        <MDBRow>
            <MDBTable responsive align='middle'>
                <MDBTableHead>
                  <tr>
                    <th>Semester</th>
                    <th>Kode Kuliah</th>
                    <th>Mata Kuliah</th>
                    <th>SKS</th>
                    <th>Nilai</th>
                    <th align="center">#</th>
                  </tr>
                </MDBTableHead>
                {dtRowsData.length > 0 ?
                <MDBTableBody>
                    {dtRowsData.map((it,idx) => (
                        <tr key={idx}>
                        <td>{it.semester}</td>
                        <td>{it.code}</td>
                        <td>{it.title_id}</td>
                        <td>{it.credits}</td>
                        <td><mark>{it.predicate.length>0 && it.predicate}</mark></td>
                        <td align="middle">
                            <a class="button button-info" 
                                rippleColor='dark' 
                                onClick={() => toggleEditModal(dtRowsData.find(f=>f.id == it.id))} >
                                Edit
                            </a>
                        </td>
                        </tr>
                    ))} 
                </MDBTableBody>: 
                <div> Data Tidak Ditemukan, Periksa Kembali Kriteria Pencarian</div>
                }
            </MDBTable>
        </MDBRow>

        <TranscriptSpecialFormPage
            isOpen={showFormPage}
            toggle={toggleFormPage}
            payLoad={payloadForm}
        />

        <MDBBtnFixed
            floating
            color="blue"
            icon="plus"
            size="lg"
            style={{bottom: "55px", right: "24px"}}
            onClick={() => history.push(`${thisUri}/create`)}
            className="no-li"
        />

    </MDBContainer>
  );
};

export default TranscriptSpecialList;
