import { SET_ACCESS } from "./type";

const INITIAL_STATE = {}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_ACCESS:
      return {...action.payload};
    default:
      return state;
  }
};
