import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import DateTimePicker from 'react-datetime-picker';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBInput,
  MDBSelect,
  toast, MDBStepper, MDBStep, MDBSpinner
} from "mdbreact";

import InputError from "../../../../components/InputError";
import BaseRequest from "../../../../../services/BaseRequest";
import moment from 'moment'
import KuisSoalPage from "./KuisSoalPage";
import SaveModal from "../../../../modals/SaveModal";
import { getDefaultTrueFalseOptions } from "../../../configs/global";

const quizCategory = require("../../shared/json/quiz-category.json");
const quizTimeStatus = require("../../shared/json/quiz-time-status.json");
const questionTypes = require('../../shared/json/question-types.json');

const thisUri = "/kurikulum/ujian-evaluasi/kuis";
const apiUri = "/quizzes";

const formField = [
  { name: "course_id", rules: { required: true } },
  { name: "course_part_id", rules: { required: true } },
  { name: "title_id", rules: { required: true } },
  { name: "time_status", rules: { required: true } },
  { name: "category", rules: { required: true } },
  { name: "number_of_questions", rules: { required: true } },
  { name: "title_id", rules: { required: true } },
  { name: "title_en" },
  { name: "title_ar" },
  { name: "weight_correct" },
  { name: "weight_wrong" },
  { name: "weight_not_answer" },
  { name: "time" },
  { name: "title_ar" },
  { name: "time_open" },
  { name: "time_close" },
  { name: "copy_from_question_bank" }
];

const KuisFormPage = () => {
  const history = useHistory();
  const { id } = useParams(); // Jika id !== undefined, maka ini adalah fitur edit data

  const { register, handleSubmit, errors, setValue, watch, trigger: triggerValidation } = useForm();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [getDataLoading, setGetDataLoading] = useState(false);
  const [step1Active, setStep1Active] = useState(true);
  const [step2Active, setStep2Active] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [disableQuestionForm, setDisableQuestionForm] = useState(false);
  const [kuis, setKuis] = useState({});
  const [selectedCoursePartName, setSelectedCoursePartName] = useState('');

  let [listCourse, setListCourse] = useState([]);
  let [listCoursePart, setListCoursePart] = useState([]);
  let [listTimeStatus, setListTimeStatus] = useState([]);
  let [listCategory, setListCategory] = useState([]);
  let [listQuestions, setListQuestions] = useState([]);
  let [listCopyQuestionBanks, setListCopyQuestionBanks] = useState([]);
  let [questionCategories, setQuestionCategories] = useState([
    { text: 'Semua kategori', value: '', checked: true }
  ]);

  const handleTimeOpenChange = date => {
    setValue('time_open', date);
  }

  const handleTimeCloseChange = date => {
    setValue('time_close', date);
  }

  const getTimeStatus = () => {
    return quizTimeStatus;
  }

  const getCategory = () => {
    return quizCategory;
  }

  const getCopyOptions = () => {
    return getDefaultTrueFalseOptions();
  }

  const getCourses = async () => {
    const uri = `/courses`
    const { data: { data } } = await BaseRequest.post(uri, {
      sortBy: 'title_id',
      desc: false,
      limit: -1
    });
    return data.data.map((v, i) => ({
      text: v.title_id,
      value: v.id
    }));
  };

  const getCourseParts = async (course_id) => {
    const uri = `/course-parts`
    const { data: { data } } = await BaseRequest.post(uri, {
      sortBy: 'title_id',
      desc: false,
      filter:{
        course_id: course_id
      }
    });

    return data.data.map((v, i) => ({
      text: v.title_id,
      value: v.id
    }));
  }

  /** Get question categories **/
  const getQuestionCategories = async () => {
    const {
      data: {data}
    } = await BaseRequest.post('question-categories', {
      limit: -1,
      sortBy: 'name',
      desc: false
    });

    return { data: data.data };
  }

  useEffect(() => {
    getQuestionCategories()
      .then((res) => {
        let categories = res.data.map(item => {
          return {
            text: item.name,
            value: item.id
          }
        });

        setQuestionCategories(questionCategories.concat(categories));
      });
  }, [id])

  const handleChange = e => {
    setValue(e.target.name, e.target.value);
  };

  const handleCourseChange = id => {
    setValue('course_id', id[0]);
  }

  const handleCopyQuestionChange = val => {
    setValue('copy_from_question_bank', val[0]);

    if(val[0] === '1')
      setDisableQuestionForm(true);
    else if(val[0] === '0')
      setDisableQuestionForm(false);
  };

  const handleCoursePartChange = id => {
    setValue('course_part_id', id[0]);
  }

  const handleTimeStatusChange = id => {
    setValue('time_status', id[0]);
  }

  const handleCategoryChange = id => {
    setValue('category', id[0]);
  }

  let course_id = watch('course_id')
  let course_part_id = watch('course_part_id')
  let time_status = watch('time_status')
  let category = watch('category')
  let copy_from_question_bank = watch('copy_from_question_bank')

  useEffect(() => {
    for (let v of formField) {
      register({ name: v.name }, v.rules || null);
    }
  }, [register]);

  useEffect(() => {
    if (id) {
      setGetDataLoading(true);

      const getDataById = async () => {
        try {
          const {
            data: { data }
          } = await BaseRequest.get(`quizzes/${id}/edit?with=quizQuestion`);

          return { data: data };
        } catch (error) {
          console.log(error);
          return { data: null };
        }
      };

      getDataById()
        .then(r => {
          setKuis(r.data);
          for (let key in r.data) {
            if (key === 'weight_not_answer' || key === 'weight_wrong' || key === 'weight_correct' || key === 'copy_from_question_bank'){
              let val = r.data[key] === 0 ? '0' : r.data[key];
              setValue(key, val || "");
            } else if (key === 'time_open' || key === 'time_close'){
              setValue(key, new Date(r.data[key]));
            } else {
              setValue(key, r.data[key] || "");
            }
          }
          let quizQuestions = r.data.quiz_question.map(item => {
            let questionType = questionTypes.filter(e => {
              let type = item.type || '';

              return e.value === type;
            });

            return {
              ...item,
              type: questionType.length > 0 ? questionType[0].text : '',
              question_type: item.is_essay ? 'Essay' : 'PG'
            }
          });

          setListQuestions(quizQuestions);
        })
        .finally(() => setGetDataLoading(false));
    }
  }, [id, setValue]);

  useEffect(() => {
    if (course_id) {
      getCourses()
      .then(res => {
        setListCourse(() => (
          res.map((v, i) => ({
            ...v,
            checked: (course_id === v.value)
          }))
        ));
      })
    }else{
      if(!id){
        getCourses()
        .then(res => {
          setListCourse(res)
        })
      }
    }
  },[course_id, id]);

  useEffect(() => {
    if (kuis && kuis.course_part_id) {
      getCourseParts(course_id)
      .then(res => {
        setListCoursePart(() => (
          res.map((v, i) => ({
            ...v,
            checked: (kuis.course_part_id === v.value)
          }))
        ))
      });
    } else {
      if (course_id && !id) {
        getCourseParts(course_id).then(res => {
          setListCoursePart(res)
        });
      }
    }
  },[course_id, kuis, id]);

  useEffect(() => {
    if(time_status){
      setListTimeStatus(()=>(
        getTimeStatus().map((v, i) => ({
          ...v,
          checked: (time_status === v.value)
        }))
      ))
    }else{
      if(!id) setListTimeStatus(getTimeStatus())
    }
  },[time_status, id]);

  useEffect(() => {
    if(category){
      setListCategory(()=>(
        getCategory().map((v, i) => ({
          ...v,
          checked: (category === v.value)
        }))
      ))
    }else{
      if(!id) setListCategory(getCategory())
    }
  },[category, id]);

  useEffect(() => {  
    if(copy_from_question_bank) {
      setListCopyQuestionBanks(() => (
        getCopyOptions().map((v, i) => ({
          ...v,
          checked: (copy_from_question_bank == v.value)? true:false
        }))
      ))
    }
    else {
      if(!id) setListCopyQuestionBanks(getCopyOptions())
    }
  },[copy_from_question_bank, kuis, id]);

  const onSubmit = async payload => {
    try {
      setSubmitLoading(true);
      let res = null;

      payload.time_open = moment(payload.time_open).format('YYYY-MM-DD HH:mm:ss');
      payload.time_close = moment(payload.time_close).format('YYYY-MM-DD HH:mm:ss');
      payload.questions = listQuestions.map(question => question.id);

      if (id) {
        res = await BaseRequest.put(`${apiUri}/${id}`, payload);
      } else {
        res = await BaseRequest.post(`${apiUri}/save`, payload);
      }
      toast.success(res.data.message, {
        closeButton: false,
        position: "bottom-right"
      });
      setTimeout(() => history.push(thisUri), 2000);
    } catch (error) {
      let errorMessage = error.response
        ? error.response.data.message
        : error.message;
      toast.error(errorMessage, {
        closeButton: false,
        position: "bottom-right"
      });
      setSubmitLoading(false);
    }
  };

  const swapStepper = (stepNo) => {
    triggerValidation().then(result => {
      if (!result) return;

      switch (stepNo) {
        case 1:
          setStep1Active(true);
          setStep2Active(false);
          break;
        case 2:
          setStep1Active(false);
          setStep2Active(true);
          break;
      }
    });
  }

  /** Toggle save modal  **/
  const toggleSaveModal = () => {
    triggerValidation()
      .then(isValid => isValid && setShowSaveModal(true));
  }

  return (
    <MDBContainer>
      <h3 className="text-center pt-4 pb-5 mb-2">
        {id ? "Edit" : "Tambah"} Kuis
      </h3>

      <MDBStepper form>
        <MDBStep form>
          <a onClick={() => swapStepper(1)}>
            <MDBBtn color={ step1Active ? "indigo" : "default" } circle>
              1
            </MDBBtn>
          </a>
          <p>Detail Kuis</p>
        </MDBStep>
        <MDBStep form>
          <a onClick={() => swapStepper(2)}>
            <MDBBtn color={ step2Active ? "indigo" : "default" } circle>
              2
            </MDBBtn>
          </a>
          <p>Pertanyaan</p>
        </MDBStep>
      </MDBStepper>

      <MDBRow center>
        <MDBCol size="12" md="12" sm="12">
          <form onSubmit={handleSubmit(onSubmit)}>
            <MDBContainer>
              <MDBRow>
                {
                  step1Active &&
                  (
                    getDataLoading ?
                      <MDBCol size="12" className="text-center">
                        <MDBSpinner big className="my-auto" />
                      </MDBCol> :
                      <MDBCol size="12">
                        <MDBSelect
                          className={errors.course_id && "is-invalid"}
                          name="course_id"
                          getValue={handleCourseChange}
                          options={listCourse}
                          selected="Pilihan Mata Kuliah"
                          label="Mata Kuliah"
                        />
                        {
                          (errors.course_id && errors.course_id.type === "required") && (
                            <span className="text-danger small">Tidak boleh kosong</span>
                          )
                        }

                        <MDBSelect
                          className={errors.course_part_id && "is-invalid"}
                          name="course_part_id"
                          getValue={handleCoursePartChange}
                          getTextContent={text => setSelectedCoursePartName(text)}
                          options={listCoursePart}
                          selected="Pilihan Opsi"
                          label="Pilih Pertemuan"
                        />
                        {errors.course_part_id &&
                        errors.course_part_id.type === "required" && (
                          <span className="text-danger small">Tidak boleh kosong</span>
                        )}

                        <MDBSelect
                          className={errors.time_status && "is-invalid"}
                          name="time_status"
                          getValue={handleTimeStatusChange}
                          options={listTimeStatus}
                          selected="Pilihan Jenis Waktu"
                          label="Jenis Waktu"
                        />
                        {errors.time_status &&
                        errors.time_status.type === "required" && (
                          <span className="text-danger small">Tidak boleh kosong</span>
                        )}

                        <MDBSelect
                          className={errors.category && "is-invalid"}
                          name="category"
                          getValue={handleCategoryChange}
                          options={listCategory}
                          selected="Pilihan Opsi"
                          label="Pilih Kategori"
                        />
                        {errors.category &&
                        errors.category.type === "required" && (
                          <span className="text-danger small">Tidak boleh kosong</span>
                        )}

                        <MDBSelect
                          name="copy_from_question_bank"
                          options={listCopyQuestionBanks}
                          label="Apakah Ujian Ini Menggunakan Semua Pertanyaan dan Jawaban Dari Bank Soal ?"
                          getValue={handleCopyQuestionChange}
                        />

                        <MDBInput
                          name="number_of_questions"
                          onChange={handleChange}
                          type="number"
                          label="Jumlah Pertanyaan"
                          value={watch("number_of_questions")}
                        />
                        {errors.number_of_questions && (
                          <span className="text-danger small">Tidak boleh kosong, minimal 1</span>
                        )}
                        <MDBInput
                          className={errors.title_id && "is-invalid"}
                          name="title_id"
                          onChange={handleChange}
                          type="text"
                          label="Judul (Indonesia)"
                          value={watch("title_id")}
                        >
                          {errors.title_id &&
                          errors.title_id.type === "required" && (
                            <InputError text="Tidak boleh kosong" />
                          )}
                        </MDBInput>

                       <MDBInput
                          name="title_en"
                          onChange={handleChange}
                          type="text"
                          label="Judul (English)"
                          value={watch("title_en")}
                        />
                        <MDBInput
                          name="title_ar"
                          onChange={handleChange}
                          type="text"
                          label="Judul (Arabic)"
                          value={watch("title_ar")}
                        />
                        <MDBInput
                          name="weight_correct"
                          onChange={handleChange}
                          type="number"
                          label="Bobot Jawaban Benar"
                          value={watch("weight_correct")}
                        />
                        <MDBInput
                          name="weight_wrong"
                          onChange={handleChange}
                          type="number"
                          label="Bobot Jawaban Salah"
                          value={watch("weight_wrong")}
                        />
                        <MDBInput
                          name="weight_not_answer"
                          onChange={handleChange}
                          type="number"
                          label="Bobot Tidak Dijawab"
                          value={watch("weight_not_answer")}
                        />
                        <MDBInput
                          name="time"
                          onChange={handleChange}
                          type="number"
                          label="Durasi (Dalam detik)"
                          value={watch("time")}
                        />

                        <label htmlFor="defaultFormLoginEmailEx" className="grey-text">
                          Waktu Dibuka
                        </label>
                        <DateTimePicker
                          onChange={handleTimeOpenChange}
                          value={watch("time_open")}
                          format="y-MM-dd HH:mm:ss"
                          className="form-control"
                          placeholder="Waktu Dibuka"
                        />
                        <br /><br />
                        <label htmlFor="defaultFormLoginEmailEx" className="grey-text">
                          Waktu Ditutup
                        </label>
                        <DateTimePicker
                          onChange={handleTimeCloseChange}
                          value={watch("time_close")}
                          format="y-MM-dd HH:mm:ss"
                          className="form-control"
                          placeholder="Waktu Ditutup"
                        />

                        

                        <MDBBtn className="float-left mt-3" color="red" size="md" onClick={() => history.push(thisUri)}>
                          Batal
                        </MDBBtn>
                        <MDBBtn className="float-right mt-3" color="blue" size="md" onClick={() => swapStepper(2)}>
                          Lanjut
                        </MDBBtn>
                      </MDBCol>
                  )
                }
                {
                  step2Active &&
                    <MDBCol size="12">
                      <KuisSoalPage
                        questionsData={listQuestions}
                        questionCategories={questionCategories}
                        isCopyingFromBank={copy_from_question_bank}
                        setSelectedSoal={setListQuestions}
                        coursePart={{
                          id: course_part_id,
                          name: selectedCoursePartName
                        }}
                      />

                      <MDBBtn className="float-left mt-3" color="blue" size="md" onClick={() => swapStepper(1)}>
                        Kembali
                      </MDBBtn>
                      <MDBBtn type="button" className="float-right mt-3" color="blue" size="md" onClick={toggleSaveModal} disabled={listQuestions.length <= 0 && !disableQuestionForm }>
                        Simpan
                      </MDBBtn>
                    </MDBCol>
                }
              </MDBRow>
            </MDBContainer>

            {/** Save Modal **/}
            <SaveModal
              isOpen={showSaveModal}
              toggle={() => setShowSaveModal(!showSaveModal)}
              handler={handleSubmit(onSubmit)}
              loadingIndicator={submitLoading}
            />
          </form>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default KuisFormPage;
