import React from "react";
import "./App.css";
import { Switch } from "react-router-dom";

import RoutesWithNavigation from "./components/RoutesWithNavigation";

import Login from "./components/pages/Login";
import LoginRoute from "./components/components/LoginRoute";
import { ToastContainer } from "mdbreact";

const App = () => {
  return (
    <div>
      <Switch>
        <LoginRoute path="/login" exact>
          <Login />
        </LoginRoute>
        <RoutesWithNavigation />
      </Switch>
      <ToastContainer
        hideProgressBar={true}
        newestOnTop={true}
        autoClose={3000}
      />
    </div>
  );
};

export default App;
