import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import {
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBInput,
  MDBSpinner,
  toast
} from "mdbreact";
import InputError from "../../../components/InputError";
import { Link } from "react-router-dom";
import BaseRequest from "../../../../services/BaseRequest";

const thisUri = "/pengaturan/predikat";
const apiUri = "/achievement-levels";

const RenderButton = ({ submitLoading }) => {
  if (submitLoading) {
    return <MDBSpinner />;
  }
  return (
    <div>
      <Link to={thisUri}>
        <MDBBtn size="md" color="red" className="mx-0">
          Kembali
        </MDBBtn>
      </Link>
      <MDBBtn type="submit" color="green" size="md">
        Simpan
      </MDBBtn>
    </div>
  );
};

const PredikatFormPage = () => {
  const { register, handleSubmit, errors, setValue, watch } = useForm();
  const [submitLoading, setSubmitLoading] = useState(false);
  const history = useHistory();
  const { id } = useParams(); // Jika id !== undefined, maka ini adalah fitur edit data

  const lowerLimitFieldValue = watch("lower_limit");
  const upperLimitFieldValue = watch("upper_limit");
  const nameFieldValue = watch("name");
  const weightScoreFieldValue = watch("weight_score");

  const handleChange = e => {
    setValue(e.target.name, e.target.value);
  };

  useEffect(() => {
    register({ name: "name" }, { required: true });
    register({ name: "lower_limit" }, { required: true, validate: {max: value => parseFloat(value) <= 100} });
    register({ name: "upper_limit" }, { validate: {max: value => parseFloat(value) <= 100} });
    register({ name: "weight_score" }, { required: true });
  }, [register]);

  useEffect(() => {
    if (id) {
      const getDataById = async () => {
        try {
          const {
            data: { data }
          } = await BaseRequest.get(`${apiUri}/${id}/edit`);
          for (let key in data) {
            setValue(key, data[key]);
          }
        } catch (error) {
          console.log(error);
        }
      };
      getDataById();
    }
  }, [id, setValue]);

  const onSubmit = async (payload) => {
    try {
      setSubmitLoading(true);
      let res;
      if (id) {
        res = await BaseRequest.put(`${apiUri}/${id}`, payload);
      } else {
        res = await BaseRequest.post(`${apiUri}/save`, payload);
      }

      toast.success(res.data.message, {
        closeButton: false,
        position: "bottom-right"
      });
      setTimeout(() => history.push(thisUri), 2000);
    } catch (error) {
      let errorMessage = error.response
        ? error.response.data.message
        : error.message;
      toast.error(errorMessage, {
        closeButton: false,
        position: "bottom-right"
      });
      setSubmitLoading(false);
    }
  };

  return (
    <MDBContainer>
      <MDBRow center>
        <MDBCol size="12" md="9" sm="12">
          <MDBCard>
            <MDBCardBody>
              <MDBCardTitle>{id ? "Edit" : "Tambah"} Predikat</MDBCardTitle>
              <hr />
              <form onSubmit={handleSubmit(onSubmit)}>
                <MDBContainer>
                  <MDBRow>
                    <MDBCol size="12">
                      <MDBInput
                        className={errors.lower_limit && "is-invalid"}
                        name="lower_limit"
                        onChange={handleChange}
                        type="number"
                        label="Batas Bawah"
                        value={lowerLimitFieldValue}
                      >
                        {errors.lower_limit && errors.lower_limit.type === "required" && (
                          <InputError text="Tidak boleh kosong" />
                        )}
                        {errors.lower_limit && errors.lower_limit.type === "max" && (
                          <InputError text="Tidak boleh melebihi angka 100" />
                        )}
                      </MDBInput>

                      <MDBInput
                        className={errors.upper_limit && "is-invalid"}
                        name="upper_limit"
                        onChange={handleChange}
                        type="number"
                        label="Batas Atas"
                        value={upperLimitFieldValue}
                      >
                        {errors.upper_limit && errors.upper_limit.type === "required" && (
                          <InputError text="Tidak boleh kosong" />
                        )}
                        {errors.upper_limit && errors.upper_limit.type === "max" && (
                          <InputError text="Tidak boleh melebihi angka 100" />
                        )}
                      </MDBInput>

                      <MDBInput
                        className={errors.name && "is-invalid"}
                        name="name"
                        onChange={handleChange}
                        type="text"
                        label="Predikat"
                        value={nameFieldValue}
                      >
                        {errors.name && errors.name.type === "required" && (
                          <InputError text="Tidak boleh kosong" />
                        )}
                      </MDBInput>

                      <MDBInput
                        className={errors.weight_score && "is-invalid"}
                        name="weight_score"
                        onChange={handleChange}
                        type="number"
                        label="Bobot"
                        value={weightScoreFieldValue}
                      >
                        {errors.weight_score && errors.weight_score.type === "required" && (
                          <InputError text="Tidak boleh kosong" />
                        )}
                      </MDBInput>

                    </MDBCol>

                  </MDBRow>
                </MDBContainer>

                <div className="text-center mt-3 black-text">
                  <RenderButton submitLoading={submitLoading} />
                </div>
              </form>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default PredikatFormPage;
