import React, { useState, createContext } from 'react';
import { connect } from "react-redux";
import { isAccessibleMenu } from '../utils/get-access-utils';

export const AccessContext = createContext();

export const AccessProvider = connect(
  state => ({
    access: state.access
  }))((props) => {

    const accessTool = {
      access: props.access,
      isAccessible: (menu, action) => {
        return isAccessibleMenu(props.access, menu, action);
      }
    }
    // const [access, setAccess] = useState(props.access);

    return <AccessContext.Provider value={accessTool}>{ props.children }</AccessContext.Provider>;
});
