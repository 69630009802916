import React, {useState, useMemo} from "react";
import {MDBBtn, MDBBtnFixed, MDBCol, MDBContainer, MDBIcon, MDBRow} from "mdbreact";
import {default as CustomDataTable} from "../../../../components/DataTable";
import TextField from "@material-ui/core/TextField";
import SelectSoalModal from "./SelectSoalModal";

const KuisSoalPage = (props) => {

  const { questionsData, questionCategories, setSelectedSoal, coursePart, isCopyingFromBank } = props;
  const [isSelectSoalModalOpen, setIsSelectSoalModalOpen] = useState(false);

  /* Datatable column definition */
  const columns = [
    {
      name: "Pertanyaan (ID)",
      selector: "question_id",
      style: {
        whiteSpace: 'none',
      },
      sortable: true,
      cell: row => {
        return (
          <div className="w-100">
            <span>
              {row.question_id} {row.isNew && <sup className="red-text">Baru</sup>}
            </span>
          </div>
        );
      }
    },
    {
      name: "Tingkat Kesulitan",
      selector: "type",
      width: "6rem",
      sortable: true
    },
    {
      name: "Jenis Soal",
      selector: "question_type",
      width: "5rem",
      sortable: true
    },
    {
      cell: (row) =>
        <div className="btn-group-sm">
          <MDBBtn variant="contained" color="red" onClick={() => deleteQuestion(row.id)}>
            <MDBIcon icon="trash" />
          </MDBBtn>
        </div>,
      button: true,
      width: "4rem",
    }
  ];

  /* Datatable variables */
  const [dtRowsData, setDtRowsData] = useState(questionsData);
  const [dtSearchMeta, setDtSearchMeta] = useState('');
  const filteredQuestions = dtRowsData.filter(item => item.question_id && item.question_id.toLowerCase().includes(dtSearchMeta.toLowerCase()));

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <MDBRow className={"w-100"}>
        <MDBCol size={"12"} md={"4"} sm={"12"}>
          <TextField
            label="Filter by Pertanyaan"
            onChange={(e) => setDtSearchMeta(e.target.value)}
            className={"w-100"}
            name="search"
          />
        </MDBCol>
      </MDBRow>
    );
  }, [dtSearchMeta]);

  const deleteQuestion = (id) => {
    let newSoal = dtRowsData.filter(item => item.id !== id);
    setDtRowsData(newSoal);
    setSelectedSoal(newSoal);
  }

  const toggleSelectSoalModal = () => {
    setIsSelectSoalModalOpen(!isSelectSoalModalOpen);
  }

  const saveSelectedSoal = (questions) => {
    setDtRowsData(questions);
    setSelectedSoal(questions);
  }

  return (
    <MDBContainer>

      <CustomDataTable
        columns={columns}
        data={filteredQuestions}
        subHeaderComponent={subHeaderComponentMemo}
        serverSide={false}
      />

      <SelectSoalModal
        isOpen={isSelectSoalModalOpen}
        toggle={toggleSelectSoalModal}
        selectedSoal={dtRowsData}
        setSelectedSoal={saveSelectedSoal}
        questionCategories={questionCategories}
        coursePart={coursePart}
      />

      <MDBBtnFixed
          floating
          color="blue"
          icon="plus"
          size="lg"
          style={{bottom: "55px", right: "24px"}}
          onClick={() => toggleSelectSoalModal()}
      />
        

      
    </MDBContainer>
  );
}

export default KuisSoalPage;
