import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import {
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBRow,
  toast,
} from "mdbreact";
import BaseRequest from "../../../../services/BaseRequest";
import {Form} from "../../../components";
let thisUri = `/kurikulum/transkrip`;
const apiUri = `/transcripts`;

const TranscriptSpecialFormPage = (props) => {
  const history = useHistory();
  const { isOpen, toggle, payLoad } = props;
  const {
    register,
    handleSubmit,
    errors,
    setValue,
    watch,
    getValues,
    trigger: triggerValidation
  } = useForm();
  const [submitLoading, setSubmitLoading] = useState(false);
  
  const formField = [
    {
      name: 'predicate',
      rules: { required: true },
      label: 'Nilai (Predikat) Akhir'
    },
  ];

  /** Register the form fields **/
  useEffect(() => {
    for (let v of formField) {
      if(typeof v.name !== 'undefined'){
        register({ name: v.name }, v.rules || null);
      }
    }
  }, [register]);

  useEffect(() => {
    console.log(payLoad);
    if (payLoad) {
      setValue('predicate', payLoad.predicate);
    }
  }, [isOpen]);


  const onSubmit = async payload => {
    try {
      setSubmitLoading(true);
      let res;
      if (payLoad.predicate_id) {
        res = await BaseRequest.put(`${apiUri}/${payLoad.predicate_id}`, payload);
      } 
      toast.success(res.data.message, {
        closeButton: false,
        position: "bottom-right"
      });
      
    } catch (error) {
      let errorMessage = error.response
        ? error.response.data.message
        : error.message;
      toast.error(errorMessage, {
        closeButton: false,
        position: "bottom-right"
      });
    } finally {
      setSubmitLoading(false);
      setTimeout(() => history.push(thisUri), 2000);
    }
  };

  /** Define form fields **/
  const getFormFields = () => {
    return formField.map((field) => ({ ...field, error: errors[field.name] }));
  };

  /** Input text change handler **/
  const handleInputTextChange = (e) => {
    const { name, value } = e.target;
    setValue(name, value);
  };

  return (
    <MDBModal
      isOpen={isOpen}
      toggle={toggle}
      backdrop={false}
      cascading
    >
      <MDBModalHeader
        toggle={toggle}
        titleClass="d-inline title"
        className="text-center green darken-3 white-text">
          Edit Nilai Mata Kuliah Khusus <br></br>
      </MDBModalHeader>
      <MDBModalBody>
        <MDBRow>
          <Form
            onSubmit={handleSubmit(onSubmit)}
            fields={getFormFields()}
            watcher={watch}
            onInputTextChange={handleInputTextChange}
            submitLoading={submitLoading}
            values={getValues()}
            triggerValidation={triggerValidation}
            backUri={thisUri}
          />
        </MDBRow>
      </MDBModalBody>
    </MDBModal>
  );
};

export default TranscriptSpecialFormPage;
