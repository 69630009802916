import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useHistory} from "react-router-dom";
import moment from "moment";
import TextField from "@material-ui/core/es/TextField/TextField";
import {
  MDBBtn,
  MDBBtnFixed,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
  MDBRow,
  MDBSpinner,
  toast
} from "mdbreact";
import {default as CustomDataTable} from "../../../components/DataTable";
import BaseRequest from "../../../../services/BaseRequest";
import KategoriSoalAddFormModal from "./KategoriSoalAddFormModal";

const thisUri = "/kurikulum/bank-soal/kategori";
const apiUri = "/question-categories";

const KategoriSoalPage = () => {
  const history = useHistory();

  const [ showCreateModal, setShowCreateModal ] = useState(false);
  const [ showDeleteModal, setShowDeleteModal ] = useState(false);
  const [ deleteLoading, setDeleteLoading ] = useState(false);
  const [ deletedID, setDeletedID ] = useState(null);

  /** Datatable variables **/
  const [ loadProgressData, setLoadProgressData ] = useState(true);
  const [ dtRowsData, setDtRowsData ] = useState([]);
  const [ dtSortBy, setDtSortBy ] = useState('created_at');
  const [ dtSortDirection, setDtSortDirection ] = useState('desc');
  const [ dtTotalRow, setDtTotalRow ] = useState(1);
  const [ dtPage, setDtPage ] = useState(1);
  const [ dtPerPage, setDtPerPage ] = useState(10);
  const [ dtSearchMeta, setDtSearchMeta ] = useState({
    filter: {},
    field_search: 'name',
    search: ''
  });

  /** Datatable columns definition **/
  const columns = [
    {
      name: "No.",
      selector: "rowNo",
      width: "4rem",
      right: true
    },
    {
      name: "Nama Kategori",
      selector: "name",
      sortable: true
    },
    {
      name: "Jumlah Soal",
      selector: "question_no",
      sortable: false,
      width: "10rem",
      right: true
    },
    {
      name: "Dibuat Tanggal",
      selector: "created_at",
      sortable: true,
      width: "13rem",
      right: true
    },
    {
      name: "Disunting Tanggal",
      selector: "updated_at",
      sortable: true,
      width: "13rem",
      right: true
    },
    {
      cell: (row) =>
        <div className="btn-group-sm">
          <MDBBtn variant="contained" color="yellow" onClick={() => history.push(`${thisUri}/${row.id}/edit`)} >
            <MDBIcon icon="pen-nib" />
          </MDBBtn>
          <MDBBtn variant="contained" color="red" onClick={() => toggleDeleteModal(row.id)}>
            <MDBIcon icon="trash" />
          </MDBBtn>
        </div>,
      button: true
    }
  ];

  /** Datatable get data **/
  const getDataFromServer = useCallback(async (page = 1, perPage = 10) => {
    let uri = `${apiUri}?with=questions`;

    try {
      setLoadProgressData(true);

      const {
        data: {data}
      } = await BaseRequest.post(uri, {
        sortBy: dtSortBy,
        desc: dtSortDirection === 'desc',
        page: page,
        limit: perPage,
        ...dtSearchMeta
      });

      setDtTotalRow(data.total);

      const mappedData = data.data.map((v, i) => ({
        rowNo: data.from + i,
        id: v.id,
        name: v.name,
        question_no: v.questions.length,
        created_at: moment(v.created_at).format("DD MMMM YYYY"),
        updated_at: moment(v.updated_at).format("DD MMMM YYYY"),
      }));

      return {rows: mappedData, paginationOptions: data};
    } catch (error) {
      let errorMessage = error.response
        ? error.response.data.message
        : error.message;
      toast.error(errorMessage, {
        closeButton: false,
        position: "bottom-right"
      });
    }

    return { rows: [] }
  }, [dtSearchMeta, dtSortBy, dtSortDirection]);

  const reloadDataFromServer = useCallback((page = 1, perPage = 10) => {
    getDataFromServer(page, perPage)
      .then((res) => {
        const { rows } = res;

        setDtRowsData(rows);
      })
      .finally(() => {
        setLoadProgressData(false);
      });
  }, [getDataFromServer]);

  useEffect(() => {
    reloadDataFromServer(dtPage, dtPerPage);
  }, [reloadDataFromServer, dtPage, dtPerPage]);

  /** Datatable sorting **/
  const onSortHandler = (column, sortDirection) => {
    setDtSortBy(column.selector);
    setDtSortDirection(sortDirection);
    reloadDataFromServer(dtPage, dtPerPage);
  };

  /** Datatable Filter **/
  const subHeaderComponentMemo = useMemo(() => {
    const handleFilter = (keyword) => {
      setLoadProgressData(true);

      let newSearchMeta = dtSearchMeta;
      newSearchMeta.search = keyword;

      setDtSearchMeta(newSearchMeta);

      getDataFromServer(newSearchMeta)
        .then((res) => {
          const { rows } = res;

          setDtRowsData(rows);
          setLoadProgressData(false);
        })
    };

    return (
      <MDBRow className={"w-100"}>
        <MDBCol size={"12"} md={"4"} sm={"12"}>
          <TextField
            label="Filter by Nama"
            onChange={(e) => handleFilter(e.target.value)}
            className={"w-100"}
            name="name"
          />
        </MDBCol>
      </MDBRow>
    )
  }, [dtSearchMeta]);

  /** Delete handler **/
  const toggleDeleteModal = (deletedID) => {
    setShowDeleteModal(!showDeleteModal);
    setDeletedID(deletedID);
  };

  const handleDelete = () => {
    setDeleteLoading(true);

    const deleteData = async () => {
      return await BaseRequest.delete(`${apiUri}/${deletedID}`);
    };

    deleteData()
      .then(() => {
        reloadDataFromServer(dtPage, dtPerPage);
      })
      .finally(() => {
        setShowDeleteModal(false);
        setDeleteLoading(false);
      });
  };

  /** Datatable Change Page handler **/
  const onPageChangeHandler = (page) => {
    setDtPage(page)
  }

  const onPerRowsChangeHandler = (perPage) => {
    setDtPerPage(perPage)
  }

  return (
    <MDBContainer fluid>
      <h3>Kategori Bank Soal</h3>

      <div>
        <CustomDataTable
          columns={columns}
          data={dtRowsData}
          totalRow={dtTotalRow}
          onSortHandler={onSortHandler}
          subHeaderComponent={subHeaderComponentMemo}
          progressPending={loadProgressData}
          handlePerRowsChange={onPerRowsChangeHandler}
          handlePageChange={onPageChangeHandler}
        />
      </div>

      <MDBBtnFixed
        floating
        color="blue"
        icon="plus"
        size="lg"
        style={{ bottom: "55px", right: "24px" }}
        onClick={() => setShowCreateModal(true)}
        className="no-li"
      />

      {/** Create new category modal **/}
      <KategoriSoalAddFormModal thisUri={thisUri} apiUri={apiUri} toggle={() => setShowCreateModal(!showCreateModal)} isOpen={showCreateModal} />

      {/** Delete Modal **/}
      <MDBModal
        isOpen={showDeleteModal}
        toggle={toggleDeleteModal}
        backdrop={false}
        centered
      >
        <MDBModalHeader>Konfirmasi Penghapusan</MDBModalHeader>
        <MDBModalBody>Anda akan menghapus data ini. Lanjutkan ?</MDBModalBody>
        <MDBModalFooter>
          {deleteLoading ? (
            <MDBSpinner />
          ) : (
            <>
              <MDBBtn color="secondary" onClick={toggleDeleteModal}>
                Tidak
              </MDBBtn>
              <MDBBtn color="primary" onClick={handleDelete}>
                Iya
              </MDBBtn>
            </>
          )}
        </MDBModalFooter>
      </MDBModal>
    </MDBContainer>
  );
};

export default KategoriSoalPage;
