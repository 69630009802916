import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {getDefaultTrueFalseOptions} from "../../configs/global";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  toast
} from "mdbreact";
import BaseRequest from "../../../../services/BaseRequest";
import {Form} from "../../../components";
import * as FileRequest from "../../../../services/FileRequest";

const queryString = require('query-string');
let thisUri = `/perkuliahan/mata-kuliah`;
const apiUri = `/courses`;

const MataKuliahFormPage = () => {
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams(); // Jika id !== undefined, maka ini adalah fitur edit data

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    watch,
    getValues,
    trigger: triggerValidation
  } = useForm();

  const [submitLoading, setSubmitLoading] = useState(false);
  const [file, setFile] = useState({});
  const [filePreview, setFilePreview] = useState('');
  const [courseCategoryOptions, setCourseCategoryOptions] = useState([]);
  const [classOptions, setClassOptions] = useState([]);
  const [specialAssesmentOptions, setSpecialAssesmentOptions] = useState([]);

  const formField = [
    {
      name: 'course_category_id',
      label: 'Pilih Kategori Mata Kuliah',
      rules: { required: true },
      type: 'select',
      options: courseCategoryOptions,
    },
    {
      name: 'classes_id',
      label: 'Pilih Kelas/Angkatan',
      rules: { required: true },
      type: 'select',
      options: classOptions,
    },
    {
      name: 'title_id',
      rules: { required: true },
      label: 'Judul (Indonesia)'
    },
    {
      name: 'title_en',
      rules: { required: false },
      label: 'Judul (English)'
    },
    {
      name: 'title_ar',
      rules: { required: false },
      label: 'Judul (Arabic)'
    },
    {
      name: 'description_id',
      type: 'textarea',
      rules: { required: true },
      label: 'Deskripsi (Indonesia)'
    },
    {
      name: 'description_en',
      type: 'textarea',
      rules: { required: false },
      label: 'Deskripsi (English)'
    },
    {
      name: 'description_ar',
      type: 'textarea',
      rules: { required: false },
      label: 'Deskripsi (Arabic)'
    },
    {
      type: 'divider',
      label: 'Silabus'
    },
    {
      name: 'code',
      rules: { required: true },
      label: 'Kode Mata Kuliah'
    },
    {
      name: 'special_assessment',
      rules: { required: true },
      label: 'Mata Kuliah Khusus',
      type: 'select',
      options: specialAssesmentOptions,
    },
    {
      name: 'credits',
      rules: { required: true },
      label: 'SKS',
      type: 'number'
    },
    {
      name: 'semester',
      rules: { required: true },
      label: 'Semester',
      type: 'number'
    },
    {
      name: 'competencies',
      rules: { required: true },
      label: 'Tujuan Mata Kuliah (Kompetensi yang di harapkan)',
      type: 'textarea'
    },
    {
      name: 'subject_per_meeting',
      rules: { required: true },
      label: 'Pokok Bahasan setiap pertemuan',
      type: 'textarea'
    },
    {
      name: 'strategy',
      rules: { required: true },
      label: 'Strategi Perkuliahan',
      type: 'textarea'
    },
    {
      name: 'evaluation',
      rules: { required: true },
      label: 'Evaluasi Perkuliahan',
      type: 'textarea'
    },
    {
      name: 'source_of_reference',
      rules: { required: true },
      label: 'Sumber Rujukan',
      type: 'textarea'
    },
    {
      name: 'file_id',
      type: 'file',
      label: 'Foto Kitab',
      filePreview: filePreview,
      setter: setFile
    }
  ];

  /** Register the form fields **/
  useEffect(() => {
    for (let v of formField) {
      if(typeof v.name !== 'undefined'){
        register({ name: v.name }, v.rules || null);
      }
    }
  }, [register]);

  const getCourseCategoryOptions = async () => {
    const uri = `/course-categories`
    const { data: { data } } = await BaseRequest.post(uri, {
      sortBy: 'title_id',
      desc: false
    });

    return data.data.map((v, i) => ({
      text: v.title_id,
      value: v.id
    }));
  }

  const getClassOptions = async () => {
    const uri = `/classes`
    const { data: { data } } = await BaseRequest.post(uri, {
      sortBy: 'name',
      desc: false
    });

    return data.data.map((v, i) => ({
      text: v.name,
      value: v.id
    }));
  }

  /** Get Mata Kuliah Data **/
  useEffect(() => {
    if (id) {
      const getDataById = async () => {
        try {
          const {
            data: { data }
          } = await BaseRequest.get(`${apiUri}/${id}/edit`);

          return data;
        } catch (error) {
          console.log(error);
        }
      };

      getDataById().then(data => {
        for (let key in data) {
          setValue(key, data[key] || "");
        }

        if(data.file){
          setFilePreview(data.file.url)
        }
      });
    }

    let specialAssessment = getValues("special_assessment");
    if(specialAssessment) {
      setSpecialAssesmentOptions(() => {
        getDefaultTrueFalseOptions().map((v) => ({
          ...v,
          checked: (specialAssessment == v.value)
        }))
      });
    } else {
      if(!specialAssessment) setSpecialAssesmentOptions(getDefaultTrueFalseOptions());
    }
  }, [id, setValue]);

  /** Get Kategori Mata Kuliah & Kelas Data **/
  useEffect(() => {
    let courseCategoryId = getValues("course_category_id");
    if (courseCategoryId) {
      getCourseCategoryOptions()
      .then(res => {
        setCourseCategoryOptions(() => (
          res.map((v, i) => ({
            ...v,
            checked: (courseCategoryId === v.value)
          }))
        ))
      });
    }

    let classId = getValues("classes_id");
    if (classId) {
      getClassOptions()
        .then(res => {
          setClassOptions(() => (
            res.map((v, i) => ({
              ...v,
              checked: (classId === v.value)
            }))
          ))
        });
    }

      getCourseCategoryOptions()
      .then(res => {
        setCourseCategoryOptions(res)
      });

      getClassOptions()
        .then(res => {
          setClassOptions(res)
        });
  },[id]);

  useEffect(() => {
    // Check where the edit form comes from
    let parsed = queryString.parse(location.search);
    if (parsed.from) {
      switch (parsed.from) {
        case 'detail-page':
          thisUri = `/perkuliahan/mata-kuliah/${parsed.id}`;
          break;
        default:
          break;
      }
    }
  }, [location])

  const onSubmit = async payload => {
    try {
      setSubmitLoading(true);

      let res;
      if (file instanceof File) {
        const fd = new FormData();
        fd.append('file', file);

        const { data } = await FileRequest.upload(fd);
        payload.file_id = data.data.id;
      }

      if (id) {
        // Delete the file object if it's not exists
        if (payload.file_id === "") delete payload.file_id;
        res = await BaseRequest.put(`${apiUri}/${id}`, payload);
      } else {
        res = await BaseRequest.post(`${apiUri}/save`, payload);
      }

      toast.success(res.data.message, {
        closeButton: false,
        position: "bottom-right"
      });

      setTimeout(() => history.push(thisUri), 2000);
    } catch (error) {
      let errorMessage = error.response
        ? error.response.data.message
        : error.message;
      toast.error(errorMessage, {
        closeButton: false,
        position: "bottom-right"
      });
    } finally {
      setSubmitLoading(false);
    }
  };

  /** Define form fields **/
  const getFormFields = () => {
    return formField.map((field) => ({ ...field, error: errors[field.name] }));
  };

  /** Input text change handler **/
  const handleInputTextChange = (e) => {
    const { name, value } = e.target;
    setValue(name, value);
  };

  /** Select Input change handler **/
  const handleSelectChange = (field, value) => {
    if (field && value) {
      setValue(field, value);
    }
  };

  return (
    <MDBContainer>
      <MDBRow center>
        <MDBCol size="12" md="12" sm="12">
          <h3 className="text-center pt-4 pb-5 mb-2">
            {id ? "Edit" : "Tambah"} Mata Kuliah
          </h3>

          <Form
            onSubmit={handleSubmit(onSubmit)}
            fields={getFormFields()}
            watcher={watch}
            onInputTextChange={handleInputTextChange}
            onSelectChange={handleSelectChange}
            submitLoading={submitLoading}
            values={getValues()}
            backUri={thisUri}
            triggerValidation={triggerValidation}
          />
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default MataKuliahFormPage;
