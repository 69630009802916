import React, {useEffect, useState} from "react";
import {MDBCol, MDBInput, MDBRow} from "mdbreact";
import BaseRequest from "../../../services/BaseRequest";
import AsyncPaginate from "react-select-async-paginate";
import InputError from "../../components/InputError";

const getSyahadahTypes = [
  {
    text: 'Program',
    value: 'class',
  },
  {
    text: 'Mata Kuliah',
    value: 'course',
  },
  {
    text: 'Pertemuan',
    value: 'course_part',
  }
];

const SyahadahTypes = (props) => {
  const {
    syahadah,
    watcher,
    setValue,
    getValues,
    errors,
    clearErrors: clearError
  } = props;

  const [type, setType] = useState(watcher('type_syahadah') || '');
  const [radioInputs, setRadioInputs] = useState([]);
  const [typeValue, setTypeValue] = useState([]);
  const [typePlaceholder, setTypePlaceholder] = useState('');

  /** Initialize the radio inputs **/
  const onSyahadahTypeChange = (value) => {
    setType(value);
    setValue('type_syahadah', value);

    // Re-initialize some data
    setTypeValue([]);
    clearError(['class', 'course', 'course_part']);

    let placeholder = '';
    switch (value) {
      case 'class':
        placeholder = 'Pilih Program...';
        break;
      case 'course':
        placeholder = 'Pilih Mata Kuliah...';
        break;
      case 'course_part':
        placeholder = 'Pilih Pertemuan...';
        break;
      default:
        break;
    }
    setTypePlaceholder(placeholder);
  };

  useEffect(() => {
    let options = getSyahadahTypes;
    let opts = []

    for (let x = 0; x < options.length; x++) {
      opts.push({
        ...options[x],
        // checked: (syahadah && ((syahadah.class_id && options[x].value == "class") 
        //   || (syahadah.course_id && options[x].value == "course") 
        //   || (syahadah.course_part_id && options[x].value == "course_part") 
        //   ))
      })
    }

    const fieldName = "type_syahadah";
    let radioInputs = opts.map((option, i) =>
      <MDBInput
        key={i}
        id={`radio-${i}`}
        className={(errors.type_syahadah || errors[option.fieldName]) && 'is-invalid'}
        name={fieldName}
        onClick={() => onSyahadahTypeChange(option.value)}
        type="radio"
        label={option.text}
        iconClass="black-text"
        value={option.value}
        checked={option.checked || type === option.value}
      />
    );

    setRadioInputs(radioInputs);
  }, [syahadah, type, errors]);

  useEffect(() => {
    switch (syahadah.type_syahadah) {
      case 'class':
        setTypeValue(syahadah.classes.map(data => ({ label: data.name, value: data.id })))
        break;
      case 'course':
        setTypeValue(syahadah.groups.map(data => ({ label: data.name, value: data.id })))
        break;
      case 'course_part':
        setTypeValue(syahadah.admin_groups.map(data => ({ label: data.name, value: data.id })))
        break;
    }
  }, [syahadah])

  /** Load recipient options **/
  const loadClassOptions = async (search, loadedOptions, {page}) => {
    let apiUri = '/classes';
    let nameField = 'name';

    const {
      data: {data}
    } = await BaseRequest.post(apiUri, {
      limit: 10,
      sortBy: nameField,
      desc: false,
      field_search: nameField,
      search: search,
      page: page
    });

    let options = data.data.map((v) => {
      return {
        label: v[nameField],
        value: v.id
      }
    });

    return {
      options: options,
      hasMore: data && (data.to < data.total),
      additional: {
        page: page + 1,
      },
    };
  }

  const loadCourseOptions = async (search, loadedOptions, {page}) => {
    let apiUri = '/courses?with=classes';
    let nameField = 'title_id';

    const {
      data: {data}
    } = await BaseRequest.post(apiUri, {
      limit: 10,
      sortBy: nameField,
      desc: false,
      field_search: nameField,
      search: search,
      page: page
    });

    let options = data.data.map((v) => {
      return {
        label: `${v[nameField]} / ${v.classes.name}`,
        value: v.id,
      }
    });

    return {
      options: options,
      hasMore: data && (data.to < data.total),
      additional: {
        page: page + 1,
      },
    };
  }

  const loadCoursePartOptions = async (search, loadedOptions, {page}) => {
    let apiUri = '/course-parts?with=course';
    let nameField = 'title_id';

    const {
      data: {data}
    } = await BaseRequest.post(apiUri, {
      limit: 10,
      sortBy: nameField,
      desc: false,
      field_search: nameField,
      search: search,
      page: page
    });

    let options = data.data.map((v) => {
      return {
        label: `${v[nameField]} / ${v.course.title_id}`,
        value: v.id,
      }
    });

    return {
      options: options,
      hasMore: data && (data.to < data.total),
      additional: {
        page: page + 1,
      },
    };
  }

  const handleSelectTypeChange = (field, values) => {
    setValue(field, values);
    setTypeValue(values);
  }

  return (
    <>
      <div className="md-form" style={{marginTop: "2.5rem"}}>
        <label style={{marginTop: "-1.5rem"}}>Jenis E-Syahadah</label>
      </div>
      <MDBRow>
        {radioInputs}
      </MDBRow>
      <MDBRow style={{marginTop: "-1rem"}}>
        <MDBCol>
          {
            errors.type_syahadah &&
            errors.type_syahadah.type === "required" && (
              <InputError text="Tidak boleh kosong" className='d-block'/>
            )
          }
        </MDBCol>
      </MDBRow>
      <MDBRow style={{marginTop: "1rem"}}>
        <MDBCol>
          {
            (type === 'class' || watcher('type_syahadah') === 'class') &&
            <>
              <AsyncPaginate
                value={typeValue}
                loadOptions={loadClassOptions}
                onChange={(value) => handleSelectTypeChange('class', value)}
                additional={{
                  page: 1,
                }}
                closeMenuOnSelect={true}
                placeholder={typePlaceholder}
              />
              {
                errors.class && (
                  <InputError text={errors.class.message} className='d-block'/>
                )
              }
            </>
          }
          {
            type === 'course' &&
            <>
              <AsyncPaginate
                value={typeValue}
                loadOptions={loadCourseOptions}
                onChange={(value) => handleSelectTypeChange('course', value)}
                additional={{
                  page: 1,
                }}
                closeMenuOnSelect={true}
                placeholder={typePlaceholder}
              />
              {
                errors.course && (
                  <InputError text={errors.course.message} className='d-block'/>
                )
              }
            </>
          }
          {
            type === 'course_part' &&
            <>
              <AsyncPaginate
                value={typeValue}
                loadOptions={loadCoursePartOptions}
                onChange={(value) => handleSelectTypeChange('course_part', value)}
                additional={{
                  page: 1,
                }}
                closeMenuOnSelect={true}
                placeholder={typePlaceholder}
              />
              {
                errors.course_part && (
                  <InputError text={errors.course_part.message} className='d-block'/>
                )
              }
            </>
          }
        </MDBCol>
      </MDBRow>
    </>
  );
}

export default SyahadahTypes;
