import React, {useCallback, useEffect, useMemo, useState} from "react";
import {MDBBadge, MDBBtn, MDBBtnFixed, MDBCol, MDBContainer, MDBIcon, MDBInput, MDBRow, MDBSelect, toast} from "mdbreact";
import {default as CustomDataTable} from "../../components/DataTable";
import DeleteModal from "../../modals/DeleteModal";
import BaseRequest from "../../../services/BaseRequest";
import moment from "moment";
import {useHistory} from "react-router";
import WarningModal from "../../modals/WarningModal";

const thisUri = '/survey'
const apiUri = '/surveys'

const getState = (row) => {
  if (moment(row.start_at).isAfter()) return 'notstarted';
  else if (moment(row.end_at).isBefore()) return 'expired';
  else if (row.is_active == 0) return 'inactive';
  else return 'active';
}

const SurveyPage = () => {
  const history = useHistory();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedDeleteItem, setSelectedDeleteItem] = useState({});
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [showActivationModal, setShowActivationModal] = useState(false);
  const [showDeactivationModal, setShowDeactivationModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [activationLoading, setActivationLoading] = useState(false);

  /** Datatable variables **/
  const [ loadProgressData, setLoadProgressData ] = useState(true);
  const [ dtRowsData, setDtRowsData ] = useState([]);
  const [ dtSortBy, setDtSortBy ] = useState('created_at');
  const [ dtSortDirection, setDtSortDirection ] = useState('desc');
  const [ dtTotalRow, setDtTotalRow ] = useState(1);
  const [ dtPage, setDtPage ] = useState(1);
  const [ dtPerPage, setDtPerPage ] = useState(10);
  const [ dtSearchMeta, setDtSearchMeta ] = useState({
    // filter: {},
    field_search: 'title',
    search: '',
    with: 'class,questions'
  });

  /** Datatable column definition **/
  const columns = [
    {
      name: 'No',
      selector: 'rowNo',
      width: "4rem",
      right: true
    },
    {
      name: "Judul Survey",
      selector: "title",
      sortable: true,
    },
    {
      name: "Peruntukan",
      selector: "class",
      cell: (row) => <span>{row.class?.name || "Semua Program"}</span>
    },
    {
      name: "Status",
      cell: (row) => {
        const state = getState(row);
        let statusStr = "?";
        if (state == "active") return <MDBBadge color="success">Aktif</MDBBadge>;
        else if (state == "inactive") return <MDBBadge color="danger">Tidak Aktif</MDBBadge>;
        else if (state == "notstarted") statusStr = "Belum Dimulai";
        else if (state == "expired") statusStr = "Sudah Berakhir";
        
        return <span>{statusStr}</span>
      },
      selector: "is_active",
      sortable: false,
    },
    {
      cell: (row) => {
          return <span>{moment(row.start_at).format("DD MMM YYYY")}</span>;
      },
      name: "Tanggal Mulai",
      selector: "start_at",
      sortable: true,
    },
    {
      cell: (row) => {
          return <span>{moment(row.end_at).format("DD MMM YYYY")}</span>;
      },
      name: "Tanggal Selesai",
      selector: "end_at",
      sortable: true,
    },
    {
      cell: (row) => {
        const state = getState(row);
        return <>
          <div className="btn-group-sm">
            <MDBBtn variant="contained" color="yellow" onClick={() => history.push(`${thisUri}/${row.id}/edit`)}>
              <MDBIcon icon="pen-nib" />
            </MDBBtn>
          </div>
          {state == "inactive" &&
            <div className="btn-group-sm">
              <MDBBtn variant="contained" color="green" onClick={() => toggleActivationModal(row)}>
                <MDBIcon icon="play" />
              </MDBBtn>
            </div>
          }
          {state == "active" &&
            <div className="btn-group-sm">
              <MDBBtn variant="contained" color="orange" onClick={() => toggleDeactivationModal(row)}>
                <MDBIcon icon="stop" />
              </MDBBtn>
            </div>
          }
          <div className="btn-group-sm">
            <MDBBtn variant="contained" color="red" onClick={() => toggleDeleteModal(row.id)}>
              <MDBIcon icon="trash" />
            </MDBBtn>
          </div>
          <div className="btn-group-sm">
            <MDBBtn variant="contained" color="blue" onClick={() => history.push(`${thisUri}/${row.id}/report`)}>
              <MDBIcon icon="percent" />
            </MDBBtn>
          </div>
        </>
      },
      button: true,
      width: "12rem"
    }
  ];

  /** Activation handler **/
  const toggleActivationModal = item => {
    if (!item) return;
    setSelectedItem(item);
    setShowActivationModal(!showActivationModal);
  }

  const toggleDeactivationModal = item => {
    if (!item) return;
    setSelectedItem(item);
    setShowDeactivationModal(!showDeactivationModal);
  }

  const handleActivation = async () => {
    try {
      setActivationLoading(true);
      // toggle
      await BaseRequest.put(`${apiUri}/${selectedItem?.id}`, { is_active: !selectedItem.is_active});
      reloadDataFromServer(dtPage, dtPerPage);
    } catch (err) {
      console.error(err);
    } finally {
      setShowActivationModal(false);
      setShowDeactivationModal(false);
      setActivationLoading(false);
    }
  }

  /** Datatable expanded row component **/
  const ItemExpandedRow = ({ data }) => (
    <div className="p-3">
      <ul style={{fontSize: 14}}>
        <li>ID: {data.id}</li>
        <li>Deskripsi: {data.description || "n/a"}</li>
        <li>
          Jumlah Pertanyaan: {data.questions.length}
          <ol>
            {data.questions.length > 0 &&
              data.questions.map((q) => (
                <li>
                  <em>
                    {q.title} ({q.type})
                  </em>
                </li>
              ))}
          </ol>
        </li>
        <li>Dibuat: {moment(data.updated_at).format("DD/MM/YYYY HH:mm")}</li>
        <li>
          Terakhir Disunting:{" "}
          {moment(data.updated_at).format("DD/MM/YYYY HH:mm")}
        </li>
      </ul>
    </div>
  );

  /** Datatable get data **/
  const getSurveys = useCallback(async (page = 1, perPage = 10) => {
    try {
      setLoadProgressData(true);

      const {
        data: { data }
      } = await BaseRequest.post(`${apiUri}?with=class`, {
        sortBy: dtSortBy,
        desc: dtSortDirection === 'desc',
        page: page,
        limit: perPage,
        ...dtSearchMeta
      });

      setDtTotalRow(data.total);

      // Map the questions data
      const mappedData = data.data.map((v, i) => {
        return{
          ...v,
          rowNo: data.from + i,
          certificate_name: `${v.cert_name} ${v.cert_period}`,
          status: v.status,
          result_message: v.result_message,
          created_at: moment(v.created_at).format("DD/MM/YYYY"),
        }
      });

      return { rows: mappedData }
    } catch (error) {
      let errorMessage = error.response
        ? error.response.data.message
        : error.message;
      toast.error(errorMessage, {
        closeButton: false,
        position: "bottom-right"
      });
    }

    return { rows: [] }
  }, [dtSearchMeta, dtSortBy, dtSortDirection]);

  const reloadDataFromServer = useCallback((page = 1, perPage = 10) => {
    getSurveys(page, perPage)
      .then((res) => {
        const { rows } = res;

        setDtRowsData(rows);
      })
      .finally(() => {
        setLoadProgressData(false);
      });
  }, [getSurveys]);

  useEffect(() => {
    reloadDataFromServer(dtPage, dtPerPage);
  }, [reloadDataFromServer, dtPage, dtPerPage]);

  /** Datatable X **/
  const subHeaderComponentMemo = useMemo(() => {
    const handleFilter = (keyword, comesFromSelect = false, XName = null) => {
      setLoadProgressData(true);

      let newSearchMeta = dtSearchMeta;

      if (comesFromSelect) {
        let selectedValue = keyword;

        if (selectedValue === '') {
          delete newSearchMeta.X[XName];
        } else {
          newSearchMeta.X[XName] = selectedValue;
        }
      } else {
        newSearchMeta.search = keyword;
      }

      setDtSearchMeta(newSearchMeta);

      getSurveys(newSearchMeta)
        .then((res) => {
          const { rows } = res;

          setDtRowsData(rows);
          setLoadProgressData(false);
        })
    };

    return (
      <div className="w-100 data-table__filter-input">
        <MDBRow className={"w-100"}>
          <MDBCol size={"12"} md={"4"} sm={"12"}>
            <MDBInput
              label="Cari Judul Survey"
              onChange={(e) => handleFilter(e.target.value)}
              className="search-input"
              name="search"
            />
          </MDBCol>
        </MDBRow>
      </div>
    )
  }, [dtSearchMeta, getSurveys]);

  /** Datatable sorting **/
  const onSortHandler = (column, sortDirection) => {
    setDtSortBy(column.selector);
    setDtSortDirection(sortDirection);

    reloadDataFromServer(dtPage, dtPerPage);
  };

  const toggleDeleteModal = (id) => {
    setSelectedDeleteItem(id);
    setShowDeleteModal(!showDeleteModal);
  }

  const handleDelete = async () => {
    try {
      setDeleteLoading(true);

      await BaseRequest.delete(`${apiUri}/${selectedDeleteItem}`);
      reloadDataFromServer(dtPage, dtPerPage);
    } catch (err) {
      console.error(err);
    } finally {
      toggleDeleteModal();
      setDeleteLoading(false);
    }
  };

  /** Datatable Change Page handler **/
  const onPageChangeHandler = (page) => {
    setDtPage(page)
  }

  const onPerRowsChangeHandler = (perPage) => {
    setDtPerPage(perPage)
  }

  return (
    <MDBContainer fluid>
      <h3>Survey</h3>

      <CustomDataTable
        columns={columns}
        data={dtRowsData}
        totalRow={dtTotalRow}
        onSortHandler={onSortHandler}
        subHeaderComponent={subHeaderComponentMemo}
        progressPending={loadProgressData}
        handlePerRowsChange={onPerRowsChangeHandler}
        handlePageChange={onPageChangeHandler}
        expandableRows={true}
        expandableRowsComponent={<ItemExpandedRow />}
      />

      <DeleteModal
        isOpen={showDeleteModal}
        toggle={toggleDeleteModal}
        handler={handleDelete}
        loadingIndicator={deleteLoading}
      />

      <WarningModal
        isOpen={showActivationModal}
        toggle={toggleActivationModal}
        handler={handleActivation}
        loadingIndicator={activationLoading}
        title={"Konfirmasi"}
        text={'Aktifkan survey?'}
      />

      <WarningModal
        isOpen={showDeactivationModal}
        toggle={toggleDeactivationModal}
        handler={handleActivation}
        loadingIndicator={activationLoading}
        title={"Konfirmasi"}
        text={'Non-aktifkan survey?'}
      />

      <MDBBtnFixed
        floating
        color="blue"
        icon="plus"
        size="lg"
        style={{bottom: "55px", right: "24px"}}
        onClick={() => history.push(`${thisUri}/create`)}
        className="no-li"
      />
    </MDBContainer>
  );
}

export default SurveyPage;
