import React, { useState } from "react";
import moment from "moment";
import BaseRequest from "../../../services/BaseRequest";
import { useHistory } from "react-router-dom";
import usePagination from "../../../hooks/UsePagination";
import {
  MDBCard,
  MDBCardBody,
  MDBContainer,
  MDBCardTitle,
  MDBBtn,
  MDBIcon,
  MDBBtnFixed,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBSpinner,
  MDBDataTable
} from "mdbreact";

const thisUri = "/quiz-answers";

const AnswerPage = props => {
  const history = useHistory();

  const ActionField = props => {
    const { data } = props;
    return (
      <div className="btn-group-sm">
        <MDBBtn
          color="yellow"
          onClick={() => history.push(`${thisUri}/${data.id}/edit`)}
        >
          <MDBIcon icon="pen-nib" />
        </MDBBtn>
        <MDBBtn color="red" onClick={() => handleClickDelete(data)}>
          <MDBIcon icon="trash" />
        </MDBBtn>
      </div>
    );
  };

  const tableHandler = async (page, limit) => {
    let uri = `${thisUri}?sortBy=created_at&desc=true&with=quizQuestion`;

    if (limit > 0) {
    }
    let uriQueries = [];
    uriQueries.push(`page=${page}`);
    uriQueries.push(`limit=${limit}`);
    uri = `${uri}&${uriQueries.join('&')}`;

    const {
      data: { data }
    } = await BaseRequest.post(uri);

    const mappedData = data.data.map((v, i) => ({
      row_num: i + 1,
      id: v.id,
      question_id: (v.quiz_question)? v.quiz_question.question_id : '',
      answer_id: v.answer_id,
      is_correct_answer: v.is_correct_answer? 'Benar' : 'Salah',
      createdAt: moment(v.created_at).format("DD MMMM YYYY"),
      updatedAt: moment(v.updated_at).format("DD MMMM YYYY"),
      action: <ActionField data={v} />,
    }));
    return { rows: mappedData, paginationOptions: data };
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedDeleteItem, setSelectedDeleteItem] = useState({});
  const [deleteLoading, setDeleteLoading] = useState(false);

  const {
    rows,
    setRows
  } = usePagination(tableHandler, 10);

  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  }

  const handleClickDelete = item => {
    setSelectedDeleteItem(item);
    toggleDeleteModal();
  };

  const handleDelete = async () => {
    const { id } = selectedDeleteItem;

    try {
      setDeleteLoading(true);
      await BaseRequest.delete(`${thisUri}/${id}`);
      const newRows = rows.filter(item => item.id !== id);
      setRows(newRows);
      toggleDeleteModal();
    } catch (err) {
      console.error(err);
    } finally {
      setDeleteLoading(false);
    }
  };

  const getDisplayRows = () => {
    const displayRows = rows.map(item => {
      const { id, ...rest } = item;
      return rest;
    })

    return displayRows;
  }

  return (
    <MDBContainer>
      <MDBCard>
        <MDBCardBody>
          <MDBCardTitle>Semua Jawaban</MDBCardTitle>
          <MDBDataTable
            btn
            responsive
            small
            striped
            bordered
            hover
            data={{ columns, rows: getDisplayRows() }}
          />
        </MDBCardBody>
      </MDBCard>

      <MDBBtnFixed
        floating
        color="blue"
        icon="plus"
        size="lg"
        style={{ bottom: "55px", right: "24px" }}
        onClick={() => history.push(`${thisUri}/create`)}
      ></MDBBtnFixed>

      <MDBModal
        isOpen={showDeleteModal}
        toggle={toggleDeleteModal}
        backdrop={false}
        centered
      >
        <MDBModalHeader>Konfirmasi Penghapusan</MDBModalHeader>
        <MDBModalBody>Anda akan menghapus data ini. Lanjutkan ?</MDBModalBody>
        <MDBModalFooter>
          {deleteLoading ? (
            <MDBSpinner />
          ) : (
            <>
              <MDBBtn color="secondary" onClick={toggleDeleteModal}>
                Tidak
              </MDBBtn>
              <MDBBtn color="primary" onClick={handleDelete}>
                Iya
              </MDBBtn>
            </>
          )}
        </MDBModalFooter>
      </MDBModal>
    </MDBContainer>
  );
};

const columns = [
  {
    label: 'No',
    field: 'row_num'
  },
  {
    label: "Pertanyaan (ID)",
    field: "question_id"
  },
  {
    label: "Jawaban (ID)",
    field: "answer_id"
  },
  {
    label: "Status Jawaban",
    field: "is_correct_answer"
  },
  {
    label: "Dibuat Tanggal",
    field: "createdAt"
  },
  {
    label: "Disunting Tanggal",
    field: "updatedAt"
  },
  {
    label: "Aksi",
    field: "action"
  }
];

export default AnswerPage;
