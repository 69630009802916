import React, {useCallback, useEffect, useState} from "react";
import moment from "moment";
import {useHistory} from "react-router-dom";
import BaseRequest from "../../../../services/BaseRequest";
import {
  MDBBtn,
  MDBBtnFixed,
  MDBIcon,
  MDBRow,
  MDBCol,
  toast,
  MDBContainer
} from "mdbreact";
import {default as CustomDataTable} from "../../../components/DataTable";
import DeleteModal from "../../../modals/DeleteModal";
import SubTipeUjianModal from "./subTipeUjianModal"

const thisUri = "/perkuliahan/skema-nilai/tipe-ujian";
const apiUri = "course-scores"

const SubTipeUjianList = (props) => {
  const {
    skemaNilaiID
  } = props;
  const history = useHistory();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedDeleteItem, setSelectedDeleteItem] = useState({});
  const [deleteLoading, setDeleteLoading] = useState(false);
  /** Datatable variables **/
  const [ dtRowsData, setDtRowsData ] = useState([]);
  const [ dtSortBy, setDtSortBy ] = useState('created_at');
  const [ dtSortDirection, setDtSortDirection ] = useState('desc');
  const [ dtTotalRow, setDtTotalRow ] = useState(1);
  const [ dtSearchMeta, setDtSearchMeta ] = useState({
    filter: {"score_type_id":skemaNilaiID},
    field_search: '',
    search: ''
  });
  const [ dtPage, setDtPage ] = useState(1);
  const [ dtPerRow, setDtPerRow ] = useState(10);
  const [ loadProgressData, setLoadProgressData ] = useState(true);
  const [ isSelectorModalOpen, setIsSelectorModalOpen] = useState(false);

  /** Datatable column definition **/
  const columns = [
    {
      name: "Tipe Ujian",
      selector: "code",
      sortable: true
    },
    {
      name: "Bobot (dalam %)",
      selector: "value",
      sortable: true
    },
    {
      name: "Dibuat Tanggal",
      selector: "created_at",
      sortable: true
    },
    {
      name: "Disunting Tanggal",
      selector: "updated_at",
      sortable: true
    },
    {
      cell: (row) =>
        <div className="btn-group-sm">
          <MDBBtn variant="contained" color="red" onClick={() => toggleDeleteModal(row.id)}>
            <MDBIcon icon="trash" />
          </MDBBtn>
        </div>,
        
      button: true
    }
  ];

  const toggleDeleteModal = (id) => {
    setSelectedDeleteItem(id);
    setShowDeleteModal(!showDeleteModal);
  }

  const handleDelete = async () => {
    try {
      setDeleteLoading(true);

      await BaseRequest.delete(`${apiUri}/${selectedDeleteItem}`);
      reloadDataFromServer();
    } catch (err) {
      console.error(err);
    } finally {
      toggleDeleteModal();
      setDeleteLoading(false);
    }
  };

  const getDataCourseScores = useCallback(async (page = 1, perPage = 10) => {
    let uri = `${apiUri}`;

    try {
      const {
        data: { data }
      } = await BaseRequest.post(uri, {
        sortBy: dtSortBy,
        desc: dtSortDirection === 'desc',
        page: page,
        limit: perPage,
        ...dtSearchMeta
      });

      setDtTotalRow(data.total);

      // Map the Quiz Type
      const mappedData = data.data.map((v, i) => ({
        rowNo: data.from + i,
        id: v.id,
        code : v.code,
        value : v.value,
        created_at: moment(v.created_at).format("DD/MM/YYYY"),
        updated_at: moment(v.updated_at).format("DD/MM/YYYY"),
      }));

      return { rows: mappedData }
    } catch (error) {
      let errorMessage = error.response
        ? error.response.data.message
        : error.message;
      toast.error(errorMessage, {
        closeButton: false,
        position: "bottom-right"
      });
    }

    return { rows: [] }
  }, [dtSearchMeta, dtSortBy, dtSortDirection]);

  const reloadDataFromServer = useCallback((page = 1, perPage = 10) => {
    getDataCourseScores(page, perPage)
      .then((res) => {
        const { rows } = res;

        setDtRowsData(rows);
      })
      .finally(() => {
        setLoadProgressData(false);
      });
  }, [getDataCourseScores]);

  useEffect(() => {
    reloadDataFromServer(dtPage, dtPerRow);
  }, [reloadDataFromServer, dtPage, dtPerRow, isSelectorModalOpen]);

  /** Datatable sorting **/
  const onSortHandler = (column, sortDirection) => {
    setDtSortBy(column.selector);
    setDtSortDirection(sortDirection);

    reloadDataFromServer();
  };

  const toggleInputUjianModal = () => {
    setIsSelectorModalOpen(!isSelectorModalOpen);
  }


  return (
    <MDBContainer fluid>
      <CustomDataTable
        columns={columns}
        data={dtRowsData}
        progressPending={loadProgressData}

        totalRow={dtTotalRow}
      />

      <DeleteModal
      isOpen={showDeleteModal}
      toggle={toggleDeleteModal}
      handler={handleDelete}
      loadingIndicator={deleteLoading}
      />

      <SubTipeUjianModal
        isOpen={isSelectorModalOpen}
        toggle={toggleInputUjianModal}
        selectedScoreTypeId={skemaNilaiID}
      />

     <MDBBtnFixed
        floating
        color="blue"
        icon="plus"
        size="lg"
        style={{bottom: "55px", right: "24px"}}
        onClick={() => toggleInputUjianModal()}
      />
    </MDBContainer>
  );
};

export default SubTipeUjianList;
