import React, {useEffect, useState} from 'react';
import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBIcon,
  MDBView,
} from 'mdbreact';
import SecureLS from "secure-ls";
import BaseRequest from "../../services/BaseRequest";
import {default as CustomDataTable} from "../components/DataTable";
import DaftarProgramAktif from "./DaftarProgramAktif";

const DV1 = () => {
  const [userFullName, setUserFullName] = useState('');
  const [jumlahPesertaAktif, setJumlahPesertaAktif] = useState(0);
  const [jumlahPesertaTidakAktif, setJumlahPesertaTidakAktif] = useState(0);
  const [jumlahPesertaDO, setJumlahPesertaDO] = useState(0);
  const [jumlahUjianAktif, setJumlahUjianAktif] = useState(0);
  const [jumlahPesertaLogin, setJumlahPesertaLogin] = useState(0);
  const [reminder, setReminder] = useState('');

  useEffect(() => {
    const ls = new SecureLS({encodingType: 'aes'});
    const data = ls.get('tsl')
    setUserFullName(data.userFullName);

    const loadDataPesertaAktif = async () => {
      const { data: { data }} = await BaseRequest.post('/participants', {
        limit: 1,
        page: 1,
        filter: {
          status: 'active'
        }
      });

      return data;
    }
    loadDataPesertaAktif().then(r => setJumlahPesertaAktif(r.total));

    const loadDataPesertaTidakAktif = async () => {
      const { data: { data }} = await BaseRequest.post('/participants', {
        limit: 1,
        page: 1,
        filter: {
          status: 'inactive'
        }
      });

      return data;
    }
    loadDataPesertaTidakAktif().then(r => setJumlahPesertaTidakAktif(r.total));

    const loadDataPesertaLogin = async () => {
      const { data: { data }} = await BaseRequest.get('/total-logged-in');

      return data;
    }
    loadDataPesertaLogin().then(r => setJumlahPesertaLogin(r));

    const loadDataUjian = async () => {
      const { data: { data }} = await BaseRequest.post('/quizzes', {
        limit: 1,
        page: 1
      });

      return data;
    }
    loadDataUjian().then(r => setJumlahUjianAktif(r.total));

    const loadReminder = async () => {
      const { data: { data }} = await BaseRequest.get('/general-info/pray-info-today', {
      });

      return data;
    }
    loadReminder().then(r => setReminder(r));
  }, [])

  const thousandSeparator = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <>
      <section>
        <MDBRow className="mb-4">
          <MDBCol className="text-center">
            <h4 style={{textTransform: "capitalize"}}>
              {
                userFullName && <u>Ahlan Wa Sahlan {userFullName}</u>
              }
            </h4>
          </MDBCol>
        </MDBRow>
        <MDBRow className="mb-5">
          <MDBCol className="text-center">
            <h4 style={{textTransform: "capitalize"}}>
              { reminder }
            </h4>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol xl='3' md='6' className='mb-4'>
            <MDBCard cascade className='cascading-admin-card'>
              <div className='admin-up'>
                <MDBIcon icon="users" className="primary-color mr-3 z-depth-2" />
                <div className='data'>
                  <p>JUMLAH PESERTA AKTIF</p>
                  <h4 className='font-weight-bold dark-grey-text'>
                    { thousandSeparator(jumlahPesertaAktif) }
                  </h4>
                </div>
              </div>
            </MDBCard>
          </MDBCol>

          <MDBCol xl='3' md='6' className='mb-4'>
            <MDBCard cascade className='cascading-admin-card'>
              <div className='admin-up'>
                <MDBIcon far icon='user' className='warning-color' />
                <div className='data'>
                  <p>JUMLAH PESERTA TIDAK AKTIF</p>
                  <h4 className='font-weight-bold dark-grey-text'>
                    { thousandSeparator(jumlahPesertaTidakAktif) }
                  </h4>
                </div>
              </div>
            </MDBCard>
          </MDBCol>

          <MDBCol xl='3' md='6' className='mb-4'>
            <MDBCard cascade className='cascading-admin-card'>
              <div className='admin-up'>
                <MDBIcon far icon='edit' className='success-color' />
                <div className='data'>
                  <p>JUMLAH UJIAN AKTIF</p>
                  <h4 className='font-weight-bold dark-grey-text'>
                    { thousandSeparator(jumlahUjianAktif) }
                  </h4>
                </div>
              </div>
            </MDBCard>
          </MDBCol>

          <MDBCol xl='3' md='6' className='mb-4'>
            <MDBCard cascade className='cascading-admin-card'>
              <div className='admin-up'>
                <MDBIcon far icon='edit' className='success-color' />
                <div className='data'>
                  <p>JUMLAH PESERTA LOGIN</p>
                  <h4 className='font-weight-bold dark-grey-text'>
                    { thousandSeparator(jumlahPesertaLogin) }
                  </h4>
                </div>
              </div>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </section>

      <section>
        <MDBRow>
          <MDBCol md="6">
            <MDBCard cascade narrow>
              <section>
                <MDBCard narrow className='z-depth-0 mt-5'>
                  <MDBView
                    cascade
                    className='gradient-card-header blue-gradient narrower py-2 mx-4 mb-3 d-flex justify-content-between align-items-center'
                  >
                    <div className="text-left"/>
                    <a href='/kelas/program' className='white-text mx-3'>
                      Daftar Program Aktif
                    </a>
                    <div className="text-right"/>
                  </MDBView>

                  <div className='px-4'>
                    <DaftarProgramAktif />
                  </div>
                </MDBCard>
              </section>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </section>

    </>
  );
}

export default DV1;
