import React, {useCallback, useEffect, useMemo, useState} from 'react';
import BaseRequest from '../../../../../../services/BaseRequest';
import { useHistory, useParams } from 'react-router-dom';
import {
  MDBContainer,
  MDBBtn,
  MDBIcon,
  toast,
  MDBRow,
  MDBCol,
  MDBCard,
} from 'mdbreact';
import {default as CustomDataTable} from "../../../../../components/DataTable";
import ResetModal from "./ResetModal";
import TextField from "@material-ui/core/TextField";
import KuisResetPage from './KuisResetPage';

const thisUri = '/kurikulum/ujian-evaluasi/kuis';
const apiUri = '/participants';

export const KuisResetParticipantPage = () => {
  const history = useHistory();
  const { quiz } = useParams();

  const [resetMessage, setResetMessage] = useState('');
  const [groupName, setGroupName] = useState('');
  const [showResetModal, setShowResetModal] = useState(false);
  const [selectedResetItem, setSelectedResetItem] = useState({});
  const [resetLoading, setResetLoading] = useState(false);
  const [ loadProgressData, setLoadProgressData ] = useState(true);

  useEffect(() => {
    console.log(quiz)
  },[])

  const handleReset = async () => {
    try {
      setResetLoading(true);

      const response = await BaseRequest.post(`/quiz-requests/reset`,{
        quiz_id: quiz.id,
        course_part_id: quiz.course_part_id,
        user_id: quiz.user.id
      });

      if(response.status === 200){
          const data = response.data
          if(data.code === 200){
            return toast.success('Reset Kuis berhasil', {
              closeButton: false,
              position: "bottom-right"
            });
          }
      }

      toast.error('Reset Kuis gagal', {
        closeButton: false,
        position: "bottom-right"
      });

    } catch (err) {
      console.error(err);
    } finally {
      setResetLoading(false);
    }
  };


  return (
    <MDBContainer>
      { (quiz!=null) ?
      <MDBCard>
      
      </MDBCard>
      :
      <h6>Data Tidak Ditemukan</h6>
      }
      
    </MDBContainer>
  );
};

export default KuisResetParticipantPage;
