import React, {useState, useEffect, useMemo} from "react";
import { useForm } from "react-hook-form";
import {
  toast,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBInput,
  MDBSelect,
  MDBModalHeader
} from "mdbreact";
import BaseRequest from "../../../services/BaseRequest";

const QuestionModal = ({question, isOpen, toggle, onRefresh}) => {
  const [loading, setLoading] = useState(false);
  const {register, handleSubmit, errors, setValue, watch} = useForm();
  const [isEditMode, setEditMode] = useState(false);

  const types = useMemo(() => [
    { text: 'Radio (pilih salah satu)', value: 'radio', checked: !question || question?.type == 'radio' },
    { text: 'Checkbox (bisa pilih beberapa)', value: 'checkbox', checked: question?.type == 'checkbox' },
    { text: 'Text (isian bebas)', value: 'text', checked: question?.type == 'text' },
  ], [question]);

  useEffect(()=>{
    setEditMode(!!question?.title);

    setValue("title", question?.title);
    setValue("hint", question?.hint);
    setValue("type", question?.type || "radio");    
    setValue("is_mandatory", question?.is_mandatory || false);
  },[question]);

  useEffect(()=>{
    register({name:"title"}, {required: true});
    register({name:"hint"});
    register({name:"type"}, {required: true});
    register({name:"is_mandatory"});
  },[]);

  const handleChange = e => {
    setValue(e.target.name, e.target.value);
  };  

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      data.survey_id = question?.survey_id;
      if (isEditMode) // update
        await BaseRequest.put(`surveys/questions/${question.id}`, data);
      else
        await BaseRequest.post(`surveys/questions/save`, data);
    } catch (err) {
      toast(err);
    } finally {
      setLoading(false);
      toggle();
      if(onRefresh) onRefresh();
    }
  }

  return (
    <MDBModal isOpen={isOpen} toggle={toggle}>
      <MDBModalHeader>{isEditMode ? "Edit" : "Tambah"} Pertanyaan</MDBModalHeader>
      <MDBModalBody>
        <form onSubmit={handleSubmit(onSubmit)}>
          <MDBInput
            name="title"
            type="text"
            label="Pertanyaan"
            value={watch("title")}
            onChange={handleChange}
          />
          <MDBInput
            name="hint"
            type="text"
            label="Instruksi/Penjelasan/Tip menjawab"
            value={watch("hint") || ""}
            onChange={handleChange}
          />
          <MDBSelect
            name="type"
            options={types}
            label="Tipe"
            getValue={(val)=> setValue("type", val[0])}
          />
          <MDBInput
            id="is_mandatory"
            name="is_mandatory"
            type="checkbox"
            label="Mandatory"
            value=""
            checked={watch("is_mandatory")}
            onChange={e => setValue("is_mandatory", e.target.checked)}
          />
          <MDBBtn type="submit" color="primary">
            {isEditMode ? "Update" : "Tambah"}
          </MDBBtn>
        </form>
      </MDBModalBody>
    </MDBModal>
  );
}

export default QuestionModal;