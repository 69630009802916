export const getStatusOptions = (status = undefined) => {
  if (status === null) return [];

  let statuses = [
    { text: 'Aktif', value: 'active' },
    { text: 'Non Aktif', value: 'inactive' }
  ];

  if (status !== undefined) statuses = statuses.filter((item) => item.value === status);

  return statuses;
}

export const getGenderOptions = (gender = undefined) => {
  if (gender === null) return [];

  let genders = [
    { text: 'Akhwat', value: 'akhwat' },
    { text: 'Ikhwan', value: 'ikhwan' }
  ];

  if (gender !== undefined) genders = genders.filter((item) => item.value === gender);

  return genders;
}

export const getCoursePriceOptions = (coursePrice = undefined) => {
  if (coursePrice === null) return [];

  let coursePrices = [
    { text: 'Gratis', value: 'gratis' },
    { text: 'Infaq Khusus Kelas/Program', value: 'infaq' }
  ];

  if (coursePrice !== undefined) coursePrices = coursePrices.filter(item => item.value === coursePrice);

  return coursePrices;
}

export const getDefaultTrueFalseOptions = (optionValue = undefined) => {
  if (optionValue === null) return [];

  let optionValues = [
    { text: 'Ya', value: '1' },
    { text: 'Tidak', value: '0' }
  ];

  if (optionValue !== undefined) optionValues = optionValues.filter(item => item.value === optionValue);

  return optionValues;
};

export const getDefaultClassTypeOptions = (classType = undefined) => {
  if (classType === null) return [];

  let classTypes = [
    { text: 'Regular', value: 'regular' },
    { text: 'Daurah', value: 'daurah' }
  ];

  if (classType !== undefined) classTypes = classTypes.filter(item => item.value === classType);

  return classTypes;
};

export const getRoleOptions = (role = undefined) => {
  if (role === null) return [];

  let roles = [
    { text: 'Admin Kurikulum', value: '16f6ed41-7bc6-477c-a2b6-b7d0f3f99240' },
    { text: 'Admin Grup', value: '3254fd49-3688-49b6-b27e-10b67e0407bb' },
    { text: 'Koordinator Admin WA', value: '4054dc8b-b517-4338-b412-d0bb9b3ee76f' },
    { text: 'Ustadz', value: '47bebd34-bd92-4f31-b01e-39145dacfd24' },
    { text: 'Admin Pusat', value: '7be566e9-3fad-426d-8036-aad53bb1a143' },
    { text: 'Superadmin', value: '8449b545-6fbc-499e-8016-294ed43513d8' },
    { text: 'Other', value: 'f786fea4-a40f-46ec-9c72-b340872ae6ab' }
  ];

  if (role !== undefined) roles = roles.filter((item) => item.value === role);

  return roles;
}

export const RECIPIENT_TYPE_CLASS = 'class';
export const RECIPIENT_TYPE_GROUP = 'group';
export const RECIPIENT_TYPE_ADMIN_GROUP = 'admin_group';
export const RECIPIENT_TYPE_PARTICIPANT = 'user';
export const RECIPIENT_TYPE_ADMIN = 'admin';

export const getAnnouncementRecipientTypes = (participantType = undefined) => {
  if (participantType === null) return [];

  let participantTypes = [
    { text: 'Kelas/Program', value: RECIPIENT_TYPE_CLASS, fieldName: 'classes' },
    { text: 'Group', value: RECIPIENT_TYPE_GROUP, fieldName: 'groups' },
    { text: 'Admin Group', value: RECIPIENT_TYPE_ADMIN_GROUP, fieldName: 'admin_groups' },
    { text: 'Peserta', value: RECIPIENT_TYPE_PARTICIPANT, fieldName: 'participants' },
    { text: 'Admin', value: RECIPIENT_TYPE_ADMIN, fieldName: 'admins' },
  ];

  if (participantType !== undefined) participantTypes = participantTypes.filter(item => item.value === participantType);

  return participantTypes;
}
