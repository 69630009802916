import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {
    MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, 
    MDBContainer,
    MDBSpinner, 
    MDBBtn,
    MDBInput,
    MDBFormInline,
    MDBSelect,
    MDBRow,
    MDBCol,
    toast
} from "mdbreact";
import BaseRequest from "services/BaseRequest";

const UserGroupMergeModal = (props) => {
    const {
        isOpen,
        toggleFunc,
        thisUri
    } = props;

    const history = useHistory();
    const [selectedClassId, setSelectedClassId] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [isNewGroup, setIsNewGroup] = useState(true);
    const [selectedGroupIds, setSelectedGroupIds] = useState([]);
    const [groupOptions, setGroupOptions] = useState([]);
    const [classOptions, setClassOptions] = useState([]);
    const [targetGroupId, setTargetGroupId] = useState();

    useEffect(()=>{
        if(selectedClassId){
            getAllGroups().then(
                (res) => {
                    let groupOptionDatas = res.data.map(item => {
                        return {
                            text: item.name_id,
                            value: item.id
                        }
                    });
                    setGroupOptions(groupOptionDatas);
                }
            );
        }
    },[selectedClassId]);

    useEffect(()=>{
        if(isOpen){
            getAllClasses().then(
                (res) => {
                    let groupOptionDatas = res.data.map(item => {
                        return {
                            text: item.name,
                            value: item.id,
                        }
                    });
                    setClassOptions(groupOptionDatas);
                }
            );
        }
    },[isOpen]);

    const handleIsNewGroupChange = (data) => () =>{
        setIsNewGroup(data);
    };

    const handleGroupSearch = result => {
        setSelectedGroupIds(result);
    };

    const handleSelectedClassId = (data) => {
        setSelectedClassId(data);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let data = await BaseRequest.post('groups/merge', {
                    group_ids: selectedGroupIds,
                    new_group_id: targetGroupId
                }
            );
            toast.success('Success message', {
                closeButton: true,
                position: "bottom-right"
            });
            setTimeout(() => history.push(`${thisUri}/${data.data.data.id}/edit`), 2000);
        }
        catch (error) {
            let errorMessage = error.response
            ? error.response.data.message
            : error.message;
            toast.error(errorMessage, {
                closeButton: false,
                position: "bottom-right"
            });
        }
    };

    const handleTargetGroup = (data) => () => {
        setTargetGroupId(data);
    };

    const getAllGroups = async() => {
        const {data: {data}} = await BaseRequest.post('groups', {
                sortBy: 'name_id',
                desc: false,
                filter: {'classes_id' :selectedClassId, 'status' :'active'}
            }
        );
        return { data: data.data };
    };

    const getAllClasses = async() => {
        const {data: {data}} = await BaseRequest.post('classes', {
                sortBy: 'name',
                desc: false,
            }
        );
        return { data: data.data };
    };


    return(
        <MDBModal isOpen={isOpen} toggle={toggleFunc} backdrop={false} size="lg" cascading>
            <MDBModalHeader 
                toggle={toggleFunc}
                titleClass="d-inline title"
                className="text-center green darken-3 white-text">
                    Proses Penggabungan Grup Belajar
            </MDBModalHeader>
            <MDBModalBody>
                { 
                    isLoading ? <MDBSpinner /> 
                    :
                    <MDBContainer fluid className="text-black">
                        <MDBRow>
                            <MDBCol md="4" className="ml-auto"> 
                                <p color="black">1. Pilih Kelas/Angkatan</p>
                            </MDBCol>
                            <MDBCol md="4" className="ml-auto">
                                <MDBSelect   
                                    getValue={value => handleSelectedClassId(value)}
                                    options={classOptions}
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md="4" className="ml-auto"> 
                                <p color="black">2. Pilih Minimal 2 Grup Untuk Digabungkan</p>
                            </MDBCol>
                            <MDBCol md="4" className="ml-auto">
                                <MDBSelect 
                                    multiple 
                                    getValue={value=> handleGroupSearch(value)}
                                    search
                                    options={groupOptions}
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md="4" className="ml-auto"> 
                                <p color="black">3. Apakah Membentuk Grup Baru</p>
                            </MDBCol>
                            <MDBCol md="4" className="ml-auto">
                                <MDBFormInline>
                                    <MDBInput
                                        gap
                                        onClick={handleIsNewGroupChange(1)}
                                        checked={isNewGroup === 1 ? true : false}
                                        label='Ya'
                                        type='radio'
                                        id='radio1'
                                        containerClass='mr-5'
                                    />
                                    <MDBInput
                                        gap
                                        onClick={handleIsNewGroupChange(0)}
                                        checked={isNewGroup === 0 ? true : false}
                                        label='Tidak'
                                        type='radio'
                                        id='radio2'
                                        containerClass='mr-5'
                                    />
                                </MDBFormInline>
                            </MDBCol>
                        </MDBRow>
                        {
                            (!isNewGroup) ? 
                            <MDBRow>
                                <MDBCol md="4" className="ml-auto"> 
                                    <p color="black">4. Input Nama Grup Tujuan</p>
                                </MDBCol>
                                <MDBCol md="4" className="ml-auto">
                                    <MDBSelect  
                                        getValue={value => handleTargetGroup(value)}
                                        search
                                        options={groupOptions}
                                    />
                                </MDBCol>
                            </MDBRow>
                            : 
                            <MDBRow>
                                <MDBCol md="4" className="ml-auto"></MDBCol>
                            </MDBRow>
                        }
                    </MDBContainer>
                }
            </MDBModalBody>
            <MDBModalFooter>
                <MDBBtn 
                    color="primary"
                    name="submit"
                    onClick={handleSubmit}>Mulai Proses</MDBBtn>
          </MDBModalFooter>
        </MDBModal>
    );
};

export default UserGroupMergeModal;