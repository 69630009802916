import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  toast
} from "mdbreact";
import BaseRequest from "../../../services/BaseRequest";
import {Form} from "../../components";
import {getGenderOptions, getStatusOptions} from "../configs/global";
import moment from "moment";
import Password from "./Password";

const thisUri = `/profile`;
const apiUri = `/me`;

const UserGroupFormPage = () => {
  const history = useHistory();
  const { id } = useParams(); // Jika id !== undefined, maka ini adalah fitur edit data
  const countryId = '105';

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    watch,
    getValues,
    trigger: triggerValidation,
    setError
  } = useForm();

  const [submitLoading, setSubmitLoading] = useState(false);
  const [educationsOption, setEducationsOption] = useState([]);
  const [jobsOption, setJobsOption] = useState([]);

  const formField = [
    {
      name: 'id',
      rules: { required: true },
      type: 'hidden'
    },
    {
      name: 'name',
      label: 'Nama',
      rules: { required: true }
    },
    {
      name: 'email',
      rules: { required: true },
      label: 'Email',
      type: 'email'
    },
    {
      name: 'phone_country_code',
      rules: { required: false },
      label: 'Kode Telepon Negara'
    },
    {
      name: 'phone',
      rules: { required: false },
      label: 'No. Telepon'
    },
    {
      name: 'birth_place',
      rules: { required: true },
      label: 'Tempat Lahir'
    },
    {
      name: 'birth_date',
      rules: { required: false },
      label: 'Tanggal Lahir',
      type: 'date',
      format: 'dd-MM-yyyy'
    },
    {
      name: 'education_id',
      type: 'select',
      rules: { required: true },
      label: 'Pendidikan',
      options: educationsOption
    },
    {
      name: 'school_name',
      rules: { required: true },
      label: 'Nama Sekolah / Universitas'
    },
    {
      name: 'job_id',
      type: 'select',
      rules: { required: true },
      label: 'Pekerjaan',
      options: jobsOption
    },
    {
      name: 'country_id',
      rules: { required: true },
      type: 'hidden',
      value: countryId
    },
    {
      name: 'gender',
      type: 'select',
      rules: { required: true },
      label: 'Gender',
      options: getGenderOptions()
    },
    {
      name: 'password',
      rules: {required: false},
      type: 'custom',
      component: <Password
        watcher={watch}
        setValue={setValue}
        name={'password'}
        errors={errors}
      />
    },
  ];

  const getEducations = async () => {
    const uri = "/educations";
    try {
      const {
        data: { data }
      } = await BaseRequest.post(uri, {
        sortBy: 'name',
        desc: true,
        limit: -1,
      });
      const allEducations = data.data;
      const optionEducations = allEducations.map((edu) => {
        return {
          text: edu.name,
          value: edu.id
        }
      });
      setEducationsOption(optionEducations);
    } catch(error) {
      let errorMessage = error.response
        ? error.response.data.message
        : error.message;
      toast.error(errorMessage, {
        closeButton: false,
        position: "bottom-right"
      });
    }
  }

  const getJobs = async () => {
    const uri = "/jobs";
    try {
      const {
        data: { data }
      } = await BaseRequest.post(uri, {
        sortBy: 'name',
        desc: true,
        limit: -1,
      });
      const allJobs = data.data;
      const optionJobs = allJobs.map((job) => {
        return {
          text: job.name,
          value: job.id
        }
      });
      setJobsOption(optionJobs);
    } catch(error) {
      let errorMessage = error.response
        ? error.response.data.message
        : error.message;
      toast.error(errorMessage, {
        closeButton: false,
        position: "bottom-right"
      });
    }
  }

  /** Load init Option */
  useEffect(() => {
    getEducations();
    getJobs();
  }, []);


  /** Register the form fields **/
  useEffect(() => {
    for (let v of formField) {
      register({ name: v.name }, v.rules || null);
    }
  }, [register]);


  /** Get Data Profile **/
  useEffect(() => {
    const getData = async () => {
      try {
        const {
          data: { data }
        } = await BaseRequest.get(`${apiUri}`);

        return data;
      } catch (error) {
        console.log(error);
      }
    };

    getData().then(data => {
      for (let key in data) {
        if(key === 'birth_date'){
          setValue(key, new Date(data[key]));
        }else{
          setValue(key, data[key] || "");
        }
      }
    });

  }, [setValue]);

  const onSubmit = async payload => {
    setSubmitLoading(true);
    payload.user_id = payload.id
    payload.birth_date = moment(payload.birth_date).format('Y-MM-DD')
    delete payload.id
    let res = await BaseRequest.put(`/profile`, payload);

    toast.success(res.data.message, {
      closeButton: false,
      position: "bottom-right"
    });

    if(payload.password){
      setValue('password','')
    }

    setTimeout(() => history.push(thisUri), 2000);
  };

  /** Define form fields **/
  const getFormFields = () => {
    return formField.map((field) => ({
      ...field,
      error: errors[field.name]
    }));
  };

  /** Input text change handler **/
  const handleInputTextChange = (e) => {
    setValue('country_id', countryId);
    const { name, value } = e.target;
    setValue(name, value);
  };

  /** Select Input change handler **/
  const handleSelectChange = (field, value) => {
    if (field && value) {
      setValue(field, value);
    }
  };

  /** Date Input change handler **/
  const handleDateChange = (field, value) => {
    if (field && value) {
      setValue(field, value);
    }
  };

  return (
    <MDBContainer>
      <MDBRow center>
        <MDBCol size="12" md="12" sm="12">
          <h3 className="text-center pt-4 pb-5 mb-2">
            Profile Saya
          </h3>

          <Form
            onSubmit={handleSubmit(onSubmit)}
            fields={getFormFields()}
            watcher={watch}
            onInputTextChange={handleInputTextChange}
            onSelectChange={handleSelectChange}
            onDateChange={handleDateChange}
            submitLoading={submitLoading}
            setSubmitLoading={setSubmitLoading}
            values={getValues()}
            backUri={thisUri}
            triggerValidation={triggerValidation}
            setError={setError}
          />
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default UserGroupFormPage;
