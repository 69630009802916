import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import BaseRequest from "../../../services/BaseRequest";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBTable,
  MDBTableBody,
  MDBBadge,
  MDBTableHead,
  MDBIcon,
  MDBSpinner
} from "mdbreact";
import moment from 'moment';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const ParticipantTranscriptPage = () => {
  const { id } = useParams(); // Jika id !== undefined, maka ini adalah fitur edit data

  const history = useHistory();
  const [user, setUser] = useState({});
  const [ipk, setIpk] = useState({});
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      const getDataById = async () => {
        try {
          setLoading(true);
          const {
            data: { data }
          } = await BaseRequest.post(`/transcripts`, {
            user_id: id
          });

          setUser(data.user);
          setIpk(data.summary_score);
          setList(data.course);
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      };
      getDataById();
    }
  }, [id]);

  return (
    <MDBContainer fluid>
      <MDBRow center>
        <MDBCol size="12" md="12" sm="12">
          <MDBBtn
            onClick={() => {
              window.history.back();
            }}
            size="md"
            color="red"
            className="pull-right"
          >
            Kembali
          </MDBBtn>
          <h3 className="mt-3">Transkrip Nilai - {user?.name}</h3>
          {loading && <MDBSpinner />}
          <MDBRow>
            <MDBCol>
              <MDBTable className="mt-2">
                <MDBTableHead>
                  <tr>
                    <th>Semester</th>
                    <th>Kode Kuliah</th>
                    <th>Mata Kuliah</th>
                    <th>SKS</th>
                    <th>Nilai</th>
                    <th>#</th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  {list.map((it,idx) => (
                    <tr key={idx}>
                      <td>{it.semester}</td>
                      <td>{it.code}</td>
                      <td>{it.title_id}</td>
                      <td>{it.credits}</td>
                      <td><mark>{it.predicate.length>0 && it.predicate}</mark></td>
                      <td>
                        <Link to={`/participants/${id}/transcript/${it.id}`}>
                          <u>Detail</u>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </MDBTableBody>
              </MDBTable>
              <p className="mt-2">
                Total SKS &rarr; <mark>{ipk?.total_credit || "?"}<br/></mark>
                IPK &rarr; <mark>{ipk?.index_cumulative_score || "?"}</mark>
              </p>
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}

export default ParticipantTranscriptPage;