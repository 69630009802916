import React, { useState, useEffect } from 'react';
import { get, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  toast
} from 'mdbreact';
import BaseRequest from '../../../../services/BaseRequest';
import {Form} from "../../../components";
import {getCoursePriceOptions, getStatusOptions, getDefaultClassTypeOptions} from "../../configs/global";
import * as FileRequest from "../../../../services/FileRequest";

const thisUri = '/kelas/program';
const apiUri = '/classes';

export const ProgramFormPage = () => {
  const history  = useHistory();
  const { id } = useParams();

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    watch,
    getValues,
    trigger: triggerValidation
  } = useForm();

  const [submitLoading, setSubmitLoading] = useState(false);
  const [statusOptions, setStatusOptions] = useState([]);
  const [classTypeOptions, setClassTypeOptions] = useState([]);
  const [coursePriceOptions, setCoursePriceOptions] = useState([]);
  const [file, setFile] = useState({});
  const [filePreview, setFilePreview] = useState('');

  const formField = [
    {
      name: 'name',
      label: 'Nama Program',
      rules: { required: true },
    },
    {
      name: 'period',
      label: 'Periode',
      rules: { required: true },
    },
    {
      name: 'course_price',
      label: 'Pilih Course Price',
      rules: { required: true },
      type: 'select',
      options: coursePriceOptions,
    },
    {
      name: 'description',
      type: 'textarea',
      rules: { required: true },
      label: 'Deskripsi'
    },
    {
      name: 'learning_goals',
      type: 'textarea',
      rules: { required: true },
      label: 'Tujuan Program'
    },
    {
      name: 'learning_needs',
      type: 'textarea',
      rules: { required: true },
      label: 'Kebutuhan Mata Kuliah'
    },
    {
      name: 'learning_outcome',
      type: 'textarea',
      label: 'Learning Outcome',
      rules: { required: true },
    },
    {
      name: 'learning_media',
      type: 'textarea',
      label: 'Media Pembelajaran',
      rules: { required: true },
    },
    {
      name: 'file_id',
      type: 'file',
      label: 'Foto',
      filePreview: filePreview,
      setter: setFile
    },
    {
      name: 'status',
      label: 'Pilih Status',
      rules: { required: true },
      type: 'select',
      options: statusOptions,
    },
    {
      name: 'type',
      label: 'Pilih Tipe Kelas',
      rules: { required: true },
      type: 'select',
      options: classTypeOptions,
    }
  ];

  /** Register the form fields **/
  useEffect(() => {
    for (let field of formField) {
      register({ name: field.name }, field.rules || null);
    }
  }, [register, formField]);

  useEffect(() => {

    if (id) {
      const findById = async () => {
      try {
        const {
          data: { data },
        } = await BaseRequest.get(`${apiUri}/${id}/edit`);
        if (data.file) {
          setFilePreview(data.file.url)
        }
        return data;
      } catch (error) {
        console.error('error', error);
      }
    };

    findById().then(data => {
      for (let key in data) {
        setValue(key, data[key] || '');
      }
      let classType = getValues("type")
      if (classType) {
        setClassTypeOptions(() => (
          getDefaultClassTypeOptions().map((v) => ({
            ...v,
            checked: (classType === v.value)
          }))
        ));
      }
    });
  }

    let status = getValues("status")
    if (status) {
      setStatusOptions(() => (
        getStatusOptions().map((v) => ({
            ...v,
            checked: (status === v.value)
          })
        ))
      );
    }else{
      if(!status) setStatusOptions(getStatusOptions())
    }

    let coursePrice = getValues("course_price")
    if (coursePrice) {
      setCoursePriceOptions(() => (
        getCoursePriceOptions().map((v) => ({
            ...v,
            checked: (coursePrice === v.value)
          })
        ))
      );
    } else {
      if(!coursePrice) setCoursePriceOptions(getCoursePriceOptions())
    }

    let classType = getValues("type")
    if (classType) {
      setClassTypeOptions(() => (
        getDefaultClassTypeOptions().map((v) => ({
          ...v,
          checked: (classType === v.value)
        }))
      ));
    } else {
      if (!classType) setClassTypeOptions(getDefaultClassTypeOptions())
    }

    
  }, [id, setValue]);

  const onSubmit = async payload => {
    try {
      setSubmitLoading(true);

      let res;
      if (file instanceof File) {
        const fd = new FormData();
        fd.append('file', file);

        const { data } = await FileRequest.upload(fd);
        payload.file_id = data.data.id;
      }

      if (id) {
        // Delete the file object if it's not exists
        if (payload.file_id === "") delete payload.file_id;
        res = await BaseRequest.put(`${apiUri}/${id}`, payload);

        if (res.data.file) {
          setFilePreview(res.data.file.url)
        }
      } else {
        res = await BaseRequest.post(`${apiUri}/save`, payload);
      }

      toast.success(res.data.message, {
        closeButton: false,
        position: 'bottom-right',
      });

      setTimeout(() => history.push(thisUri), 2000);
    } catch (error) {
      let errorMessage = error.response
        ? error.response.data.message
        : error.message;

      toast.error(errorMessage, {
        closeButton: false,
        position: 'bottom-right',
      });

      setSubmitLoading(false);
    }
  };

  /** Define form fields **/
  const getFormFields = () => {
    return formField.map((field) => ({ ...field, error: errors[field.name] }));
  };

  /** Input text change handler **/
  const handleInputTextChange = (e) => {
    const { name, value } = e.target;
    setValue(name, value);
  };

  /** Select Input change handler **/
  const handleSelectChange = (field, value) => {
    if (field && value) {
      setValue(field, value);
    }
  };

  return (
    <MDBContainer>
      <MDBRow center>
        <MDBCol size="12" md="12" sm="12">
          <h3 className="text-center pt-4 pb-5 mb-2">
            {id ? "Edit" : "Tambah"} Program
          </h3>

          <Form
            onSubmit={handleSubmit(onSubmit)}
            fields={getFormFields()}
            watcher={watch}
            onInputTextChange={handleInputTextChange}
            onSelectChange={handleSelectChange}
            submitLoading={submitLoading}
            values={getValues()}
            backUri={thisUri}
            triggerValidation={triggerValidation}
          />
        </MDBCol>
      </MDBRow>
    </MDBContainer>

    /*<MDBContainer>
      <MDBRow center>
        <MDBCol size="12" md="9" sm="12">
          <MDBCard>
            <MDBCardBody>
              <MDBCardTitle>{id ? 'Edit' : 'Tambah'} Program</MDBCardTitle>
              <hr />
              <form onSubmit={handleSubmit(onSubmit)}>
                <MDBContainer>
                  <MDBRow>
                    <MDBCol size="12">
                      <MDBInput
                        className={errors.name && 'is-invalid'}
                        name="name"
                        onChange={handleChange}
                        type="text"
                        label="Nama"
                        icon="address-card"
                        iconClass="black-text"
                        value={watch('name')}
                      >
                        {errors.name && errors.name.type === 'required' && (
                          <InputError text="Tidak boleh kosong" />
                        )}
                      </MDBInput>
                      <MDBInput
                        className={errors.period && 'is-invalid'}
                        name="period"
                        onChange={handleChange}
                        type="text"
                        label="Periode"
                        icon="address-card"
                        iconClass="black-text"
                        value={watch('period')}
                      >
                        {errors.period && errors.period.type === 'required' && (
                          <InputError text="Tidak boleh kosong" />
                        )}
                      </MDBInput>

                      <MDBSelect
                        className={errors.status && "is-invalid"}
                        name="type"
                        getValue={handleStatusChange}
                        options={listStatus}
                        selected="Pilihan Status"
                        label="Status"
                      />
                      {errors.status &&
                        errors.status.type === "required" && (
                          <span className="text-danger small">Tidak boleh kosong</span>
                        )}

                    </MDBCol>
                  </MDBRow>
                </MDBContainer>

                <div className="text-center mt-3 black-text">
                  <FormButtons submitLoading={submitLoading} />
                </div>
              </form>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>*/
  );
};

export default ProgramFormPage;
