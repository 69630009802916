import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
    MDBContainer,
    MDBBtn,
    MDBIcon,
    MDBAutocomplete,
    MDBRow,
    MDBCol,
    MDBSelect
} from "mdbreact";
import { default as CustomDataTable } from "../../../../../components/DataTable";
import BaseRequest from "services/BaseRequest";
import KuisRevisiModal from "./modal/KuisRevisionModal";


const KuisRevisionPage = () => {
    const [ loadProgressData, setLoadProgressData ] = useState(false);
    const [ listCourseStrings, setListCourseStrings ] = useState([]);
    const [ listAllCourseIds, setListAllCourseIds ] = useState([]);
    const [ listAllQuizzes, setListAllQuizzes] = useState([]);
    const [ selectedCourse, setSelectedCourse ] = useState([]);
    const [ selectedQuiz, setSelectedQuiz ] = useState();
    const [ selectedQuestionId, setSelectedQuestionId ] = useState();
    /**
     * datatable states
     */
     const [ dtTotalRow, setDtTotalRow ] = useState(1);
     const [ dtPage, setDtPage ] = useState(1);
     const [ dtPerPage, setDtPerPage ] = useState(10);
     const [ dtRowsData, setDtRowsData ] = useState([]);
     /**
      * Revision Modal States
      */
     const [ isRevisionModalOpen, setIsRevisionModalOpen ] = useState(false);

    const history = useHistory();

    useEffect(() => {
        const getAllCourses = async() => {
            const data = await BaseRequest.post('/courses', {
                sortBy: 'created_at',
                desc: 'true',
                page: 1,
                limit: 100,
            });

            let arrCourse = (data.data.data.data.map((val,idx) => (
                val.title_id
            )));

            let arrCourseOptions = (data.data.data.data.map((val,idx) => ({
                id: val.id,
                title_id : val.title_id,
            })));

            setListCourseStrings(arrCourse);
            setListAllCourseIds(arrCourseOptions);
        };

        getAllCourses();
    },[]);

    useEffect(() => {
        const getActiveQuizzes = async(course) => {
            let courseId = course[0];
            const data = await BaseRequest.post('/quizzes', {
                sortBy: 'created_at',
                desc: 'true',
                page: 1,
                limit: 50,
                filter: {
                    course_id: courseId.id
                },
            });
            let arrQuizzes = (data.data.data.data.map((val,idx) => ({
                value: val.id,
                text : val.title_id,
            })));

            setListAllQuizzes(arrQuizzes);
        };

        if(selectedCourse.length > 0)
            getActiveQuizzes(selectedCourse);
    }, [selectedCourse]);

    const handleCourseChange = (selectedCourse) => {
        const selectedCourseId = listAllCourseIds.filter(f => f.title_id == selectedCourse);
        setSelectedCourse(selectedCourseId);
    };

    const handleQuizChanges = (selectedQuizId) => {
        if(selectedQuizId.length > 0) {
            getQuizQuestions(selectedQuizId[0]).then((data) => {
                setDtRowsData(data);
            });
            setSelectedQuiz(selectedQuizId);
        }
    };


    /**
     * Datatables handler
     * 
     */
    const columns = [
        {
            name: "No",
            selector: "rowNo",
            width: "4rem",
            right: true,
        },
        {
            name: "Pertanyaan",
            selector: "question_id",
            width: "25rem"
        },
        {
            name: "Essay?",
            selector: "is_essay",
            width: "10rem"
        },
        {
            name: "Dibuat Tanggal",
            selector: "created_at",
            width: "12rem",
        },
        {
            cell:(row) => (
                <div className="btn-group-sm">
                    <MDBBtn
                        variant="contained"
                        title="Edit"
                        color="red"
                        onClick={() => openRevisionModal(row.id)}
                    >
                        <MDBIcon icon="pen-nib"/>
                    </MDBBtn>
                </div>
            )
        }
    ];

    const toggleEditModal = () => {
        setIsRevisionModalOpen(!isRevisionModalOpen);
    };

    const openRevisionModal = (selectedId) => {
        setSelectedQuestionId(selectedId);
        toggleEditModal();
    };

     /** Change Page and Row Count handler **/
    const onPageChangeHandler = (page) => {
        setDtPage(page);
        getQuizQuestions(selectedQuiz).then((data) => {
            setDtRowsData(data);
        });
    };

    const onPerRowsChangeHandler = (perPage, page) => {
        setDtPerPage(perPage);
        setDtPage(page);
        getQuizQuestions(selectedQuiz).then((data) => {
            setDtRowsData(data);
        });
    };

    const getQuizQuestions = useCallback(
        async(selectedQuizId) => {
            if(selectedQuizId === undefined) {
                return [];
            } else {
                setLoadProgressData(true);

                const {data: {data}} = await BaseRequest.post('/quiz-questions', {
                    page: dtPage,
                    limit: dtPerPage,
                    desc: true,
                    filter: {
                        quiz_id: selectedQuizId,
                    },
                });

                setDtTotalRow(data.total);

                const mappedData = data.data.map((v,i) => ({
                    ...v,
                    rowNo: data.from + i,
                }));

                setLoadProgressData(false);
                return mappedData;
            }
        }, [dtPage, dtPerPage]
    );

    return(
        <MDBContainer>
            <h3>Form Revisi Ujian Aktif</h3>
            <MDBRow>
                <MDBCol size="12">
                <MDBAutocomplete
                    name="course_id"
                    label="Pilih Mata Kuliah (Ketik Minimal 3 karakter Untuk Mencari Mata Kuliah)"
                    clear
                    clearClass="grey-text"
                    id="courses"
                    data={listCourseStrings}
                    getValue={handleCourseChange}
                />
                </MDBCol>
            </MDBRow>
            <MDBRow>
                <MDBCol size="12">
                <MDBSelect
                    name="quiz_id"
                    label="Pilih Ujian Aktif"
                    searchLabel="Pilihan Ujian Aktif/Belum Tutup"
                    options={listAllQuizzes}
                    getValue={handleQuizChanges}
                />
                </MDBCol>
            </MDBRow>
            <MDBRow>
                <MDBCol size={"12"}>
                    <CustomDataTable
                        columns={columns}
                        data={dtRowsData}
                        totalRow={dtTotalRow}
                        progressPending={loadProgressData}
                        defaultSortField={"question_id"}
                        defaultSortDir={"asc"}
                        handlePerRowsChange={onPerRowsChangeHandler}
                        handlePageChange={onPageChangeHandler}
                        paginationPerPage={10}
                        paginationRowsPerPageOptions={[10,25,50]}
                        noDataComponent={`Data tidak ditemukan`}
                    />
                </MDBCol>
            </MDBRow>

            <KuisRevisiModal 
                isOpen = {isRevisionModalOpen}
                toggleEditModal = {toggleEditModal}
                selectedQuizQuestionId = {selectedQuestionId}
            />
        </MDBContainer>
    );
};
  
export default KuisRevisionPage;