import React, {useEffect, useState} from "react";
import { useForm } from "react-hook-form";
import {Link, useHistory, useParams} from "react-router-dom";
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCol,
  MDBContainer,
  MDBInput,
  MDBRow,
  MDBSelect,
  MDBSpinner,
  toast
} from "mdbreact";

import InputError from "../../../components/InputError";
import BaseRequest from "../../../../services/BaseRequest";
import * as queryString from "query-string";
import {useLocation} from "react-router";

let thisUri = "/perkuliahan/mata-kuliah-pilihan";
const apiUri = "/course-batches";

const listStatus = () => {
    return [
      {text:'Wajib',value:'required'},
      {text:'Pilihan',value:'optional'},
      {text:'Bersyarat',value:'advanced'}
    ]
}

const formField = [
  { name: "course_id", rules: { required: true } },
  { name: "asatidz_id", rules: { required: true } },
  { name: "semester", rules: { required: true } },
  { name: "period", rules: { required: true } },
  { name: "status", rules: { required: true } }
];

const RenderButton = ({ submitLoading }) => {
  if (submitLoading) {
    return <MDBSpinner />;
  }
  return (
    <div>
      <Link to={thisUri}>
        <MDBBtn size="md" color="red" className="mx-0">
          Kembali
        </MDBBtn>
      </Link>
      <MDBBtn type="submit" color="green" size="md">
        Simpan
      </MDBBtn>
    </div>
  );
};

const MataKuliahPilihanFormPage = () => {
  const history = useHistory();
  const { id } = useParams(); // Jika id !== undefined, maka ini adalah fitur edit data
  const location = useLocation();

  const { register, control, handleSubmit, errors, setValue, watch, getValues } = useForm();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [materiPerkuliahan, setMateriPerkuliahan] = useState({});

  let [listCourse, setListCourse] = useState([]);
  let [listAsatidzs, setListAsatidzs] = useState([]);

  const getCourses = async () => {
    const uri = `/courses`
    const { data: { data } } = await BaseRequest.post(uri, {
      sortBy: 'title_id',
      desc: false
    });
    return data.data.map((v, i) => ({
      text: v.title_id,
      value: v.id
    }));
  }

  const getAsatidzs = async () => {
    const uri = `/asatidzs`
    const { data: { data } } = await BaseRequest.post(uri, {
      sortBy: 'name',
      desc: false
    });
    return data.data.map((v, i) => ({
      text: v.name,
      value: v.id
    }));
  }

  const handleCourseChange = id => {
    setValue('course_id', id[0]);
  }


  /* TODO: Wait for the valid implementation*/
  const handleAsatidzChange = id => {
    setValue('asatidz_id', id[0]);
  }

  const handleChange = e => {
    setValue(e.target.name, e.target.value);
  };

  const handleSelectStatusChange = (value) => {
    if (value) {
      setValue('status', value[0]);
    }
  };


  let course_id = watch('course_id')
  let asatidz_id = watch('asatidz_id')

  useEffect(() => {
    for (let v of formField) {
      register({ name: v.name }, v.rules || null);
    }
  }, [register]);

  useEffect(() => {
    if (id) {
      const getDataById = async () => {
        try {
          const {
            data: { data }
          } = await BaseRequest.get(`${apiUri}/${id}/edit`);

          setMateriPerkuliahan(data);

          for (let key in data) {
            setValue(key, data[key] || "");
          }

        } catch (error) {
          console.log(error);
        }
      };
      getDataById();
    }
  }, [id, setValue]);

  useEffect(() => {
    if(course_id) {
      getCourses()
      .then(res => {
        setListCourse(()=>(
          res.map((v, i) => ({
            ...v,
            checked: (course_id === v.value)
          }))
        ))
      })
    } else {
      if(!id){
        getCourses()
        .then(res => {
          setListCourse(res)
        })
      }
    }
  },[course_id, id]);

  useEffect(() => {
    if(asatidz_id){
      getAsatidzs()
        .then(res => {
          setListAsatidzs(()=>(
            res.map((v, i) => ({
              ...v,
              checked: (asatidz_id === v.value)
            }))
          ))
        })
    }else{
      getAsatidzs()
        .then(res => {
            setListAsatidzs(res)
        })
    }
  },[asatidz_id, id]);

  useEffect(() => {
    // Check where the edit form comes from
    let parsed = queryString.parse(location.search);
    if (parsed.from) {
      switch (parsed.from) {
        case 'detail-page':
          thisUri = `/perkuliahan/mata-kuliah-pilihan/${parsed.id}`;
          break;
        default:
          break;
      }
    }
  }, [location])

  const onSubmit = async payload => {
    try {
      setSubmitLoading(true);
      let res = null;
      if (id) {
        res = await BaseRequest.put(`${apiUri}/${id}`, payload);
      } else {
        res = await BaseRequest.post(`${apiUri}/save`, payload);
      }
      toast.success(res.data.message, {
        closeButton: false,
        position: "bottom-right"
      });
      setTimeout(() => history.push(thisUri), 2000);
    } catch (error) {
      let errorMessage = error.response
        ? error.response.data.message
        : error.message;
      toast.error(errorMessage, {
        closeButton: false,
        position: "bottom-right"
      });
      setSubmitLoading(false);
    }
  };

  return (
    <MDBContainer>
      <MDBRow center>
        <MDBCol size="12" lg="9" md="12" sm="12">
          <MDBCard>
            <MDBCardBody>
              <MDBCardTitle>
                {id ? "Edit" : "Tambah"} Mata Kuliah Pilihan
              </MDBCardTitle>
              <hr />
              <form onSubmit={handleSubmit(onSubmit)}>
                <MDBContainer>
                  <MDBRow>
                    <MDBCol size="12">

                      <MDBSelect
                        className={errors.course_id && "is-invalid"}
                        name="course_id"
                        getValue={handleCourseChange}
                        options={listCourse}
                        selected="Pilihan Mata Kuliah"
                        label="Mata Kuliah"
                      />
                      {errors.course_id &&
                        errors.course_id.type === "required" && (
                          <span className="text-danger small">Tidak boleh kosong</span>
                        )}


                      <MDBSelect
                        className={errors.asatidz_id && "is-invalid"}
                        name="asatidz_id"
                        getValue={handleAsatidzChange}
                        options={listAsatidzs}
                        selected="Pilihan Asatidz"
                        label="Asatidz"
                      />
                      {errors.asatidz_id &&
                        errors.asatidz_id.type === "required" && (
                          <span className="text-danger small">Tidak boleh kosong</span>
                        )}

                      <MDBInput
                        className={errors.semester && "is-invalid"}
                        name="semester"
                        onChange={handleChange}
                        type="text"
                        label="Semester"
                        value={watch("semester")}
                      >
                        {errors.semester &&
                        errors.semester.type === "required" && (
                          <InputError text="Tidak boleh kosong" />
                        )}
                      </MDBInput>

                      <MDBInput
                        className={errors.period && "is-invalid"}
                        name="period"
                        onChange={handleChange}
                        type="text"
                        label="Periode"
                        value={watch("period")}
                      >
                        {errors.period &&
                        errors.period.type === "required" && (
                          <InputError text="Tidak boleh kosong" />
                        )}
                      </MDBInput>

                      <MDBSelect
                        className={errors.status && "is-invalid"}
                        name="status"
                        options={listStatus()}
                        getValue={handleSelectStatusChange}
                        selected="Status Mata Kuliah"
                        label="Status Mata Kuliah"
                      />
                      {errors.status &&
                        errors.status.type === "required" && (
                          <span className="text-danger small">Tidak boleh kosong</span>
                        )}

                    </MDBCol>
                  </MDBRow>
                </MDBContainer>

                <div className="text-center mt-3 black-text">
                  <RenderButton submitLoading={submitLoading} />
                </div>
              </form>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default MataKuliahPilihanFormPage;
