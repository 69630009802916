import React from 'react'

const CustomEventSpan = (props) => {
  const { data } = props

  return (
    <span>{data.title}</span>
  )
}

export default CustomEventSpan