import React, {useState, useEffect} from 'react';
import BaseRequest from '../../../../../../services/BaseRequest';
import { useHistory, useParams } from 'react-router-dom';
import {
  MDBContainer,
  MDBBtn,
  MDBInput,
  toast,
  MDBCol,
  MDBRow,
  MDBSpinner
} from "mdbreact";
const thisUri = '/kurikulum/ujian-evaluasi/kuis';

export const KuisResetPage = () => {
  const history = useHistory();
  const { quizId, coursePartId, classId } = useParams();

  const [ participant, setParticipant ] = useState();
  const [ participantId, setParticipantId ] = useState();
  const [ quizData, setQuizData ] = useState();
  const [ resetLoading, setResetLoading ] = useState(false);
  const [ participantFound, setParticipantFound ] = useState(false);
  const [ logData, setLogData ] = useState({});
  const [ resetAll, setResetAll ] = useState(false);
  const [ loadProgressData, setLoadProgressData ] = useState(false);

  useEffect(()=>{
    const loadQuizInfo = async() => {
      const quiz = await BaseRequest.post('/quizzes', {
        filter : {
          id: quizId
        }
      })
      
      if(quiz.data.code == 200) {
        setQuizData(quiz.data.data.data[0].title_id)
      }
    }

    loadQuizInfo().catch(console.error)
  },[])

  useEffect(()=>{
    const loadQuizLogInfo = async() => {
      const participantLog = await BaseRequest.post('/quiz-requests/get-participant-log', {
        user_id: participantId,
        quiz_id: quizId
      })

      if(participantLog.data.code === 200) {
        let QuizLog = participantLog.data.data

        setLogData({
          ...QuizLog
        })
      }
    }

    loadQuizLogInfo().catch(console.error)
  },[participantId])

  const handleResetForParticipant = async() => {
    try {
      setResetLoading(true);

      const response = await BaseRequest.post(`/quiz-requests/reset`,{
        quiz_id: quizId,
        user_id: participantId,
        course_part_id: coursePartId
      });

      if(response.status === 200){
          const data = response.data
          if(data.code === 200) {
            setParticipantFound(false)

            return toast.success('Reset Ujian Berhasil', {
              closeButton: false,
              position: "bottom-right"
            });
          }
      }

      toast.error('Reset Kuis gagal', {
        closeButton: false,
        position: "bottom-right"
      });
    } catch (error) {
      console.error(error);
    } finally {
      setResetLoading(false);
    }
  }

  const handleReset = async () => {
    try {
      setResetLoading(true);

      const response = await BaseRequest.post(`/quiz-requests/reset-class`,{
        quiz_id: quizId,
        course_part_id: coursePartId,
        class_id: 0
      });

      if(response.status === 200){
          const data = response.data
          if(data.code === 200){

            return toast.success('Reset Ujian Untuk Semua Peserta Berhasil', {
              closeButton: false,
              position: "bottom-right"
            });
          }
      }

      toast.error('Reset Kuis gagal', {
        closeButton: false,
        position: "bottom-right"
      });

    } catch (err) {
      console.error(err);
    } finally {
      setResetLoading(false);
    }
  };

  const handleStatusChange = (data) => {
    setResetAll(data)
  }

  const handleParticipantChange = (data) => {
    setParticipant(data)
  }

  const handleSearchParticipant = async () => {
    try{
      setLoadProgressData(true)

      const participantDetail = await BaseRequest.post('/users' , {
        filter: {
          nip: participant
        }
      })

      if(participantDetail.data.data.data.length > 0)
      {
        setParticipantId(participantDetail.data.data.data[0].id)
        setParticipantFound(true)
      } else {
        setParticipantFound(false)
      }
    } catch(err) {
      console.log(err)
    } finally {
      setLoadProgressData(false)
    }
  }

  return (
    <MDBContainer>
      <h3 className="text-center pt-4">
        Reset Kuis {quizData}
      </h3>
        <MDBRow>
          <MDBCol></MDBCol>
          <MDBCol size='6' align='center'>
            <MDBInput
              type="checkbox"
              id="is_resetall"
              label="Reset Kuis Untuk Semua Peserta ?"
              onChange={e => handleStatusChange(e.target.checked)}
              checked={resetAll}
            />
          </MDBCol>
          <MDBCol></MDBCol>
        </MDBRow>
        { !resetAll &&
        <MDBRow>
          <MDBCol><hr/></MDBCol>
          <MDBCol size='6'><p align='center'>Atau :</p></MDBCol>
          <MDBCol><hr/></MDBCol>
        </MDBRow>
        }
        
        { !resetAll &&
        <MDBRow>
          <MDBCol></MDBCol>
          <MDBCol size='6'>
            <MDBInput 
              type="text" 
              label="Cari Berdasarkan NIP Peserta" 
              onBlur={v => handleParticipantChange(v.target.value)}
            >
            </MDBInput>
          </MDBCol>
          <MDBCol size='4'>
            <MDBBtn outline color='primary' size='lg' className='mr-auto' onClick={ handleSearchParticipant }>Cari</MDBBtn>
          </MDBCol>
        </MDBRow>
        }
        <MDBRow>&nbsp;</MDBRow>

        { loadProgressData &&
        <MDBRow>
          <MDBCol></MDBCol>
          <MDBCol align='center'><MDBSpinner label='Harap Tunggu, Sedang Memuat Data'/></MDBCol>
          <MDBCol></MDBCol>
        </MDBRow>
        }

        { resetLoading &&
        <MDBRow>
          <MDBCol></MDBCol>
          <MDBCol align='center'><MDBSpinner/> Harap Tunggu, </MDBCol>
          <MDBCol></MDBCol>
        </MDBRow>
        }

        { participantFound && logData.users ?
        <MDBRow>
          <MDBCol></MDBCol>
          <MDBCol align='center' size='6'>
            <MDBInput type='text' disabled value={logData.users.nip} label="NIP"/>
            <MDBInput type='text' disabled value={logData.users.name} label="Nama Peserta"/>
            <MDBInput type='text' disabled value={logData.request_date} label="Tangal Pengerjaan Ujian"/>
            <MDBBtn className="float-left mt-3" color="red" size="sm" onClick={handleResetForParticipant} align='center'>
              Reset Kuis Untuk Peserta Ini
            </MDBBtn>
          </MDBCol>
          <MDBCol></MDBCol>
        </MDBRow>
        : 
        <MDBRow>
        </MDBRow>
        }

        { participantFound && logData.users == null &&
        <MDBRow>
          <MDBCol></MDBCol>
          <MDBCol align='center'>Afwan, Data Tidak Ditemukan Atau Peserta Belum Melakukan Pengerjaan Ujian</MDBCol>
          <MDBCol></MDBCol>
        </MDBRow>
        }

      <MDBBtn className="float-left mt-3" color="blue" size="md" onClick={() => history.push(`${thisUri}`)}>
        Cancel
      </MDBBtn>

      { resetAll && 
        <MDBRow>
          <MDBBtn className="float-left mt-3" color="red" size="md" onClick={handleReset}>
            Reset Kuis Untuk Semua Peserta
          </MDBBtn>
        </MDBRow>
      }

    </MDBContainer>
  );
};

export default KuisResetPage;
