import React, { useEffect, useState } from "react";
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCol,
  MDBContainer,
  MDBRow,
  toast,
} from "mdbreact";
import { useHistory, useParams } from "react-router";
import CustomDataTable from "../../components/DataTable";
import BaseRequest from "../../../services/BaseRequest";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import GroupingForm from "./components/grouping/GroupingForm";

const PesertaGroupPage = () => {
  const history = useHistory();
  const { groupId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [kelas, setKelas] = useState("");
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [jmlPesertaAktif, setJmlPesertaAktif] = useState(0);

  const defaultGroupingPayload = {
    temp_group_id: groupId,
    angkatan: null,
    tahun_angkatan: null,
    kode_kelas: null,
    user_per_group: null,
  };
  const [groupingPayload, setGroupingPayload] = useState(
    defaultGroupingPayload
  );

  const onGrouping = async ({ angkatan, tahun_angkatan, kode_kelas, user_per_group }) => {
    setGroupingPayload({
      ...groupingPayload,
      angkatan,
      tahun_angkatan,
      kode_kelas,
      user_per_group
    });
    setShowConfirmDialog(true);
  };

  const doGrouping = async () => {
    try {
      setSubmitLoading(true);

      /** HTTP request */
      const { data } = await BaseRequest.post("/pendaftaran/generate-group", groupingPayload);

      /** Go to GroupingPesertaPage */
      history.push("/pmb/grouping");

      toast.success(data.message, {
        closeButton: false,
        position: "bottom-right",
      });
    } catch (error) {
      const errorMessage = error.response
        ? error.response.data.message
        : error.message;
      toast.error(errorMessage, {
        closeButton: false,
        position: "bottom-right",
      });
    } finally {
      setSubmitLoading(false);
      setShowConfirmDialog(false);
      setGroupingPayload(defaultGroupingPayload);
    }
  };

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        const { data } = await BaseRequest.get(
          `/groups/${groupId}/edit?with=participants`
        );
        setKelas(data.data.classes.name);

        setData(
          data.data.participants.map((v, i) => ({
            no: i + 1,
            ...v,
          }))
        );
        setJmlPesertaAktif(
          data.data.participants.filter((v) => v.status === "active").length
        );
      } catch (error) {
        const errorMessage = error.response
          ? error.response.data.message
          : error.message;
        toast.error(errorMessage, {
          closeButton: false,
          position: "bottom-right",
        });
      } finally {
        setIsLoading(false);
      }
    };

    getData();
  }, [groupId]);

  return (
    <MDBContainer fluid>
      <MDBBtn
        size="sm"
        color="red"
        onClick={() => history.push("/pmb/grouping")}
      >
        Back
      </MDBBtn>

      <MDBRow className="mt-2">
        <MDBCol xs="12" sm="6">
          <GroupingForm
            onGrouping={onGrouping}
            jmlPesertaAktif={jmlPesertaAktif}
          />
        </MDBCol>
        <MDBCol xs="12" sm="6">
          <MDBCard>
            <MDBCardBody>
              <MDBCardTitle>
                Peserta {kelas} yang Belum di Grouping
              </MDBCardTitle>
              <CustomDataTable
                progressPending={isLoading}
                serverSide={false}
                columns={columns}
                data={data}
                defaultSortField="no"
              />
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
      <ConfirmationDialog
        isOpen={showConfirmDialog}
        title="Konfirmasi"
        description="Anda akan grouping peserta group ini. Peserta akan di group jika mereka sudah mempunyai NIM. Jika belum, akan di skip. Lanjutkan ?"
        isLoading={submitLoading}
        backdrop={!submitLoading}
        toggleFunc={() => {
          setShowConfirmDialog(!showConfirmDialog);
          setGroupingPayload(defaultGroupingPayload);
        }}
        onYesClick={() => doGrouping()}
      />
    </MDBContainer>
  );
};

/** Datatable column definition **/
const columns = [
  {
    name: "No",
    selector: "no",
    width: "4rem",
    right: true,
    sortable: true,
  },
  {
    name: "Nama Peserta",
    selector: "name",
    sortable: true,
  },
  {
    name: "NIP",
    selector: "nip",
    sortable: true,
  },
];

export default PesertaGroupPage;
