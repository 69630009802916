import React, {useCallback, useEffect, useMemo, useState} from 'react';
import BaseRequest from '../../../../services/BaseRequest';
import {useHistory, useParams} from 'react-router-dom';
import {
  MDBContainer,
  MDBBtn,
  toast,
  MDBRow,
  MDBCol
} from "mdbreact";
import {default as CustomDataTable} from "../../../components/DataTable";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import DeleteModal from "../../../modals/DeleteModal";

const thisUri = '/kelas';
const apiUri = '/participants';

export const ProgramParticipantsPage = () => {
  const history = useHistory();
  const { id } = useParams();

  const [ classData, setClassData ] = useState();
  const [selectedParticipants, setSelectedParticipants] = useState([]);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  /** Datatable variables **/
  const [ dtRowsData, setDtRowsData ] = useState([]);
  const [ dtSortBy, setDtSortBy ] = useState('name');
  const [ dtSortDirection, setDtSortDirection ] = useState('asc');
  const [ dtTotalRow, setDtTotalRow ] = useState(1);
  const [ dtSearchMeta, setDtSearchMeta ] = useState({
    filter: {},
    field_search: 'name',
    search: ''
  });
  const [ loadProgressData, setLoadProgressData ] = useState(true);

  /** Datatable column definition **/
  const columns = [
    {
      name: 'No',
      selector: 'rowNo',
      width: "4rem",
      right: true
    },
    {
      name: "Nama",
      selector: "name",
      sortable: true,
      style: {
        whiteSpace: 'none',
      }
    },
    {
      name: "Gender",
      selector: "gender",
      sortable: true,
      width: "8rem"
    },
    {
      cell: (row) => row.groups && row.groups.length > 0
        ? <ul className="list-on-datatable">
          {
            row.groups.map((group, index) =>
              <li key={index}>
                <a href={`/kelas/groups/${group.id}/edit`} target="_blank" rel="noopener noreferrer">{group.name_id}</a>
              </li>
            )
          }
        </ul>
        : '-',
      name: "Group",
      selector: "groups",
      sortable: true,
    },
    {
      name: "No. Telepon",
      selector: "phone_no",
      width: "12rem"
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
      width: "8rem"
    },
    {
      name: "Dibuat Tanggal",
      selector: "created_at",
      sortable: true,
      width: "8rem"
    },
    {
      name: "Disunting Tanggal",
      selector: "updated_at",
      sortable: true,
      width: "8rem"
    }
  ];

  /** Datatable get data **/
  const getClassParticipantsData = useCallback(async (page = 1, perPage = 10) => {
    let uri = `${apiUri}/${id}/by-class?with=groups`;

    try {
      const {
        data: { data }
      } = await BaseRequest.post(uri, {
        sortBy: dtSortBy,
        desc: dtSortDirection === 'desc',
        page: page,
        limit: perPage,
        ...dtSearchMeta
      });

      setDtTotalRow(data.total);

      // Map the questions data
      const mappedData = data.data.map((v, i) => ({
        rowNo: data.from + i,
        id: v.id,
        name: v.name,
        gender: v.gender,
        groups: v.groups,
        phone_no: `${v.phone_country_code} ${v.phone}`,
        status: v.status,
        created_at: moment(v.created_at).format("DD/MM/YYYY"),
        updated_at: moment(v.updated_at).format("DD/MM/YYYY"),
      }));

      return { rows: mappedData }
    } catch (error) {
      let errorMessage = error.response
        ? error.response.data.message
        : error.message;
      toast.error(errorMessage, {
        closeButton: false,
        position: "bottom-right"
      });
    }

    return { rows: [] }
  }, [dtSearchMeta, dtSortBy, dtSortDirection]);

  const reloadDataFromServer = useCallback((page = 1, perPage = 10) => {
    getClassParticipantsData(page, perPage)
      .then((res) => {
        const { rows } = res;

        setDtRowsData(rows);
      })
      .finally(() => {
        setLoadProgressData(false);
      });
  }, [getClassParticipantsData]);

  useEffect(() => {
    const getClassById = async (id) => {
      const {
        data: { data }
      } = await BaseRequest.get(`/classes/${id}/edit`);

      return data;
    }

    reloadDataFromServer();
    getClassById(id).then(classData => {
      setClassData(classData);
    });

  }, [reloadDataFromServer, id]);

  /** Datatable sorting **/
  const onSortHandler = (column, sortDirection) => {
    setDtSortBy(column.selector);
    setDtSortDirection(sortDirection);

    reloadDataFromServer();
  };

  /** Datatable Filter **/
  const subHeaderComponentMemo = useMemo(() => {
    const handleFilter = (keyword) => {
      setLoadProgressData(true);

      let newSearchMeta = dtSearchMeta;
      newSearchMeta.search = keyword;

      setDtSearchMeta(newSearchMeta);

      getClassParticipantsData(newSearchMeta)
        .then((res) => {
          const { rows } = res;

          setDtRowsData(rows);
          setLoadProgressData(false);
        })
    };

    return (
      <MDBRow className={"w-100"}>
        <MDBCol size={"12"} md={"4"} sm={"12"}>
          <TextField
            label="Search"
            onChange={(e) => handleFilter(e.target.value)}
            className={"w-100"}
            name="search"
          />
        </MDBCol>
      </MDBRow>
    )
  }, [dtSearchMeta, getClassParticipantsData]);

  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  }

  const handleDelete = async () => {
    try {
      setDeleteLoading(true);

      await BaseRequest.post(`/classes/delete-mass-participant`, {
        classes_id: id,
        users: selectedParticipants.map(participant => {
          return participant.id
        })
      });

      reloadDataFromServer();
    } catch (err) {
      console.error(err);
    } finally {
      toggleDeleteModal();
      setDeleteLoading(false);
    }
  };

  const handleRowSelected = React.useCallback(state => {
    setSelectedParticipants(state.selectedRows);
  }, []);

  const contextActions = React.useMemo(() => {
    return <MDBBtn type="button" color="red" size="sm" onClick={toggleDeleteModal}>
      Delete
    </MDBBtn>
  }, [selectedParticipants]);

  return (
    <MDBContainer fluid>
      <MDBRow>
        <h3>Daftar Mahasiswa {classData && `${classData.name} ~ ${classData.period || ''}`}</h3>
      </MDBRow>

      <CustomDataTable
        columns={columns}
        data={dtRowsData}
        defaultSortField={'name'}
        totalRow={dtTotalRow}
        onSortHandler={onSortHandler}
        subHeaderComponent={subHeaderComponentMemo}
        progressPending={loadProgressData}
        selectableRows={true}
        onSelectedRowsChange={handleRowSelected}
        contextActions={contextActions}
      />

      <MDBRow>
        <MDBBtn color="red" size="sm" className="float-left" onClick={() => history.push(thisUri)}>
          Kembali
        </MDBBtn>
      </MDBRow>

      <DeleteModal
        isOpen={showDeleteModal}
        toggle={toggleDeleteModal}
        handler={handleDelete}
        loadingIndicator={deleteLoading}
        message={`Anda akan menghapus ${selectedParticipants.length} participant. Lanjutkan ?`}
      />

    </MDBContainer>
  );
};

export default ProgramParticipantsPage;
