/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useState} from "react";
import BaseRequest from "../../../services/BaseRequest";
import { useHistory, useParams } from "react-router-dom";
import {
  MDBContainer,
  MDBBtn,
  MDBIcon,
  toast,
  MDBRow
} from "mdbreact";
import {default as CustomDataTable} from "../../components/DataTable";
import QuizDetailModal from "./QuizDetailModal";

const apiUri = "/quiz-archives/status";

const QuizArchivePage = () => {
  const { quiz_id, class_id } = useParams();
  const history = useHistory();

  /** Datatable variables **/
  const [ dtRowsData, setDtRowsData ] = useState([]);
  const [ dtSortBy, setDtSortBy ] = useState('created_at');
  const [ dtSortDirection, setDtSortDirection ] = useState('desc');
  const [ dtTotalRow, setDtTotalRow ] = useState(1);
  const [ dtPage, setDtPage ] = useState(1);
  const [ dtPerPage, setDtPerPage ] = useState(20);
  const [ dtSearchMeta, setDtSearchMeta ] = useState({
    filter: {},
    field_search: 'name, nip',
    search: ''
  });
  const [ loadProgressData, setLoadProgressData ] = useState(true);
  const [ showDetailModal, setShowDetailModal ] = useState(false);
  const [ selectedQuiz, setSelectedQuiz ] = useState(null);
  const [ selectedUser, setSelectedUser ] = useState(null);

  /** Datatable column definition **/
  const columns = [
    {
      name: 'No',
      selector: 'rowNo',
      width: "4rem",
      right: true
    },
    {
      name: "NIP",
      selector: "nip",
      width: "12rem",
    },
    {
      name: "Nama",
      selector: "name",
      width: "25rem",
    },
    {
      name: "Nilai",
      selector: "score",
      width: "6rem",
    },
    {
      name: "Waktu",
      selector: "time",
      width: "8rem"
    },
    {
      name: "Status Pengerjaan",
      selector: "Status_Pengerjaan",
      width: "18rem"
    },
    {
      cell: (row) =>
        <div className="btn-group-sm">
          <MDBBtn variant="contained" color="blue" onClick={() => toggleDetailModal(row, quiz_id)}>
            <MDBIcon icon="percent" />
          </MDBBtn>
        </div>,
      button: true,
      width: "8rem"
    }
  ];

  /** Datatable get data **/
  const getQuizData = useCallback(async (page = 1, perPage = 20) => {
    let uri = `${apiUri}`;

    try {
      setLoadProgressData(true);

      const {
        data: { data }
      } = await BaseRequest.post(uri, {
        quiz_id,
        class_id,
        sortBy: dtSortBy,
        desc: dtSortDirection === 'desc',
        page: page,
        limit: perPage,
        ...dtSearchMeta
      });

      const participant = data.participant_status;
      setDtTotalRow(participant.total);

      // Map the questions data
      const mappedData = participant.data.map((v, i) => ({
        ...v,
        rowNo: participant.from + i,
        id: v.id,
        nip: v.nip,
        name: v.name,
        score: v.score,
        time: v.time,
        Status_Pengerjaan: v.Status_Pengerjaan,
      }));

      return { rows: mappedData }
    } catch (error) {
      let errorMessage = error.response
        ? error.response.data.message
        : error.message;
      toast.error(errorMessage, {
        closeButton: false,
        position: "bottom-right"
      });
    }

    return { rows: [] }
  }, [dtSearchMeta, dtSortBy, dtSortDirection]);

  const reloadQuizDataFromServer = useCallback((page = 1, perPage = 20) => {
    getQuizData(page, perPage)
      .then((res) => {
        const { rows } = res;

        setDtRowsData(rows);
      })
      .finally(() => {
        setLoadProgressData(false);
      });
  }, [getQuizData]);

  useEffect(() => {
    reloadQuizDataFromServer(dtPage, dtPerPage);
  }, [dtPage, dtPerPage]);

  /** Datatable sorting **/
  const onSortHandler = (column, sortDirection) => {
    setDtSortBy(column.selector);
    setDtSortDirection(sortDirection);

    reloadQuizDataFromServer(dtPage, dtPerPage);
  };

  const toggleDetailModal = (user, quizId) => {
    setSelectedUser(user);
    setSelectedQuiz(quizId);
    setShowDetailModal(!showDetailModal);
  }

  /** Datatable Change Page handler **/
  const onPageChangeHandler = (page) => {
    setLoadProgressData(true);
    reloadQuizDataFromServer(page);
  }

  const onPerRowsChangeHandler = (perPage, page) => {
    setLoadProgressData(true);
    reloadQuizDataFromServer(page, perPage);
  }

  return (
    <MDBContainer fluid>
      <MDBRow>
        <MDBBtn color="red" size="sm" className="float-left" onClick={() => history.push(`/kurikulum/ujian-evaluasi/kuis/${quiz_id}/archive`)}>
          Kembali
        </MDBBtn>
      </MDBRow>
      <br />
      <h3>Kuis Archive</h3>
      <div>
        <CustomDataTable
          columns={columns}
          data={dtRowsData}
          totalRow={dtTotalRow}
          onSortHandler={onSortHandler}
          // subHeaderComponent={subHeaderComponentMemo}
          progressPending={loadProgressData}
          handlePerRowsChange={onPerRowsChangeHandler}
          handlePageChange={onPageChangeHandler}
        />
      </div>

      <QuizDetailModal
        isOpen={showDetailModal}
        toggle={toggleDetailModal}
        quizId={selectedQuiz}
        user={selectedUser}
      />
    </MDBContainer>
  );
};

export default QuizArchivePage;
