import React, {useEffect, useMemo, useState} from "react";
import {
  MDBBtn,
  MDBCol,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBRow, MDBSelect,
  MDBSimpleChart,
  MDBContainer,
  toast
} from "mdbreact";
import {default as CustomDataTable} from "../../../../components/DataTable";
import BaseRequest from "../../../../../services/BaseRequest";

const StatisticModal = (props) => {
  const {
      isOpen,
      toggle,
      quiz
    } = props;
  
  const [totalNotTaken, setTotalNotTaken] = useState(0);
  const [totalTaken, setTotalTaken] = useState(0);
  const [total, setTotal] = useState(0);
  
  useEffect(()=>{
    if (isOpen && quiz) {
      getStatistic().then(res => {
        setTotalNotTaken(res.notTaken);
        setTotalTaken(res.taken);
        setTotal(res.notTaken + res.taken);
      });  
    }
  },[quiz, isOpen]);

  const getStatistic = async (page = 1, perPage = 5) => {
    try {
      let [notTakenResp, takenResp] = await Promise.all([
        BaseRequest.post('not-taken-quiz', {
          page: page,
          limit: perPage,
          quiz_id: quiz.id
        }),
        BaseRequest.post('taken-quiz', {
          page: page,
          limit: perPage,
          quiz_id: quiz.id
        })
      ]);
      // console.log("notTaken", notTakenResp.data.data);
      // console.log("taken", takenResp.data.data);

      return { 
        notTaken: notTakenResp.data.data.total, 
        taken: takenResp.data.data.total, 
      }
    } catch (error) {
      let errorMessage = error.response
        ? error.response.data.message
        : error.message;
      toast.error(errorMessage, {
        closeButton: false,
        position: "bottom-right"
      });
    }
    return { rows: [] }
  };  

  if (!quiz) return null;
  
  return (
      <MDBModal size="md" isOpen={isOpen} toggle={toggle} backdrop={false}>
        <MDBModalHeader toggle={toggle}>Kuis: {quiz.title_id}</MDBModalHeader>
        <MDBModalBody>
          <MDBContainer>
                <div style={{fontSize:14}}>
                  {quiz.course_title_id}<br/>
                  {quiz.course_part_title_id}<br/>
                  {quiz.category}
                </div>
            <hr/>
            <MDBRow className='text-center'>
              <MDBCol sm='6'>
                <h5>Sudah Menjawab</h5>
                <MDBSimpleChart
                  width={150}
                  height={150}
                  strokeWidth={75}
                  percent={total>0? Number(totalTaken/total * 100.0).toFixed() : 0}
                  strokeColor='#4FB64E'
                  labelFontWeight='300'
                  labelColor='#000'
                />
                <p>{totalTaken} orang <a href={`/kurikulum/ujian-evaluasi/kuis/${quiz.id}/list/sudah-menjawab`} target="_blank" rel="noopener noreferrer">(list)</a></p>
              </MDBCol>
              <MDBCol sm='6'>
                <h5>Belum Menjawab</h5>
                <MDBSimpleChart
                  width={150}
                  height={150}
                  strokeWidth={75}
                  percent={total>0? Number(totalNotTaken/total * 100.0).toFixed() : 0}
                  strokeColor='#F7CA18'
                  labelFontWeight='300'
                  labelColor='#000'
                />
                <p>{totalNotTaken} orang <a href={`/kurikulum/ujian-evaluasi/kuis/${quiz.id}/list/belum-menjawab`} target="_blank" rel="noopener noreferrer">(list)</a></p>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBModalBody>
      </MDBModal>
    );    
}

export default StatisticModal;
