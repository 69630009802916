import React, {useCallback, useEffect, useMemo, useState, useContext} from "react";
import ReactDOM from "react-dom";
import moment from "moment";
import BaseRequest from "../../../../services/BaseRequest";
import {useHistory} from "react-router-dom";
import {
  MDBContainer,
  MDBBtn,
  MDBIcon,
  toast,
  MDBRow,
  MDBCol,
  MDBBtnFixed, MDBSelect, MDBInput
} from "mdbreact";
import {default as CustomDataTable} from "../../../components/DataTable";
import DeleteModal from "../../../modals/DeleteModal";
import { AccessContext } from '../../../AccessContext';

const thisUri = "/perkuliahan/materi";
const apiUri = "/course-materials";

const MateriPerkuliahanPage = () => {
  const history = useHistory();
  const menu = 'materi-perkuliahan';
  const access = useContext(AccessContext);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedDeleteItem, setSelectedDeleteItem] = useState({});
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [courseOptions, setCourseOptions] = useState([]);
  const [coursePartOptions, setCoursePartOptions] = useState([]);

  /** Datatable variables **/
  const [ dtRowsData, setDtRowsData ] = useState([]);
  const [ dtSortBy, setDtSortBy ] = useState('created_at');
  const [ dtSortDirection, setDtSortDirection ] = useState('desc');
  const [ dtTotalRow, setDtTotalRow ] = useState(1);
  const [ dtSearchMeta, setDtSearchMeta ] = useState({
    filter: {},
    field_search: 'title_id',
    search: ''
  });
  const [ dtPage, setDtPage ] = useState(1);
  const [ dtPerRow, setDtPerRow ] = useState(10);
  const [ loadProgressData, setLoadProgressData ] = useState(true);

  /** Datatable column definition **/
  const columns = [
    {
      name: 'No',
      selector: 'rowNo',
      width: "4rem",
      right: true
    },
    {
      cell: (row) => row.course_id
        ? (access.isAccessible('mata-kuliah', 'edit')? <a href={`/perkuliahan/mata-kuliah/${row.course_id}/edit`} target="_blank" rel="noopener noreferrer">{row.course_title_id}</a> : row.course_title_id )
        : '-',
      name: "Mata Kuliah",
      selector: "course_title_id",
      sortable: true
    },
    {
      cell: (row) => row.course_part_id
        ? (access.isAccessible('pertemuan', 'edit')? <a href={`/perkuliahan/pertemuan/${row.course_part_id}/edit`} target="_blank" rel="noopener noreferrer">{row.course_part_title_id}</a> : row.course_part_title_id)
        : '-',
      name: "Pertemuan",
      selector: "course_part_title_id",
      sortable: true
    },
    {
      name: "Materi",
      selector: "title_id",
      sortable: true
    },
    {
      name: "Pemateri",
      selector: "author",
      sortable: true
    },
    {
      name: "Dibuat Tanggal",
      selector: "created_at",
      sortable: true,
      width: "8rem"
    },
    {
      name: "Disunting Tanggal",
      selector: "updated_at",
      sortable: true,
      width: "8rem"
    },
    {
      cell: (row) =>
        <div className="btn-group-sm">
          { access.isAccessible(menu, 'edit') &&
            <MDBBtn variant="contained" color="yellow" onClick={() => history.push(`${thisUri}/${row.id}/edit`)} >
              <MDBIcon icon="pen-nib" />
            </MDBBtn>
          }
          { access.isAccessible(menu, 'delete') &&
            <MDBBtn variant="contained" color="red" onClick={() => toggleDeleteModal(row.id)}>
              <MDBIcon icon="trash" />
            </MDBBtn>
          }
        </div>,
      button: true
    }
  ];

  /** Datatable get data **/
  const getDataCourseMaterials = useCallback(async (page = 1, perPage = 10) => {
    let uri = `${apiUri}`;

    try {
      const {
        data: { data }
      } = await BaseRequest.post(uri, {
        sortBy: dtSortBy,
        desc: dtSortDirection === 'desc',
        page: page,
        limit: perPage,
        with: 'course,coursepart',
        ...dtSearchMeta
      });

      setDtTotalRow(data.total);

      // Map the questions data
      const mappedData = data.data.map((v, i) => ({
        rowNo: data.from + i,
        id: v.id,
        course_id: (v.course) ? v.course.id : '',
        course_title_id: (v.course) ? v.course.title_id : '',
        course_part_id: (v.coursepart) ? v.coursepart.id : '',
        course_part_title_id: (v.coursepart) ? v.coursepart.title_id : '',
        title_id: v.title_id,
        author: v.author,
        created_at: moment(v.created_at).format("DD/MM/YYYY"),
        updated_at: moment(v.updated_at).format("DD/MM/YYYY"),
      }));

      return { rows: mappedData }
    } catch (error) {
      let errorMessage = error.response
        ? error.response.data.message
        : error.message;
      toast.error(errorMessage, {
        closeButton: false,
        position: "bottom-right"
      });
    }

    return { rows: [] }
  }, [dtSearchMeta, dtSortBy, dtSortDirection]);

  const reloadDataFromServer = useCallback((page = 1, perPage = 10) => {
    getDataCourseMaterials(page, perPage)
      .then((res) => {
        const { rows } = res;

        setDtRowsData(rows);
      })
      .finally(() => {
        setLoadProgressData(false);
      });
  }, [getDataCourseMaterials]);

  useEffect(() => {
    reloadDataFromServer(dtPage, dtPerRow);
  }, [reloadDataFromServer, dtPage, dtPerRow]);

  /** Datatable sorting **/
  const onSortHandler = (column, sortDirection) => {
    setDtSortBy(column.selector);
    setDtSortDirection(sortDirection);

    reloadDataFromServer();
  };

  /** Get mata kuliah options for the filter **/
  useEffect(() => {
    const getCourses = async () => {
      const { data: { data }} = await BaseRequest.post('/courses', {
        page: 1,
        limit: -1,
        sortBy: "title_id",
        desc: false
      });

      return data.data.map((course) => {
        return {
          text: course.title_id,
          value: course.id
        }
      });
    }

    getCourses()
      .then((data) => {
        ReactDOM.unstable_batchedUpdates(() => {
          setCourseOptions([{
            "text": 'Semua mata kuliah',
            "value": '',
            "checked": true
          }, ...data]);
        });
      })
  }, []);

  /** Get pertemuan options for the filter **/
  useEffect(() => {
    const getCourses = async () => {
      const { data: { data }} = await BaseRequest.post('/course-parts', {
        page: 1,
        limit: -1,
        sortBy: "title_id",
        desc: false
      });

      return data.data.map((course) => {
        return {
          text: course.title_id,
          value: course.id
        }
      });
    }

    getCourses()
      .then((data) => {
        setCoursePartOptions([{
          "text": 'Semua pertemuan',
          "value": '',
          "checked": true
        }, ...data]);
      })
  }, []);

  /** Datatable Filter **/
  const subHeaderComponentMemo = useMemo(() => {
    const handleFilter = (keyword, comesFromSelect = false, filterName = null) => {
      setLoadProgressData(true);

      let newSearchMeta = dtSearchMeta;

      if (comesFromSelect) {
        let selectedValue = keyword;

        if (selectedValue === '') {
          delete newSearchMeta.filter[filterName];
        } else {
          newSearchMeta.filter[filterName] = selectedValue;
        }
      } else {
        newSearchMeta.search = keyword;
      }

      setDtSearchMeta(newSearchMeta);

      getDataCourseMaterials(newSearchMeta)
        .then((res) => {
          const { rows } = res;

          setDtRowsData(rows);
          setLoadProgressData(false);
        })
    };

    return (
      <div className="w-100 data-table__filter-input">
        <MDBRow className={"w-100"}>
          <MDBCol size={"12"} md={"4"} sm={"12"}>
            <MDBSelect
              label="Mata Kuliah"
              getValue={(val) => handleFilter(val[0], true, "course_id")}
              className={"w-100"}
              name="course"
              options={courseOptions}
            />
          </MDBCol>
          <MDBCol size={"12"} md={"4"} sm={"12"}>
            <MDBSelect
              search
              label="Pertemuan"
              getValue={(val) => handleFilter(val[0], true, "course_part_id")}
              className={"w-100"}
              name="course"
              options={coursePartOptions}
            />
          </MDBCol>
          <MDBCol size={"12"} md={"4"} sm={"12"}>
            <MDBInput
              label="Cari Judul (ID)"
              onChange={(e) => handleFilter(e.target.value)}
              className="search-input"
              name="search"
            />
          </MDBCol>
        </MDBRow>
      </div>
    )
  }, [dtSearchMeta, getDataCourseMaterials, courseOptions, coursePartOptions]);

  const toggleDeleteModal = (id) => {
    setSelectedDeleteItem(id);
    setShowDeleteModal(!showDeleteModal);
  }

  const handleDelete = async () => {
    try {
      setDeleteLoading(true);

      await BaseRequest.delete(`${apiUri}/${selectedDeleteItem}`);
      reloadDataFromServer();
    } catch (err) {
      console.error(err);
    } finally {
      toggleDeleteModal();
      setDeleteLoading(false);
    }
  };

  const handlePageChange = (page) => {
    setDtPage(page)
  }

  const handlePerRowsChange = (perRow) => {
    setDtPerRow(perRow)
  }

  return (
    <MDBContainer fluid>
      <h3>Materi Perkuliahan</h3>

      <CustomDataTable
        columns={columns}
        data={dtRowsData}
        onSortHandler={onSortHandler}
        subHeaderComponent={subHeaderComponentMemo}
        progressPending={loadProgressData}

        totalRow={dtTotalRow}
        handlePageChange={handlePageChange}
        handlePerRowsChange={handlePerRowsChange}
        hasDropDownFilter={true}
      />

      <DeleteModal
        isOpen={showDeleteModal}
        toggle={toggleDeleteModal}
        handler={handleDelete}
        loadingIndicator={deleteLoading}
      />

    { access.isAccessible(menu, 'create') &&
        <MDBBtnFixed
          floating
          color="blue"
          icon="plus"
          size="lg"
          style={{bottom: "55px", right: "24px"}}
          onClick={() => history.push(`${thisUri}/create`)}
          className="no-li"
        />
      }


    </MDBContainer>
  );
};

export default MateriPerkuliahanPage;
