import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  toast,
} from "mdbreact";
import BaseRequest from "../../../../services/BaseRequest";
import * as FileRequest from "../../../../services/FileRequest";
import {Form} from "../../../components";

const thisUri = `/kurikulum/silabus`;
const apiUri = `/course-parts`;

const SilabusFormPage = () => {
  const history = useHistory();
  const { id } = useParams(); // Jika id !== undefined, maka ini adalah fitur edit data

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    watch,
    getValues,
    trigger: triggerValidation
  } = useForm();

  const [submitLoading, setSubmitLoading] = useState(false);
  const [courseOptions, setCourseOptions] = useState([]);
  const [file, setFile] = useState({});
  const [filePreview, setFilePreview] = useState('');

  const formField = [
    {
      name: 'course_id',
      label: 'Pilih Mata Kuliah',
      rules: { required: true },
      type: 'select',
      options: courseOptions,
    },
    {
      name: 'title_id',
      rules: { required: true },
      label: 'Judul (Indonesia)'
    },
    {
      name: 'title_en',
      rules: { required: false },
      label: 'Judul (English)'
    },
    {
      name: 'title_ar',
      rules: { required: false },
      label: 'Judul (Arabic)'
    },
    {
      name: 'sks',
      rules: { required: false },
      label: 'SKS',
      type: 'number'
    },
    {
      name: 'semester',
      rules: { required: false },
      label: 'Semester',
      type: 'number'
    },
    {
      name: 'description_id',
      type: 'textarea',
      rules: { required: true },
      label: 'Deskripsi (Indonesia)'
    },
    {
      name: 'description_en',
      type: 'textarea',
      rules: { required: false },
      label: 'Deskripsi (English)'
    },
    {
      name: 'description_ar',
      type: 'textarea',
      rules: { required: false },
      label: 'Deskripsi (Arabic)'
    },
    {
      name: 'kompetensi',
      type: 'textarea',
      rules: { required: false },
      label: 'Tujuan Mata Kuliah (Kompetensi yang diharapkan)'
    },
    {
      name: 'pokok_bahasan_setiap_pertemuan',
      type: 'textarea',
      label: 'Pokok Bahasan Setiap Pertemuan'
    },
    {
      name: 'strategi',
      type: 'textarea',
      label: 'Strategi Perkuliahan'
    },
    {
      name: 'evaluasi',
      type: 'textarea',
      label: 'Evaluasi Perkuliahan'
    },
    {
      name: 'sumber_rujukan',
      type: 'textarea',
      label: 'Sumber Rujukan'
    },
    {
      name: 'file_id',
      type: 'file',
      label: 'Upload Gambar',
      filePreview: filePreview,
      setter: setFile
    }
  ];

  /** Register the form fields **/
  useEffect(() => {
    for (let field of formField) {
      register({ name: field.name }, field.rules || null);
    }
  }, [register, formField]);

  const getCourses = async () => {
    const uri = `/courses`;
    const { data: { data } } = await BaseRequest.post(uri, {
      sortBy: 'title_id',
      desc: false
    });
    return data.data.map((v, i) => ({
      text: v.title_id,
      value: v.id
    }));
  }

  /** Get Silabus data **/
  useEffect(() => {
    if (id) {
      const getDataById = async () => {
        try {
          const {
            data: { data }
          } = await BaseRequest.get(`${apiUri}/${id}/edit`);

          return data;
        } catch (error) {
          console.log(error);
        }
      };

      getDataById().then(data => {
        for (let key in data) {
          setValue(key, data[key] || "");
        }

        if(data.file){
          setFilePreview(data.file.url)
        }
      });
    }
  }, [id, setValue]);

  /** Load courses and asatidzs data **/
  useEffect(() => {
    let courseId = getValues("course_id");
    if (courseId) {
      getCourses()
        .then(res => {
          setCourseOptions(() => (
            res.map((v, i) => ({
              ...v,
              checked: (courseId === v.value)
            }))
          ))
        });
    } else if (!id) {
      getCourses()
      .then(res => {
        setCourseOptions(res)
      });
    }
  },[id]);

  const onSubmit = async payload => {
    try {
      setSubmitLoading(true);

      let res;
      if (file instanceof File) {
        const fd = new FormData();
        fd.append('file', file);

        const { data } = await FileRequest.upload(fd);
        payload.file_id = data.data.id;
      }

      if (id) {
        // Delete the file object if it's not exists
        if (payload.file_id === "") delete payload.file_id;
        res = await BaseRequest.put(`${apiUri}/${id}`, payload);

        if (res.data.file) {
          setFilePreview(res.data.file.url)
        }
      } else {
        res = await BaseRequest.post(`${apiUri}/save`, payload);
      }

      toast.success(res.data.message, {
        closeButton: false,
        position: "bottom-right"
      });

      setTimeout(() => history.push(thisUri), 2000);
    } catch (error) {
      let errorMessage = error.response
        ? error.response.data.message
        : error.message;

      toast.error(errorMessage, {
        closeButton: false,
        position: "bottom-right"
      });

      setSubmitLoading(false);
    }
  };

  /** Define form fields **/
  const getFormFields = () => {
    return formField.map((field) => ({ ...field, error: errors[field.name] }));
  };

  /** Input text change handler **/
  const handleInputTextChange = (e) => {
    const { name, value } = e.target;
    setValue(name, value);
  };

  /** Select Input change handler **/
  const handleSelectChange = (field, value) => {
    if (field && value) {
      setValue(field, value);
    }
  };

  return (
    <MDBContainer>
      <MDBRow center>
        <MDBCol size="12" md="12" sm="12">
          <h3 className="text-center pt-4 pb-5 mb-2">
            {id ? "Edit" : "Tambah"} Silabus
          </h3>

          <Form
            onSubmit={handleSubmit(onSubmit)}
            fields={getFormFields()}
            watcher={watch}
            onInputTextChange={handleInputTextChange}
            onSelectChange={handleSelectChange}
            submitLoading={submitLoading}
            values={getValues()}
            backUri={thisUri}
            triggerValidation={triggerValidation}
          />
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default SilabusFormPage;
