import React, {useMemo, useState} from "react";
import {useHistory} from "react-router-dom";
import {
  MDBBtn,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBInput
} from "mdbreact";
import {default as CustomDataTable} from "../../../components/DataTable";

const thisUri = "/perkuliahan/materi";

const MateriPerkuliahanList = (props) => {
  const {
    mataKuliahID,
    materiPerkuliahanData
  } = props;
  const history = useHistory();

  /** Datatable variables **/
  const [filterText, setFilterText] = useState('');
  const filteredMateriPerkuliahan = materiPerkuliahanData.filter(item => item.title_id && item.title_id.toLowerCase().includes(filterText.toLowerCase()));

  /** Datatable column definition **/
  const columns = [
    {
      name: "Judul (ID)",
      selector: "title_id",
      sortable: true
    },
    {
      name: "Judul (EN)",
      selector: "title_en",
      sortable: true
    },
    {
      name: "Judul (AR)",
      selector: "title_ar",
      sortable: true
    },
    {
      name: "Pemateri",
      selector: "author",
      sortable: true
    },
    {
      name: "Dibuat Tanggal",
      selector: "created_at",
      sortable: true
    },
    {
      name: "Disunting Tanggal",
      selector: "updated_at",
      sortable: true
    },
    {
      cell: (row) =>
        <div className="btn-group-sm">
          <MDBBtn variant="contained" color="yellow" onClick={() => history.push(`${thisUri}/${row.id}/edit?from=detail-page&id=${mataKuliahID}`)} >
            <MDBIcon icon="pen-nib" />
          </MDBBtn>
        </div>,
      button: true
    }
  ];

  /** Datatable Filter **/
  const subHeaderComponentMemo = useMemo(() => {
    return (
      <div className="w-100 data-table__filter-input">
        <MDBRow className={"w-100"}>
          <MDBCol size={"12"} md={"4"} sm={"12"}>
            <MDBInput
              label="Cari Judul (ID)"
              onChange={(e) => setFilterText(e.target.value)}
              className="search-input"
              name="search"
            />
          </MDBCol>
        </MDBRow>
      </div>
    )
  }, [filteredMateriPerkuliahan]);

  return (
      <CustomDataTable
        serverSide={false}
        columns={columns}
        data={filteredMateriPerkuliahan}
        subHeaderComponent={subHeaderComponentMemo}
      />
  );
};

export default MateriPerkuliahanList;
