import React, {useEffect, useState} from "react";
import {MDBCol, MDBInput, MDBRow} from "mdbreact";
import InputError from "../../components/InputError";

const Password = (props) => {
  const {
    watcher,
    setValue,
    errors,
    name
  } = props;

  return (
    <div style={{ background: 'rgb(243 243 243)', padding: '10px', border: '1px #ccc solid', borderRadius: '5px'}}>
    <label>Ganti Password</label>
    <MDBInput
      name={name}

      onChange={(e) => setValue(e.target.name, e.target.value)}
      type="password"
      label={'Password'}
      iconClass="black-text"
      value={watcher(name)}
      autoComplete={"new-password"}
    >
    {errors.password &&
      <InputError text={errors.password.message} className='d-block'/>
    }

    </MDBInput>

    </div>
  );
}

export default Password;
