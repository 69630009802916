import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import {
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBInput,
  MDBSpinner,
  toast
} from "mdbreact";
import InputError from "../../components/InputError";
import { Link } from "react-router-dom";
import BaseRequest from "../../../services/BaseRequest";

const thisUri = "/countries";

const RenderButton = ({ submitLoading }) => {
  if (submitLoading) {
    return <MDBSpinner />;
  }
  return (
    <div>
      <Link to={thisUri}>
        <MDBBtn size="md" color="red" className="mx-0">
          Kembali
        </MDBBtn>
      </Link>
      <MDBBtn type="submit" color="green" size="md">
        Simpan
      </MDBBtn>
    </div>
  );
};

const CountryFormPage = () => {
  const { register, handleSubmit, errors, setValue, watch } = useForm();
  const [submitLoading, setSubmitLoading] = useState(false);
  const history = useHistory();
  const { id } = useParams(); // Jika id !== undefined, maka ini adalah fitur edit data

  const nameFieldValue = watch("name");
  const alpha2FieldValue = watch("alpha_2");
  const alpha3FieldValue = watch("alpha_3");
  const countryCodeFieldValue = watch("country_code");
  const isoFieldValue = watch("iso_3166_2");
  const regionFieldValue = watch("region");
  const subRegionFieldValue = watch("sub_region");
  const intermediateRegionFieldValue = watch("intermediate_region");
  const regionCodeFieldValue = watch("region_code");
  const subRegionCodeFieldValue = watch("sub_region_code");
  const intermediateRegionCodeFieldValue = watch("intermediate_region_codee");

  const handleChange = e => {
    setValue(e.target.name, e.target.value);
  };

  useEffect(() => {
    register({ name: "name" }, { required: true });
    register({ name: "alpha_2" });
    register({ name: "alpha_3" });
    register({ name: "country_code" });
    register({ name: "iso_3166_2" });
    register({ name: "region" });
    register({ name: "sub_region" });
    register({ name: "intermediate_region" });
    register({ name: "region_code" });
    register({ name: "sub_region_code" });
    register({ name: "intermediate_region_codee" });
  }, [register]);

  useEffect(() => {
    if (id) {
      const getDataById = async () => {
        try {
          const {
            data: { data }
          } = await BaseRequest.get(`/countries/${id}/edit`);
          for (let key in data) {
            setValue(key, data[key] || '');
          }
        } catch (error) {
          console.log(error);
        }
      };
      getDataById();
    }
  }, [id, setValue]);

  const onSubmit = async (payload) => {
    try {
      setSubmitLoading(true);
      let res;
      if (id) {
        res = await BaseRequest.put(`/countries/${id}`, payload);
      } else {
        res = await BaseRequest.post("/countries/save", payload);
      }

      toast.success(res.data.message, {
        closeButton: false,
        position: "bottom-right"
      });
      setTimeout(() => history.push(thisUri), 2000);
    } catch (error) {
      let errorMessage = error.response
        ? error.response.data.message
        : error.message;
      toast.error(errorMessage, {
        closeButton: false,
        position: "bottom-right"
      });
      setSubmitLoading(false);
    }
  };

  return (
    <MDBContainer>
      <MDBRow center>
        <MDBCol size="12" md="9" sm="12">
          <MDBCard>
            <MDBCardBody>
              <MDBCardTitle>{id ? "Edit" : "Tambah"} Negara</MDBCardTitle>
              <hr />
              <form onSubmit={handleSubmit(onSubmit)}>
                <MDBContainer>
                  <MDBRow>
                    <MDBCol>
                      <MDBInput
                        className={errors.name && "is-invalid"}
                        name="name"
                        onChange={handleChange}
                        type="text"
                        label="Nama Negara"
                        icon="address-card"
                        iconClass="black-text"
                        value={nameFieldValue}
                      >
                        {errors.name && errors.name.type === "required" && (
                          <InputError text="Tidak boleh kosong" />
                        )}
                      </MDBInput>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol>
                      <MDBInput
                        name="alpha_2"
                        onChange={handleChange}
                        type="text"
                        label="Alpha 2"
                        icon="address-card"
                        iconClass="black-text"
                        value={alpha2FieldValue}
                      />
                    </MDBCol>
                    <MDBCol>
                      <MDBInput
                        name="alpha_3"
                        onChange={handleChange}
                        type="text"
                        label="Alpha 3"
                        icon="address-card"
                        iconClass="black-text"
                        value={alpha3FieldValue}
                      />
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol>
                      <MDBInput
                        name="country_code"
                        onChange={handleChange}
                        type="text"
                        label="Kode Negara"
                        icon="address-card"
                        iconClass="black-text"
                        value={countryCodeFieldValue}
                      />
                    </MDBCol>
                    <MDBCol>
                      <MDBInput
                        name="iso_3166_2"
                        onChange={handleChange}
                        type="text"
                        label="ISO 3166 2"
                        icon="address-card"
                        iconClass="black-text"
                        value={isoFieldValue}
                      />
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol>
                      <MDBInput
                        name="region"
                        onChange={handleChange}
                        type="text"
                        label="Benua"
                        icon="address-card"
                        iconClass="black-text"
                        value={regionFieldValue}
                      />
                    </MDBCol>
                    <MDBCol>
                      <MDBInput
                        name="region_code"
                        onChange={handleChange}
                        type="text"
                        label="Kode Benua"
                        icon="address-card"
                        iconClass="black-text"
                        value={regionCodeFieldValue}
                      />
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol>
                      <MDBInput
                        name="sub_region"
                        onChange={handleChange}
                        type="text"
                        label="Sub Benua"
                        icon="address-card"
                        iconClass="black-text"
                        value={subRegionFieldValue}
                      />
                    </MDBCol>
                    <MDBCol>
                      <MDBInput
                        name="sub_region_code"
                        onChange={handleChange}
                        type="text"
                        label="Kode Sub Benua"
                        icon="address-card"
                        iconClass="black-text"
                        value={subRegionCodeFieldValue}
                      />
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol>
                      <MDBInput
                        name="intermediate_region"
                        onChange={handleChange}
                        type="text"
                        label="Intermediate Region"
                        icon="address-card"
                        iconClass="black-text"
                        value={intermediateRegionFieldValue}
                      />
                    </MDBCol>
                    <MDBCol>
                      <MDBInput
                        name="intermediate_region_codee"
                        onChange={handleChange}
                        type="text"
                        label="Kode Intermediate Region"
                        icon="address-card"
                        iconClass="black-text"
                        value={intermediateRegionCodeFieldValue}
                      />
                    </MDBCol>
                  </MDBRow>
                </MDBContainer>

                <div className="text-center mt-3 black-text">
                  <RenderButton submitLoading={submitLoading} />
                </div>
              </form>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default CountryFormPage;
