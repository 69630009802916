import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import {
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBInput,
  MDBSpinner,
  toast
} from "mdbreact";
import InputError from "../../components/InputError";
import { Link } from "react-router-dom";
import BaseRequest from "../../../services/BaseRequest";

const thisUri = "/roles";

const RenderButton = ({ submitLoading }) => {
  if (submitLoading) {
    return <MDBSpinner />;
  }
  return (
    <div>
      <Link to={thisUri}>
        <MDBBtn size="md" color="red" className="mx-0">
          Kembali
        </MDBBtn>
      </Link>
      <MDBBtn type="submit" color="green" size="md">
        Simpan
      </MDBBtn>
    </div>
  );
};

const RoleFormPage = () => {
  const { register, handleSubmit, errors, setValue, watch } = useForm();
  const [submitLoading, setSubmitLoading] = useState(false);
  const history = useHistory();
  const { id } = useParams(); // Jika id !== undefined, maka ini adalah fitur edit data

  const nameFieldValue = watch("name");

  const handleChange = e => {
    setValue(e.target.name, e.target.value);
  };

  useEffect(() => {
    register({ name: "name" }, { required: true });
  }, [register]);

  useEffect(() => {
    if (id) {
      const getDataById = async () => {
        try {
          const { data: { data } } = await BaseRequest.get(`/roles/${id}/edit`);
          setValue("name", data.name); 
        } catch (error) {
          console.log(error);
        }
      };
      getDataById()
    }
  }, [id, setValue]);

  const onSubmit = async ({ name }) => {
    try {
      setSubmitLoading(true);
      let res;
      if (id) {
        res = await BaseRequest.put(`/roles/${id}`, { name });
      } else {
        res = await BaseRequest.post("/roles/save", { name });
      }

      toast.success(res.data.message, {
        closeButton: false,
        position: "bottom-right"
      });
      setTimeout(() => history.push(thisUri), 2000);
    } catch (error) {
      let errorMessage = error.response ? error.response.data.message : error.message;
      toast.error(errorMessage, {
        closeButton: false,
        position: "bottom-right"
      });
      setSubmitLoading(false);
    }
  };

  return (
    <MDBContainer>
      <MDBRow center>
        <MDBCol size="12" md="9" sm="12">
          <MDBCard>
            <MDBCardBody>
              <MDBCardTitle>{id ? "Edit" : "Tambah"} Peran</MDBCardTitle>
              <hr />
              <form onSubmit={handleSubmit(onSubmit)}>
                <MDBInput
                  className={errors.name && "is-invalid"}
                  name="name"
                  onChange={handleChange}
                  type="text"
                  label="Nama Peran"
                  icon="address-card"
                  iconClass="black-text"
                  value={nameFieldValue}
                >
                  {errors.name && errors.name.type === "required" && (
                    <InputError text="Tidak boleh kosong" />
                  )}
                </MDBInput>
                <div className="text-center mt-3 black-text">
                  <RenderButton submitLoading={submitLoading} />
                </div>
              </form>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default RoleFormPage;
