import React, {useState, useEffect, useMemo} from "react";
import { useForm } from "react-hook-form";
import {
  toast,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBInput,
  MDBSelect,
  MDBModalHeader
} from "mdbreact";
import BaseRequest from "../../../services/BaseRequest";

const OptionModal = ({option, isOpen, toggle, onRefresh}) => {
  const [loading, setLoading] = useState(false);
  const {register, handleSubmit, errors, setValue, watch} = useForm();
  const [isEditMode, setEditMode] = useState(false);

  useEffect(()=>{
    setEditMode(!!option?.label);
    setValue("label", option?.label);
  },[option]);

  useEffect(()=>{
    register({name:"label"}, {required: true});
  },[]);

  const handleChange = e => {
    setValue(e.target.name, e.target.value);
  };  

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      data.question_id = option?.question_id;
      if (isEditMode) // update
        await BaseRequest.put(`surveys/options/${option.id}`, data);
      else
        await BaseRequest.post(`surveys/options/save`, data);
    } catch (err) {
      toast(err);
    } finally {
      setLoading(false);
      toggle();
      if(onRefresh) onRefresh();
    }
  }

  return (
    <MDBModal isOpen={isOpen} toggle={toggle}>
      <MDBModalHeader>{isEditMode ? "Edit" : "Tambah"} Jawaban</MDBModalHeader>
      <MDBModalBody>
        <form onSubmit={handleSubmit(onSubmit)}>
          <MDBInput
            name="label"
            type="text"
            label="Jawaban"
            value={watch("label")}
            onChange={handleChange}
          />
          <MDBBtn type="submit" color="primary">
            {isEditMode ? "Update" : "Tambah"}
          </MDBBtn>
        </form>
      </MDBModalBody>
    </MDBModal>
  );
}

export default OptionModal;