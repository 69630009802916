import React, {useState} from 'react';
import {
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBInput,
  MDBSpinner,
  MDBSelect,
  MDBFileInput,
  MDBFormInline
} from 'mdbreact';
import { Link } from 'react-router-dom';
import InputError from './InputError';
import {FormStepper} from "./FormStepper";
import SaveModal from "../modals/SaveModal";
import DateTimePicker from 'react-datetime-picker';

const FormButtons = ({ submitLoading, backUri, toggleSaveModal }) => {
  if (submitLoading) {
    return <MDBSpinner />;
  }
  return (
    <div>
      <Link to={backUri}>
        <MDBBtn size="md" color="red" className="mx-0">
          Kembali
        </MDBBtn>
      </Link>
      <MDBBtn color="green" size="md" onClick={toggleSaveModal}>
        Simpan
      </MDBBtn>
    </div>
  );
};

/**
 * Select component
 * */
export const Select = (props) => {
  const { error, label, options, onChange, value, name } = props;
  let opts = []
  for(let x=0; x<options.length; x++){
      options[x].value = options[x].value.toString()
      opts.push({
          ...options[x],
          checked: (value === options[x].value)
      })
  }
  return (
    <>
      <MDBSelect
        className={error && 'is-invalid'}
        getValue={(v) => onChange(name, v[0])}
        name={name}
        options={opts}
        selected={label}
        label={label}
      />
      <ErrorComponent error={error} />
    </>
  );
};

/**
 * Textarea component
 * */
export const Textarea = (props) => {
  const { name, error, label, onChange, value, icon } = props;
  return (
    <MDBInput
      className={error && 'is-invalid'}
      name={name}
      onChange={onChange}
      type="textarea"
      label={label}
      rows="2"
      value={value}
      icon={ icon || "" }
      iconClass="black-text"
    >
      <ErrorComponent error={error} />
    </MDBInput>
  );
};

/**
 * File Component
 * */
export const FileInput = (props) => {
  const { error, label, onChange, filePreview } = props;
  return (
    <>
      <MDBFileInput textFieldTitle={label} getValue={(v) => onChange(v[0])} />
      {
        filePreview &&
          <img src={filePreview} className='rounded' style={{ maxWidth: '300px'}} alt="" />
      }
      <ErrorComponent error={error} />
    </>
  );
}

/**
 * Input password component
 * */
export const InputPassword = (props) => {
  const { name, error, label, onChange, value, icon } = props;
  return (
    <MDBInput
      className={error && 'is-invalid'}
      name={name}
      onChange={onChange}
      type="password"
      label={label}
      icon={ icon || "" }
      iconClass="black-text"
      value={value}
      autoComplete={"new-password"}
    >
      <ErrorComponent error={error} />
    </MDBInput>
  );
}

/**
 * Input number component
 * */
export const InputNumber = (props) => {
  const { name, error, label, onChange, value, icon } = props;
  return (
    <MDBInput
      className={error && 'is-invalid'}
      name={name}
      onChange={onChange}
      type="number"
      label={label}
      icon={ icon || "" }
      iconClass="black-text"
      value={value}
    >
      <ErrorComponent error={error} />
    </MDBInput>
  );
}

/**
 * Input number component
 * */
export const InputEmail = (props) => {
  const { name, error, label, onChange, value, icon} = props;

  return (
    <MDBInput
      className={error && 'is-invalid'}
      name={name}
      onChange={onChange}
      type="email"
      label={label}
      icon={ icon || "" }
      iconClass="black-text"
      value={value}
    >
      <ErrorComponent error={error} />
    </MDBInput>
  );
}
/**
 * Input radio component
 * */
export const InputRadio = (props) => {
  const { name, error, onChange, icon, label, value, options } = props;

  let opts = []
  for(let x=0; x<options.length; x++){
    opts.push({
      ...options[x],
      checked: (value === options[x].value)
    })
  }

  let radioInputs = opts.map((option, i) =>
    <MDBInput
      key={i}
      className={error && 'is-invalid'}
      name={name}
      onClick={() => onChange(name, option.value)}
      type="radio"
      label={option.text}
      icon={ icon || "" }
      iconClass="black-text"
      value={option.value}
      checked={option.checked}
    >
      <ErrorComponent error={error} />
    </MDBInput>
  );

  return (
    <>
      <div className="md-form" style={{marginTop: "2rem"}}>
        <label style={{marginTop: "-1.5rem"}}>{ label }</label>
      </div>
      <MDBRow>
        { radioInputs }
      </MDBRow>
    </>
  );
}

/**
 * Input date component
 * */
export const InputDate = (props) => {
  const { name, error, label, format, onChange, value, icon } = props;
  return (
    <>
      <label htmlFor="defaultFormLoginEmailEx" className="grey-text">
        {label}
      </label>
      <DateTimePicker
        onChange={(val) => onChange(name, val)}
        value={value}
        format={format}
        className="form-control"
        placeholder={label}
      />

    </>
  );
};

/**
 * Input text component
 * */
export const Input = (props) => {
  const { name, error, label, onChange, value, icon } = props;
  return (
    <MDBInput
      className={error && 'is-invalid'}
      name={name}
      onChange={onChange}
      type="text"
      label={label}
      icon={ icon || "" }
      iconClass="black-text"
      value={value}
    >
      <ErrorComponent error={error} />
    </MDBInput>
  );
};

export const InputHidden = (props) => {
  const { name, error, onChange, value } = props;
  return (
    <MDBInput
      className={error && 'is-invalid'}
      name={name}
      onChange={onChange}
      type="hidden"
      value={value}
    >
      <ErrorComponent error={error} />
    </MDBInput>
  );
}

/**
 * Error Component
 * */
export const ErrorComponent = (props) => {
  const { error } = props;

  if (!error) return '';

  let errorMessage = "";

  switch (error.type) {
    case 'required':
      errorMessage = "Tidak boleh kosong";
      break;
    default:
      errorMessage = error.message;
  }

  return (
    <InputError text={errorMessage} />
  )
}

/**
 * Divider Component
 * */
export const Divider = (props) => {
  const { label } = props;

  return (
    <h3 className="text-center pt-4 pb-3">{ label }</h3>
  );
}

/**
 * Form component
 * */
export const Form = (props) => {
  const {
    fields,
    onSubmit,
    submitLoading,
    setSubmitLoading,
    watcher,
    onInputTextChange,
    onSelectChange,
    onDateChange,
    values,
    backUri,
    triggerValidation,
    stepper,
    stepperDetails,
    setError
  } = props;

  const [showSaveModal, setShowSaveModal] = useState(false);

  /** Toggle save modal  **/
  const toggleSaveModal = () => {
    triggerValidation()
      .then(isValid => isValid && setShowSaveModal(true));
  }
  const generateTheForm = (fields) => {
    return fields.map((field) => {
      switch (field.type) {
        case 'select':
          return (
            <Select
              onChange={onSelectChange}
              value={`${values[field.name]}`}
              key={field.name}
              {...field}
            />
          );
        case 'date':
          return (
            <InputDate
              onChange={onDateChange}
              value={watcher(field.name)}
              key={field.name}
              {...field}
            />
          );
        case 'textarea':
          return (
            <Textarea
              key={field.name}
              onChange={onInputTextChange}
              value={watcher(field.name)}
              {...field}
            />
          );
        case 'file':
          return (
            <FileInput
              key={field.name}
              onChange={field.setter}
              value={watcher(field.name)}
              filePreview={field.filePreview}
              {...field}
            />
          );
        case 'number':
          return (
            <InputNumber
              key={field.name}
              onChange={onInputTextChange}
              value={watcher(field.name)}
              {...field}
            />
          );
        case 'password':
          return (
            <InputPassword
              key={field.name}
              onChange={onInputTextChange}
              value={watcher(field.name)}
              setShowSaveModal={setShowSaveModal}
              {...field}
            />
          );
        case 'email':
          return (
            <InputEmail
              key={field.name}
              onChange={onInputTextChange}
              value={watcher(field.name)}
              setShowSaveModal={setShowSaveModal}
              {...field}
            />
          );
        case 'custom':
          return (
            <div key={field.name}>
              { field.label && <label style={{fontSize: 13, color: '#757575', marginBottom: 0}}>{field.label}</label> }
              { field.component }
              { /*field.error && <div style={{color: '#f44336', fontSize: 13}}>invalid</div>*/ }
            </div>
          );
        case 'divider':
          return (
            <Divider label={field.label} key={field.label} />
          );
        case 'hidden':
          return (
            <InputHidden
              key={field.name}
              onChange={onInputTextChange}
              value={watcher(field.name)}
              {...field}
            />
          );
        default:
          return (
            <Input
              key={field.name}
              onChange={onInputTextChange}
              value={watcher(field.name)}
              {...field}
            />
          );
      }
    });
  }

  const submitForm = () => {
    onSubmit()
      .catch(error => {
        let errorResponse = error.response;
        if (errorResponse && errorResponse.data.code === 400) {
          Object.keys(errorResponse.data.data).map(key => {
            setError(key, {
              type: 'server-error',
              message: errorResponse.data.data[key]
            });
          })
        }
      })
      .finally(() => {
        setTimeout(() => {
          setShowSaveModal(false);
          setSubmitLoading && setSubmitLoading(false);
        }, 2000);
      });
  }

  return (
    <div>
      {
        stepper ?
        <FormStepper
          stepperDetails={stepperDetails}
          onSubmit={onSubmit}
          triggerValidation={triggerValidation}
          backUri={backUri}
          generateTheForm={() => generateTheForm(fields)}
        /> :
          <form onSubmit={onSubmit}>
            <MDBRow>
              <MDBCol size="12">
                { generateTheForm(fields) }
              </MDBCol>
            </MDBRow>

            <div className="text-center mt-3 black-text">
              <FormButtons submitLoading={submitLoading} backUri={props.backUri} toggleSaveModal={toggleSaveModal} />
            </div>
          </form>
      }

      {/** Save Modal **/}
      <SaveModal
        isOpen={showSaveModal}
        toggle={() => setShowSaveModal(!showSaveModal)}
        handler={submitForm}
        loadingIndicator={submitLoading}
      />
    </div>
  );
};
