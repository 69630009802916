import React, { useCallback, useEffect, useState } from "react";
import { MDBBtn, MDBBtnFixed, MDBContainer, MDBIcon, toast } from "mdbreact";
import { default as CustomDataTable } from "../../components/DataTable";
import DeleteModal from "../../modals/DeleteModal";
import BaseRequest from "../../../services/BaseRequest";
import moment from "moment";
import { useHistory } from "react-router";

const thisUri = "/e-student-card";
const apiUri = "/ecard-settings";

const StudentCardPage = () => {
  const history = useHistory();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedDeleteItem, setSelectedDeleteItem] = useState({});
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [loadProgressData, setLoadProgressData] = useState(true);
  const [dtRowsData, setDtRowsData] = useState([]);
  const [dtTotalRow, setDtTotalRow] = useState(1);
  const [dtPage, setDtPage] = useState(1);
  const [dtPerPage, setDtPerPage] = useState(10);

  const columns = [
    {
      name: "Periode",
      selector: "period",
    },
    {
      name: "Warna",
      cell: (row) => (
        <>
          <span
            style={{
              height: "16px",
              width: "16px",
              backgroundColor: `rgb(${row.qrcode_color})`,
              marginRight: "8px",
            }}
          />
          rgb({row.qrcode_color})
        </>
      ),
    },
    {
      name: "Dibuat Tanggal",
      selector: "created_at",
    },
    {
      cell: (row) => (
        <div className="btn-group-sm">
          <MDBBtn
            variant="contained"
            color="yellow"
            onClick={() => history.push(`${thisUri}/${row.id}/edit`)}
          >
            <MDBIcon icon="pen-nib" />
          </MDBBtn>
          <MDBBtn
            variant="contained"
            color="red"
            onClick={() => toggleDeleteModal(row.id)}
          >
            <MDBIcon icon="trash" />
          </MDBBtn>
        </div>
      ),
      button: true,
    },
  ];

  const getStudentCard = useCallback(async (page = 1, perPage = 10) => {
    let uri = `${apiUri}`;

    try {
      setLoadProgressData(true);

      const {
        data: { data },
      } = await BaseRequest.post(uri, {
        page: page,
        limit: perPage,
      });

      setDtTotalRow(data.total);

      const mappedData = data.data.map((data) => ({
        ...data,
        created_at: moment(data.created_at).format("DD/MM/YYYY"),
      }));

      return { rows: mappedData };
    } catch (error) {
      let errorMessage = error.response
        ? error.response.data.message
        : error.message;
      toast.error(errorMessage, {
        closeButton: false,
        position: "bottom-right",
      });
    }

    return { rows: [] };
  }, []);

  const reloadDataFromServer = useCallback(
    (page = 1, perPage = 10) => {
      getStudentCard(page, perPage)
        .then((res) => {
          const { rows } = res;
          setDtRowsData(rows);
        })
        .finally(() => {
          setLoadProgressData(false);
        });
    },
    [getStudentCard]
  );

  useEffect(() => {
    reloadDataFromServer(dtPage, dtPerPage);
  }, [reloadDataFromServer, dtPage, dtPerPage]);

  const toggleDeleteModal = (id) => {
    setSelectedDeleteItem(id);
    setShowDeleteModal(!showDeleteModal);
  };

  const handleDelete = async () => {
    try {
      setDeleteLoading(true);

      await BaseRequest.delete(`${apiUri}/${selectedDeleteItem}`);
      reloadDataFromServer(dtPage, dtPerPage);
    } catch (err) {
      console.error(err);
    } finally {
      toggleDeleteModal();
      setDeleteLoading(false);
    }
  };

  const onPageChangeHandler = (page) => {
    setDtPage(page);
  };

  const onPerRowsChangeHandler = (perPage) => {
    setDtPerPage(perPage);
  };

  return (
    <MDBContainer fluid>
      <h3>E-Student Card</h3>

      <CustomDataTable
        columns={columns}
        data={dtRowsData}
        totalRow={dtTotalRow}
        progressPending={loadProgressData}
        handlePerRowsChange={onPerRowsChangeHandler}
        handlePageChange={onPageChangeHandler}
      />

      <DeleteModal
        isOpen={showDeleteModal}
        toggle={toggleDeleteModal}
        handler={handleDelete}
        loadingIndicator={deleteLoading}
      />

      <MDBBtnFixed
        floating
        color="blue"
        icon="plus"
        size="lg"
        style={{ bottom: "55px", right: "24px" }}
        onClick={() => history.push(`${thisUri}/create`)}
        className="no-li"
      />
    </MDBContainer>
  );
};

export default StudentCardPage;
