import React, { useContext } from "react";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import { AccessContext } from "../../../AccessContext";

export const ProgramExpandedRow = (props) => {
  const { data } = props;
  const access = useContext(AccessContext);
  const menu = "program";

  let asatidzs = [];
  let courses =
    data.courses &&
    data.courses
      .sort((a, b) => (a.title_id > b.title_id ? 1 : -1))
      .map((course, index) => {
        course.asatidzs.map((asatidz) =>
          asatidzs.push({
            id: asatidz.id,
            name: asatidz.name,
          })
        );

        return (
          <li key={index}>
            {access.isAccessible(menu, "edit") ? (
              <a
                href={`/kurikulum/mata-kuliah/${course.id}/edit`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {course.title_id}
              </a>
            ) : (
              <span>{course.title_id}</span>
            )}
          </li>
        );
      });

  let uniqueAsatidzs =
    asatidzs.length > 0
      ? Array.from(new Set(asatidzs.map((asatidz) => asatidz.id))).map(
          (id, index) => {
            let name = asatidzs.find((s) => s.id === id).name;

            return (
              <li key={index}>
                {access.isAccessible(menu, "edit") ? (
                  <a
                    href={`/kurikulum/asatidz/${id}/edit`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {name}
                  </a>
                ) : (
                  <span>{name}</span>
                )}
              </li>
            );
          }
        )
      : [];

  let groupAdmins =
    data.groups &&
    data.groups
      .sort((a, b) => (a.name_id > b.name_id ? 1 : -1))
      .map((group, index) => {
        return (
          <li key={index}>
            <a
              href={`/kelas/groups/${group.id}/edit`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {group.name_id}
            </a>
            &nbsp;/&nbsp;
            {group.admin ? (
              <a
                href={`/admin-group/${group.admin.id}/edit`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {group.admin.name}
              </a>
            ) : (
              "-"
            )}
          </li>
        );
      });

  let total = data.ikhwan + data.akhwat;
  let jumlahPeserta = (
    <div>
      Total : {total}
      <br />
      Ikhwan : {data.ikhwan}{" "}
      {total > 0 ? `(${((data.ikhwan / total) * 100).toFixed()}%)` : ""}
      <br />
      Akhwat : {data.akhwat}{" "}
      {total > 0 ? `(${((data.akhwat / total) * 100).toFixed()}%)` : ""}
      <br />
    </div>
  );

  return (
    <MDBTable small className="expandable-table">
      <MDBTableHead>
        <tr>
          <th width="25%">Mata Kuliah</th>
          <th width="25%">Asatidz</th>
          <th>Group / Admin</th>
          <th width="20%">Peserta</th>
        </tr>
      </MDBTableHead>
      <MDBTableBody>
        <tr>
          <td>
            <ul style={{ marginBottom: 0, paddingLeft: 10 }}>{courses}</ul>
          </td>
          <td>
            <ul style={{ marginBottom: 0, paddingLeft: 10 }}>
              {uniqueAsatidzs}
            </ul>
          </td>
          <td>
            <ul style={{ marginBottom: 0, paddingLeft: 10 }}>{groupAdmins}</ul>
          </td>
          <td>{jumlahPeserta}</td>
        </tr>
      </MDBTableBody>
    </MDBTable>
  );
};
