import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { MDBContainer, MDBIcon, toast } from "mdbreact";
import CustomDataTable from "../../components/DataTable";
import BaseRequest from "../../../services/BaseRequest";
import ConfirmationDialog from "../../components/ConfirmationDialog";

const GroupingPesertaPage = () => {
  const history = useHistory();

  const [dtRowsData, setDtRowsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [selectedGroupId, setSelectedGroupId] = useState("");
  const [deleteLoading, setDeleteLoading] = useState(false);

  const onDeleteGroup = (id) => {
    setSelectedGroupId(id);
    setShowDeleteConfirm(true);
  };

  const deleteGroup = async () => {
    try {
      setDeleteLoading(true);
      await BaseRequest.delete(`/groups/${selectedGroupId}`);
      toast.success("Group berhasil dihapus", {
        closeButton: false,
        position: "bottom-right",
      });
    } catch (error) {
      const errorMessage = error.response
        ? error.response.data.message
        : error.message;
      toast.error(errorMessage, {
        closeButton: false,
        position: "bottom-right",
      });
    } finally {
      setDeleteLoading(false);
      setSelectedGroupId("");
      setShowDeleteConfirm(false);
    }
  };

  /** Datatable column definition **/
  const columns = [
    {
      name: "No",
      selector: "no",
      width: "4rem",
      right: true,
    },
    {
      name: "Kelas",
      selector: "classes.name",
    },
    {
      name: "Gender",
      selector: "gender",
      sortable: true,
    },
    {
      name: "#Peserta yang Belum Dapat Group",
      selector: "jml_peserta",
      sortable: true,
    },
    {
      name: "Action",
      selector: "action",
      cell: (row) => {
        const { iconBtnStyle } = styles;

        return (
          <div>
            <MDBIcon
              icon="users"
              style={iconBtnStyle}
              onClick={() => history.push(`/pmb/grouping/${row.id}/peserta`)}
            />{" "}
            {row.jml_peserta === 0 && (
              <MDBIcon
                icon="trash"
                style={iconBtnStyle}
                onClick={() => onDeleteGroup(row.id)}
              />
            )}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        const { data } = await BaseRequest.post("/groups", {
          with: "participants",
          filter: {
            name_id: "temp",
          },
        });

        setDtRowsData(
          data.data.data.map((v, i) => {
            return {
              ...v,
              no: i + 1,
              jml_peserta: v.participants.length,
            };
          })
        );
      } catch (error) {
        const errorMessage = error.response
          ? error.response.data.message
          : error.message;
        toast.error(errorMessage, {
          closeButton: false,
          position: "bottom-right",
        });
      } finally {
        setIsLoading(false);
      }
    };

    getData();
  }, []);

  return (
    <MDBContainer fluid>
      <h3>List Grup Sementara</h3>

      <CustomDataTable
        serverSide={false}
        columns={columns}
        data={dtRowsData}
        defaultSortField="no"
        progressPending={isLoading}
      />

      <ConfirmationDialog
        isOpen={showDeleteConfirm}
        title="Konfirmasi"
        description="Anda Menghapus grup ini. Lanjutkan ?"
        isLoading={deleteLoading}
        backdrop={!deleteLoading}
        toggleFunc={() => {
          const state = !showDeleteConfirm;
          setShowDeleteConfirm(state);
          if (!state) {
            setSelectedGroupId("");
          }
        }}
        onYesClick={() => deleteGroup()}
      />
    </MDBContainer>
  );
};

const styles = {
  iconBtnStyle: {
    cursor: "pointer",
  },
};

export default GroupingPesertaPage;
