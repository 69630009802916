import React, {useContext} from "react";
import {
  MDBCol,
  MDBIcon,
  MDBNavLink,
  MDBRow
} from "mdbreact";
import { AccessContext } from '../../../AccessContext';

const UjianEvaluasiPage = () => {
  const access = useContext(AccessContext);
  return (
    <section className="text-center my-5">
      <h3 className="h1-responsive font-weight-bold my-5">
        Ujian/Evaluasi
      </h3>
      <MDBRow>
        <MDBCol md="6" sm="12">
          <MDBNavLink to="/kurikulum/ujian-evaluasi/kuis">
            <MDBIcon icon="diagnoses" size="5x" className="blue-text" />
            <h5 className="font-weight-bold mt-2 black-text">
              Kuis
            </h5>
          </MDBNavLink>
          <p className="lead grey-text w-responsive mx-auto mb-5">
            Pengaturan kuis per angkatan
          </p>
        </MDBCol>

        {access.isAccessible('pengaturan-ujian', 'show') &&
          <MDBCol md="6" sm="12">
            <MDBNavLink to="/kurikulum/ujian-evaluasi/pengaturan">
              <MDBIcon icon="cogs" size="5x" className="blue-text" />
              <h5 className="font-weight-bold mt-2 black-text">
                Pengaturan Ujian
              </h5>
            </MDBNavLink>
            <p className="lead grey-text w-responsive mx-auto mb-5">
              Pengaturan ujian per angkatan, <br />kelas/program/rombel
            </p>
          </MDBCol>
        }

        <MDBCol md="6" sm="12">
          <MDBNavLink to="/kurikulum/ujian-evaluasi/log">
            <MDBIcon icon="list-alt" size="5x" className="blue-text" />
            <h5 className="font-weight-bold mt-2 black-text">
              Log Ujian
            </h5>
          </MDBNavLink>
          <p className="lead grey-text w-responsive mx-auto mb-5">
            Log event ketika ujian, mahasiswa/i yang sudah mengerjakan/belum ujian
          </p>
        </MDBCol>
        <MDBCol md="6" sm="12">
          <MDBNavLink to="/kurikulum/ujian-evaluasi/revision">
            <MDBIcon icon="redo" size="5x" className="blue-text" />
            <h5 className="font-weight-bold mt-2 black-text">
              Revisi Ujian
            </h5>
          </MDBNavLink>
          <p className="lead grey-text w-responsive mx-auto mb-5">
            Halaman Untuk Melakukan Revisi Ujian Yang Sedang Aktif
          </p>
        </MDBCol>
      </MDBRow>
    </section>
  );
};

export default UjianEvaluasiPage;
