import { useState, useEffect } from "react";
import useDebounce from './UseDebounce';

/**
 *
 * @callback HandlerCallback
 * @param {number} page
 * @param {number} limit
 * @param {string} search
 * @returns {Promise<{rows: array, paginationOptions: object}>}
 */

/**
 * Cara Mudah menggunakan hook ini :
 * 1) Antum buat handler, parameter & return value harus sesuai dengan dokumentasi JSDoc nya.
 * 2) Panggil hook ini, masukkan handler & jumlah data per halaman sbg parameter
 * 3) Voila, antum akan mendapatkan semua props yang dibutuhkan untuk memanggil component TSLDataTable
 *
 * Tentang parameter `perPage`:
 * - jika perPage === 0, maka ini berarti fetch semua data tanpa di limit
 * - jadi harus di kondisikan di handler yg antum buat.
 */

/**
 *
 * @param {HandlerCallback} handler
 * @param {number} perPage
 */
const usePagination = (handler, perPage) => {
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [paginationOptions, setPaginationOptions] = useState({});
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(perPage);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState([]);
  const [listFilter, setListFilter] = useState([]);
  const debouncedSearchTerm = useDebounce(search, 500);

  const onPageChanged = page => {
    setPage(page);
  };
  const onLimitChanged = limit => {
    setLimit(limit);
  };
  const onSearchChanged = e => {
    setSearch(e.target.value);
  };
  const onFilterChanged = obj => {
    let findIdx = listFilter.findIndex(v => v.key === obj.key)
    if(findIdx >= 0){
      listFilter[findIdx].value = obj.value
    }else{
      listFilter.push(obj)
    }
    setListFilter(listFilter);
    let filter = {};
    for(var x=0;x<listFilter.length;x++){
      let f = listFilter[x]
      if(f && f.value && f.value !== 'all'){
        filter = {
          ...filter,
          [f.key]: f.value
        }
      }
    }
    setFilter(filter)
  };

  // Jalankan hook `useEffect` ini jika nilai page, limit, filter, atau debouncedSearchTerm berubah
  useEffect(() => {
    setIsLoading(true);
    handler(page, limit, debouncedSearchTerm, filter)
      .then(res => {
        const { rows: r, paginationOptions: po } = res;
        setRows(r);
        setPaginationOptions(po);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [page, limit, debouncedSearchTerm, filter]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    rows,
    isLoading,
    paginationOptions,
    onPageChanged,
    onLimitChanged,
    onSearchChanged,
    onFilterChanged,
    setRows
  };
};

export default usePagination;
