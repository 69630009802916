import React from "react";
import { connect } from "react-redux";
import { setMenu } from "../store/menu/action";
import { setAccess } from "../store/access/action";
import { getRole } from '../utils'

import {
  MDBSideNavLink,
  MDBSideNavCat,
  MDBSideNavNav,
  MDBSideNav,
  MDBIcon
} from "mdbreact";

class SideNavigation extends React.Component {
  componentDidMount() {
    // Cek Role di local storage
    this.props.setMenu(getRole());
    this.props.setAccess(getRole());
  }

  // render MDBSideNav Link
  rSNL(to, text) {
    return (
      <MDBSideNavLink key={to} to={to} onClick={this.props.onLinkClick}>
        {text}
      </MDBSideNavLink>
    );
  }

  renderMenu() {
    // onLinkClick ? bisa dilihat di prop assignmentnya di file `RoutesWithNavigation.js`
    const { menu, onLinkClick } = this.props;
    return menu.map(m => {
      if (!m.children) {
        return (
          <MDBSideNavLink
            key={m.id}
            id={m.id}
            topLevel
            to={m.to}
            onClick={onLinkClick}
          >
            <MDBIcon icon={`${m.icon} mr-2`} />
            {m.name}
          </MDBSideNavLink>
        );
      }

      return (
        <MDBSideNavCat key={m.id} name={m.name} id={m.id} icon={m.icon}>
          {m.children.map(c => {
            return this.rSNL(c.to, c.name);
          })}
        </MDBSideNavCat>
      );
    });
  }

  render() {
    const { onLinkClick } = this.props;
    return (
      <div className="white-skin">
        <MDBSideNav
          logo={require('./../assets/images/tsl-logo-with-text.png')}
          bg={require('./../assets/images/alhambra-spain.webp')}
          mask="strong"
          fixed
          breakWidth={this.props.breakWidth}
          triggerOpening={this.props.triggerOpening}
          style={{ transition: "padding-left .3s" }}
        >
          <form role="search" className="search-form">
            <div className="form-group md-form mt-0 pt-1 ripple-parent">
              <input
                type="text"
                placeholder="Search"
                className="form-control"
              />
            </div>
          </form>
          <MDBSideNavNav>
            <MDBSideNavLink topLevel to="/" onClick={onLinkClick}>
              <MDBIcon icon="tachometer-alt mr-2" />
              Dashboard
            </MDBSideNavLink>
            {this.renderMenu()}
          </MDBSideNavNav>
        </MDBSideNav>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  menu: state.menu,
  access: state.access
});

export default connect(mapStateToProps, { setMenu, setAccess })(SideNavigation);
