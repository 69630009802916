import React, {useEffect, useState} from "react";
import {useParams, useHistory} from "react-router";
import { useForm } from "react-hook-form";

import {
  MDBBtn,
  MDBBtnFixed,
  MDBBtnFixedItem,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBInput,
  MDBRow,
  MDBSelect,
  MDBSpinner,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  toast
} from "mdbreact";

import NavBarSubMenu from "../../../../components/NavBarSubMenu";
import BaseRequest from "../../../../../services/BaseRequest";
import DataTable from "react-data-table-component";
import InputError from "../../../../components/InputError";
import DeleteModal from "../../../../modals/DeleteModal";
import SaveModal from "../../../../modals/SaveModal";

const subMenus = require('../json/sub-menu.json');
let questionDifficultyLevel = require('../json/question-types.json');

/** Expandable component row on Answer PG Table **/
const ExpandableRowComponent = ({ data }) => (
  <MDBContainer>
    <MDBTable>
      <MDBTableHead>
        <tr>
          <th>Jawaban (English)</th>
          <th>Jawaban (Arabic)</th>
        </tr>
      </MDBTableHead>
      <MDBTableBody>
        <tr>
          <th>{data.answerEn === '' ? '-' : data.answerEn }</th>
          <th>{data.answerAr === '' ? '-' : data.answerAr }</th>
        </tr>
      </MDBTableBody>
    </MDBTable>
  </MDBContainer>
);

const thisUri = "/kurikulum/bank-soal/kategori";
const answerTypes = {
  ESSAY: 'essay',
  BS: 'bs',
  PG: 'pg'
};
const bsAnswers = {
  BENAR: 'Benar',
  SALAH: 'Salah',
  translation: {
    benar: {
      id: 'Benar',
      en: 'Correct',
      ar: 'حق'
    },
    salah: {
      id: 'Salah',
      en: 'Incorrect',
      ar: 'خطأ'
    }
  }
}

const answerPGFormFields = [
  {
    name: 'pg_answer_id'
  },
  {
    name: "pg_answer_list",
    rules: { validate: value => (value && value.length >= 2) || "PG harus berisi minimal 2 jawaban" }
  },
  {
    name: "pg_correct_answer_no",
    rules: { validate: value => (value && value >= 1) || "PG harus berisi minimal 1 jawaban benar" }
  },
  {
    name: "pg_weight_score"
  },
];

const SoalFormPage = () => {
  const {idCategory, id} = useParams();
  const history = useHistory();

  const pageTitle = `${idCategory ? 'Kategori Soal / ' : ''}${ id ? 'Edit' : 'Tambah'} Soal`;
  const [fixedBtnStyle, setFixedBtnStyle] = useState({
    transform: "scaleY(0.4) scaleX(0.4) translateY(40px) translateX(0)",
    opacity: "0"
  });

  const {register, handleSubmit, errors, setValue, setError, getValues, watch, clearErrors: clearError, unregister, trigger: triggerValidation} = useForm();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);

  const [answerType, setAnswerType] = useState(answerTypes.ESSAY);
  const [bsAnswer, setBsAnswer] = useState('');
  const [pgAnswers, setPgAnswers] = useState([]);

  const [isEditPGAnswerMode, setIsEditPGAnswerMode] = useState(false);
  const [showDeletePGModal, setShowDeletePGModal] = useState(false);
  const [deletePGLoading, setDeletePGLoading ] = useState(false);
  const [deletedPGID, setDeletedPGID ] = useState(null);

  const [initialDataLoading, setInitialDataLoading] = useState(false);
  const [categoryName, setCategoryName] = useState('');
  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const [courseOptions, setCourseOptions] = useState([]);

  /** Form fields definition **/
  let formFields = [
    { name: 'course_part_id', rules: { required: true } },
    { name: 'type', rules: { required: true } },
    { name: 'question_id', rules: { required: true } },
    { name: 'question_en' },
    { name: 'question_ar' },
    { name: "answer_index" },
    { name: "answer_id", rules: { required: true } },
    { name: "answer_en" },
    { name: "answer_ar" },
    { name: "is_correct_answer" },
    { name: "essay_weight_score" }
  ];

  /** Load Course & Category list **/
  useEffect(() => {
    setInitialDataLoading(true);

    questionDifficultyLevel = questionDifficultyLevel.map(level => {
      return { ...level, checked: false };
    });

    const getInitialCategoryData = async () => {
      /* Get Categories List */
      let response = await BaseRequest.post('question-categories', {
        sortBy: 'name',
        desc: false,
        limit: -1
      });
      let categories = response.data.data.data.map((v) => {
        let checked = false;
        if (v.id === idCategory) {
          checked = true;
          setCategoryName(v.name);
        }

        return {
          text: v.name,
          value: v.id,
          checked: checked
        }
      });

      return {
        categories: categories
      }
    }

    const getInitialData = async () => {
      /* Get Soal Data */
      let soal, response;

      if (id) {
        response = await BaseRequest.get(`questions/${id}/edit?with=question_answers`);
        soal = response.data.data;
      }

      /* Get Course Parts List */
      response = await BaseRequest.post('course-parts', {
        sortBy: 'title_id',
        desc: false,
        limit: -1
      });
      let courses = response.data.data.data.map((v) => ({
        text: v.title_id,
        value: v.id,
        checked: v.id === (soal && soal.course_part_id || null)
      }));

      return {
        soal: soal,
        courses: courses
      };
    };

    getInitialData()
      .then((res) => {
        let courses = res.courses;

        /* Set initial form data */
        if (id) {
          /* Set value for each form field */
          formFields.forEach((field) => {
            setValue(field.name, res.soal[field.name] || '');
          });

          /* Set checked value for tipe soal */
          questionDifficultyLevel = questionDifficultyLevel.map((level) => ({
            ...level,
            checked: level.value === res.soal.type
          }));

          /* Populate the question answers based on answer type */
          let questionAnswers = res.soal.question_answers;

          if (res.soal.is_essay) {
            setAnswerType(answerTypes.ESSAY);
            setValue('answer_id', questionAnswers[0].answer_id);
            setValue('answer_en', questionAnswers[0].answer_en);
            setValue('answer_ar', questionAnswers[0].answer_ar);
            setValue('essay_weight_score', res.soal.weight_score);

          } else if (questionAnswers.filter((answer) =>
            answer.answer_id === bsAnswers.translation.benar.id || answer.answer_id === bsAnswers.translation.salah.id).length === 2
          ) {
            let correctBsAnswer = questionAnswers.find((answer) => answer.is_correct_answer).answer_id;
            setAnswerType(answerTypes.BS);
            setBsAnswer(correctBsAnswer);
            setValue('answer_id', correctBsAnswer);

          } else {
            let pgAnswerCorrectNo = questionAnswers.filter((answer) => answer.is_correct_answer).length;
            let pgAnswers = questionAnswers.map((answer, key) => ({
              id: key,
              answerId: answer.answer_id,
              answerEn: answer.answer_en,
              answerAr: answer.answer_ar,
              isCorrectAnswer: answer.is_correct_answer,
              status: answer.is_correct_answer ? 'Benar' : 'Salah',
              pg_weight_score: answer.weight_score ? answer.weight_score : '0'
            }));

            setAnswerType(answerTypes.PG);
            setPgAnswers(pgAnswers);

            setValue('pg_answer_list', pgAnswers);
            setValue('pg_correct_answer_no', pgAnswerCorrectNo);

            unregister('answer_id');
          }
        }

        setCourseOptions(courses);
      })
      .finally(() => setInitialDataLoading(false));

    getInitialCategoryData()
      .then(res => {
        setCategoriesOptions(res.categories);
      });

  }, [id, setValue]);

  /** Register the form field **/
  useEffect(() => {
    let formFieldsToBeRegistered = formFields;

    if (idCategory === undefined) {
      formFieldsToBeRegistered = [...formFieldsToBeRegistered, { name: 'category_id', rules: { required: true } }]
    }

    if (answerType === answerTypes.PG) {
      // Combine the form field with PG answer
      formFieldsToBeRegistered = [...formFieldsToBeRegistered, ...answerPGFormFields];

      // Remove unused field for PG answer
      formFieldsToBeRegistered = formFieldsToBeRegistered.filter((field) => field.name !== 'answer_id');

      /*if (pgAnswers.length >= 2) {
        formFieldsToBeRegistered = formFieldsToBeRegistered.filter((field) => field.name !== 'pg_correct_answer_no');
      }*/
    }

    for (let v of formFieldsToBeRegistered) {
      register({ name: v.name }, v.rules || null);
    }
  }, [register, answerType]);

  /** Change input handler **/
  const handleChange = e => {
    setValue(e.target.name, e.target.value);
  };

  /** Change checkbox handler **/
  const handleCheckboxChange = e => {
    setValue(e.target.name, e.target.checked)
  };

  /** On click answer type handler **/
  const onClickAnswerType = (answerType) => {
    setAnswerType(answerType);

    /* Re-register the answer form field */
    switch (answerType) {
      case answerTypes.ESSAY:
        unregister('pg_weight_score');
        break;
      case answerTypes.BS:
        unregister([
          'pg_answer_list',
          'pg_correct_answer_no',
          'pg_answer_id'
        ]);
        break;
      case answerTypes.PG:
        unregister('answer_id');
        unregister('essay_weight_score');
        setValue('pg_correct_answer_no', 0);
        break;
      default:
        break;
    }

    resetPGAnswerForm();
  };

  /** Reset PG answer form **/
  const resetPGAnswerForm = () => {
    setValue('answer_index', '');
    setValue('pg_answer_id', '');
    setValue('answer_id', '');
    setValue('answer_en', '');
    setValue('answer_ar', '');
    setValue('is_correct_answer', false);
    setValue('pg_weight_score',0);

    clearError('answer_id');
    clearError('pg_answer_list');
    clearError('pg_correct_answer_no');
    clearError('pg_answer_id');
  };

  /** Add PG answer handler **/
  const addPGAnswersHandler = (e) => {
    let formValues = getValues();

    if (formValues.pg_answer_id === undefined || formValues.pg_answer_id === '') {
      clearError('pg_answer_list');
      setError('pg_answer_list', 'required', 'Tidak boleh kosong');
      return;
    }

    let isCorrectAnswer = formValues.is_correct_answer === true;

    let pgAnswerList = {
      id: Date.now().toString(),
      answerId: formValues.pg_answer_id,
      answerEn: formValues.answer_en,
      answerAr: formValues.answer_ar,
      isCorrectAnswer: isCorrectAnswer,
      status: isCorrectAnswer ? 'Benar' : 'Salah',
      weight_score: formValues.pg_weight_score,
    };

    let newPgAnswerList = [...pgAnswers, pgAnswerList];
    setPgAnswers(newPgAnswerList);
    setValue('pg_answer_list', newPgAnswerList);

    if (isCorrectAnswer) setValue('pg_correct_answer_no', (formValues.pg_correct_answer_no || 0) + 1);
    resetPGAnswerForm();

    // if (newPgAnswerList.length >= 2) unregister('pg_answer_id');
  };

  /** Activate Edit PG Answer Mode **/
  const editPGAnswer = (row) => {
    console.log(row);
    setIsEditPGAnswerMode(true);

    setValue('answer_index', row.id);
    setValue('pg_answer_id', row.answerId);
    setValue('pg_weight_score', row.pg_weight_score);
    setValue('answer_en', row.answerEn);
    setValue('answer_ar', row.answerAr);
    setValue('is_correct_answer', row.isCorrectAnswer);
  }

  /** Cancel edit PG answer **/
  const cancelPGAnswerEditHandler = () => {
    resetPGAnswerForm();
    setIsEditPGAnswerMode(false);
  }

  /** Update PG Answer Handler **/
  const updatePGAnswersHandler = (row) => {
    let formValues = getValues();
    let isCorrectAnswer = formValues.is_correct_answer;

    let newPgAnswerList = pgAnswers.map(pg => {
      return pg.id === formValues.answer_index ?
        {
          id: row.id || Date.now(),
          answerId: formValues.pg_answer_id,
          answerEn: formValues.answer_en,
          answerAr: formValues.answer_ar,
          isCorrectAnswer: isCorrectAnswer,
          pg_weight_score: formValues.pg_weight_score,
          status: isCorrectAnswer ? 'Benar' : 'Salah'
        } :
        pg;
    });

    setPgAnswers(newPgAnswerList);
    setValue('pg_answer_list', newPgAnswerList);
    setIsEditPGAnswerMode(false);
    setValue('pg_correct_answer_no', newPgAnswerList.filter(answer => answer.isCorrectAnswer).length);

    resetPGAnswerForm();
  }

  /** Delete PG Answer Handler **/
  const toggleDeletePGModal = (deletedID) => {
    setShowDeletePGModal(!showDeletePGModal);
    setDeletedPGID(deletedID);
  };

  const deletePGAnswerHandler = () => {
    setDeletePGLoading(true);

    let deletedAnswer = pgAnswers.find((pg) => {
      return pg.id === deletedPGID;
    });

    let newPgAnswerList = pgAnswers.filter((pg) => {
      return pg.id !== deletedPGID;
    });

    setPgAnswers(newPgAnswerList);
    setValue('pg_answer_list', newPgAnswerList);
    if (deletedAnswer.isCorrectAnswer) setValue('pg_correct_answer_no', (getValues("pg_correct_answer_no") || 0) + 1);

    setShowDeletePGModal(false);
    setDeletePGLoading(false);
  }

  /** Submit the form **/
  const submitForm = async (payload) => {
    setSubmitLoading(true);
    let formData = {
      question_category_id: idCategory,
      question_id: payload.question_id,
      question_en: payload.question_en,
      question_ar: payload.question_ar,
      type: payload.type,
      is_essay: answerType === answerTypes.ESSAY,
      course_part_id: payload.course_part_id,
      weight_score: (answerType === answerTypes.ESSAY)? payload.essay_weight_score:0,
    };

    let answers = [];
    switch (answerType) {
      case answerTypes.ESSAY:
        answers.push({
          is_correct_answer: true,
          answer_id: payload.answer_id,
          answer_en: payload.answer_en,
          answer_ar: payload.answer_ar,
        })
        break;
      case answerTypes.BS:
        answers.push({
          is_correct_answer: payload.answer_id === bsAnswers.BENAR,
          answer_id: bsAnswers.translation.benar.id,
          answer_en: bsAnswers.translation.benar.en,
          answer_ar: bsAnswers.translation.benar.ar,
        }, {
          is_correct_answer: payload.answer_id === bsAnswers.SALAH,
          answer_id: bsAnswers.translation.salah.id,
          answer_en: bsAnswers.translation.salah.en,
          answer_ar: bsAnswers.translation.salah.ar,
        })
        break;
      case answerTypes.PG:
        for (let pgAnswer of pgAnswers) {
          answers.push({
            is_correct_answer: pgAnswer.isCorrectAnswer,
            answer_id: pgAnswer.answerId,
            answer_en: pgAnswer.answerEn,
            answer_ar: pgAnswer.answerAr,
            weight_score : pgAnswer.pg_weight_score,
          });
        }
        break;
      default:
        break;
    }

    formData = {...formData, answers: answers};

    try {
      let res;

      if (id) {
        res = await BaseRequest.put(`questions/${id}`, formData);
      } else {
        res = await BaseRequest.post('questions/save', formData);
      }

      toast.success(res.data.message, {
        closeButton: false,
        position: "bottom-right"
      });

      setTimeout(() => history.push(`${thisUri}/${idCategory}/edit`), 1000);

    } catch (error) {
      let errorMessage = error.response
        ? error.response.data.message
        : error.message;
      toast.error(errorMessage, {
        closeButton: false,
        position: "bottom-right"
      });
      setSubmitLoading(false);
    }

  };

  /** Toggle save modal  **/
  const toggleSaveModal = () => {
    triggerValidation()
      .then(isValid => isValid && setShowSaveModal(true));
  }

  /** Column definition for PG answers table **/
  const answerDTColumns = [
    {
      name: 'Jawaban (ID)',
      selector: 'answerId'
    },
    {
      name: 'Status Jawaban',
      selector: 'status'
    },
    {
      name: 'Bobot Jawaban',
      selector: 'pg_weight_score'
    },
    {
      cell: (row) =>
        <div className="btn-group-sm">
          <MDBBtn variant="contained" color="yellow" onClick={() => editPGAnswer(row)} >
            <MDBIcon icon="pen-nib" />
          </MDBBtn>
          <MDBBtn variant="contained" color="red" onClick={() => toggleDeletePGModal(row.id)}>
            <MDBIcon icon="trash" />
          </MDBBtn>
        </div>,
      button: true
    }
  ];

  /** Fixed Button event handler **/
  const onHover = () => {
    setFixedBtnStyle({
      transform: "scaleY(1) scaleX(1) translateY(0) translateX(0)",
      opacity: "1"
    })
  };

  const onMouseLeave = () => {
    setFixedBtnStyle({
      transform: "scaleY(0.4) scaleX(0.4) translateY(40px) translateX(0)",
      opacity: "0"
    })
  };

  return (
    <MDBContainer fluid>
      <h3>{pageTitle}</h3>
      {
        initialDataLoading ?
          <MDBRow>
            <MDBCol size="12" className="text-center my-5">
              <MDBSpinner big />
            </MDBCol>
          </MDBRow> :
          <form onSubmit={handleSubmit(submitForm)}>
            <MDBRow className={"mt-2"}>
              {/* Pertanyaan Section */}
              <MDBCol size={"12"} md={"6"} sm={"12"}>
                <MDBCard>
                  <MDBCardBody>
                    <MDBCardTitle>Pertanyaan</MDBCardTitle>
                    <MDBCardBody className="p-0">
                      <MDBRow className="compact">
                        <MDBCol size="12">
                          {
                            idCategory ?
                              <MDBInput
                                label="Kategori"
                                value={categoryName}
                                className="mb-2"
                                disabled
                              /> :
                              <>
                                <MDBSelect
                                  name="category_id"
                                  label="Kategori"
                                  selected="Pilih kategori soal"
                                  options={categoriesOptions}
                                  getValue={(v) => setValue("category_id", v[0])}
                                />
                                {
                                  errors.category_id &&
                                  errors.type.category_id === "required" && (
                                    <span className="text-danger small">Tidak boleh kosong</span>
                                  )
                                }
                              </>
                          }
                        </MDBCol>
                        <MDBCol size="12">
                          {/** Course List **/}
                          {
                            <>
                              <MDBSelect
                                name="course_part_id"
                                label="Pertemuan"
                                selected="Pilih pertemuan"
                                options={courseOptions}
                                className={errors.course_part_id && 'is-invalid'}
                                getValue={(v) => setValue("course_part_id", v[0])}
                              />
                              {
                                errors.course_part_id &&
                                errors.course_part_id.type === "required" && (
                                  <span className="text-danger small">Tidak boleh kosong</span>
                                )
                              }
                            </>
                          }
                        </MDBCol>
                        <MDBCol size="12">
                          {/** Tipe Soal **/}
                          <MDBSelect
                            name='type'
                            label="Tipe Soal"
                            selected="Pilih tipe soal"
                            options={questionDifficultyLevel}
                            className={errors.type && 'is-invalid'}
                            getValue={(v) => setValue('type', v[0])}
                          />
                          {
                            errors.type &&
                            errors.type.type === "required" && (
                              <span className="text-danger small">Tidak boleh kosong</span>
                            )
                          }
                        </MDBCol>

                        <MDBCol size="12">
                          {
                            answerType === answerTypes.ESSAY && <>
                            {/** Bobot Soal **/}
                            <MDBInput
                              label="Bobot Jawaban"
                              name="essay_weight_score"
                              onChange={handleChange}
                              className={`compact`}
                              value={watch('essay_weight_score')}
                            />
                            </>
                          }
                        </MDBCol>

                        {/** Pertanyaan ID **/}
                        <MDBCol size="12">
                          <MDBInput
                            type="textarea"
                            name="question_id"
                            onChange={handleChange}
                            className={errors.question_id && 'is-invalid'}
                            label="Pertanyaan (Indonesia)"
                            value={watch('question_id')}
                          >
                            {
                              (errors.question_id && errors.question_id.type === "required") ?
                                <InputError text="Tidak boleh kosong" /> : ''
                            }
                          </MDBInput>
                        </MDBCol>

                        {/** Pertanyaan EN **/}
                        <MDBCol size="12">
                          <MDBInput
                            type="textarea"
                            name="question_en"
                            onChange={handleChange}
                            className={`compact`}
                            label="Pertanyaan (English)"
                            value={watch('question_en')}
                          />
                        </MDBCol>

                        {/** Pertanyaan AR **/}
                        <MDBCol size="12">
                          <MDBInput
                            type="textarea"
                            name="question_ar"
                            onChange={handleChange}
                            className={`compact`}
                            label="Pertanyaan (Arabic)"
                            value={watch('question_ar')}
                          />
                        </MDBCol>
                      </MDBRow>
                    </MDBCardBody>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>

              {/* Jawaban Section */}
              <MDBCol size={"12"} md={"6"} sm={"12"}>
                <MDBCard>
                  <MDBCardBody>
                    <MDBCardTitle>Jawaban</MDBCardTitle>
                    <MDBCardBody className="p-0">
                      <MDBRow>
                        {/** Answers Type **/}
                        <MDBCol size="12">
                          <div className="form-inline">
                            <MDBInput
                              onClick={() => onClickAnswerType(answerTypes.ESSAY)}
                              checked={answerType === answerTypes.ESSAY}
                              label='Essay'
                              type='radio'
                              id='esay_type'
                              containerClass='mr-5'
                            />
                            <MDBInput
                              onClick={() => onClickAnswerType(answerTypes.BS)}
                              checked={answerType === answerTypes.BS}
                              label='Benar/Salah'
                              type='radio'
                              id='bs_type'
                              containerClass='mr-5'
                            />
                            <MDBInput
                              onClick={() => onClickAnswerType(answerTypes.PG)}
                              checked={answerType === answerTypes.PG}
                              label='Pilihan Ganda'
                              type='radio'
                              id='pg_type'
                              containerClass='mr-5'
                            />
                          </div>
                        </MDBCol>

                        {/** Answers **/}
                        <MDBCol size="12">
                          {
                            answerType === answerTypes.ESSAY &&
                            <>
                              <MDBInput
                                label="Jawaban (Indonesia)"
                                name="answer_id"
                                className={errors.answer_id && answerType === answerTypes.ESSAY ? 'is-invalid' : ''}
                                onChange={handleChange}
                                value={watch('answer_id')}
                              >
                                {
                                  (errors.answer_id && answerType === answerTypes.ESSAY && errors.answer_id.type === "required") ?
                                    <InputError text="Tidak boleh kosong" /> : ''
                                }
                              </MDBInput>
                              <MDBInput
                                label="Jawaban (English)"
                                name="answer_en"
                                onChange={handleChange}
                                value={watch('answer_en')}
                              />
                              <MDBInput
                                label="Jawaban (Arabic)"
                                name="answer_ar"
                                onChange={handleChange}
                                value={watch('answer_ar')}
                              />
                            </>
                          }
                          {
                            answerType === answerTypes.BS &&
                            <div className="mt-3">
                              Pilih jawaban yang benar
                              <div className="form-inline">
                                <MDBInput
                                  onClick={() => {
                                    setBsAnswer(bsAnswers.BENAR);
                                    setValue('answer_id', bsAnswers.BENAR);
                                  }}
                                  checked={bsAnswer === bsAnswers.BENAR}
                                  label={bsAnswers.translation.benar.id}
                                  type='radio'
                                  id='bs_benar'
                                  containerClass='mr-5'
                                  name='answer_id'
                                  className={errors.answer_id && answerType === answerTypes.BS ? 'is-invalid' : ''}
                                />

                                <MDBInput
                                  onClick={() => {
                                    setBsAnswer(bsAnswers.SALAH);
                                    setValue('answer_id', bsAnswers.SALAH);
                                  }}
                                  checked={bsAnswer === bsAnswers.SALAH}
                                  label={bsAnswers.translation.salah.id}
                                  type='radio'
                                  id='bs_salah'
                                  containerClass='mr-5'
                                  className={errors.answer_id && answerType === answerTypes.BS ? 'is-invalid' : ''}
                                />
                              </div>
                              {
                                (errors.answer_id && answerType === answerTypes.BS && errors.answer_id.type === "required") ?
                                  <span className="text-danger small">Tidak boleh kosong</span> : ''
                              }
                            </div>
                          }
                          {
                            answerType === answerTypes.PG &&
                            <div>
                              <MDBRow>
                                <MDBCol size="12">
                                  <MDBInput
                                    label="Jawaban (Indonesia)"
                                    className={(errors.pg_answer_list) && 'is-invalid'}
                                    name="pg_answer_id"
                                    onChange={handleChange}
                                    value={watch('pg_answer_id')}
                                  >
                                    {
                                      errors.pg_answer_list &&
                                        <InputError text={errors.pg_answer_list.message} />
                                    }
                                  </MDBInput>
                                  <MDBInput
                                    label="Jawaban (English)"
                                    name="answer_en"
                                    onChange={handleChange}
                                    value={watch('answer_en')}
                                  />
                                  <MDBInput
                                    label="Jawaban (Arabic)"
                                    name="answer_ar"
                                    onChange={handleChange}
                                    value={watch('answer_ar')}
                                  />
                                  <MDBInput
                                    label="Bobot Jawaban"
                                    name="pg_weight_score"
                                    onChange={handleChange}
                                    value={watch('pg_weight_score')}
                                  />
                                </MDBCol>
                              </MDBRow>
                              <MDBRow>
                                <MDBCol size="8">
                                  <div className="form-check">
                                    <input
                                      type="checkbox"
                                      className="form-control form-check-input mt-0 mb-0"
                                      name="is_correct_answer" id="checkbox1"
                                      onChange={handleCheckboxChange}
                                      checked={watch('is_correct_answer') || false}
                                    />
                                    <label className="form-check-label mr-5" htmlFor="checkbox1" data-error="" data-success="" id="checkbox1">Jawaban benar</label>
                                    {
                                      errors.pg_correct_answer_no &&
                                      <>
                                        <br />
                                        <span className="text-danger small">{errors.pg_correct_answer_no.message}</span>
                                      </>
                                    }
                                  </div>
                                </MDBCol>
                                <MDBCol size="4" className="text-right">
                                  {
                                    isEditPGAnswerMode ?
                                      <>
                                        <MDBBtn size="sm" color="yellow" type="button" onClick={updatePGAnswersHandler}>Ubah</MDBBtn>
                                        <MDBBtn size="sm" color="red" type="button" onClick={cancelPGAnswerEditHandler}>Batal</MDBBtn>
                                      </> :
                                      <MDBBtn size="sm" color="green" type="button" onClick={addPGAnswersHandler}>Tambahkan</MDBBtn>
                                  }
                                </MDBCol>
                              </MDBRow>
                              <MDBRow>
                                <DataTable
                                  noHeader
                                  persistTableHead
                                  noDataComponent={"Belum ada data jawaban"}
                                  columns={answerDTColumns}
                                  data={pgAnswers}
                                  highlightOnHover
                                  expandableRows
                                  expandableRowsComponent={<ExpandableRowComponent />}
                                />
                              </MDBRow>

                              {/** Delete Modal **/}
                              <DeleteModal
                                isOpen={showDeletePGModal}
                                toggle={toggleDeletePGModal}
                                handler={deletePGAnswerHandler}
                                loadingIndicator={deletePGLoading}
                              />
                            </div>
                          }
                        </MDBCol>
                      </MDBRow>
                    </MDBCardBody>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
            </MDBRow>

            {/** Menu Button **/}
            <div className={"fixed-btn-group btn-item-2"}>
              <MDBBtnFixed
                floating
                color="blue"
                icon="ellipsis-h"
                size="lg"
                style={{ bottom: "55px", right: "24px" }}
                onMouseEnter={onHover}
                onMouseLeave={onMouseLeave}
              >
                {/** Cancel soal creation **/}
                <MDBBtnFixedItem
                  buttonStyle={fixedBtnStyle}
                  color="red"
                  icon="times-circle"
                  title={"Cancel"}
                  onClick={() => history.push(`${thisUri}/${idCategory}/edit`)}
                />

                {/** Submit Button **/}
                <MDBBtnFixedItem
                  buttonStyle={fixedBtnStyle}
                  color="green"
                  icon="save"
                  title={"Simpan"}
                  onClick={toggleSaveModal}
                />
              </MDBBtnFixed>
            </div>

            {/** Save Modal **/}
            <SaveModal
              isOpen={showSaveModal}
              toggle={() => setShowSaveModal(!showSaveModal)}
              handler={handleSubmit(submitForm)}
              loadingIndicator={submitLoading}
            />
          </form>
      }
    </MDBContainer>
  );
};

export default  SoalFormPage;
