import React, {useCallback, useEffect, useState} from "react";
import {default as CustomDataTable} from "../components/DataTable";
import BaseRequest from "../../services/BaseRequest";

const DaftarProgramAktif = () => {
  /** Datatable variables **/
  const [ loadProgressData, setLoadProgressData ] = useState(true);
  const [ dtRowsData, setDtRowsData ] = useState([]);
  const [ dtSortBy, setDtSortBy ] = useState('name');
  const [ dtSortDirection, setDtSortDirection ] = useState('desc');
  const [ dtTotalRow, setDtTotalRow ] = useState(1);
  const [ dtPage, setDtPage ] = useState(1);
  const [ dtPerPage, setDtPerPage ] = useState(5);

  const columns = [
    {
      name: "Nama",
      selector: "name",
      sortable: true,
      style: {
        whiteSpace: 'none',
      }
    },
    {
      name: "Total Peserta",
      selector: "total_participant",
      sortable: false,
    },
    {
      name: "Ikhwan",
      selector: "total_ikhwan",
      sortable: false,
      width: '5rem'
    },
    {
      name: "Akhwat",
      selector: "total_akhwat",
      sortable: false,
      width: '5rem'
    },
  ]

  const loadDataProgramAktif = useCallback(async () => {
    setLoadProgressData(true);

    const { data: { data }} = await BaseRequest.post('/classes', {
      sortBy: dtSortBy,
      desc: dtSortDirection === 'desc',
      page: dtPage,
      limit: dtPerPage,
      filter: {
        status: 'active'
      }
    });

    setDtTotalRow(data.total);

    return { data: data.data };
  }, [dtSortBy, dtSortDirection, dtPage]);

  const reloadDataFromServer = useCallback(() => {
    loadDataProgramAktif()
      .then((res) => {
        const { data } = res;

        setDtRowsData(data);
      })
      .finally(() => {
        setLoadProgressData(false);
      });
  }, [loadDataProgramAktif]);

  useEffect(() => {
    reloadDataFromServer();
  }, [reloadDataFromServer]);

  /** Datatable sorting **/
  const onSortHandler = (column, sortDirection) => {
    setDtSortBy(column.selector);
    setDtSortDirection(sortDirection);
  };

  /** Datatable Change Page handler **/
  const onPageChangeHandler = (page) => {
    setDtPage(page)
  }

  return (
    <CustomDataTable
      noHeader={true}
      columns={columns}
      data={dtRowsData}
      totalRow={dtTotalRow}
      progressPending={loadProgressData}
      defaultSortField={dtSortBy}
      defaultSortDir={dtSortDirection}
      onSortHandler={onSortHandler}
      noRowsPerPage={true}
      handlePageChange={onPageChangeHandler}
      paginationPerPage={dtPerPage}
    />
  );
}

export default DaftarProgramAktif;
