import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import {
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBIcon,
  MDBInput,
  MDBCollapse,

  MDBTable, MDBTableBody, MDBTableHead
} from "mdbreact";
import InputError from "../../components/InputError";

const formField = [
  { name: "id" },
  { name: "question_idx" },
  { name: "question_id", rules: { required: true } },
  { name: "question_en" },
  { name: "question_ar" },

  { name: "answer_idx" },
  { name: "answer_id" },
  { name: "answer_en" },
  { name: "answer_ar" },
  { name: "is_correct_answer" },
];


const RenderButton = ({ closeModal }) => {
  return (
    <div>
      <MDBBtn size="md" color="red" className="mx-0" onClick={closeModal}>
        Tutup
      </MDBBtn>
      <MDBBtn type="submit" color="green" size="md">
        Simpan
      </MDBBtn>
    </div>
  );
};

const QuizQuestionFormPage = ({ setQuestions, closeModal, question }) => {
  const { id } = useParams(); // Jika id !== undefined, maka ini adalah fitur edit data
  const refCheckbox = useRef(null);
  const { register, handleSubmit, errors, setValue, watch } = useForm();

  let [collapseID, setCollapseID] = useState(false);
  let [listAnswer, setListAnswer] = useState([]);

  const clearAnswer = () => {
    setValue('answer_idx','')
    setValue('answer_id','')
    setValue('answer_en','')
    setValue('answer_ar','')
    setValue('is_correct_answer', false)
  }
  const toggleCollapse = () => {
    setCollapseID(!collapseID)
    clearAnswer()
  }

  let answer_idx = watch('answer_idx')
  let answer_id = watch('answer_id')
  let answer_en = watch('answer_en')
  let answer_ar = watch('answer_ar')
  let is_correct_answer = watch('is_correct_answer')

  const handleChange = e => {
    setValue(e.target.name, e.target.value);
  };

  const handleCorrectAnswerChange = e => {
    setValue(e.target.name, e.target.checked);
  };

  useEffect(() => {
    for (let v of formField) {
      register({ name: v.name }, v.rules || null);
    }
  }, [register]);

  useEffect(() => {
    if (question) {
      for (let key in question) {
        setValue(key, question[key] || "");
      }

      let answers = question.answers.map((v, i) => ({
        answer_idx: v.id,
        answer_id: v.answer_id,
        answer_en: v.answer_en,
        answer_ar: v.answer_ar,
        is_correct_answer: v.is_correct_answer
      }))

      setListAnswer(answers)
    }
  }, [question, setValue]);

  const addAnswer = () => {
    if(!answer_id) return false
    let answers = listAnswer
    let idx = answers.findIndex(v => v.answer_idx === answer_idx)
    if(idx >= 0){
      answers[idx] = {
        answer_idx,
        answer_id,
        answer_en,
        answer_ar,
        is_correct_answer
      }
    }else{
      let d = new Date()
      let answer_idx = d.getFullYear()+d.getMonth()+d.getDate()+d.getHours()+d.getMinutes()+d.getSeconds()+d.getMilliseconds()
      answers.push({
        answer_idx,
        answer_id,
        answer_en,
        answer_ar,
        is_correct_answer
      })
    }

    clearAnswer()
    setCollapseID(false)
    setListAnswer(answers)
  }

  const deleteAnswer = (answer) => {
    let answers = listAnswer
    const idx = answers.findIndex(v => v.answer_idx === answer.answer_idx);
    answers.splice(idx, 1);
    setListAnswer(answers)
    clearAnswer()
    setCollapseID(false)
  }

  const editAnswer = (answer) => {
    setValue('answer_idx', answer.answer_idx)
    setValue('answer_id', answer.answer_id)
    setValue('answer_en', answer.answer_en)
    setValue('answer_ar', answer.answer_ar)
    setValue('is_correct_answer', answer.is_correct_answer)
    setCollapseID(true)
  }

  const onSubmit = async payload => {
    delete payload.answer_idx
    delete payload.answer_id
    delete payload.answer_en
    delete payload.answer_ar
    delete payload.is_correct_answer
    payload.answers = listAnswer

    setQuestions(payload)
    closeModal()
  };

  return (
    <MDBContainer>
      <MDBRow center>
        <MDBCol size="12" md="9" sm="12">
          <MDBCard>
            <MDBCardBody>
              <MDBCardTitle>
                {id ? "Edit" : "Tambah"} Pertanyaan
              </MDBCardTitle>
              <hr />
              <form onSubmit={handleSubmit(onSubmit)}>
                <MDBContainer>
                  <MDBRow>
                    <MDBCol size="12">

                      <MDBInput
                        className={errors.question_id && "is-invalid"}
                        name="question_id"
                        onChange={handleChange}
                        type="textarea"
                        label="Pertanyaan (Indonesia)"
                        value={watch("question_id")}
                      >
                        {errors.question_id &&
                          errors.question_id.type === "required" && (
                            <InputError text="Tidak boleh kosong" />
                          )}
                      </MDBInput>

                      <MDBInput
                        name="question_en"
                        onChange={handleChange}
                        type="textarea"
                        label="Pertanyaan (English)"
                        value={watch("question_en")}
                      />

                      <MDBInput
                        name="question_ar"
                        onChange={handleChange}
                        type="textarea"
                        label="Pertanyaan (Arabic)"
                        value={watch("question_ar")}
                      />
                      <div className="d-flex justify-content-end">
                        <MDBBtn className="" size="md" color="secondary" onClick={toggleCollapse}>Tambah Jawaban</MDBBtn>
                      </div>
                      <MDBCollapse id="basicCollapse" className="mb-3" isOpen={collapseID}>
                        <MDBCard className="card-body" style={{ marginTop: "1rem" }}>
                          <MDBInput
                            name="answer_id"
                            onChange={handleChange}
                            type="text"
                            label="Jawaban (Indonesia)"
                            value={watch("answer_id")}
                          />

                          <MDBInput
                            name="answer_en"
                            onChange={handleChange}
                            type="text"
                            label="Jawaban (English)"
                            value={watch("answer_en")}
                          />

                          <MDBInput
                            name="answer_ar"
                            onChange={handleChange}
                            type="text"
                            label="Jawaban (Arabic)"
                            value={watch("answer_ar")}
                          />

                          <MDBCol md="4" className="mb-3">
                            <div className="custom-control custom-checkbox pl-2">
                              <input
                                className="custom-control-input"
                                type="checkbox"
                                name="is_correct_answer"
                                onChange={handleCorrectAnswerChange}
                                ref={refCheckbox}
                                checked={watch('is_correct_answer') || false}
                                id="invalidCheck"
                              />
                              <label className="custom-control-label" htmlFor="invalidCheck">
                                Jawaban Benar<br/><i className='small'>Ceklis jika jawaban benar</i>
                              </label>
                            </div>
                          </MDBCol>
                          <div className="d-flex justify-content-end">
                            <MDBBtn className="" size="md" color="secondary" onClick={addAnswer}>{ watch('answer_idx')? 'Edit' : 'Add' }</MDBBtn>
                          </div>
                        </MDBCard>
                      </MDBCollapse>

                      <MDBTable>
                        <MDBTableHead>
                          <tr>
                            <th>#</th>
                            <th>Jawaban (ID)</th>
                            <th>Jawaban (En)</th>
                            <th>Jawaban (Ar)</th>
                            <th>Status Jawaban</th>
                            <th></th>
                          </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                          { listAnswer.map((a, index)=>
                            <tr key={index}>
                              <td> {(index + 1)}</td>
                              <td>{a.answer_id}</td>
                              <td>{a.answer_en}</td>
                              <td>{a.answer_ar}</td>
                              <td>{a.is_correct_answer? 'Benar' : 'Salah'}</td>
                              <td>
                              <MDBBtn size="md" color="red" onClick={() => deleteAnswer(a)}>
                                <MDBIcon icon="trash" />
                              </MDBBtn>
                              <MDBBtn size="md" color="yellow" onClick={() => editAnswer(a, index)}>
                                <MDBIcon icon="pen-nib" />
                              </MDBBtn>
                              </td>
                            </tr>
                          )}
                        </MDBTableBody>
                      </MDBTable>

                    </MDBCol>
                  </MDBRow>
                </MDBContainer>
                <div className="text-center mt-3 black-text">
                  <RenderButton closeModal={closeModal} />
                </div>
              </form>

            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default QuizQuestionFormPage;
