import React, {useCallback, useEffect, useMemo, useState} from "react";
import moment from "moment";
import BaseRequest from "../../../services/BaseRequest";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
  MDBRow,
  MDBSelect,
  MDBSpinner,
  toast
} from "mdbreact";
import {default as CustomDataTable} from "../../components/DataTable";
import DeleteModal from "../../modals/DeleteModal";
import { debounce } from "@material-ui/core";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const thisUri = "/participants";
const apiUri = "/participants";

const ParticipantPage = () => {
  const history = useHistory();
  const location = useLocation();
  let { gender } = useParams();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showGroupParticipantDeleteModal, setShowGroupParticipantDeleteModal] = useState(false);
  const [deleteGrouParticipantLoading, setDeleteGrouParticipantLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [isGroupModalOpen, setIsGroupModalOpen] = useState(false);
  const [saveGroupLoading, setSaveGroupLoading] = useState(false);
  const [selectedParticipant, setSelectedParticipant] = useState(null);
  const [selectedDeletedParticipant, setSelectedDeletedParticipant] = useState(null);
  const [selectedDeletedGroup, setSelectedDeletedGroup] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState([]);
  const [classListForOptions, setClassListForOptions] = useState([]);
  const [groupListForOptions, setGroupListForOptions] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [selectedParticipants] = useState([]);
  /** Datatable variables **/
  const [ dtRowsData, setDtRowsData ] = useState([]);
  const [ dtSortBy, setDtSortBy ] = useState('name');
  const [ dtSortDirection, setDtSortDirection ] = useState('asc');
  const [ dtTotalRow, setDtTotalRow ] = useState(1);
  const [ dtSearchMeta, setDtSearchMeta ] = useState({
    filter: {
      gender: gender
    },
    field_search: 'name,nip, phone',
    search: '',
    classId: null,
    groups: [],
  });
  const [ dtPage, setDtPage ] = useState(1);
  const [ dtPerRow, setDtPerRow ] = useState(10);
  const [ loadProgressData, setLoadProgressData ] = useState(false);

  /** Datatable column definition **/
  const columns = [
    {
      name: 'No',
      selector: 'rowNo',
      width: "4rem",
      right: true
    },
    {
      cell: (row) =>
        <div>
          <span style={{color:'rgb(140, 131, 131)'}}>{row.nip}</span>
          <br/>
          <span style={{fontWeight:'bold'}}>
            <span style={{color:'rgb(48, 139, 218)'}}>{row.name}</span> ({row.age}thn)
          </span>
          <br/>
          {((row.country) ? row.country.name : '')} {((row.city) ? <>| {row.city.name}</> : '')}
        </div>,
      name: "Peserta",
      selector: "name",
      sortable: true,
      grow: 1,
    },
    {
      name: "Kontak",
      selector: "nilai",
      width: "8rem",
    },
    {
      cell: (row) => {
        return (
          <ul className={"mt-2"} style={{ padding:0, listStyleType: "none"}}>
            {
              row.groups
                .sort((a, b) => a.classes ? (a.classes.name > b.classes.name ? 1 : -1) : -1)
                .map((group, i) =>
                  <li key={i}>
                    &rarr; {group.classes && group.classes.name}<br/>
                    &rarr; <Link to={`/kelas/groups/${group.classes.id}/${group.id}/detail`}>Group {group.name_id}</Link>
                    <MDBBtn color="link" rippleColor='danger' onClick={() => handleGroupParticipantDeleteClick(row.id, group.id)}>
                      <MDBIcon icon="thrash"/>[x]Hapus Keanggotaan?
                    </MDBBtn>
                  </li>
                )
            }
          </ul>
        );
      },
      name: "Kelas/Groups",
      selector: "groups",
      grow:1,
    },
    {
      name: "Last Login",
      selector: "last_login_at",
      cell: (row) => 
        <div>
          <span>{row.last_login_at || <span className="text-muted">n/a</span>}</span>
          {row.last_login_at && <>
            <br/><small>{moment(row.last_login_at).fromNow()}</small>
          </>}
        </div>,
      compact: true,
      grow: 0,
    },
    {
      cell: (row) =>
        <div className="btn-group-sm">
          <MDBBtn variant="contained" color="blue" title="Grup" onClick={() => showGroupPicker(row.id, row.groups)} >
            <MDBIcon icon="users" />
          </MDBBtn>
          <MDBBtn variant="contained" color="yellow" title="Edit" onClick={() => history.push(`${thisUri}/${row.id}/edit`)} >
            <MDBIcon icon="pen-nib" />
          </MDBBtn>
          <MDBBtn variant="contained" color="purple" title="Detail" onClick={() => history.push(`${thisUri}/${row.id}/show`)} >
            <MDBIcon icon="eye" />
          </MDBBtn>
          <MDBBtn variant="contained" color="green" title="Log" onClick={() => history.push(`${thisUri}/${row.id}/logs`)} >
            <MDBIcon icon="list-alt" />
          </MDBBtn>
          <MDBBtn variant="contained" color="info" title="Transkrip" onClick={() => history.push(`${thisUri}/${row.id}/transcript`)} >
            <MDBIcon icon="graduation-cap" />
          </MDBBtn>
        </div>,
      button: true,
      name: "#",
      grow: 0,
      minWidth: "10rem"
    }
  ];

  const showGroupPicker = (participantID, assignedParticipantGroups) => {
    setSelectedParticipant(participantID);
    setSelectedGroup(assignedParticipantGroups);

    let participantGroupList = groupList.map((group) => ({
      ...group,
      checked: assignedParticipantGroups.some(participantGroup => participantGroup.id === group.value)
    }))

    setGroupList(participantGroupList);

    toggleGroupModal();
  }

  const getClassesWithGroupList = useCallback(async () => {
    let uri = `classes`;

    try {
      const {
        data: { data }
      } = await BaseRequest.post(uri, {
        sortBy: 'name',
        desc: false,
        page: 1,
        limit: -1,
        with: 'groups',
        filter: {
          'groups.gender': gender
        }
      });

      let classesForOptions = data.data.map((classes) => ({ text: classes.name, value: classes.id }));
      setClassListForOptions([{
        "text": 'Semua kelas/program',
        "value": '',
        "checked": true
      }, ...classesForOptions]);

      // Map the group data
      let classesWithGroups = [];
      data.data.forEach((classes) => {
        if (classes.groups) {
          classesWithGroups.push({
            disabled: true,
            text: classes.name
          });

          let mappedGroup = classes.groups
            .sort((a, b) => a.name_id > b.name_id ? 1 : -1)
            .map((group) => ({
              text: group.name_id,
              value: group.id
            }));

          classesWithGroups = [...classesWithGroups, ...mappedGroup];
        }
      });

      setGroupList(classesWithGroups);
    } catch (error) {
      let errorMessage = error.response
        ? error.response.data.message
        : error.message;
      toast.error(errorMessage, {
        closeButton: false,
        position: "bottom-right"
      });
    }

    return { rows: [] }
  }, [gender])

  useEffect(() => {
    getClassesWithGroupList();
  }, [getClassesWithGroupList])

  const handleSendWA = item => {
    let waNumber = item.phone_country_code.replace('+','') + item.phone
    window.open(`https://api.whatsapp.com/send?phone=${waNumber}&text=`,'_blank')
  }

  /** Datatable get data **/
  const getDataParticipants = useCallback(async (page = 1, perPage = 10) => {
    let uri = `${apiUri}`;
    let filter = dtSearchMeta;

    if (filter.classId) {
      uri += `/${filter.classId}/by-class`;

      if (filter.groups) {
        filter.groups = filter.groups.filter(el => {
          return el !== '';
        });        
        if (filter.groups.length <= 0) delete filter['groups'];
      }
    }

    try {
      setLoadProgressData(true);

      const {
        data: { data }
      } = await BaseRequest.post(uri, {
        sortBy: dtSortBy,
        desc: dtSortDirection === 'desc',
        page: page,
        limit: perPage,
        with: 'groups',
        ...filter
      });

      setDtTotalRow(data.total);

      // Map the questions data
      const mappedData = data.data.map((v, i) => ({
        ...v,
        rowNo: data.from + i,
        id: v.id,
        nilai: (
          <div className="btn-group-sm">
            <MDBBtn color="green" onClick={() => handleSendWA(v)}>
              {/*<MDBIcon icon="whatsapp" />*/}Kirim WA
            </MDBBtn>
          </div>
        ),
        groups: v.groups || [],
        created_at: moment(v.created_at).format("DD/MM/YYYY"),
        updated_at: moment(v.updated_at).format("DD/MM/YYYY"),
      }));

      return { rows: mappedData }
    } catch (error) {
      let errorMessage = error.response
        ? error.response.data.message
        : error.message;
      toast.error(errorMessage, {
        closeButton: false,
        position: "bottom-right"
      });
    } finally {
      setLoadProgressData(false);
    }

    return { rows: [] }
  }, [dtSearchMeta, dtSortBy, dtSortDirection]);

  const reloadDataFromServer = useCallback((page = 1, perPage = 10) => {
    getDataParticipants(page, perPage)
      .then((res) => {
        const { rows } = res;

        setDtRowsData(rows);
      });
  }, [getDataParticipants]);

  const getGroupsByClassId = async (classId) => {
    try {
      const { data: { data } } = await BaseRequest.post('/groups', {
        limit: -1,
        sortBy: 'name_id',
        desc: false,
        filter: {
          classes_id: classId
        }
      });

      let groupsForOptions = data.data.map((group) => ({ text: group.name_id, value: group.id }));
      setGroupListForOptions([{
        "text": 'Semua group',
        "value": '',
        "checked": true
      }, ...groupsForOptions]);

    } catch (error) {
      let errorMessage = error.response
        ? error.response.data.message
        : error.message;
      toast.error(errorMessage, {
        closeButton: false,
        position: "bottom-right"
      });
    }
  }

  useEffect(() => {
    let searchMeta = dtSearchMeta;
    searchMeta.filter = {
      gender: gender
    }
    searchMeta.search = '';
    setDtSearchMeta(searchMeta);
  }, [location])

  /** Datatable sorting **/
  const onSortHandler = (column, sortDirection) => {
    setDtSortBy(column.selector);
    setDtSortDirection(sortDirection);

    reloadDataFromServer();
  };

  /** Datatable Filter **/
  const subHeaderComponentMemo = useMemo(() => {

    const handleFilter = debounce((keyword) => {
      let newSearchMeta = dtSearchMeta;
      newSearchMeta.search = keyword;
      setDtSearchMeta(newSearchMeta);

      getDataParticipants(dtPage, dtPerRow)
        .then((res) => {
          const { rows } = res;
          setDtRowsData(rows);
        });
    }, 500);

    const handleFilterByClass = (keyword, fieldName) => {
      let filter = dtSearchMeta;
      filter[fieldName] = keyword;

      if (fieldName === 'classId' && filter.groups) delete filter.groups;

      setDtSearchMeta(filter);

      if (fieldName === 'classId') {
        // get the group list based on selected class
        getGroupsByClassId(keyword);
      } else {
        // by groups, sekarang baru kita refresh data
        getDataParticipants(dtPage, dtPerRow)
          .then((res) => {
            const { rows } = res;
            setDtRowsData(rows);
          });
      }
    }

    return (
      <>
        <MDBRow className={"w-100"}>
          <MDBCol size={"12"} md={"4"} sm={"12"}>
            <MDBInput
              label="Cari Nama / NIP / No HP"
              onChange={(e) => handleFilter(e.target.value)}
              name="search"
              autoComplete="off"
            />
          </MDBCol>
          <MDBCol size={"12"} md={"4"} sm={"12"}>
            <MDBSelect
              label="Cari Kelas"
              getValue={(val) => handleFilterByClass(val[0], 'classId')}
              className={"w-100"}
              name="class"
              options={classListForOptions}
            />
          </MDBCol>
          <MDBCol size={"12"} md={"4"} sm={"12"}>
            <MDBSelect
              label="Cari Group"
              getValue={(val) => handleFilterByClass(val, 'groups')}
              className={"w-100"}
              name="class"
              options={groupListForOptions}
            />
          </MDBCol>
      </MDBRow>
      {dtTotalRow>0 && <small className="font-weight-bolder">Menemukan &rarr; {dtTotalRow} peserta</small>}
    </>

    )
  }, [dtSearchMeta, getDataParticipants, classListForOptions, groupListForOptions, dtTotalRow]);

  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  }

  const handleDelete = async () => {
    try {
      /*setDeleteLoading(true);

      await BaseRequest.delete(`${apiUri}/${selectedDeleteItem}`);
      reloadDataFromServer();*/
    } catch (err) {
      console.error(err);
    } finally {
      toggleDeleteModal();
      setDeleteLoading(false);
    }
  };

  const handlePageChange = (page) => {
    setDtPage(page)
    reloadDataFromServer(page, dtPerRow)
  }

  const handlePerRowsChange = (perRow) => {
    setDtPerRow(perRow)
    reloadDataFromServer(dtPage, perRow)
  }

  const toggleGroupModal = () => {
    setIsGroupModalOpen(!isGroupModalOpen);
  }

  //
  const handleGroupParticipantDelete = async () => {
    setDeleteGrouParticipantLoading(true);
    try {
      let res = await BaseRequest.post("group-participants/delete-group",{
        user_id: selectedDeletedParticipant,
        group_id: selectedDeletedGroup
      }).finally(() => {
        setDeleteGrouParticipantLoading(false);
      });

      toast.success(res.data.message, {
        closeButton: false,
        position: 'bottom-right',
      });

      setShowGroupParticipantDeleteModal(false);
      reloadDataFromServer();
    } catch (error) {
      let errorMessage = error.response
        ? error.response.data.message
        : error.message;
      toast.error(errorMessage, {
        closeButton: false,
        position: "bottom-right"
      });
    }
  }

  const handleGroupParticipantDeleteClick = (selectedUserId, selectedGroupId) => {
    setShowGroupParticipantDeleteModal(true);
    setSelectedDeletedParticipant(selectedUserId);
    setSelectedDeletedGroup(selectedGroupId);
  }

  const toggleGroupParticipantDeleteModal = () => {
    setShowGroupParticipantDeleteModal(!showGroupParticipantDeleteModal);
  }
  //  

  const saveGroupForParticipant = async () => {
    setSaveGroupLoading(true);

    try {
      let res = await BaseRequest.post("group-participants/save-multiple-group", {
        groups: selectedGroup,
        user_id: selectedParticipant
      }).finally(() => {
        setSaveGroupLoading(false);
      });

      toast.success(res.data.message, {
        closeButton: false,
        position: 'bottom-right',
      });

      setIsGroupModalOpen(false);
      reloadDataFromServer();

    } catch (error) {
      let errorMessage = error.response
        ? error.response.data.message
        : error.message;
      toast.error(errorMessage, {
        closeButton: false,
        position: "bottom-right"
      });
    }
  }

  const onSelectGroupChangeHandler = (value) => {
    setSelectedGroup(value);
  }

  return (
    <MDBContainer fluid>
      <h3>Peserta {gender[0].toUpperCase() + gender.slice(1)}</h3>

      <CustomDataTable
        columns={columns}
        data={dtRowsData}
        defaultSortField={'name'}
        onSortHandler={onSortHandler}
        subHeaderComponent={subHeaderComponentMemo}
        progressPending={loadProgressData}
        totalRow={dtTotalRow}
        handlePageChange={handlePageChange}
        handlePerRowsChange={handlePerRowsChange}
      />

      {/* Select Group Modal */}
      <MDBModal
        isOpen={isGroupModalOpen}
        toggle={toggleGroupModal}
        backdrop={false}
        cascading
      >
        <MDBModalHeader
          toggle={toggleGroupModal}
          titleClass="d-inline title"
          className="text-center blue darken-3 white-text"
        >
          <MDBIcon icon="save" className={"pr-2"}/>
          Pilih group untuk participant
        </MDBModalHeader>
        <MDBModalBody>
          <MDBSelect
            search
            multiple
            options={groupList}
            label="Pilih group"
            getValue={(value => onSelectGroupChangeHandler(value))}
          />
        </MDBModalBody>
        <MDBModalFooter>
          {
            saveGroupLoading ?
              <MDBSpinner /> :
              <>
                <MDBBtn color="secondary" onClick={toggleGroupModal}>
                  Batal
                </MDBBtn>
                <MDBBtn color="primary" onClick={saveGroupForParticipant}>
                  Simpan
                </MDBBtn>
              </>
          }
        </MDBModalFooter>
      </MDBModal>

      <DeleteModal
        isOpen={showDeleteModal}
        toggle={toggleDeleteModal}
        handler={handleDelete}
        loadingIndicator={deleteLoading}
        message={`Anda akan menghapus ${selectedParticipants.length} participant. Lanjutkan ?`}
      />

      <DeleteModal 
        isOpen={showGroupParticipantDeleteModal}
        toggle={toggleGroupParticipantDeleteModal}
        handler={handleGroupParticipantDelete}
        loadingIndicator={deleteGrouParticipantLoading}
        message={`Peserta akan dihapus keanggotaannya dari Grup. Lanjutkan?`}
      />
    </MDBContainer>
  );
};

export default ParticipantPage;
