import React, {useState, useEffect} from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  toast,
  MDBStepper,
  MDBStep,
  MDBBtn,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBIcon,
  MDBModal,
  MDBModalBody
} from "mdbreact";
import BaseRequest from "../../../services/BaseRequest";
import QuestionModal from "./QuestionModal";
import WarningModal from "components/modals/WarningModal";
import OptionModal from "./OptionModal";

const Option = ({option, onEdit, onDelete}) => {
  return (
    <li>
      {option.label}{" "}
      <button className="border border-light" onClick={onEdit} style={{color:'#0000cc'}}>
        Edit
      </button>{" "}
      <button className="border border-light" onClick={onDelete} style={{color:'#cc0000'}}>
        Hapus
      </button>
    </li>
  );
}

const SurveyQuestionsForm = ({id}) => {
  const [questions, setQuestions] = useState([]);
  const [questionModal, setQuestionModal] = useState({visible: false, item:{}});
  const [optionModal, setOptionModal] = useState({visible: false, item:{}});
  const [deleteModal, setDeleteModal] = useState({visible: false, item:{}, mode: null});

  const getQuestions = async () => {
    try {
      const {
        data: {data}
      } = await BaseRequest.post(`surveys/questions/`, { survey_id: id});
      setQuestions(data);
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeletion = async () => {
    try {
      if (deleteModal?.mode == "question")
        await BaseRequest.delete(`surveys/questions/${deleteModal?.item.id}`);
      else
        await BaseRequest.delete(`surveys/options/${deleteModal?.item.id}`);
    } catch (err) {
      toast(err);
    } finally {
      setDeleteModal({visible: false, item:{}});
      getQuestions();
    }
  }
  
  useEffect(()=>{
    getQuestions();
  },[]);

  return <div>
    <MDBTable bordered>
      <MDBTableHead color="light">
        <tr>
          <th>Pertanyaan</th>
          <th>Tipe</th>
          <th>Mandatory</th>
          <th>&nbsp;</th>
        </tr>
      </MDBTableHead>
      <MDBTableBody>
        {questions?.length==0 && <tr><td colSpan="4">Belum ada pertanyaan</td></tr>}
        {questions.map((question,idx) => 
          <tr key={idx}>
            <td>
              <div style={{fontWeight: "bold"}}>{question.title}</div>
              <div><em>{question.hint || "n/a"}</em></div>
              {question.type!="text" && 
              <ul>
                {question.options.map((opt, idx) => 
                  <Option option={opt} key={idx} 
                    onEdit={() => setOptionModal({visible:true, item:opt})}
                    onDelete={() => setDeleteModal({visible:true, item:opt, mode:'option'})}
                    />
                  )}
                <li>
                  <button className="border border-light" 
                    onClick={()=>setOptionModal({visible:true, item:{question_id:question.id}})} style={{color:'#0000cc'}}>+
                  </button>
                </li>
              </ul>
              }
            </td>
            <td>{question.type}</td>
            <td>{question.is_mandatory? "Ya":"Tidak"}</td>
            <td>
              <div className="btn-group-sm">
                <MDBBtn variant="contained" color="yellow" onClick={() => {
                  setQuestionModal({visible: true, item: question});
                }}>
                  <MDBIcon icon="pen-nib" />
                </MDBBtn>
                <MDBBtn variant="contained" color="red" onClick={() => {
                  setDeleteModal({visible: true, item: question, mode:'question'});
                }}>
                  <MDBIcon icon="trash" />
                </MDBBtn>
              </div>
            </td>
          </tr>
        )}
      </MDBTableBody>
    </MDBTable>
    <MDBBtn color="primary" onClick={()=> setQuestionModal({visible:true, item:{survey_id: id}})}>
      Tambah Pertanyaan
    </MDBBtn>
    <QuestionModal
      question={questionModal.item}
      isOpen={questionModal.visible}
      toggle={() => {
        setQuestionModal({visible: !questionModal.visible, item: {}});
      }}
      onRefresh={getQuestions}
      />
    <OptionModal
      option={optionModal.item}
      isOpen={optionModal.visible}
      toggle={() => {
        setOptionModal({visible: !optionModal.visible, item: {}});
      }}
      onRefresh={getQuestions}
      />
    <WarningModal
      isOpen={deleteModal.visible}
      toggle={() => setDeleteModal({visible: !deleteModal.visible, item: {}})}
      handler={handleDeletion}
      // loadingIndicator={activationLoading}
      title={"Konfirmasi"}
      text={`Hapus item ini?`}
    />
  </div>;
}

export default SurveyQuestionsForm;