import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import {
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBInput,
  MDBSelect,
  MDBSpinner,
  toast
} from "mdbreact";
import InputError from "../../components/InputError";
import { Link } from "react-router-dom";
import BaseRequest from "../../../services/BaseRequest";

const thisUri = `/quiz-answers`;

const formField = [
  { name: "quiz_question_id", rules: { required: true } },
  { name: "answer_id", rules: { required: true } },
  { name: "answer_en" },
  { name: "answer_ar" },
  { name: "is_correct_answer" },
];

const RenderButton = ({ submitLoading }) => {
  if (submitLoading) {
    return <MDBSpinner />;
  }
  return (
    <div>
      <Link to={thisUri}>
        <MDBBtn size="md" color="red" className="mx-0">
          Kembali
        </MDBBtn>
      </Link>
      <MDBBtn type="submit" color="green" size="md">
        Simpan
      </MDBBtn>
    </div>
  );
};

const AnswerFormPage = () => {
  const history = useHistory();
  const { id } = useParams(); // Jika id !== undefined, maka ini adalah fitur edit data
  const refCheckbox = useRef(null);
  const { register, handleSubmit, errors, setValue, watch } = useForm();
  const [submitLoading, setSubmitLoading] = useState(false);

  let [listQuestion, setListQuestion] = useState([]);

  const getQuestion = async () => {
    const uri = `/quiz-questions?sortBy=created_at&desc=true`
    const { data: { data } } = await BaseRequest.post(uri);
    return data.data.map((v, i) => ({
      text: v.question_id,
      value: v.id
    }));
  };

  let quiz_question_id = watch('quiz_question_id')

  const handleChange = e => {
    setValue(e.target.name, e.target.value);
  };

  const handleCorrectAnswerChange = e => {
    setValue(e.target.name, e.target.checked);
  };

  const handleQuestionChange = id => {
    setValue('quiz_question_id', id[0]);
  }

  useEffect(() => {
    for (let v of formField) {
      register({ name: v.name }, v.rules || null);
    }
  }, [register]);

  useEffect(() => {
    if (id) {
      const getDataById = async () => {
        try {
          const {
            data: { data }
          } = await BaseRequest.get(`${thisUri}/${id}/edit`);

          for (let key in data) {
            setValue(key, data[key] || "");
          }

        } catch (error) {
          console.log(error);
        }
      };
      getDataById();
    }
  }, [id, setValue]);

  useEffect(() => {
    if(quiz_question_id){
      getQuestion()
      .then(res => {
        setListQuestion(()=>(
          res.map((v, i) => ({
            ...v,
            checked: (quiz_question_id === v.value)? true :false
          }))
        ))
      })
    }else{
      if(!id){
        getQuestion()
        .then(res => {
          setListQuestion(res)
        })
      }
    }
  },[quiz_question_id, id]);

  const onSubmit = async payload => {
    try {
      setSubmitLoading(true);
      let res = null;

      if(!payload.is_correct_answer) payload.is_correct_answer = false
      if (id) {
        res = await BaseRequest.put(`${thisUri}/${id}`, payload);
      } else {
        res = await BaseRequest.post(`${thisUri}/save`, payload);
      }
      toast.success(res.data.message, {
        closeButton: false,
        position: "bottom-right"
      });
      setTimeout(() => history.push(thisUri), 2000);
    } catch (error) {
      let errorMessage = error.response
        ? error.response.data.message
        : error.message;
      toast.error(errorMessage, {
        closeButton: false,
        position: "bottom-right"
      });
      setSubmitLoading(false);
    }
  };

  return (
    <MDBContainer>
      <MDBRow center>
        <MDBCol size="12" md="9" sm="12">
          <MDBCard>
            <MDBCardBody>
              <MDBCardTitle>
                {id ? "Edit" : "Tambah"} Jawaban
              </MDBCardTitle>
              <hr />
              <form onSubmit={handleSubmit(onSubmit)}>
                <MDBContainer>
                  <MDBRow>
                    <MDBCol size="12">

                      <MDBSelect
                        className={errors.quiz_question_id && "is-invalid"}
                        name="quiz_question_id"
                        getValue={handleQuestionChange}
                        options={listQuestion}
                        selected="Pilihan Pertanyaan"
                        label="Pertanyaan"
                      />
                      {errors.quiz_question_id &&
                        errors.quiz_question_id.type === "required" && (
                          <span className="text-danger small">Tidak boleh kosong</span>
                        )}

                        <MDBInput
                          name="answer_id"
                          onChange={handleChange}
                          type="text"
                          label="Jawaban (Indonesia)"
                          value={watch("answer_id")}
                        >
                        {errors.answer_id &&
                          errors.answer_id.type === "required" && (
                            <InputError text="Tidak boleh kosong" />
                          )}
                        </MDBInput>

                        <MDBInput
                          name="answer_en"
                          onChange={handleChange}
                          type="text"
                          label="Jawaban (English)"
                          value={watch("answer_en")}
                        />

                        <MDBInput
                          name="answer_ar"
                          onChange={handleChange}
                          type="text"
                          label="Jawaban (Arabic)"
                          value={watch("answer_ar")}
                        />

                        <MDBCol md="4" className="mb-3">
                          <div className="custom-control custom-checkbox pl-2">
                            <input
                              className="custom-control-input"
                              type="checkbox"
                              name="is_correct_answer"
                              onChange={handleCorrectAnswerChange}
                              ref={refCheckbox}
                              checked={watch('is_correct_answer')}
                              id="invalidCheck"
                            />
                            <label className="custom-control-label" htmlFor="invalidCheck">
                              Jawaban Benar<br/><i className='small'>Ceklis jika jawaban benar</i>
                            </label>
                          </div>
                        </MDBCol>

                    </MDBCol>
                  </MDBRow>
                </MDBContainer>

                <div className="text-center mt-3 black-text">
                  <RenderButton submitLoading={submitLoading} />
                </div>
              </form>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default AnswerFormPage;
