import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import {
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBInput,
  MDBSpinner,
  toast,
} from "mdbreact";
import InputError from "../../../components/components/InputError";
import { Link } from "react-router-dom";
import BaseRequest from "../../../services/BaseRequest";
import { SketchPicker } from "react-color";

const thisUri = "/e-student-card";
const apiUri = "/ecard-settings";

const RenderButton = ({ submitLoading }) => {
  if (submitLoading) {
    return <MDBSpinner />;
  }
  return (
    <div>
      <Link to={thisUri}>
        <MDBBtn size="md" color="red" className="mx-0">
          Kembali
        </MDBBtn>
      </Link>
      <MDBBtn type="submit" color="green" size="md">
        Simpan
      </MDBBtn>
    </div>
  );
};

const StudentCardFormPage = () => {
  const { register, handleSubmit, errors, setValue, watch } = useForm();
  const [submitLoading, setSubmitLoading] = useState(false);
  const history = useHistory();
  const { id } = useParams();
  const periodFieldValue = watch("period");
  const qrcodeColorFieldValue = watch("qrcode_color");
  const [color, setColor] = useState("0,0,0");

  const handleChange = (e) => {
    setValue(e.target.name, e.target.value);
  };

  useEffect(() => {
    register({ name: "period" }, { required: true });
  }, [register]);

  useEffect(() => {
    if (id) {
      const getDataById = async () => {
        try {
          const {
            data: { data },
          } = await BaseRequest.get(`${apiUri}/${id}/edit`);
          for (let key in data) {
            setValue(key, data[key]);
            setColor(data.qrcode_color);
          }
        } catch (error) {
          console.log(error);
        }
      };
      getDataById();
    }
  }, [id, setValue, qrcodeColorFieldValue]);

  const onSubmit = async (payload) => {
    try {
      setSubmitLoading(true);
      let res;
      if (id) {
        res = await BaseRequest.put(`${apiUri}/${id}`, {
          period: payload.period,
          qrcode_color: color,
        });
      } else {
        res = await BaseRequest.post(`${apiUri}/save`, {
          period: payload.period,
          qrcode_color: color,
        });
      }

      toast.success(res.data.message, {
        closeButton: false,
        position: "bottom-right",
      });
      setTimeout(() => history.push(thisUri), 2000);
    } catch (error) {
      let errorMessage = error.response
        ? error.response.data.message
        : error.message;
      toast.error(errorMessage, {
        closeButton: false,
        position: "bottom-right",
      });
      setSubmitLoading(false);
    }
  };

  return (
    <MDBContainer>
      <MDBRow center>
        <MDBCol size="12" md="9" sm="12">
          <MDBCard>
            <MDBCardBody>
              <MDBCardTitle>
                {id ? "Edit" : "Tambah"} E-Student Card
              </MDBCardTitle>
              <hr />
              <form onSubmit={handleSubmit(onSubmit)}>
                <MDBContainer>
                  <MDBRow>
                    <MDBCol size="12">
                      <MDBInput
                        className={errors.period && "is-invalid"}
                        name="period"
                        onChange={handleChange}
                        type="text"
                        label="Periode"
                        value={periodFieldValue}
                      >
                        {errors.period && errors.period.type === "required" && (
                          <InputError text="Tidak boleh kosong" />
                        )}
                      </MDBInput>

                      <MDBInput
                        name="qrcode_color"
                        type="text"
                        label="Warna QR Code"
                        value={`rgb(${color})`}
                        readOnly
                      >
                      </MDBInput>
                      <SketchPicker
                        name="qrcode_color"
                        color={`rgb(${color})`}
                        onChange={(color) => {
                          setColor(`${color.rgb.r},${color.rgb.g},${color.rgb.b}`)
                        }}
                        value={color}
                      />
                    </MDBCol>
                  </MDBRow>
                </MDBContainer>

                <div className="text-center mt-3 black-text">
                  <RenderButton submitLoading={submitLoading} />
                </div>
              </form>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default StudentCardFormPage;
