import React from "react";
import PropTypes from "prop-types";
import { MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBSpinner, MDBBtn } from "mdbreact";

const ConfirmationDialog = props => {
  const {
    isOpen,
    toggleFunc,
    backdrop,
    title,
    description,
    isLoading,
    onYesClick
  } = props;
  return (
    <MDBModal isOpen={isOpen} toggle={toggleFunc} backdrop={backdrop} centered>
      <MDBModalHeader>{title}</MDBModalHeader>
      <MDBModalBody>{description}</MDBModalBody>
      <MDBModalFooter>
        {isLoading ? (
          <MDBSpinner />
        ) : (
          <>
            <MDBBtn color="secondary" onClick={toggleFunc}>
              Tidak
            </MDBBtn>
            <MDBBtn color="primary" onClick={onYesClick}>
              Iya
            </MDBBtn>
          </>
        )}
      </MDBModalFooter>
    </MDBModal>
  );
};

ConfirmationDialog.propTypes = {
  isOpen: PropTypes.bool,
  toggleFunc: PropTypes.func.isRequired,
  backdrop: PropTypes.bool,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  onYesClick: PropTypes.func.isRequired
};

ConfirmationDialog.defaultProps = {
  isOpen: false,
  backdrop: true,
  isLoading: false
};

export default ConfirmationDialog;
