import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useContext,
} from "react";
import BaseRequest from "../../../../services/BaseRequest";
import { useHistory } from "react-router-dom";
import {
  MDBContainer,
  MDBBtn,
  MDBIcon,
  MDBBtnFixed,
  toast,
  MDBRow,
  MDBCol,
  MDBSelect,
  MDBInput
} from "mdbreact";
import { default as CustomDataTable } from "../../../components/DataTable";
import DeleteModal from "../../../modals/DeleteModal";
import moment from "moment";
import { getGenderOptions, getStatusOptions } from "../../configs/global";
import { AccessContext } from "../../../AccessContext";
import UserGroupMergeModal from "../../users/components/UserGroupMergeModal";

const thisUri = "/kelas/groups";
const apiUri = "/groups";

export const GroupsPage = () => {
  const history = useHistory();

  const menu = "group";
  const access = useContext(AccessContext);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedDeleteItem, setSelectedDeleteItem] = useState({});
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [showMergeModal, setShowMergeModal] = useState(false);

  /** Datatable variables **/
  const [dtRowsData, setDtRowsData] = useState([]);
  const [dtSortBy, setDtSortBy] = useState("");
  const [dtSortDirection, setDtSortDirection] = useState("desc");
  const [dtTotalRow, setDtTotalRow] = useState(1);
  const [dtPage, setDtPage] = useState(1);
  const [dtPerPage, setDtPerPage] = useState(10);
  const [dtSearchMeta, setDtSearchMeta] = useState({
    filter: {},
    field_search: "name_id, name_en, name_ar, classes_id, gender",
    search: "",
  });

  const [listClasses, setListClasses] = useState([]);
  const [loadProgressData, setLoadProgressData] = useState(true);

  /** Datatable column definition **/
  const columns = [
    {
      name: "No",
      selector: "rowNo",
      width: "4rem",
      right: true,
    },
    {
      name: "Nama (ID)",
      selector: "name_id",
      sortable: true,
      style: {
        whiteSpace: "none",
      },
    },
    {
      cell: (row) =>
        row.admin_id ? (
          <a
            href={`/kelas/admin-group/${row.admin_id}/edit`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {row.admin_name || ''}
          </a>
        ) : (
          "-"
        ),
      name: "Admin",
      selector: "admin_id",
    },
    {
      name: "Kelas",
      selector: "class_name",
    },
    {
      name: "IPS",
      selector: "index_score",
    },
    {
      name: "Kuis (Aktif)",
      selector: "quizz_name",
    },
    {
      name: "Nilai Kuis",
      selector: "quizz_score",
    },
    {
      name: "Gender",
      selector: "gender",
      sortable: true,
      width: "8rem",
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
      width: "8rem",
    },
    {
      name: "Dibuat Tanggal",
      selector: "created_at",
      sortable: true,
      width: "8rem",
    },
    {
      name: "Disunting Tanggal",
      selector: "updated_at",
      sortable: true,
      width: "8rem",
    },
    {
      cell: (row) => (
        <div className="btn-group-sm">
          {access.isAccessible(menu, "detail") && (
            <MDBBtn
              variant="contained"
              color="blue"
              onClick={() =>
                history.push(`${thisUri}/${row.classes_id}/${row.id}/detail`)
              }
            >
              <MDBIcon icon="users" />
            </MDBBtn>
          )}
          {access.isAccessible(menu, "edit") && (
            <MDBBtn
              variant="contained"
              color="yellow"
              onClick={() => history.push(`${thisUri}/${row.id}/edit`)}
            >
              <MDBIcon icon="pen-nib" />
            </MDBBtn>
          )}
          {access.isAccessible(menu, "delete") && (
            <MDBBtn
              variant="contained"
              color="red"
              onClick={() => toggleDeleteModal(row.id)}
            >
              <MDBIcon icon="trash" />
            </MDBBtn>
          )}
        </div>
      ),
      button: true,
    },
  ];

  /** Datatable get data **/
  const getGroupData = useCallback(
    async (page = 1, perPage = 10) => {
      let uri = `${apiUri}`;

      try {
        setLoadProgressData(true);

        let response

        /** Saat halaman ini dibuka / refresh, data akan diambil dari /api/groups/getOrdered.
         * Ini requirement baru yg mengharuskan display data disortir perdasarkan kelas terbaru & nama group nya ASC.
         * Ini tidak bisa menggunakan "default" payload. Jadi sy harus pakai endpoint baru.
         */
        if (!dtSortBy && !dtSearchMeta.search) {
          response = await BaseRequest.post(uri + '/getOrdered', {
            page: page,
            limit: perPage,
          });
        } else {
          response = await BaseRequest.post(uri, {
            sortBy: dtSortBy || undefined,
            desc: dtSortDirection === "desc",
            page: page,
            limit: perPage,
            ...dtSearchMeta,
          });
        }

        const { data: { data } } = response;

        setDtTotalRow(data.total);

        // Map the questions data
        const mappedData = data.data.map((v, i) => ({
          rowNo: data.from + i,
          id: v.id,
          classes_id: v.classes_id,
          name_id: v.name_id,
          class_name: v.class_name ? v.class_name : v.classes?.name || '',
          admin_id: v.admin ? v.admin.id : v.admin_id,
          admin_name: v.admin ? v.admin.name : v.admin_name,
          index_score: v.index_score,
          quizz_name: v.quizz_title_id,
          quizz_score: v.score,
          gender: v.gender && getGenderOptions(v.gender)[0].text,
          status: v.status && getStatusOptions(v.status)[0].text,
          created_at: moment(v.created_at).format("DD/MM/YYYY"),
          updated_at: moment(v.updated_at).format("DD/MM/YYYY"),
        }));

        return { rows: mappedData };
      } catch (error) {
        let errorMessage = error.response
          ? error.response.data.message
          : error.message;
        toast.error(errorMessage, {
          closeButton: false,
          position: "bottom-right",
        });
      }

      return { rows: [] };
    },
    [dtSearchMeta, dtSortBy, dtSortDirection]
  );

  const reloadGroupDataFromServer = useCallback(
    (page = 1, perPage = 10) => {
      getGroupData(page, perPage)
        .then((res) => {
          const { rows } = res;

          setDtRowsData(rows);
        })
        .finally(() => {
          setLoadProgressData(false);
        });
    },
    [getGroupData]
  );

  const getListClasses = async () => {
    let uri = `/classes`;

    const { data: { data } } = await BaseRequest.post(uri, {
      sortBy: 'name',
      desc: false,
    });
    return data.data.map((v, i) => ({
      text: v.name,
      value: v.id
    }));
  }

  useEffect(() => {
    getListClasses()
    .then(res => {
      setListClasses(()=>(
        res.map((v, i) => ({
          ...v
        }))
      ))
    })
  },[]);

  useEffect(() => {
    reloadGroupDataFromServer(dtPage, dtPerPage);
  }, [dtPage, dtPerPage, reloadGroupDataFromServer]);

  /** Datatable sorting **/
  const onSortHandler = (column, sortDirection) => {
    setDtSortBy(column.selector);
    setDtSortDirection(sortDirection);

    reloadGroupDataFromServer(dtPage, dtPerPage);
  };

  /** Datatable Filter **/
  const subHeaderComponentMemo = useMemo(() => {
    const handleFilter = (keyword) => {
      setLoadProgressData(true);

      let newSearchMeta = dtSearchMeta;
      newSearchMeta.search = keyword;

      setDtSearchMeta(newSearchMeta);

      getGroupData(newSearchMeta).then((res) => {
        const { rows } = res;

        setDtRowsData(rows);
        setLoadProgressData(false);
      });
    };

    const handleClassesChange = val => {
      handleFilter(val[0])
    }

    const handleGenderChange = val => {
      handleFilter(val[0])
    }

    return (
      <MDBRow className={"w-100"}>
        <MDBCol size={"12"} md={"4"} sm={"12"}>
          <MDBInput
            label="Filter by Nama"
            onChange={(e) => handleFilter(e.target.value)}
            className={"w-100"}
            name="search"
          />
        </MDBCol>
        <MDBCol size={"12"} md={"4"} sm={"12"}>
          <MDBSelect
            className={"w-100"}
            name="classes_id"
            getValue={handleClassesChange}
            options={listClasses}
            selected="Pilihan Kelas"
            label="Kelas"
          />
        </MDBCol>
        <MDBCol size={"12"} md={"4"} sm={"12"}>
          <MDBSelect
            className={"w-100"}
            name="gender"
            getValue={handleGenderChange}
            options={getGenderOptions()}
            selected="Pilihan Gender"
            label="Gender"
          />
        </MDBCol>
      </MDBRow>
    );
  }, [dtSearchMeta, getGroupData, listClasses]);

  const toggleDeleteModal = (id) => {
    setSelectedDeleteItem(id);
    setShowDeleteModal(!showDeleteModal);
  };

  const handleDelete = async () => {
    try {
      setDeleteLoading(true);

      await BaseRequest.delete(`${apiUri}/${selectedDeleteItem}`);
      reloadGroupDataFromServer(dtPage, dtPerPage);
    } catch (err) {
      console.error(err);
    } finally {
      toggleDeleteModal();
      setDeleteLoading(false);
    }
  };

  /** Datatable Change Page handler **/
  const onPageChangeHandler = (page) => {
    setDtPage(page);
  };

  const onPerRowsChangeHandler = (perPage) => {
    setDtPerPage(perPage);
  };

  const toggleMergeModal = () => {
    setShowMergeModal(!showMergeModal);
  };

  const handleMerge = (e) => {
    e.preventDefault();
    toggleMergeModal();
  };


  return (
    <MDBContainer fluid>
      <h3>Group</h3>
      <MDBBtn onClick={handleMerge} outline color="red" size="sm">Penggabungan Grup</MDBBtn>
      <CustomDataTable
        columns={columns}
        defaultSortField=""
        data={dtRowsData}
        totalRow={dtTotalRow}
        onSortHandler={onSortHandler}
        subHeaderComponent={subHeaderComponentMemo}
        progressPending={loadProgressData}
        handlePerRowsChange={onPerRowsChangeHandler}
        handlePageChange={onPageChangeHandler}
      />

      <DeleteModal
        isOpen={showDeleteModal}
        toggle={toggleDeleteModal}
        handler={handleDelete}
        loadingIndicator={deleteLoading}
      />

      <UserGroupMergeModal
        isOpen={showMergeModal}
        toggle={() => setShowMergeModal(!showMergeModal)}
        thisUri = {thisUri}
      />

      {!access.isAccessible(menu, "create") ? null : (
        <MDBBtnFixed
          floating
          color="blue"
          icon="plus"
          size="lg"
          style={{ bottom: "55px", right: "24px" }}
          onClick={() => history.push(`${thisUri}/create`)}
          className="no-li"
        />
        
      )}
      
    </MDBContainer>
  );
};

export default GroupsPage;
