import React, {useCallback, useEffect, useState} from "react";
import {
  MDBBtn,
  MDBCol,
  MDBContainer, MDBIcon,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBRow,
  MDBSpinner,
  MDBTabContent, MDBTabPane
} from "mdbreact";
import {useHistory, useParams} from "react-router";
import BaseRequest from "../../../../services/BaseRequest";

import SubTipeUjianList from "./SubTipeUjianList";
import {Link} from "react-router-dom";

const thisUri = '/perkuliahan/skema-nilai';

const CourseScoreDetail = () => {
  const { id } = useParams();
  const history = useHistory();

  const [skemaNilai, setSkemaNilai] = useState({});
  const [loadingData, setLoadingData] = useState(false);
  const [activeItemJustified, setActiveItemJustified] = useState("1");

  const getSkemaNilaiDetail = useCallback(async () => {
    setLoadingData(true);

    const { data: {
      data
    } } = await BaseRequest.get(`/score-types/${id}/edit?with=course,courseScore`);

    return data;
  }, [id])

  useEffect(() => {
    getSkemaNilaiDetail()
      .then((data) => setSkemaNilai(data))
      .finally(() => setLoadingData(false));
  }, [getSkemaNilaiDetail])

  const toggleJustified = (tabNo) => {
    if (activeItemJustified !== tabNo) {
      setActiveItemJustified(tabNo);
    }
  }

  return (
    <MDBContainer fluid>
      <MDBRow center>
        <h4>Detail Skema Penilaian</h4>
      </MDBRow>
      <MDBRow center>
        <h3 className="pt-1 pb-3 mb-2">
          {skemaNilai && skemaNilai.name}
        </h3>

        <MDBCol size="12" md="12" sm="12">
          {
            (loadingData && skemaNilai) ?
            <div className="text-center">
              <MDBSpinner big />
            </div> :
              <div>
                <MDBNav tabs className="nav-justified" color='blue'>
                  <MDBNavItem>
                    <MDBNavLink to="#" className={activeItemJustified !== "1" ? 'inactive-nav-link' : ''} onClick={() => toggleJustified("1")} role="tab" >
                      Detail Skema Penilaian
                    </MDBNavLink>
                  </MDBNavItem>
                  <MDBNavItem>
                    <MDBNavLink to="#" className={activeItemJustified !== "2" ? 'inactive-nav-link' : ''} onClick={() => toggleJustified("2")} role="tab" >
                      Jenis Ujian
                    </MDBNavLink>
                  </MDBNavItem>
                </MDBNav>

                <MDBTabContent
                  className="card"
                  activeItem={activeItemJustified}
                >
                  {/** Detail Skema **/}
                  <MDBTabPane tabId="1" role="tabpanel">
                    <MDBRow className="mt-2 p-2">
                      {/** Detail Skema **/}
                      <MDBCol>
                        <MDBRow>
                          <MDBCol className="text-center">
                            <h4>Skema Penilaian</h4>
                          </MDBCol>
                        </MDBRow>
                        <MDBRow className="p-2">
                          <MDBCol>Mata Kuliah</MDBCol>
                          <MDBCol>: {
                            skemaNilai.course ?
                              <a href={`/perkuliahan/mata-kuliah/${skemaNilai.course.id}/edit`} target="_blank">
                                { skemaNilai.course.title_id || '-' } <MDBIcon icon="external-link-alt" size="sm"/>
                              </a> : '-'
                          }
                          </MDBCol>
                        </MDBRow>
        
                        <MDBRow className="p-2">
                          <MDBCol>Nama </MDBCol>
                          <MDBCol>: { skemaNilai.name || '-' }</MDBCol>
                        </MDBRow>
                        <MDBRow className="p-2">
                          <MDBCol>Deskripsi</MDBCol>
                          <MDBCol>: { skemaNilai.description || '-' }</MDBCol>
                        </MDBRow>
                        
                        <MDBRow className="p-2">
                          <MDBBtn color="yellow" onClick={() => history.push(`${thisUri}/${id}/edit?from=detail-page&id=${id}`)} size="sm">
                            Edit Skema Penilaian
                          </MDBBtn>
                        </MDBRow>
                      </MDBCol>

                      
                    </MDBRow>
                  </MDBTabPane>

                  {/** Pertemuan **/}
                  <MDBTabPane tabId="2" role="tabpanel">
                    <SubTipeUjianList skemaNilaiID={id} subSkemaNilaiData={skemaNilai.course_score || [] } />
                  </MDBTabPane>

                </MDBTabContent>

                <MDBRow className="mt-3" center>
                  <Link to={thisUri}>
                    <MDBBtn size="md" color="red" className="mx-0">
                      Kembali
                    </MDBBtn>
                  </Link>
                </MDBRow>
              </div>
          }
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}

export default CourseScoreDetail;
