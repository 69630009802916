import React, { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment";
import BaseRequest from "../../../../services/BaseRequest";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBIcon,
  MDBInput,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
  MDBRow,
  MDBSelect,
  MDBSpinner,
  toast,
} from "mdbreact";
import { default as CustomDataTable } from "../../../components/DataTable";
import { debounce } from "@material-ui/core";

const thisUri = "/participants";
const apiUri = "/participants";

const GroupsParticipantPage = () => {
  const history = useHistory();
  const location = useLocation();
  let { classId, groupId } = useParams();
  const [groupLabel, setGroupLabel] = useState("");

  /** Datatable variables **/
  const [dtRowsData, setDtRowsData] = useState([]);
  const [dtSortBy, setDtSortBy] = useState("name");
  const [dtSortDirection, setDtSortDirection] = useState("asc");
  const [dtTotalRow, setDtTotalRow] = useState(1);
  const [dtSearchMeta, setDtSearchMeta] = useState({
    filter: {},
    field_search: "name,nip",
    search: "",
    classId,
    groups: [groupId],
  });
  const [dtPage, setDtPage] = useState(1);
  const [dtPerRow, setDtPerRow] = useState(10);
  const [loadProgressData, setLoadProgressData] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);

  /** Datatable column definition **/
  const columns = [
    {
      name: "No",
      selector: "rowNo",
      width: "4rem",
      right: true,
    },
    {
      cell: (row) => (
        <div>
          <span style={{ color: "rgb(140, 131, 131)" }}>{row.nip}</span>
          <br />
          <span style={{ fontWeight: "bold" }}>
            <span style={{ color: "rgb(48, 139, 218)" }}>{row.name}</span> (
            {row.age}thn)
          </span>
          <br />
          {row.country ? row.country.name : ""}{" "}
          {row.city ? <>| {row.city.name}</> : ""}
        </div>
      ),
      name: "Peserta",
      selector: "name",
      sortable: true,
      grow: 1,
    },
    {
      name: "Kontak",
      selector: "nilai",
      width: "10rem",
    },
    {
      name: "Last Login",
      selector: "last_login_at",
      cell: (row) => (
        <div>
          <span>
            {row.last_login_at || <span className="text-muted">n/a</span>}
          </span>
          {row.last_login_at && (
            <>
              <br />
              <small>{moment(row.last_login_at).fromNow()}</small>
            </>
          )}
        </div>
      ),
      compact: true,
      grow: 0,
    },
    {
      cell: (row) => (
        <div className="btn-group-sm">
          <MDBBtn
            variant="contained"
            color="purple"
            onClick={() => history.push(`${thisUri}/${row.id}/show`)}
          >
            <MDBIcon icon="eye" />
          </MDBBtn>
          <MDBBtn
            variant="contained"
            color="green"
            onClick={() => history.push(`${thisUri}/${row.id}/logs`)}
          >
            <MDBIcon icon="list-alt" />
          </MDBBtn>
          <MDBBtn
            variant="contained"
            color="info"
            onClick={() => history.push(`${thisUri}/${row.id}/transcript`)}
          >
            <MDBIcon icon="graduation-cap" />
          </MDBBtn>
        </div>
      ),
      button: true,
      name: "#",
      grow: 0,
      minWidth: "10rem"
    },
  ];

  const handleSendWA = (item) => {
    let waNumber = item.phone_country_code.replace("+", "") + item.phone;
    window.open(
      `https://api.whatsapp.com/send?phone=${waNumber}&text=`,
      "_blank"
    );
  };

  /** Datatable get data **/
  const getDataParticipants = useCallback(
    async (page = 1, perPage = 10) => {
      let uri = `${apiUri}`;
      let filter = dtSearchMeta;

      if (filter.classId) {
        uri += `/${filter.classId}/by-class`;

        if (filter.groups) {
          filter.groups = filter.groups.filter((el) => {
            return el !== "";
          });
          if (filter.groups.length <= 0) delete filter["groups"];
        }
      }

      try {
        setLoadProgressData(true);

        const {
          data: { data },
        } = await BaseRequest.post(uri, {
          sortBy: dtSortBy,
          desc: dtSortDirection === "desc",
          page: page,
          limit: perPage,
          with: "groups",
          ...filter,
        });

        setDtTotalRow(data.total);
        if (data.total > 0) {
          if (data.data[0].groups.length > 0) {
            setGroupLabel(data.data[0].groups[0].name_id);
          }
        }

        // Map the questions data
        const mappedData = data.data.map((v, i) => ({
          ...v,
          rowNo: data.from + i,
          id: v.id,
          nilai: (
            <div className="btn-group-sm">
              <MDBBtn color="green" onClick={() => handleSendWA(v)}>
                {/*<MDBIcon icon="whatsapp" />*/}Kirim WA
              </MDBBtn>
            </div>
          ),
          groups: v.groups || [],
          created_at: moment(v.created_at).format("DD/MM/YYYY"),
          updated_at: moment(v.updated_at).format("DD/MM/YYYY"),
        }));

        return { rows: mappedData };
      } catch (error) {
        let errorMessage = error.response
          ? error.response.data.message
          : error.message;
        toast.error(errorMessage, {
          closeButton: false,
          position: "bottom-right",
        });
      } finally {
        setLoadProgressData(false);
      }

      return { rows: [] };
    },
    [dtSearchMeta, dtSortBy, dtSortDirection]
  );

  const reloadDataFromServer = useCallback(
    (page = 1, perPage = 10) => {
      getDataParticipants(page, perPage).then((res) => {
        const { rows } = res;

        setDtRowsData(rows);
      });
    },
    [getDataParticipants]
  );

  useEffect(() => {
    let searchMeta = dtSearchMeta;
    searchMeta.filter = {};
    searchMeta.search = "";
    setDtSearchMeta(searchMeta);
  }, [location]);

  useEffect(() => {
    reloadDataFromServer();
  }, [classId, groupId]);

  /** Datatable sorting **/
  const onSortHandler = (column, sortDirection) => {
    setDtSortBy(column.selector);
    setDtSortDirection(sortDirection);

    reloadDataFromServer();
  };

  /** Datatable Filter **/
  const subHeaderComponentMemo = useMemo(() => {
    const handleFilter = debounce((keyword) => {
      let newSearchMeta = dtSearchMeta;
      newSearchMeta.search = keyword;
      setDtSearchMeta(newSearchMeta);

      getDataParticipants(dtPage, dtPerRow).then((res) => {
        const { rows } = res;
        setDtRowsData(rows);
      });
    }, 500);

    return (
      <>
        <MDBRow className={"w-100"}>
          <MDBCol size={"12"} md={"4"} sm={"12"}>
            <MDBInput
              label="Cari Nama / NIP"
              onChange={(e) => handleFilter(e.target.value)}
              name="search"
              autoComplete="off"
            />
          </MDBCol>
        </MDBRow>
        {dtTotalRow > 0 && (
          <small className="font-weight-bolder">
            Menemukan &rarr; {dtTotalRow} peserta
          </small>
        )}
      </>
    );
  }, [dtSearchMeta, getDataParticipants, dtTotalRow]);

  const handlePageChange = (page) => {
    setDtPage(page);
    reloadDataFromServer(page, dtPerRow);
  };

  const handlePerRowsChange = (perRow) => {
    setDtPerRow(perRow);
    reloadDataFromServer(dtPage, perRow);
  };

  const downloadCSV = async () => {
    try {
      setExportLoading(true);
      const {
        data: { data },
      } = await BaseRequest.post(`/groups/${groupId}/csv`);
      const { content, filename } = data;

      const bom = "\uFEFF";
      const a = window.document.createElement("a");
      a.setAttribute(
        "href",
        "data:text/csv; charset=utf-8," +
          bom +
          encodeURIComponent(bom + content)
      );
      a.setAttribute("download", filename);
      window.document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (error) {
      const errorMessage = error.response
        ? error.response.data.message
        : error.message;
      toast.error(errorMessage, {
        closeButton: false,
        position: "bottom-right",
      });
    } finally {
      setExportLoading(false);
    }
  };

  const downloadVCF = async () => {
    try {
      setExportLoading(true);
      const {
        data: { data },
      } = await BaseRequest.post(`/groups/${groupId}/vcf`);
      const { content, filename } = data;

      const a = window.document.createElement("a");
      a.setAttribute(
        "href",
        "data:text/vcard; charset=utf-8," +
          encodeURIComponent(content)
      );
      a.setAttribute("download", filename);
      window.document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (error) {
      const errorMessage = error.response
        ? error.response.data.message
        : error.message;
      toast.error(errorMessage, {
        closeButton: false,
        position: "bottom-right",
      });
    } finally {
      setExportLoading(false);
    }
  };

  return (
    <MDBContainer fluid>
      <MDBBtn
        onClick={() => {
          window.history.back();
        }}
        size="md"
        color="red"
        className="float-left"
        style={{ zIndex: 100 }}
      >
        Kembali
      </MDBBtn>
      {exportLoading ? (
        <span className="ml-4">
          <MDBSpinner />
        </span>
      ) : (
        <MDBDropdown>
          <MDBDropdownToggle caret color="primary">
            Export Data
          </MDBDropdownToggle>
          <MDBDropdownMenu basic>
            <MDBDropdownItem onClick={downloadCSV}>
              Export menjadi CSV
            </MDBDropdownItem>
            <MDBDropdownItem onClick={downloadVCF}>Export menjadi VCF</MDBDropdownItem>
          </MDBDropdownMenu>
        </MDBDropdown>
      )}

      <h3 className="mt-3">Peserta Group {groupLabel}</h3>

      <CustomDataTable
        columns={columns}
        data={dtRowsData}
        defaultSortField={"name"}
        onSortHandler={onSortHandler}
        subHeaderComponent={subHeaderComponentMemo}
        progressPending={loadProgressData}
        totalRow={dtTotalRow}
        handlePageChange={handlePageChange}
        handlePerRowsChange={handlePerRowsChange}
      />
    </MDBContainer>
  );
};

export default GroupsParticipantPage;
