import React from "react";
import { MDBBtn, MDBCard, MDBCardBody, MDBCardText, MDBInput } from "mdbreact";
import { useForm } from "react-hook-form";
import InputError from "../../../../components/InputError";

const GroupingForm = ({ onGrouping, jmlPesertaAktif }) => {
  const { cardStyle } = styles;
  const { register, handleSubmit, errors, watch } = useForm();

  return (
    <MDBCard style={cardStyle}>
      <MDBCardBody>
        <MDBCardText>
          Mohon diisi konfigurasi grupnya terlebih dahulu
        </MDBCardText>
        <form id="groupingform" onSubmit={handleSubmit(onGrouping)}>
          <div className="grey-text">
            <MDBInput
              className={errors.angkatan && "is-invalid"}
              id="angkatan"
              label="Angkatan"
              name="angkatan"
              group
              hint="1"
              outline
              value={watch("angkatan")}
              inputRef={register({
                required: "Tidak boleh kosong",
                maxLength: {
                  value: 1,
                  message: `Max digit = 1`,
                },
                validate: {
                  numeric: (v) => !isNaN(v) || "Harus numeric",
                  positive: (v) => v >= 0 || "Harus positif",
                },
              })}
            >
              {errors.angkatan && <InputError text={errors.angkatan.message} />}
            </MDBInput>

            <MDBInput
              className={errors.tahun_angkatan && "is-invalid"}
              id="tahun_angkatan"
              label="Tahun Angkatan"
              name="tahun_angkatan"
              group
              hint="21"
              outline
              value={watch("tahun_angkatan")}
              inputRef={register({
                required: "Tidak boleh kosong",
                maxLength: {
                  value: 2,
                  message: `Max digit = 2`,
                },
                validate: {
                  numeric: (v) => !isNaN(v) || "Harus numeric",
                  positive: (v) => v >= 0 || "Harus positif",
                },
              })}
            >
              {errors.tahun_angkatan && (
                <InputError text={errors.tahun_angkatan.message} />
              )}
            </MDBInput>
            <MDBInput
              className={errors.kode_kelas && "is-invalid"}
              id="kode_kelas"
              label="Kode Kelas"
              name="kode_kelas"
              group
              hint="01"
              outline
              value={watch("kode_kelas")}
              inputRef={register({
                required: "Tidak boleh kosong",
                maxLength: {
                  value: 2,
                  message: `Max digit = 2`,
                },
                validate: {
                  numeric: (v) => !isNaN(v) || "Harus numeric",
                  positive: (v) => v >= 0 || "Harus positif",
                },
              })}
            >
              {errors.kode_kelas && (
                <InputError text={errors.kode_kelas.message} />
              )}
            </MDBInput>
            <MDBInput
              className={errors.user_per_group && "is-invalid"}
              id="user_per_group"
              label="Kuota per group"
              name="user_per_group"
              group
              hint="250"
              outline
              value={watch("user_per_group")}
              inputRef={register({
                required: "Tidak boleh kosong",
                validate: {
                  numeric: (v) => !isNaN(v) || "Harus numeric",
                  positive: (v) => v >= 0 || "Harus positif",
                },
              })}
            >
              {errors.user_per_group && (
                <InputError text={errors.user_per_group.message} />
              )}
            </MDBInput>
          </div>
          <div className="text-center">
            <MDBBtn color="green" type="submit" disabled={jmlPesertaAktif === 0}>
              { jmlPesertaAktif === 0 ? "Belum Ada Peserta Yang Aktif" : "Submit" }
            </MDBBtn>
          </div>
        </form>
      </MDBCardBody>
    </MDBCard>
  );
};

const styles = {
  cardStyle: {
    width: "100%",
  },
  validationErrorMessageStyle: {
    color: "red",
  },
};

export default GroupingForm;
