import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  toast
} from "mdbreact";
import BaseRequest from "../../../services/BaseRequest";
import {Form} from "../../components";
import {getGenderOptions, getStatusOptions} from "../configs/global";

const thisUri = `/users/user-groups`;
const apiUri = `/users`;

const UserGroupFormPage = () => {
  const history = useHistory();
  const { id } = useParams(); // Jika id !== undefined, maka ini adalah fitur edit data
  const countryId = '105';

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    watch,
    getValues,
    trigger: triggerValidation,
    setError
  } = useForm();

  const [submitLoading, setSubmitLoading] = useState(false);
  const [rolesOption, setRolesOption] = useState([]);

  const formField = [
    {
      name: 'name',
      label: 'Nama',
      rules: { required: true }
    },
    {
      name: 'email',
      rules: { required: true },
      label: 'Email',
      type: 'email'
    },
    {
      name: 'password',
      rules: !id && { required: true },
      label: 'Password',
      type: 'password'
    },
    {
      name: 'nip',
      label: 'NIP',
      rules: { required: true }
    },
    {
      name: 'phone_country_code',
      rules: { required: false },
      label: 'Kode Telepon Negara'
    },
    {
      name: 'phone',
      rules: { required: false },
      label: 'No. Telepon'
    },
    {
      name: 'age',
      rules: { required: true },
      label: 'Umur'
    },
    {
      name: 'country_id',
      rules: { required: true },
      type: 'hidden',
      value: countryId
    },
    {
      name: 'gender',
      type: 'select',
      rules: { required: true },
      label: 'Gender',
      options: getGenderOptions()
    },
    {
      name: 'status',
      type: 'select',
      rules: { required: true },
      label: 'Status',
      options: getStatusOptions()
    },
    {
      name: 'role_id',
      type: 'select',
      rules: { required: true },
      label: 'Role',
      options: rolesOption
    },
  ];

  const getRoles = async () => {
    const uri = "/roles";
    try {
      const {
        data: { data }
      } = await BaseRequest.post(uri, {
        sortBy: 'name',
        desc: true,
        limit: -1,
      });
      
      // const allowedRoleName = [
      //   'admin pusat',
      //   'admin group'
      // ];

      const allRoles = data.data;
      // const allowedRoles = allRoles.filter((role) => {
      //   return allowedRoleName.includes(role.name);
      // });

      const optionRoles = allRoles.map((role) => {
        return {
          text: role.name,
          value: role.id
        }
      });

      setRolesOption(optionRoles);
    } catch(error) {
      let errorMessage = error.response
        ? error.response.data.message
        : error.message;
      toast.error(errorMessage, {
        closeButton: false,
        position: "bottom-right"
      });
    }
  }

  /** Load Roles Option */
  useEffect(() => {
    getRoles();
  }, []);  

  /** Register the form fields **/
  useEffect(() => {
    for (let v of formField) {
      register({ name: v.name }, v.rules || null);
    }
  }, [register]);

  /** Get Mata Kuliah Data **/
  useEffect(() => {
    if (id) {
      const getDataById = async () => {
        try {
          const {
            data: { data }
          } = await BaseRequest.get(`${apiUri}/${id}/edit`);

          return data;
        } catch (error) {
          console.log(error);
        }
      };

      getDataById().then(data => {
        for (let key in data) {
          setValue(key, data[key] || "");
        }
      });
    }
  }, [id, setValue]);

  const onSubmit = async payload => {
    setSubmitLoading(true);
    let res;

    if (id) {
      res = await BaseRequest.put(`${apiUri}/${id}`, payload);
    } else {
      res = await BaseRequest.post(`${apiUri}/save`, payload);
    }

    toast.success(res.data.message, {
      closeButton: false,
      position: "bottom-right"
    });

    setTimeout(() => history.push(thisUri), 2000);
  };

  /** Define form fields **/
  const getFormFields = () => {
    return formField.map((field) => ({ 
      ...field, 
      error: errors[field.name] 
    }));
  };

  /** Input text change handler **/
  const handleInputTextChange = (e) => {
    setValue('country_id', countryId);
    const { name, value } = e.target;
    setValue(name, value);
  };

  /** Select Input change handler **/
  const handleSelectChange = (field, value) => {
    if (field && value) {
      setValue(field, value);
    }
  };

  return (
    <MDBContainer>
      <MDBRow center>
        <MDBCol size="12" md="12" sm="12">
          <h3 className="text-center pt-4 pb-5 mb-2">
            {id ? "Edit" : "Tambah"} User Group
          </h3>

          <Form
            onSubmit={handleSubmit(onSubmit)}
            fields={getFormFields()}
            watcher={watch}
            onInputTextChange={handleInputTextChange}
            onSelectChange={handleSelectChange}
            submitLoading={submitLoading}
            setSubmitLoading={setSubmitLoading}
            values={getValues()}
            backUri={thisUri}
            triggerValidation={triggerValidation}
            setError={setError}
          />
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default UserGroupFormPage;
