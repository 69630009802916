import React from "react";
import {MDBBtn, MDBIcon, MDBModal, MDBModalBody, MDBModalFooter, MDBModalHeader, MDBSpinner} from "mdbreact";

const DeleteModal = (props) => {
  const { isOpen, toggle, handler, loadingIndicator, message } = props;

  let deleteMessage = message || "Anda akan menghapus data ini. Lanjutkan ?";

  return (
    <MDBModal
      isOpen={isOpen}
      toggle={toggle}
      backdrop={false}
      cascading
    >
      <MDBModalHeader
        toggle={toggle}
        titleClass="d-inline title"
        className="text-center red darken-3 white-text"
      >
        <MDBIcon icon="trash" className={"pr-2"}/>
        Konfirmasi Penghapusan
      </MDBModalHeader>
      <MDBModalBody>{deleteMessage}</MDBModalBody>
      <MDBModalFooter>
        {
          loadingIndicator ?
          <MDBSpinner /> :
          <>
            <MDBBtn color="secondary" onClick={toggle}>
              Tidak
            </MDBBtn>
            <MDBBtn color="primary" onClick={handler}>
              Iya
            </MDBBtn>
          </>
        }
      </MDBModalFooter>
    </MDBModal>
  );
};

export default DeleteModal;
