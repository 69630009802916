import React, {useCallback, useEffect, useMemo, useState} from "react";
import {MDBBtn, MDBBtnFixed, MDBCol, MDBContainer, MDBIcon, MDBRow, toast} from "mdbreact";
import {default as CustomDataTable} from "../../../../components/DataTable";
import DeleteModal from "../../../../modals/DeleteModal";
import BaseRequest from "../../../../../services/BaseRequest";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import {useHistory} from "react-router-dom";
const quizCategory = require("../../shared/json/quiz-category.json");

const thisUri = "/kurikulum/ujian-evaluasi/pengaturan";
const apiUri = "/quiz-configurations";

const PengaturanPage = () => {
  const history = useHistory();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedDeleteItem, setSelectedDeleteItem] = useState({});
  const [deleteLoading, setDeleteLoading] = useState(false);

  /** Datatable variables **/
  const [ dtRowsData, setDtRowsData ] = useState([]);
  const [ dtSortBy, setDtSortBy ] = useState('created_at');
  const [ dtSortDirection, setDtSortDirection ] = useState('desc');
  const [ dtTotalRow, setDtTotalRow ] = useState(1);
  const [ dtPage, setDtPage ] = useState(1);
  const [ dtPerPage, setDtPerPage ] = useState(10);
  const [ dtSearchMeta, setDtSearchMeta ] = useState({
    filter: {},
    field_search: 'type',
    search: ''
  });
  const [ loadProgressData, setLoadProgressData ] = useState(true);

  /** Datatable column definition **/
  const columns = [
    {
      name: 'No',
      selector: 'rowNo',
      width: "4rem",
      right: true
    },
    {
      name: "Tipe Soal",
      selector: "tipe_soal",
      sortable: true
    },
    {
      name: "Jumlah Pertanyaan",
      selector: "number_of_questions",
      sortable: true
    },
    {
      name: "Dibuat Tanggal",
      selector: "created_at",
      width: "8rem",
      sortable: true
    },
    {
      name: "Disunting Tanggal",
      selector: "updated_at",
      width: "8rem",
      sortable: true
    },
    {
      cell: (row) =>
        <div className="btn-group-sm">
          <MDBBtn variant="contained" color="yellow" onClick={() => history.push(`${thisUri}/${row.id}/edit`)} >
            <MDBIcon icon="pen-nib" />
          </MDBBtn>
          <MDBBtn variant="contained" color="red" onClick={() => toggleDeleteModal(row.id)}>
            <MDBIcon icon="trash" />
          </MDBBtn>
        </div>,
      button: true,
    }
  ];

  /** Datatable get data **/
  const getQuizConfigurationData = useCallback(async (page = 1, perPage = 10) => {
    let uri = `${apiUri}`;

    try {
      setLoadProgressData(true);

      const {
        data: { data }
      } = await BaseRequest.post(uri, {
        sortBy: dtSortBy,
        desc: dtSortDirection === 'desc',
        page: page,
        limit: perPage,
        ...dtSearchMeta
      });

      setDtTotalRow(data.total);

      // Map the questions data
      const mappedData = data.data.map((v, i) => {
        let tipeSoal = quizCategory.filter(category => category.value === v.type);
        tipeSoal = tipeSoal.length > 0 ? tipeSoal[0].text : v.type;

        return {
          ...v,
          rowNo: data.from + i,
          tipe_soal: tipeSoal,
          created_at: moment(v.created_at).format("DD/MM/YYYY"),
          updated_at: moment(v.updated_at).format("DD/MM/YYYY")
        }
      });

      return { rows: mappedData }
    } catch (error) {
      let errorMessage = error.response
        ? error.response.data.message
        : error.message;
      toast.error(errorMessage, {
        closeButton: false,
        position: "bottom-right"
      });
    }

    return { rows: [] }
  }, [dtSearchMeta, dtSortBy, dtSortDirection]);

  const reloadQuizDataFromServer = useCallback((page = 1, perPage = 10) => {
    getQuizConfigurationData(page, perPage)
      .then((res) => {
        const { rows } = res;

        setDtRowsData(rows);
      })
      .finally(() => {
        setLoadProgressData(false);
      });
  }, [getQuizConfigurationData]);

  useEffect(() => {
    reloadQuizDataFromServer(dtPage, dtPerPage);
  }, [dtPage, dtPerPage]);

  /** Datatable sorting **/
  const onSortHandler = (column, sortDirection) => {
    setDtSortBy(column.selector);
    setDtSortDirection(sortDirection);

    reloadQuizDataFromServer(dtPage, dtPerPage);
  };

  /** Datatable Filter **/
  const subHeaderComponentMemo = useMemo(() => {
    const handleFilter = (keyword) => {
      setLoadProgressData(true);

      let newSearchMeta = dtSearchMeta;
      newSearchMeta.search = keyword;

      setDtSearchMeta(newSearchMeta);

      getQuizConfigurationData(newSearchMeta)
        .then((res) => {
          const { rows } = res;

          setDtRowsData(rows);
          setLoadProgressData(false);
        })
    };

    return (
      <MDBRow className={"w-100"}>
        <MDBCol size={"12"} md={"4"} sm={"12"}>
          <TextField
            label="Filter by Tipe Soal"
            onChange={(e) => handleFilter(e.target.value)}
            className={"w-100"}
            name="search"
          />
        </MDBCol>
      </MDBRow>
    )
  }, [dtSearchMeta]);

  const toggleDeleteModal = (id) => {
    setSelectedDeleteItem(id);
    setShowDeleteModal(!showDeleteModal);
  }

  const handleDelete = async () => {
    try {
      setDeleteLoading(true);

      await BaseRequest.delete(`${apiUri}/${selectedDeleteItem}`);
      reloadQuizDataFromServer(dtPage, dtPerPage);
    } catch (err) {
      console.error(err);
    } finally {
      toggleDeleteModal();
      setDeleteLoading(false);
    }
  };

  /** Datatable Change Page handler **/
  const onPageChangeHandler = (page) => {
    setLoadProgressData(true);
    setDtPage(page)
  }

  const onPerRowsChangeHandler = (perPage, page) => {
    setLoadProgressData(true);
    setDtPage(page);
    setDtPerPage(perPage);
  }

  return (
    <MDBContainer fluid>
      <h3>Pengaturan Ujian</h3>

      <div>
        <CustomDataTable
          columns={columns}
          data={dtRowsData}
          totalRow={dtTotalRow}
          onSortHandler={onSortHandler}
          subHeaderComponent={subHeaderComponentMemo}
          progressPending={loadProgressData}
          handlePerRowsChange={onPerRowsChangeHandler}
          handlePageChange={onPageChangeHandler}
        />
      </div>

      <MDBBtnFixed
        floating
        color="blue"
        icon="plus"
        size="lg"
        style={{bottom: "55px", right: "24px"}}
        onClick={() => history.push(`${thisUri}/create`)}
        className="no-li"
      />

      <DeleteModal
        isOpen={showDeleteModal}
        toggle={toggleDeleteModal}
        handler={handleDelete}
        loadingIndicator={deleteLoading}
      />
    </MDBContainer>
  );
}

export default PengaturanPage;
