/**
 * This Component is based on react-data-table-component
 * URL: https://www.npmjs.com/package/react-data-table-component#header
 * Sample: https://jbetancur.github.io/react-data-table-component/?path=/story/general--kitchen-sink
 * */

import React from "react";
import { makeStyles } from "@material-ui/styles";
import LinearProgress from "@material-ui/core/es/LinearProgress/LinearProgress";
import { default as RDTDataTable } from "react-data-table-component";

const DataTable = (props) => {
  const {
    columns,
    data,
    totalRow,
    defaultSortField = "created_at",
    defaultSortDir = "asc",
    onSortHandler,
    progressPending,
    subHeaderComponent,
    handlePageChange,
    handlePerRowsChange,
    serverSide = true,
    paginationPerPage = 10,
    paginationRowsPerPageOptions = [10, 15, 20, 25, 30],
    expandableRows = false,
    expandableRowsComponent,
    hasDropDownFilter = false,
    noDataComponent = "Data tidak ditemukan",
    noHeader = false,
    noRowsPerPage = false,
    selectableRows = false,
    onSelectedRowsChange,
    contextActions,
  } = props;

  /**
   * Datatable custom style
   * URL: https://github.com/jbetancur/react-data-table-component/blob/master/src/DataTable/styles.js
   * */
  const dataTableCustomStyle = {
    header: {
      style: {
        display: `${noHeader ? "none" : "flex"} !important`,
      },
    },
    tableWrapper: {
      style: {
        minHeight: hasDropDownFilter ? "60vh" : "",
      },
    },
    subHeader: {
      style: {
        paddingLeft: ".5rem",
        marginBottom: "1rem",
        display: `${noHeader ? "none" : "flex"} !important`,
      },
    },
    headCells: {
      style: {
        fontSize: "1rem",
      },
    },
    rows: {
      style: {
        fontSize: "0.9rem",
      },
    },
    noData: {
      style: {
        marginTop: "1rem",
        whiteSpace: "pre-wrap",
        textAlign: "center",
        paddingBottom: ".5rem",
      },
    },
  };

  /** Datatable progress indicator **/
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing,
      },
    },
  }));

  const LinearIndeterminate = () => {
    const classes = useStyles();

    return (
      <div className={classes.root}>
        <LinearProgress />
      </div>
    );
  };

  let expandRowComponent = expandableRows ? expandableRowsComponent : undefined;

  return (
    <div className={"data-table"}>
      {serverSide ? (
        <RDTDataTable
          /* Styling */
          noHeader={!selectableRows}
          customStyles={dataTableCustomStyle}
          persistTableHead
          highlightOnHover
          striped
          /* Sub Header */
          subHeader
          subHeaderAlign="left"
          subHeaderComponent={subHeaderComponent}
          /* Progress Indicator */
          progressComponent={<LinearIndeterminate />}
          progressPending={progressPending}
          /* Data */
          columns={columns}
          data={data}
          noDataComponent={noDataComponent}
          /* Sorting */
          defaultSortField={defaultSortField}
          defaultSortAsc={defaultSortDir === "asc"}
          onSort={onSortHandler}
          sortServer
          /* Pagination */
          pagination
          paginationServer
          paginationTotalRows={totalRow}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          paginationPerPage={paginationPerPage}
          paginationRowsPerPageOptions={paginationRowsPerPageOptions}
          paginationComponentOptions={{ noRowsPerPage: noRowsPerPage }}
          /* Expandable Row */
          expandableRows={expandableRows}
          expandableRowsComponent={expandRowComponent}
          selectableRows={selectableRows}
          onSelectedRowsChange={selectableRows && onSelectedRowsChange}
          contextActions={selectableRows && contextActions}
        />
      ) : (
        <RDTDataTable
          /* Styling */
          noHeader
          customStyles={dataTableCustomStyle}
          persistTableHead
          highlightOnHover
          /* Sub Header */
          subHeader
          subHeaderAlign="left"
          subHeaderComponent={subHeaderComponent}
          /* Progress Indicator */
          progressComponent={<LinearIndeterminate />}
          progressPending={progressPending}
          /* Data */
          columns={columns}
          data={data}
          noDataComponent={"Data tidak ditemukan"}
          /* Sorting */
          defaultSortField={defaultSortField}
          /* Pagination */
          pagination
          paginationPerPage={paginationPerPage}
          paginationRowsPerPageOptions={paginationRowsPerPageOptions}
        />
      )}
    </div>
  );
};

export default DataTable;
