import React, { useEffect, useState, useCallback, useMemo } from 'react';
import BaseRequest from "../../../../services/BaseRequest";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBSelect,
} from "mdbreact";
import { default as CustomDataTable } from "../../../components/DataTable";

const ParticipantLogPage = () => {
  /** Datatable variables **/
  const [id, setId] = useState('a');
  const [dtRowsData, setDtRowsData] = useState([]);
  const [dtTotalRow, setDtTotalRow] = useState(11);
  const [loadProgressData, setLoadProgressData] = useState(true);
  const [dtPage, setDtPage] = useState(1);
  const [dtPerPage, setDtPerPage] = useState(10);
  const [dtSearchMeta, setDtSearchMeta] = useState({
    filter: {
      status: 'active',
      gender: 'ikhwan'
    },
    field_search: 'name,nip',
    search: '',
    classId: null,
    groups: [],
    sortBy: 'name',
    desc: false,
    page: 1,
    limit: 10,
    with: 'groups'
  });
  const [participants, setParticipants] = useState([]);
  const [gender, setGender] = useState('ikhwan');
  const genders = [ { text: "ikhwan", value: "ikhwan" }, { text: "akhwat", value: "akhwat" } ];

  /** Datatable column definition */
  const columns = [
    { name: "No", selector: "rowNo", width: "4rem", right: true },
    { name: "Time", selector: "time" },
    { name: "User Full Name / NIM", selector: "student" },
    { name: "Event Contex", selector: "function" },
    { name: "Deskripsi", selector: "description" },
    { name: "Origin (Web/App)", selector: "app" },
    { name: "IP Adress", selector: "ip_address" },
  ];

  /** data-static */
  const _staticData = [
    { rowNo: 1, time: "10-Oct-2021 20:01", student: "100101 - Januar", function: "Logs", description: "View", app: "Web", ip_address: "127.0.0.1" },
    { rowNo: 2, time: "10-Oct-2021 20:01", student: "100101 - Januar", function: "Logs", description: "View", app: "Web", ip_address: "127.0.0.1" },
    { rowNo: 3, time: "10-Oct-2021 20:01", student: "100101 - Januar", function: "Logs", description: "View", app: "Web", ip_address: "127.0.0.1" },
    { rowNo: 4, time: "10-Oct-2021 20:01", student: "100101 - Januar", function: "Logs", description: "View", app: "Web", ip_address: "127.0.0.1" },
    { rowNo: 5, time: "10-Oct-2021 20:01", student: "100101 - Januar", function: "Logs", description: "View", app: "Web", ip_address: "127.0.0.1" },
    { rowNo: 6, time: "10-Oct-2021 20:01", student: "100101 - Januar", function: "Logs", description: "View", app: "Web", ip_address: "127.0.0.1" },
    { rowNo: 7, time: "10-Oct-2021 20:01", student: "100101 - Januar", function: "Logs", description: "View", app: "Web", ip_address: "127.0.0.1" },
    { rowNo: 8, time: "10-Oct-2021 20:01", student: "100101 - Januar", function: "Logs", description: "View", app: "Web", ip_address: "127.0.0.1" },
    { rowNo: 9, time: "10-Oct-2021 20:01", student: "100101 - Januar", function: "Logs", description: "View", app: "Web", ip_address: "127.0.0.1" },
    { rowNo: 10, time: "10-Oct-2021 20:01", student: "100101 - Januar", function: "Logs", description: "View", app: "Web", ip_address: "127.0.0.1" },
    { rowNo: 11, time: "10-Oct-2021 20:01", student: "100101 - Januar", function: "Logs", description: "View", app: "Web", ip_address: "127.0.0.1" },
  ];

  useEffect(() => {
    _reloadQuizDataFromServer(dtPerPage, dtPerPage);
  }, [id]);

  useEffect(() => {
    getDataParticipants(gender)
  }, [gender]);

  /** Datatable get data **/
  const _getQuizData = useCallback(
    async (page = 1, perPage = 10) => {
      const from = ((page-1) * perPage);
      const to = (page * perPage);
      const rows = [];

      try {
        let uri = `user-log/${id}/activities`;
        setLoadProgressData(true);

        const {
          data: { data }
        } = await BaseRequest.get(uri, {
          page: page,
          limit: perPage
        });

        setDtTotalRow(data.total);
        const _data = data.map((v, i) => ({
          rowNo: from + i + 1,
          time: v.date_time,
          student: v.user_id,
          function: v.event_context,
          description: v.description,
          app: v.app,
          ip_address: v.ip_address
        }));

        return { rows : _data };
      } catch(e) {
        console.log('Astagfirullah error')
        console.log(e)
      }

      return { rows: rows }
    }, []
  ); // Datatable get data

  const _reloadQuizDataFromServer = useCallback(
    (page = 1, perPage = 10) => {
      _getQuizData(page, perPage)
        .then((res) => {
          const { rows } = res;
          setDtRowsData(rows);
        })
        .finally(() => {
          setLoadProgressData(false);
        });
    },
    [_getQuizData]
  );

  /** Datatable Change Page handler **/
  const onPageChangeHandler = (page) => {
    setLoadProgressData(true);
    _reloadQuizDataFromServer(page);
  };

  /** Get Participants */
  const getDataParticipants = useCallback(async (gender) => {
    let filter = dtSearchMeta;
    filter.filter.gender = gender;

    console.log(gender)
    if(!gender) return;

    const {
      data: {data}
    } = await BaseRequest.post('/participants', filter);

    const mappedData = data.data.map(row => ({
      text: row.name,
      value: row.id
    }));
    
    setParticipants(mappedData)
  },[]); // end Get Participants

  /** Datatable Filter */
  const subHeaderComponentMemo = useMemo(() => {
    return (
      <>
        <MDBRow className={"w-100"}>
          <MDBCol size={"12"} md={"4"} sm={"12"}>
            <MDBSelect
              label="Ikhwan / Akhwat"
              getValue={(val) => setGender(val[0])}
              name="inputGender"
              className={"w-100"}
              options={genders}
            />
          </MDBCol>
          <MDBCol size={"12"} md={"4"} sm={"12"}>
            <MDBSelect
              label="Cari Nama / NIP"
              getValue={(val) => setId(val[0])}
              name="search"
              className={"w-100"}
              options={participants}
            />
          </MDBCol>
        </MDBRow>
      </>
    )
  }, [participants]); // end Datatable Filter

  return (
    <MDBContainer fluid>
      <h3>Log Aktifitas Peserta</h3>
      <CustomDataTable 
        columns={columns}
        data={dtRowsData}
        totalRow={dtTotalRow}
        subHeaderComponent={subHeaderComponentMemo}
        progressPending={loadProgressData}
        handlePageChange={onPageChangeHandler}
      />
    </MDBContainer>
  );
}

export default ParticipantLogPage;