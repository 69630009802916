import React, {useCallback, useEffect, useMemo, useState, useContext} from "react";
import moment from "moment";
import { useForm } from "react-hook-form";
import {useHistory, useLocation} from "react-router-dom";
import {MDBBtn, MDBBtnFixed, MDBCol, MDBContainer, MDBIcon, MDBInput, MDBRow, MDBSelect, toast} from "mdbreact";

import BaseRequest from "../../../../services/BaseRequest";
import {Form} from "../../../components";
import {getDefaultTrueFalseOptions} from "../../configs/global";

const thisUri = `/perkuliahan/mata-kuliah`;
const apiUri = `/courses`;

const MataKuliahPasteFormPage = () => {
    const history = useHistory();
    const location = useLocation();
    const {
        register,
        handleSubmit,
        errors,
        setValue,
        watch,
        getValues,
        trigger: triggerValidation
      } = useForm();
    const [submitLoading, setSubmitLoading] = useState(false);
    const [courseCategoryOptions, setCourseCategoryOptions] = useState([]);
    const [classOptions, setClassOptions] = useState([]);
    const [courseOptions, setCourseOptions] = useState([]);
    const [specialAssesmentOptions, setSpecialAssesmentOptions] = useState([]);
    const formField = [
        {
            name: 'course_category_id',
            label: 'Pilih Kategori Mata Kuliah',
            rules: { required: true },
            type: 'select',
            options: courseCategoryOptions,
        },
        {
            name: 'classes_id',
            label: 'Pilih Kelas/Angkatan',
            rules: { required: true },
            type: 'select',
            options: classOptions,
        },
        {
            name: 'from_course_id',
            label: 'Pilih Mata Kuliah Yang Ingin Digunakan',
            rules: { required: true },
            type: 'select',
            options: courseOptions,
        },
        {
            name: 'title_id',
            rules: { required: true },
            label: 'Judul (Indonesia)'
        },
        {
            name: 'code',
            rules: { required: true },
            label: 'Kode Mata Kuliah'
        },
        {
            name: 'special_assessment',
            rules: { required: true },
            label: 'Mata Kuliah Khusus',
            type: 'select',
            options: specialAssesmentOptions,
        },
        {
            name: 'credits',
            rules: { required: true },
            label: 'SKS',
            type: 'number'
        },
    ];

    /** Register the form fields **/
    useEffect(() => {
        for (let v of formField) {
        if(typeof v.name !== 'undefined'){
            register({ name: v.name }, v.rules || null);
        }
        }
    }, [register]);

    /** Define form fields **/
    const getFormFields = () => {
        return formField.map((field) => ({ ...field, error: errors[field.name] }));
    };

    /** Input text change handler **/
    const handleInputTextChange = (e) => {
        const { name, value } = e.target;
        setValue(name, value);
    };

    /** Select Input change handler **/
    const handleSelectChange = (field, value) => {
        if (field && value) {
        setValue(field, value);
        }
    };

    useEffect(() => {
        getCourseCategoryOptions().then(res => {
            setCourseCategoryOptions(res);
        });

        getClassOptions().then(res => {
            setClassOptions(res);
        });

        getCourseOptions().then(res => {
            setCourseOptions(res);
        });

        setSpecialAssesmentOptions(getDefaultTrueFalseOptions());
    },[]);

    const getCourseCategoryOptions = async () => {
        const uri = `/course-categories`
        const { data: { data } } = await BaseRequest.post(uri, {
            sortBy: 'title_id',
            desc: false
        });

        return data.data.map((v, i) => ({
            text: v.title_id,
            value: v.id
        }));
    }

    const getClassOptions = async () => {
        const uri = `/classes`
        const { data: { data } } = await BaseRequest.post(uri, {
            sortBy: 'name',
            desc: false
        });

        return data.data.map((v, i) => ({
            text: v.name,
            value: v.id
        }));
    }

    const getCourseOptions = async() => {
        const uri = `/courses`;
        const { data: { data } } = await BaseRequest.post(uri, {
            sortBy: 'created_at',
            desc: true
        });

        return data.data.map((v, i) => ({
            text: v.title_id,
            value: v.id
        }));
    }

    const onSubmit = async payload => {
        try {
            setSubmitLoading(true);
            console.log(payload);
            const apiUri = `/courses/from-existing`;
            const res = await BaseRequest.post(apiUri, payload);
            console.log(res);
            toast.success(res.data.message, {
                closeButton: false,
                position: "bottom-right"
            });

            setTimeout(() => history.push(thisUri), 2000);
        } catch (error) {
            let errorMessage = error.response
                ? error.response.data.message
                : error.message;
            toast.error(errorMessage, {
                closeButton: false,
                position: "bottom-right"
            });
        } finally {
            setSubmitLoading(false);
        }
    };

    return(
        <MDBContainer>
            <MDBRow center>
                <MDBCol size="12" md="12" sm="12">
                    <h3 className="text-center pt-4 pb-5 mb-2">
                        Tambah Mata Kuliah Dari Bank Data
                    </h3>
                    <Form
                        onSubmit={handleSubmit(onSubmit)}
                        fields={getFormFields()}
                        watcher={watch}
                        onInputTextChange={handleInputTextChange}
                        onSelectChange={handleSelectChange}
                        submitLoading={submitLoading}
                        values={getValues()}
                        backUri={thisUri}
                        triggerValidation={triggerValidation}
                    />
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );
};

export default MataKuliahPasteFormPage;