import React, {useCallback, useEffect, useState} from "react";
import {MDBContainer, toast} from "mdbreact";
import {default as CustomDataTable} from "../../components/DataTable";
import BaseRequest from "../../../services/BaseRequest";
import {useHistory, useParams} from "react-router-dom";
import moment from 'moment';

const SurveyTextReportPage = () => {
  const history = useHistory();
  const {id} = useParams();

  /** Datatable variables **/
  const [ loadProgressData, setLoadProgressData ] = useState(true);
  const [ dtRowsData, setDtRowsData ] = useState([]);
  const [ dtTotalRow, setDtTotalRow ] = useState(1);
  const [ dtPage, setDtPage ] = useState(1);
  const [ dtPerPage, setDtPerPage ] = useState(10);

  /** Datatable column definition **/
  const columns = [
    {
      name: 'No',
      selector: 'rowNo',
      width: "4rem",
      right: true
    },
    {
      name: "Jawaban",
      selector: "text_value",
      cell: (row) => {
        return <div>
          <div style={{fontSize:12}}>{moment(row.created_at).format("DD MMM YYYY")} - {row.user?.name} ({row.user?.nip})</div>
          <p>{row.text_value}</p>
        </div>
      }
    },
  ];

  /** Datatable get data **/
  const getReports = useCallback(async (page = 1, perPage = 20) => {
    try {
      setLoadProgressData(true);
      const {
        data: { data }
      } = await BaseRequest.post('/surveys/textreports', {
        page: page,
        filter: {
          question_id: id
        },
        with: 'user'
      });

      setDtTotalRow(data.total);

      // Map the questions data
      const mappedData = data.data.map((v, i) => {
        return{
          ...v,
          rowNo: data.from + i,
        }
      });

      return { rows: mappedData }
    } catch (error) {
      let errorMessage = error.response
        ? error.response.data.message
        : error.message;
      toast.error(errorMessage, {
        closeButton: false,
        position: "bottom-right"
      });
    }

    return { rows: [] }
  }, []);

  const reloadDataFromServer = useCallback((page = 1, perPage = 10) => {
    getReports(page, perPage)
      .then((res) => {
        const { rows } = res;

        setDtRowsData(rows);
      })
      .finally(() => {
        setLoadProgressData(false);
      });
  }, [getReports]);

  useEffect(() => {
    reloadDataFromServer(dtPage, dtPerPage);
  }, [reloadDataFromServer, dtPage, dtPerPage]);

  /** Datatable Change Page handler **/
  const onPageChangeHandler = (page) => {
    setDtPage(page)
  }

  const onPerRowsChangeHandler = (perPage) => {
    setDtPerPage(perPage)
  }

  return (
    <MDBContainer fluid>
      <h3 className="text-center">Text Report</h3>
      <CustomDataTable
        noHeader={true}
        columns={columns}
        data={dtRowsData}
        totalRow={dtTotalRow}
        progressPending={loadProgressData}
        handlePerRowsChange={onPerRowsChangeHandler}
        handlePageChange={onPageChangeHandler}
        expandableRows={false}      
      />
    </MDBContainer>
  );
}

export default SurveyTextReportPage;
